import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BtnSeleccion, BtnCancelar, BtnEncender, BtnApagar } from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
import moment from 'moment';
import { consulta, crear_form_data, formulario, mostrarError } from '../../../global/js/funciones';
import { actualizarMensaje, mostrarModulo } from "../../../redux/actions/actGlobal";
import { connect } from 'react-redux';

class Listar_Asignaturas extends Component {
    constructor(props) {
        super(props)
        this.state = {
          relacion:{},
          cambios:false,
        }
      }

	render() {
        let array=[]
		return (
			<div>
			    <ListarDatos
                    id='tbl_lista_asignaturas'
                    datos={array}
                    opciones={true}
                    titulo='Reporte Profesores'
                    buscar={true}
                    filtrar={false}
                    fila_principal={({ nombre }) => `${nombre}`.toUpperCase()}
                    filas={[                
                        { 'mostrar': ({ codigo }) => `${codigo}`,
                        'id': 'codigo', 'enLista': true
                        },
                    ]}
                />
			</div>
		);
	}
}

const mapStateToProps = state => {
    let { usuario } = state.redGlobal;
  let { ModalAdd } = state.redSisef;
          return { usuario, ModalAdd }
  }
  
  const mapDispatchToProps = { 
    mostrarModulo,
    actualizarMensaje
  }
      
  Listar_Asignaturas.propTypes = {
    //Funciones
    ModalAdd: PropTypes.bool.isRequired,
    mostrarModulo: PropTypes.func.isRequired,
    actualizarMensaje: PropTypes.func.isRequired,
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Listar_Asignaturas);