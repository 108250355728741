import React, { Component } from 'react'
import { Checkbox, Select, MenuItem, FormControlLabel, Dialog, DialogContent, DialogActions, Grid, TextField, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import AppBarModal from '../../../general/AppBarModal';
import { BtnForm, BtnFormPage } from '../../../general/BotonesAccion';
import TareasTerminadas from '../../../general/TareasTerminadas';
import { ValidatorForm } from "react-material-ui-form-validator";
import { validateInputs, obtenerValoresFiltros, getError, consulta } from "../../../../global/js/funciones";
import emma_w from '../../../../global/imagenes/emma_w.png';
import { CODIGO_SUBTIPO_CONVENIO_OTRO, TIPOS_SOLICITUD_CONVENIOS, PAISES, SUBTIPOS_CONVENIOS, ACREDITACIONES } from '../helper'
import ConveniosBuscarRanking from '../../convenios/ConveniosBuscarRanking';
import InputBuscar from '../../../general/InputBuscar';
import { Alert } from "@material-ui/lab";
import AgregarValor from '../../ui/AgregarValor';


function AgregarPosicion({
  open,
  show,
  posicion,
  onChange,
  guardarPosicion,
  guardarPosicionRango,
  check_rango_ranking,
  checked,
  posicion_una,
  posicion_dos,
  errores,
}) {
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={() => show(false)}
    >
      <AppBarModal
        titulo="Agregar Posición"
        mostrarModal={() => show(false)}
        titulo_accion=""
      />
      <DialogContent className="scroll">
        <Grid item xs={12} md={12}>
          <FormControl fullWidth style={{ marginTop: "10px" }}>
            {posicion.length > 0 ?
              <Grid item xs={12} style={{ padding: "0 12px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check_rango_ranking}
                      onChange={checked}
                      id="check_rango_ranking"
                      name="check_rango_ranking"
                      color="primary"
                      disabled
                    />
                  }
                  label="¿Rango?"
                />
              </Grid> : <Grid item xs={12} style={{ padding: "0 12px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check_rango_ranking}
                      onChange={checked}
                      id="check_rango_ranking"
                      name="check_rango_ranking"
                      color="primary"
                    />
                  }
                  label="¿Rango?"
                />
              </Grid>
            }
            {check_rango_ranking ?
              <Grid container spacing={1} style={{ justifyContent: "space-around" }}>
                <Grid item xs={5}>
                  <TextField
                    value={posicion_una}
                    required
                    error={getError("posicion_una", errores).length > 0}
                    helperText={getError("posicion_una", errores)}
                    type="number"
                    id="posicion_una"
                    name="posicion_una"
                    label="Posición 1"
                    fullWidth
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={1} style={{ marginTop: "23px", textAlign: "center" }}>
                  -
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    value={posicion_dos}
                    required
                    error={getError("posicion_dos", errores).length > 0}
                    helperText={getError("posicion_dos", errores)}
                    type="number"
                    id="posicion_dos"
                    name="posicion_dos"
                    label="Posición 2"
                    fullWidth
                    onChange={onChange}
                  />
                </Grid>
              </Grid> : <TextField
                value={posicion}
                required
                error={getError("posicion", errores).length > 0}
                helperText={getError("posicion", errores)}
                type="number"
                id="posicion"
                name="posicion"
                label="Posición"
                fullWidth
                onChange={onChange}
              />
            }
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Cerrar" callback={() => show(false)} />
        {check_rango_ranking ? <BtnForm texto="Guardar" callback={() => guardarPosicionRango(posicion_una, posicion_dos)} /> : <BtnForm texto="Guardar" callback={() => guardarPosicion(posicion)} />}
      </DialogActions>
    </Dialog>
  );
}

class Instituciones extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nombre_univ: '',
      pais_uni: [],
      ciudad_uni: '',
      idioma_uni: '',
      subtipos: [],
      paises: [],
      cargando: true,
      errores: [],
      subtipo_convenio: [],
      otro_subtipo_convenio: '',
      ranking: [],
      modal_add_posicion: false,
      modal_buscar_ranking: false,
      modal_agg_pais: false,
      posicion_una: '',
      posicion_dos: '',
      posicion: '',
      check_rango_ranking: false,
      acreditacion_institucional: [],
      acreditacion_programa: [],
      acreditaciones: [],
      programa_facultad: '',
      inf_conevnio: [],
      cumplimiento: [],
      codigo_conve: '',
    }
  }

  async componentDidUpdate({ modalAddInstituciones, modalModInstituciones }) {
    let { modalAddInstituciones: modalAddInstitucionesNew, modalModInstituciones: modalModInstitucionesNew, institucionActual } = this.props
    if (modalAddInstituciones !== modalAddInstitucionesNew) {
      if (modalAddInstitucionesNew) {
        this.setState({ cargando: true })
        await this.obtenerGenericas()
        this.setState({ cargando: false })
      }
      if (!modalAddInstitucionesNew) {
        this.resetState()
      }
    }
    if (modalModInstituciones !== modalModInstitucionesNew) {
      if (modalModInstitucionesNew) {
        this.setState({ cargando: true })
        await this.obtenerGenericas()
        let inf_conevnio = [], cumplimiento = []
        inf_conevnio = await this.obtenerBorradorInfConvenio(institucionActual.id)
        cumplimiento = await this.obtenerBorradorCumplimiento(institucionActual.id)
        this.cargarDatosBorrador(institucionActual, cumplimiento, inf_conevnio)
        this.setState({ inf_conevnio, cumplimiento, cargando: false })
      }
      if (!modalModInstitucionesNew) {
        this.resetState()
      }
    }
  }

  obtenerGenericas = async () => {
    let subtipos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': SUBTIPOS_CONVENIOS }]);
    let paises = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PAISES }]);
    let acreditaciones = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ACREDITACIONES }]);
    this.setState({ subtipos, paises, acreditaciones });
  }

  async cargarDatosBorrador(datos, cumplimiento, inf_conevnio) {
    let { institucion, otro_subtipo_convenio, pais_vinculante, programa_facultad_3, codigo_convenio } = datos;
    let { Acreditacion_inst, Acreditacion_prog, Ranking } = cumplimiento;
    let { TipoConvenio, Subtipo_convenio } = inf_conevnio;
    let helpAcreditacionInst = [];
    let helpAcreditacionProg = [];
    let helpRanking = [];
    let helpTipoConvenio = [];
    let helpSubtipo_convenio = [];

    this.setState({
      nombre_univ: institucion ? institucion : "",
      pais_uni: pais_vinculante ? [{ id: pais_vinculante.id, nombre: pais_vinculante.nombre }] : [],
      otro_subtipo_convenio: otro_subtipo_convenio ? otro_subtipo_convenio : "",
      programa_facultad: programa_facultad_3 ? programa_facultad_3 : "",
      codigo_conve: codigo_convenio ? codigo_convenio : ''
    });
    if (TipoConvenio.length > 0) {
      TipoConvenio.map((item) => {
        helpTipoConvenio.push(item.tipo_convenio_convenio.id);
      });
      this.setState({ tipo_convenio: helpTipoConvenio });
    }
    if (Subtipo_convenio.length > 0) {
      Subtipo_convenio.map((item) => {
        helpSubtipo_convenio.push(item.sub_tipo_convenio.id);
      });
      this.setState({ subtipo_convenio: helpSubtipo_convenio });
    }
    if (Acreditacion_inst.length > 0) {
      Acreditacion_inst.map((item) => {
        helpAcreditacionInst.push(item.acreditacion_ins.id);
      });
      this.setState({ acreditacion_institucional: helpAcreditacionInst });
    }
    if (Acreditacion_prog.length > 0) {
      Acreditacion_prog.map((item) => {
        helpAcreditacionProg.push(item.acreditacion_prog.id);
      });
      this.setState({ acreditacion_programa: helpAcreditacionProg });
    }
    if (Ranking.length > 0) {
      Ranking.map((item) => {
        if (item.posicion_dos) {
          helpRanking.push({
            id: item.ranking.id,
            nombre: item.ranking.nombre,
            posicion: item.posicion + ' - ' + item.posicion_dos,
          });
        } else {
          helpRanking.push({
            id: item.ranking.id,
            nombre: item.ranking.nombre,
            posicion: item.posicion,
          });
        }
      });
      this.setState({ ranking: helpRanking });
    }
  }

  async obtenerBorradorInfConvenio(id_convenio) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/internacionalizacion/solicitudes/${id_convenio}/obtener_pasoInfConvenio`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async obtenerBorradorCumplimiento(id_convenio) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/internacionalizacion/solicitudes/${id_convenio}/obtener_pasoCumplimiento`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  resetState = () => {
    this.setState({
      nombre_univ: '',
      pais_uni: '',
      ciudad_uni: '',
      idioma_uni: '',
      subtipos: [],
      paises: [],
      cargando: true,
      errores: [],
      subtipo_convenio: [],
      otro_subtipo_convenio: '',
      ranking: [],
      modal_add_posicion: false,
      modal_buscar_ranking: false,
      posicion_una: '',
      posicion_dos: '',
      posicion: '',
      check_rango_ranking: false,
      acreditacion_institucional: [],
      acreditacion_programa: [],
      acreditaciones: [],
      programa_facultad: '',
      inf_conevnio: [],
      cumplimiento: [],
      codigo_conve: ''
    })
  }

  onSubmit = async () => {
    let { multiple } = this.props
    let { nombre_univ, pais_uni, ciudad_uni, idioma_uni, errores, subtipo_convenio, otro_subtipo_convenio, acreditacion_institucional, ranking, programa_facultad, acreditacion_programa, acreditacion_internacional_inst, acreditacion_internacional_prog, codigo_conve } = this.state
    let titulo = ''
    let help = [];
    this.setState({ cargando: false })
    ranking.map((item) => {
      if (item.posicion_una && item.posicion_dos) {
        help.push([item.id, item.posicion_una, item.posicion_dos]);
      } else {
        help.push([item.id, item.posicion]);
      }
    });
    help = JSON.stringify(help);

    errores = await validateInputs([
      { value: nombre_univ, name: "nombre_univ", err: { empty: true } },
      { value: pais_uni, name: "pais_uni", err: { empty: true } },
      { value: subtipo_convenio, name: "subtipo_convenio", err: { empty: true } },
    ]);

    if (subtipo_convenio.includes(CODIGO_SUBTIPO_CONVENIO_OTRO)) {
      if (!otro_subtipo_convenio) {
        errores.push({
          llave: "otro_subtipo_convenio",
          mensaje: `Este campo es obligatorio`,
        });
      }
    }
    // if (acreditacion_institucional.length === 0 && acreditacion_programa.length === 0) {
    //   if (ranking.length === 0) titulo = "Seleccione un ranking o al menos una acreditacion"
    // }
    // if (acreditacion_programa.length > 0) {
    //   if (!programa_facultad) titulo = "Digite al menos un programa"
    // }
    this.setState({ errores });

    if (titulo) {
      this.props.actualizarMensaje({
        titulo,
        tipo: "info",
        mostrar: true,
        tiempo: 6000,
      });
    }

    if (errores.length === 0 && !titulo) {
      let pais_data
      if (!multiple) {
        pais_data = pais_uni[0].id
      } else {
        pais_data = pais_uni.map(({ id }) => id)
      }
      let data = { nombre_univ, pais_uni: pais_data, ciudad_uni, idioma_uni, tipo_solicitud: TIPOS_SOLICITUD_CONVENIOS, subtipo_convenio, otro_subtipo_convenio, check: 'true', help, programa_facultad, acreditacion_programa, acreditacion_internacional_inst, acreditacion_internacional_prog, acreditacion_institucional }
      this.props.guardarInstitucion(data, () => this.resetState())
    }
    this.setState({ cargando: true })
  }

  onSubmitEditar = async () => {

    let { nombre_univ, pais_uni, ciudad_uni, idioma_uni, errores, subtipo_convenio, otro_subtipo_convenio, acreditacion_institucional, ranking, programa_facultad, acreditacion_programa, acreditacion_internacional_inst, acreditacion_internacional_prog, codigo_conve } = this.state
    let { id_institucionActual, multiple } = this.props
    let titulo = ''
    let help = [];
    ranking.map((item) => {
      if (item.posicion_una && item.posicion_dos) {
        help.push([item.id, item.posicion_una, item.posicion_dos]);
      } else {
        help.push([item.id, item.posicion]);
      }
    });
    help = JSON.stringify(help);

    errores = await validateInputs([
      { value: nombre_univ, name: "nombre_univ", err: { empty: true } },
      { value: pais_uni, name: "pais_uni", err: { empty: true } },
      { value: subtipo_convenio, name: "subtipo_convenio", err: { empty: true } },
    ]);

    if (subtipo_convenio.includes(CODIGO_SUBTIPO_CONVENIO_OTRO)) {
      if (!otro_subtipo_convenio) {
        errores.push({
          llave: "otro_subtipo_convenio",
          mensaje: `Este campo es obligatorio`,
        });
      }
    }
    // if (acreditacion_institucional.length === 0 && acreditacion_programa.length === 0) {
    //   if (ranking.length === 0) titulo = "Seleccione un ranking o al menos una acreditacion"
    // }
    // if (acreditacion_programa.length > 0) {
    //   if (!programa_facultad) titulo = "Digite al menos un programa"
    // }
    this.setState({ errores });

    if (titulo) {
      this.props.actualizarMensaje({
        titulo,
        tipo: "info",
        mostrar: true,
        tiempo: 6000,
      });
    }

    if (errores.length === 0 && !titulo) {
      let pais_data
      if (!multiple)
        pais_data = pais_uni[0].id
      else
        pais_data = pais_uni.map(({ id }) => id)
      let data = { nombre_univ, pais_uni: pais_data, ciudad_uni, idioma_uni, tipo_solicitud: TIPOS_SOLICITUD_CONVENIOS, subtipo_convenio, otro_subtipo_convenio, check: 'true', help, programa_facultad, acreditacion_programa, acreditacion_internacional_inst, acreditacion_internacional_prog, acreditacion_institucional, codigo_conve }
      this.props.editarInstitucion(data, id_institucionActual, () => this.resetState())

      this.setState({ cargando: true })
    }
  }

  cambiarEstado = (estados) => {
    this.setState(estados);
  };

  onAddPosicionRango = async (valor1, valor2) => {
    let { ranking, posicion_una, posicion_dos } = this.state;
    let helpvalidator = parseInt(ranking[ranking.length - 1].valora)
    let errores = await validateInputs([
      { value: parseInt(posicion_una), name: "posicion_una", err: { empty: true, range: true }, value2: helpvalidator },
      { value: parseInt(posicion_dos), name: "posicion_dos", err: { empty: true, range: true }, value2: helpvalidator },
    ]);
    this.setState({ errores });

    if (errores.length === 0) {
      if (valor1 < valor2) {
        let rankings = [...ranking];
        rankings.map((id) => {
          id['posicion_una'] = posicion_una
          id['posicion_dos'] = posicion_dos
        })

        let ultimo_ranking = { ...ranking[ranking.length - 1] };
        let valor = valor1 + '-' + valor2;
        ultimo_ranking.posicion = valor;
        ultimo_ranking.posicion_una = valor1;
        ultimo_ranking.posicion_dos = valor2;
        rankings[ranking.length - 1] = ultimo_ranking;
        this.setState({
          ranking: rankings,
          posicion: "",
          modal_add_posicion: false,
          posicion_dos: '',
          posicion_una: '',
          check_rango_ranking: false,
        });

      } else {
        errores.push({ llave: 'posicion_una', 'mensaje': `Error` });
        errores.push({ llave: 'posicion_dos', 'mensaje': `Error` });
        this.setState({ errores });
      }
    }
  };

  onAddPosicion = async (valor) => {
    let { ranking, posicion } = this.state;
    let helpvalidator = parseInt(ranking[ranking.length - 1].valora)
    let errores = await validateInputs([
      { value: parseInt(posicion), name: "posicion", err: { empty: true, range: true }, value2: helpvalidator },
    ]);
    this.setState({ errores });

    if (errores.length === 0) {
      let rankings = [...ranking];
      let ultimo_ranking = { ...ranking[ranking.length - 1] };
      ultimo_ranking.posicion = valor;
      rankings[ranking.length - 1] = ultimo_ranking;
      this.setState({
        ranking: rankings,
        posicion: "",
        modal_add_posicion: false,
      });
    } else return false;
  };

  onCloseAddPosicion = (modal) => {
    let { ranking } = this.state;
    ranking.splice(-1, 1); // Eliminar último ranking seleccionado de la lista de seleccionados si no se agrega posición
    this.setState({ modal_add_posicion: modal, posicion: "", ranking });
  };

  checked = ({ target }) => {
    this.setState({
      [target.name]: target.checked,
    });
  };

  formularioInstitucion = () => {
    let { nombre_univ, errores, pais_uni, paises, subtipo_convenio, subtipos, otro_subtipo_convenio, ranking, acreditacion_institucional, acreditaciones, acreditacion_programa, programa_facultad, modal_buscar_ranking, modal_add_posicion, codigo_conve, modal_agg_pais } = this.state
    let { opcion } = this.props
    return (
      <>
        <ValidatorForm >
          <Grid container spacing={3} style={{ marginTop: "1%", marginBottom: '1%' }}>
            <Grid item xs={12}>
              <Alert severity="info">
                Antes de agregar una nueva institución, verifica que no esté registrada en la plataforma.
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={nombre_univ}
                required
                error={getError("nombre_univ", errores).length > 0}
                helperText={getError("nombre_univ", errores)}
                type="text"
                id="nombre_univ"
                name="nombre_univ"
                label="Nombre de la institución vinculante"
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6}>
              <InputBuscar
                label="País"
                placeholder={"Seleccione el país"}
                id="pais"
                value={
                  pais_uni.length > 0
                    ? pais_uni
                      .map((r) => r.nombre)
                      .join(", ")
                    : ""
                }
                callback={() => this.cambiarEstado({ modal_agg_pais: true })}
                icon_default={false}
              />
              <AgregarValor
                modalOpen={modal_agg_pais}
                mostrarModal={(modal) => this.cambiarEstado({ modal_agg_pais: modal })}
                nombreModal="Paises"
                datos={paises}
                opciones={true}
                buscar={true}
                fila_principal="nombre"
                listaSeleccionados={pais_uni}
                seleccionar={({ id, nombre }) => {
                  this.cambiarEstado({
                    pais_uni: pais_uni.concat({
                      id: id,
                      nombre: nombre,
                    }),
                    modal_agg_pais: false,
                  });
                }}
                quitar={({ id }) => {
                  this.cambiarEstado({
                    pais_uni: pais_uni.filter((ran) => ran.id != id),
                  });
                }}
              />
              {/* <FormControl
                className="form-control"
                required
                error={getError("pais_uni", errores).length > 0}
              >
                <InputLabel id="pais_uni_label">
                  País
                </InputLabel>
                <Select
                  labelId="pais_uni_label"
                  id="pais_uni"
                  name="pais_uni"
                  value={pais_uni}
                  fullWidth
                  onChange={this.onChange}
                >
                  {paises.map(({ id, nombre }) => (
                    <MenuItem key={id} value={id}>
                      {nombre.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{getError("pais_uni", errores)}</FormHelperText>
              </FormControl> */}
            </Grid>
            <Grid item xs={6}>
              <FormControl
                className="form-control"
                required
                error={getError("subtipo_convenio", errores).length > 0}
              >
                <InputLabel id="subtipo_convenio_label">
                  Funciones misionales que impacta
                </InputLabel>
                <Select
                  labelId="subtipo_convenio_label"
                  id="subtipo_convenio"
                  name="subtipo_convenio"
                  value={subtipo_convenio}
                  fullWidth
                  multiple
                  onChange={this.onChange}
                >
                  {subtipos
                    .sort(
                      ({ id: previousID }, { id: currentID }) =>
                        previousID - currentID
                    )
                    .map((tipo) => (
                      <MenuItem key={tipo.id} value={tipo.id}>
                        {tipo.nombre}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  {getError("subtipo_convenio", errores)}
                </FormHelperText>
              </FormControl>
            </Grid>
            {subtipo_convenio.includes(CODIGO_SUBTIPO_CONVENIO_OTRO) && (
              <Grid item xs={12}>
                <TextField
                  value={otro_subtipo_convenio}
                  id="otro_subtipo_convenio"
                  required
                  error={getError("otro_subtipo_convenio", errores).length > 0}
                  helperText={getError("otro_subtipo_convenio", errores)}
                  name="otro_subtipo_convenio"
                  label="Otro subtipo de convenio"
                  fullWidth
                  onChange={this.onChange}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ConveniosBuscarRanking
                seleccionar={({ id, nombre, valora }) => {
                  this.cambiarEstado({
                    ranking: ranking.concat({
                      id: id,
                      nombre: nombre,
                      posicion: "0",
                      valora: valora
                    }),
                  });
                }}
                quitar={({ id }) => {
                  this.cambiarEstado({
                    ranking: ranking.filter((ran) => ran.id != id),
                  });
                }}
                seleccionadas={ranking}
                modalBuscarRanking={modal_buscar_ranking}
                mostrarModalBuscarRanking={(modal_buscar_ranking) =>
                  this.cambiarEstado({ modal_buscar_ranking })
                }
                modalAddPosicion={modal_add_posicion}
                mostrarModalAddPosicion={(modal_add_posicion) =>
                  this.cambiarEstado({ modal_add_posicion })
                }
              />
              <InputBuscar
                placeholder="Seleccione los Rankings"
                label={"Posición en Rankings"}
                id="ranking"
                value={
                  ranking.length > 0
                    ? ranking
                      .map((r) => `${r.nombre} [pos: ${r.posicion}] `)
                      .join(", ")
                    : ""
                }
                callback={() => this.cambiarEstado({ modal_buscar_ranking: true })}
                icon_default={false}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl className="form-control" required>
                <InputLabel id="acreditacion_institucional_label">
                  Acreditación institucional
                </InputLabel>
                <Select
                  labelId="acreditacion_institucional_label"
                  id="acreditacion_institucional"
                  multiple
                  error={
                    getError("acreditacion_institucional", errores).length > 0
                  }
                  helperText={getError("acreditacion_institucional", errores)}
                  name="acreditacion_institucional"
                  value={acreditacion_institucional}
                  fullWidth
                  onChange={this.onChange}
                >
                  {acreditaciones
                    .sort(
                      ({ id: previousID }, { id: currentID }) =>
                        previousID - currentID
                    )
                    .map((acreditacion) => (
                      <MenuItem key={acreditacion.id} value={acreditacion.id}>
                        {acreditacion.nombre}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl className="form-control" required>
                <InputLabel id="acreditacion_programa_label">
                  Acreditación del programa
                </InputLabel>
                <Select
                  labelId="acreditacion_programa_label"
                  id="acreditacion_programa"
                  error={getError("acreditacion_programa", errores).length > 0}
                  helperText={getError("acreditacion_programa", errores)}
                  multiple
                  name="acreditacion_programa"
                  value={acreditacion_programa}
                  fullWidth
                  onChange={this.onChange}
                >
                  {acreditaciones
                    .sort(
                      ({ id: previousID }, { id: currentID }) =>
                        previousID - currentID
                    )
                    .map((acreditacion) => (
                      <MenuItem key={acreditacion.id} value={acreditacion.id}>
                        {acreditacion.nombre}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            {acreditacion_programa.length > 0 && (
              <Grid item xs={6}>
                <TextField
                  value={programa_facultad}
                  required
                  error={getError("programa_facultad", errores).length > 0}
                  helperText={getError("programa_facultad", errores)}
                  id="programa_facultad"
                  name="programa_facultad"
                  label="Programa o facultad"
                  fullWidth
                  onChange={this.onChange}
                />
              </Grid>
            )}

            {opcion == 'editar' &&
              <Grid item xs={6} >
                <FormControl className="form-control" >
                  <TextField
                    value={codigo_conve.toUpperCase()}
                    id="txtcodigo_conve"
                    label="Código convenio"
                    name="codigo_conve"
                    placeholder="Digite el código del convenio. Ejemplo: ABC-123"
                    multiline
                    onChange={this.onChange}
                  />
                </FormControl>
              </Grid>
            }

          </Grid>
        </ValidatorForm>
      </>
    )
  }

  render() {
    let { modalAddInstituciones, mostrarModalAddInstituciones, titulo, modalModInstituciones, mostrarModalModInstituciones, tituloEdi } = this.props
    let { cargando, errores, modal_add_posicion, posicion, posicion_una, posicion_dos, check_rango_ranking } = this.state
    return (
      <>
        {/* MODAL AGREGAR INSTITUCIÓN */}
        <Dialog open={modalAddInstituciones} onClose={mostrarModalAddInstituciones} fullWidth={true} maxWidth="md" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo={titulo} mostrarModal={mostrarModalAddInstituciones} titulo_accion="" accion={() => { }} />
          <DialogContent className='scroll'>
            {
              cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                : this.formularioInstitucion()
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CERRAR" callback={mostrarModalAddInstituciones} />
            <BtnFormPage
              texto="GUARDAR"
              callback={this.onSubmit}
              type="submit"
            />
          </DialogActions>
        </Dialog>

        {/* MODAL AGG PROSICION */}
        <AgregarPosicion
          open={modal_add_posicion}
          show={(modal_add_posicion) =>
            this.onCloseAddPosicion(modal_add_posicion)
          }
          posicion={posicion}
          onChange={this.onChange}
          guardarPosicion={this.onAddPosicion}
          guardarPosicionRango={this.onAddPosicionRango}
          errores={errores}
          check_rango_ranking={check_rango_ranking}
          checked={this.checked}
          posicion_una={posicion_una}
          posicion_dos={posicion_dos}
        />

        {/* MODAL EDITAR INSTITUCIONES */}
        <Dialog open={modalModInstituciones} onClose={mostrarModalModInstituciones} fullWidth={true} maxWidth="md" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo={tituloEdi} mostrarModal={mostrarModalModInstituciones} titulo_accion="" accion={() => { }} />
          <DialogContent className='scroll'>
            {
              cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                : this.formularioInstitucion()
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CERRAR" callback={mostrarModalModInstituciones} />
            <BtnFormPage
              texto="GUARDAR"
              callback={this.onSubmitEditar}
              type="submit"
            />
          </DialogActions>
        </Dialog>
      </>
    )

  }
}

export default Instituciones;
