import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { consulta, crear_form_data, formulario, mostrarError, transformarEnviado } from "../../../global/js/funciones";
import { actualizarMensaje, mostrarModulo } from "../../../redux/actions/actGlobal";
import AppBarModal from '../../general/AppBarModal';
import { BtnApagar, BtnCancelar, BtnEncender, BtnForm, BtnSeleccion } from '../../general/BotonesAccion';
import {obtenerValoresFiltros } from '../../../global/js/funciones';
import { PROGRAMAS } from "../helper";
import ListarDatos from '../../general/ListarDatos';
import { Autocomplete } from '@material-ui/lab';

class Asignar_Asignatura extends Component{
    constructor(props) {
      super(props)
      this.state = {
        relacion:{},
        programas:[],
        asignaturas:[],
        programa:null,
        periodo_actual:null
      }
    }

    componentDidMount() {
      this.obtenerDatosIniciales();
    }

    componentDidUpdate({ Modal},{programa}) {
      let {Modal: ModalNew}=this.props
      let {programa: programaNew }=this.state
      if (ModalNew !== Modal) {
        this.obtenerDatosIniciales()
         this.VerificarAsignaturas()
      }

      if(programaNew !== programa){
        this.BuscarAsignaturas()
      }
    }

    async obtenerDatosIniciales(){
      let programas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PROGRAMAS }, { 'llave': 'estado', 'valor': 1 }]);
      let data_periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Sisef' }, { 'llave': 'estado', 'valor': 1 }]);
      this.setState({'programas': transformarEnviado(programas), periodo_actual:data_periodo[0].nombre})
    }
  
    async VerificarAsignaturas(){
      let {data} = this.props
      let relacion = await this.AsignaturaDocente(data.id);
      this.setState({relacion})
    }

    async BuscarAsignaturas(){
      let {programa, periodo_actual} = this.state
      if(programa != null){
        let asig = await this.ConsultarAsignaturas(programa.codigo, periodo_actual);
        asig=asig.asignaturas
        let asignaturas=[], aux={}
        for(const item in asig){
          console.log(asig[item][3])
          if(asignaturas.length != 0){aux = asignaturas.filter(asigna => asigna.codigo == asig[item][2])}else{aux=[]}
          if(aux.length==0){asignaturas.push({ nombre: asig[item][3], codigo: asig[item][2] })}
        } 
          
        this.setState({asignaturas: asignaturas})
      }
    }

    async AsignaturaDocente(id_persona) {
      let {periodo_actual}=this.state
        return new Promise(resolve => {
          consulta(`api/v1.0/sisef/docente_asignaturas/${id_persona}/${periodo_actual}/${0}`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
          })
        })
    }

      async ConsultarAsignaturas(programa, periodo) {
        return new Promise(resolve => {
          consulta(`api/v1.0/sisef/asignaturas/${programa}/${periodo}`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
          })
        })
      }

    GuardarRelacion(asignatura, id_persona, periodo){
        let data ={
            nombre_asignatura: asignatura.nombre,
            codigo_asignatura: asignatura.codigo,
            docente: id_persona,
            periodo: periodo
          }
          this.enviar(data);
    }

    enviar = async (data) => {
        let {actualizarMensaje} = this.props;
        let form = await crear_form_data(data);
        formulario(`api/v1.0/sisef/asignar_asignatura`, form, "post", (error, estado, resp) => {
          let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
          if (!error) {
            if (estado === 200) {
              tipo = 'success';
              titulo = resp.titulo;
              this.VerificarAsignaturas();
            } else {
              tipo = 'info';
              titulo = resp.titulo ? resp.titulo : mostrarError(resp);
            }
          }
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        })
    }

    UpdateMod = async (id, data) => {
      let {actualizarMensaje} = this.props;
      let form = await crear_form_data(data);
      formulario(`api/v1.0/sisef/${id}/modificar_docente_asignaturas`, form, "patch", (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.VerificarAsignaturas()
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);  
          }actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
          } 
        })
    }

    EliminarRelacion = (id) => {
        let{actualizarMensaje}=this.props;
        consulta(`api/v1.0/sisef/${id}/eliminar_docente_asignaturas`, {}, "put", (error, estado, resp) => {
          let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
          if (!error) {
            if (estado === 200) {
              tipo = 'success';
              titulo = resp.titulo;
              this.VerificarAsignaturas();
            } else {
              tipo = 'info';
              titulo = resp.titulo ? resp.titulo : mostrarError(resp);
            }
          }
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        });
      }

    acciones(rowData){
        let {relacion, periodo_actual}=this.state
        const Seleccionar = (row) => {
           let {data}=this.props
           this.GuardarRelacion(row, data.id, periodo_actual)
        }

        const Cancelar = (id) => {
            this.EliminarRelacion(id)
        }

        const CambiarMod=(row)=>{
          let data ={
            coordinador: (row.coordinador==0)?1:0
          }
          this.UpdateMod(row.id, data)
        }

        let info_rel = Object.values(relacion).filter((item)=>item.codigo_asignatura==rowData.codigo)
         return(
            (info_rel.length==0) ?
                <div>
                    <BtnSeleccion texto="Seleccionar" callback={() => Seleccionar(rowData)}/>
                </div>
            :(info_rel[0].coordinador == 0) ? 
            <div>
                <BtnCancelar texto="Cancelar" callback={() => Cancelar(info_rel[0].id)}/>
                <BtnEncender texto="Modo Coordinador" callback={() => CambiarMod(info_rel[0])}/>
            </div>
            :
            <div>
              <BtnCancelar texto="Cancelar" callback={() => Cancelar(info_rel[0].id)}/>
              <BtnApagar texto="Modo Docente" callback={() => CambiarMod(info_rel[0])}/>
            </div>
         ) 
    }

    render(){
        let {Modal, cerrarModal} = this.props
        let {programas, programa, asignaturas}=this.state
        // let asignaturas=[{id: 1, nombre: "Matematicas", codigo: 123}, {id: 2, nombre: "Competencias Ciudadanas", codigo: 321}, {id: 3, nombre: "Estructura de datos", codigo: 456}]
      return(
        <Dialog fullWidth={true} maxWidth='md' open={Modal} onClose={()=>cerrarModal(false)} aria-labelledby="form-dialog-title">
          <AppBarModal titulo="Asignaturas" mostrarModal={() =>  cerrarModal(false)} titulo_accion="Mostrar Asignaturas" accion="" />
          <DialogContent className='scroll'>
            <Grid container style={{ padding: 30 }}>
                <Grid item xs={8}>
                  {
                    <FormControl className="form-control">
                      <Autocomplete
                        options={programas}
                        getOptionLabel={(option) => option.label}
                        id="id_programas"
                        value={programa}
                        onChange={(event, newValue) => {
                          this.setState({programa:newValue})                        
                        }}
                        renderInput={(params) => <TextField {...params} label="Seleccione el programa" margin="normal" />}
                      />
                    </FormControl>
                  }
                </Grid>
            </Grid>
            <ListarDatos
              id='tbl_lista_asignaturas'
              datos={asignaturas}
              acciones={(row) => this.acciones(row)}
              opciones={true}
              titulo='Asignaturas'
              buscar={true}
              filtrar={false}
              fila_principal={({ nombre }) => `${nombre}`.toUpperCase()}
              filas={[                
                { 'mostrar': ({ codigo }) => `${codigo}`,
                  'id': 'codigo', 'enLista': true
                },
              ]}
            />
          </DialogContent>
          <DialogActions>
              <BtnForm texto="CANCELAR" callback={()=>cerrarModal(false)}/>
          </DialogActions>
        </Dialog>
      );
    }
  }

const mapStateToProps = state => {
  let { usuario } = state.redGlobal;
let { ModalAdd } = state.redSisef;
        return { usuario, ModalAdd }
}

const mapDispatchToProps = { 
  mostrarModulo,
  actualizarMensaje
}
    
Asignar_Asignatura.propTypes = {
  //Funciones
ModalAdd: PropTypes.bool.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Asignar_Asignatura);