import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { consulta, mostrarError, obtenerValores, crear_form_data, formulario } from "../../../../global/js/funciones";
import { mostrarModalAddInstituciones, mostrarModalModInstituciones, mostrarModalDelInstituciones, mostrarModalDetInstituciones, actualizarDataInstituciones, mostrarModalDetConvInst } from '../../../../redux/actions/actInternacionalizacion';
import { InstitucionesDetalle } from './InstitucionesDetalle';
import ConfirmarAccion from '../../../general/ConfirmarAccion';
import { ID_JF } from '../helper';
import Instituciones from './InstitucionesCrear';
import ListarDatos from '../../../general/ListarDatos';
import TareasTerminadas from '../../../general/TareasTerminadas';
import emma_w from '../../../../global/imagenes/emma_w.png';
import { BtnDetalle, BtnEditar, BtnEliminar } from '../../../general/BotonesAccion';
import ConvenioCrearDetalle from '../../convenios/ConvenioCrearDetalle';
import InternacionalizacionDetalle from '../../InternacionalizacionDetalle';
import { InstitucionesDetalleConvenio } from './InstitucionesDetalleConvenio';

class InternacionalizacionInstituciones extends Component {
  constructor(props) {
    super(props)
    this.state = {
      input_institucion: '',
      cargando: true,
      tipos_instituciones: [],
      tipoSolicitud: 'Con_Int',
      institucionActual: [],
      id_institucionActual: null,
      opcion: '',
      estado_actual: '',
      solicitud: {},
      tipo_paso_infoconveni: [],
      pasocumplimientorequisito: [],
      pasotipoactividad: [],
      opcionDetalle: '',
      cargandoCon: false,
    }
  }

  async componentDidUpdate({ modalDetInstituciones, modalModInstituciones }, { estado_actual }) {
    let { estado_actual: estado_actualNew, institucionActual, cargandoCon: cargandoConNew } = this.state;
    let { modalDetInstituciones: modalDetInstitucionesNew, modalModInstituciones: modalModInstitucionesNew } = this.props
    if (modalDetInstituciones !== modalDetInstitucionesNew) {
      if (!modalDetInstitucionesNew) {
        this.setState({ cargando: true })
      }
    }
    if (modalModInstituciones !== modalModInstitucionesNew) {
      if (!modalModInstitucionesNew) {
        this.setState({ opcion: '' })
      }
    }
  }

  guardarInstitucion = async (datos, callback) => {
    let { actualizarMensaje, mostrarModalAddInstituciones } = this.props;
    let data = await crear_form_data(datos);
    formulario(
      `api/v1.0/internacionalizacion/convenios/crear_inst_sin_conv`,
      data,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            this.setState({ cargando: true });
            this.props.obtenerInstituciones();
            mostrarModalAddInstituciones(false);
            this.setState({ cargando: false });
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        callback();
      }
    )
  }

  editarInstitucion = async (data, id, callback) => {
    let { actualizarMensaje, mostrarModalModInstituciones } = this.props; //edi
    let datos = await crear_form_data(data);
    formulario(`api/v1.0/internacionalizacion/convenios/${id}/modificar_inst_sin_conv`, datos, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.setState({ cargando: true });
          this.props.obtenerInstituciones();
          mostrarModalModInstituciones(false)
          this.setState({ cargando: false });
        } else {
          tipo = 'info'
          titulo = resp.titulo
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
      callback();
    })
  }

  eliminarInstitucion = () => {
    let { id_institucionActual } = this.state
    let { actualizarMensaje, mostrarModalDelInstituciones } = this.props;
    consulta(`api/v1.0/internacionalizacion/convenios/${id_institucionActual}/eliminar_inst_sin_conv`, {}, "put",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.setState({ cargando: true });
            this.props.obtenerInstituciones();
            mostrarModalDelInstituciones(false);
            this.setState({ cargando: false });
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp)
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  acciones = (data) => {
    let { solicitud: { estado_actual: { codigo } } } = data;
    let { usuario, mostrarModalDetConvInst } = this.props
    const onClickDetalle = (data) => {
      this.setState({ institucionActual: data, estado_actual: codigo, solicitud: data.solicitud, id_institucionActual: data.solicitud.id, opcionDetalle: 'detalle' })
      if (codigo == 'sin_conv_uni') this.props.mostrarModalDetInstituciones(true)
      else if (codigo == 'Con_regis') mostrarModalDetConvInst(true)
    }
    const onClickModificar = (data) => {
      this.setState({ id_institucionActual: data.solicitud.id, institucionActual: data, editar: 'editar', opcion: 'editar' })
      this.props.mostrarModalModInstituciones(true);
    }
    const onClickEliminar = (data) => {
      this.setState({ id_institucionActual: data.solicitud.id, institucionActual: data })
      this.props.mostrarModalDelInstituciones(true);
    }

    let detalle = <BtnDetalle callback={() => onClickDetalle(data)} />
    let modificar = <BtnEditar callback={() => onClickModificar(data)} />
    let eliminar = <BtnEliminar callback={() => onClickEliminar(data)} />

    return (usuario.id === ID_JF || usuario.perfil.codigo === "Per_Adm") && codigo === 'sin_conv_uni' ? (
      <> {detalle} {modificar} {eliminar} </>
    ) : (usuario.id === ID_JF || usuario.perfil.codigo === "Per_Adm") && codigo !== 'sin_conv_uni' ? (<>{detalle}</>) : (<>{detalle}</>)
  }

  obtenerColor = (codigo) => {
    let color = 'white';

    const colores = {
      'sin_conv_uni': { 'backgroundColor': '#EEEEEE', color },
      'Con_regis': { 'backgroundColor': '#008000', color },
    }
    return (colores[codigo])
  }
  pintarDetalle = () => {
    let { mostrarModalDetInstituciones, modalDetInstituciones, mostrarModalDetConvInst, modalDetConvInst } = this.props;
    let { institucionActual, estado_actual, id_institucionActual, solicitud, cargando, pasotipoactividad, opcionDetalle, cargandoCon } = this.state;
    if (estado_actual == 'sin_conv_uni') {
      return <InstitucionesDetalle
        mostrarModalDetInstituciones={mostrarModalDetInstituciones}
        modalDetInstituciones={modalDetInstituciones}
        institucionActual={institucionActual}
      />
    } else if (estado_actual == 'Con_regis') {
      return <InstitucionesDetalleConvenio
        mostrarModalDetConvInst={mostrarModalDetConvInst}
        modalDetConvInst={modalDetConvInst}
        id={id_institucionActual}
        solicitud={solicitud}
      />
    }
  }

  render() {
    let {
      mostrarModalAddInstituciones,
      mostrarModalDetInstituciones,
      mostrarModalModInstituciones,
      mostrarModalDelInstituciones,
      modalAddInstituciones,
      modalDetInstituciones,
      modalModInstituciones,
      modalDelInstituciones,
      usuario,
      actualizarMensaje,
      administrador,
      instituciones_list,
      modalDetConvInst,
      mostrarModalDetConvInst,
    } = this.props;
    let { id_institucionActual, cargando, institucionActual, opcion, estado_actual, solicitud } = this.state;
    if (!cargando) return <TareasTerminadas mensaje='Cargandoxxx..' marginTop='7%' imagen={emma_w} widthImg="7%" />;

    return (
      <div>
        {administrador ?
          <Instituciones
            titulo={'¡ Nueva Institución !'}
            modalAddInstituciones={modalAddInstituciones}
            mostrarModalAddInstituciones={() => mostrarModalAddInstituciones(false)}
            guardarInstitucion={this.guardarInstitucion}
            actualizarMensaje={actualizarMensaje}
            modalModInstituciones={modalModInstituciones}
            mostrarModalModInstituciones={() => mostrarModalModInstituciones(false)}
            tituloEdi={'Editar Institución'}
            editarInstitucion={this.editarInstitucion}
            opcion={opcion}
            id_institucionActual={id_institucionActual}
            institucionActual={institucionActual}
            obtenerInstituciones={this.props.obtenerInstituciones}
            multiple={false}
          />
          : ''
        }
        <ListarDatos
          datos={instituciones_list && instituciones_list.length > 0 ? instituciones_list : []}
          titulo='Lista de Instituciones'
          buscar={true}
          opciones={true}
          agregar={administrador ? true : false}
          actAgregar={() => mostrarModalAddInstituciones(true)}
          sub_titulo='Instituciones'
          id='tbl_instituciones'
          acciones={row => this.acciones(row)}
          avatar={({ solicitud }) => solicitud.estado_actual.nombre.charAt(0).toUpperCase()}
          color_avatar={({ solicitud }) => this.obtenerColor(solicitud.estado_actual.codigo)}
          fila_principal={({ institucion }) => institucion}
          filas={[
            { 'nombre': 'Nombre del solicitante: ', 'id': 'identificacion', 'mostrar': ({ solicitud: { solicitante: { primer_nombre, primer_apellido, segundo_apellido } } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
            { 'nombre': 'Correo electronico del solicitante: ', 'id': 'correo', 'mostrar': ({ solicitud: { solicitante: { correo } } }) => correo },
            { 'nombre': 'Estado actual: ', 'id': 'estado_actual', 'mostrar': ({ solicitud: { estado_actual: { nombre } } }) => nombre }
          ]}
        />

        {this.pintarDetalle()}

        <ConfirmarAccion
          mensaje1={`La institución "${institucionActual.institucion}" será eliminada, `}
          ejecutarAccion={this.eliminarInstitucion}
          mostrarModalConfAccion={mostrarModalDelInstituciones}
          modalConAccion={modalDelInstituciones}
          dato={id_institucionActual}
          titulo={'¿ Eliminar Institución ?'}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let { usuario } = state.redGlobal;
  let { modalAddInstituciones, modalModInstituciones, modalDelInstituciones, modalDetInstituciones, instituciones, modalDetConvInst } = state.redInternacionalizacion;
  return { modalAddInstituciones, modalModInstituciones, modalDelInstituciones, modalDetInstituciones, instituciones, usuario, modalDetConvInst }
}

const mapDispatchToProps = { mostrarModalAddInstituciones, mostrarModalModInstituciones, mostrarModalDelInstituciones, mostrarModalDetInstituciones, actualizarDataInstituciones, mostrarModalDetConvInst }

InternacionalizacionInstituciones.propTypes = {
  // variables
  instituciones: PropTypes.array.isRequired,
  modalAddInstituciones: PropTypes.bool.isRequired,
  modalModInstituciones: PropTypes.bool.isRequired,
  modalDelInstituciones: PropTypes.bool.isRequired,
  modalDetInstituciones: PropTypes.bool.isRequired,
  modalDetConvInst: PropTypes.bool.isRequired,
  // funciones
  actualizarDataInstituciones: PropTypes.func.isRequired,
  mostrarModalAddInstituciones: PropTypes.func.isRequired,
  mostrarModalModInstituciones: PropTypes.func.isRequired,
  mostrarModalDelInstituciones: PropTypes.func.isRequired,
  mostrarModalDetInstituciones: PropTypes.func.isRequired,
  mostrarModalDetConvInst: PropTypes.func.isRequired,

}

export default connect(mapStateToProps, mapDispatchToProps)(InternacionalizacionInstituciones);
