import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import BuscarSelect from "../general/BuscarSelect";
import InputFile from "../general/InputFile";
import AppBarModal from "./../general/AppBarModal";
import { BtnForm } from "./../general/BotonesAccion";
import { consulta, generarFiltros } from "./../../global/js/funciones";
import TareasTerminadas from "./../general/TareasTerminadas";
import emma_w from './../../global/imagenes/emma_w.png';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { TIPO_HOMOLOGACION } from './helper';

function MensajeValidacionId({ modal, mostrarmodal, mensaje, cargando }) {
  return (
    <Dialog open={modal} maxWidth="sm" fullWidth={true} onClose={() => mostrarmodal(false)} >
      <AppBarModal titulo='¡ Mensaje !' mostrarModal={mostrarmodal} titulo_accion='' />
      <DialogContent className='scroll' >
        {cargando ?
          <TareasTerminadas mensaje="Espere un momento. Estamos validando su numero de identificacion" marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true} /> :
          <Typography variant="body1" color="textSecondary" component="p" align="justify">{mensaje}</Typography>
        }
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Cerrar" callback={() => mostrarmodal(false)} />
      </DialogActions>
    </Dialog>
  );
}


export default class InscripcionAgregarMisDatos extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      mensaje: '',
      datos: {},
    }
    this.fileInput = React.createRef();
  }

  llenar_campos(datos = null) {
    if (datos) {
      this.props.props_c.setPrimer_nombre(datos.estudiante && datos.estudiante.primer_nombre || '')
      this.props.props_c.setSegundo_nombre(datos.estudiante && datos.estudiante.segundo_nombre || '')
      this.props.props_c.setPrimer_apellido(datos.estudiante && datos.estudiante.primer_apellido || '')
      this.props.props_c.setSegundo_apellido(datos.estudiante && datos.estudiante.segundo_apellido || '')
      this.props.props_c.setTipo_identificacion(datos.estudiante && datos.estudiante.tipo_identificacion && { value: datos.estudiante.tipo_identificacion.id, label: datos.estudiante.tipo_identificacion.nombre, codigo: datos.estudiante.tipo_identificacion.codigo } || '')
      this.props.props_c.setTipo_identificacion_saber(datos.estudiante && datos.estudiante.tipo_identificacion && { value: datos.estudiante.tipo_identificacion.id, label: datos.estudiante.tipo_identificacion.nombre, codigo: datos.estudiante.tipo_identificacion.codigo } || '')
      this.props.props_c.setTelefono1(datos.telefono1 || '')
      this.props.props_c.setCelular1(datos.celular1 || '')
      this.props.props_c.setCorreo(datos.estudiante && datos.estudiante.correo || '')
      this.props.props_c.setCodigo_pruebas(datos.codigo_pruebas || 'AC')
      this.props.props_c.setPuntaje_pruebas(datos.puntaje_pruebas || '')
      this.props.props_c.setOpcion_sisben(datos.sisben && { value: datos.sisben, label: datos.sisben.toUpperCase() } || '')
      this.props.props_c.setPuntaje_sisben(datos.puntaje_sisben || '')
      this.props.props_c.setGrupo(datos.grupo && { value: datos.grupo.id, label: datos.grupo.nombre, codigo: datos.grupo.codigo } || '')
      this.props.props_c.setDiscapacidad(datos.discapacidad && { value: datos.discapacidad.id, descripcion: datos.discapacidad.descripcion, label: datos.discapacidad.nombre, codigo: datos.discapacidad.codigo } || '')
      this.props.props_c.setAdj_identificacion(datos.adj_identificacion || '')
      this.props.props_c.setAdj_diploma(datos.adj_diploma || '')
      this.props.props_c.setAdj_saber(datos.adj_saber || '')
      this.props.props_c.setAdj_certificado(datos.adj_certificado || '')
      this.props.props_c.setDepartamento(datos.departamento && { value: datos.departamento.id, label: datos.departamento.nombre } || '')
      this.props.props_c.setCiudad(datos.ciudad && { value: datos.ciudad.id, label: datos.ciudad.nombre } || '')
      this.props.props_c.setBarrio(datos.barrio || '')
      this.props.props_c.setDireccion(datos.direccion || '')
      this.props.props_c.setOpcion_sedes(datos.sede && { value: datos.sede.id, label: datos.sede.nombre } || '')
      this.props.props_c.setEstrato(datos.estrato && { value: datos.estrato.id, label: datos.estrato.nombre } || '')
      this.props.props_c.setOpcion_conociste(datos.conocio && { value: datos.conocio.id, label: datos.conocio.nombre } || '')
      this.props.props_c.setCarrera(datos.carrera && { value: datos.carrera.id, codigo: datos.carrera.codigo, label: datos.carrera.nombre } || '')
      this.props.props_c.setAdj_docu_notas(datos.adj_docu_notas || '')
      this.props.props_c.setNombreEps(datos.nombre_eps && { value: datos.nombre_eps.id, label: datos.nombre_eps.nombre } || { value: '' })
      this.props.props_c.setNombreEpsOtro(datos.nombre_otro_eps || '')
    } else {
      this.props.props_c.setPrimer_nombre("")
      this.props.props_c.setSegundo_nombre("")
      this.props.props_c.setPrimer_apellido("")
      this.props.props_c.setSegundo_apellido("")
      // this.props.props_c.setTipo_identificacion("")
      this.props.props_c.setTelefono1("")
      this.props.props_c.setCelular1("")
      this.props.props_c.setCorreo("")
      this.props.props_c.setCodigo_pruebas("AC")
      this.props.props_c.setPuntaje_pruebas("")
      this.props.props_c.setOpcion_sisben("")
      this.props.props_c.setPuntaje_sisben("")
      this.props.props_c.setGrupo("")
      this.props.props_c.setDiscapacidad("")
      this.props.props_c.setAdj_identificacion("")
      this.props.props_c.setAdj_diploma("")
      this.props.props_c.setAdj_saber("")
      this.props.props_c.setAdj_certificado("")
      this.props.props_c.setDepartamento("")
      this.props.props_c.setCiudad("")
      this.props.props_c.setBarrio("")
      this.props.props_c.setDireccion("")
      this.props.props_c.setEstrato("")
      this.props.props_c.setOpcion_conociste("")
      this.props.props_c.setCarrera("")
      this.props.props_c.setAdj_docu_notas("")
      this.props.props_c.setNombreEps('')
      this.props.props_c.setNombreEpsOtro('')
    }
  }

  validar_id = async ({ target }) => {
    let { actualizarMensaje, setMostrarBtn, mostrarModalMensajeValidacionId, mensaje } = this.props.props_c
    let identificacion = target.value
    if (identificacion) {
      this.setState({ mensaje: '', cargando: true, datos: {} });
      mostrarModalMensajeValidacionId(true)
      let f = await generarFiltros([{ 'llave': 'estudiante__identificacion', 'valor': identificacion }]);
      consulta(`api/v1.0/inscripcion/validar_id?${f}`, null, null, (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            if (resp.length > 0) {
              if ([16880, 118].includes(resp[0].tipo)) {
                setMostrarBtn(true)
                this.llenar_campos()
                this.props.setBotonSiguiente(true)
                this.setState({ mensaje: `¡Ya estás inscrito en la plataforma, comunicate vía Whatsapp con el múmero 3168569919!`, cargando: false });
              } else {
                setMostrarBtn(false)
                this.llenar_campos(resp[0])
                this.props.setBotonSiguiente(false)
                this.setState({ mensaje: `¡Ya estás registrado en nuestra plataforma! Para continuar: Revisa tus datos y actualizalos. ${mensaje}.`, cargando: false });
              }
            } else {
              setMostrarBtn(true)
              this.llenar_campos()
              this.props.setBotonSiguiente(false)
              this.setState({ mensaje: `Tu identificación es válida para la inscripcion. Puedes continuar.`, cargando: false });
            }
          }
        } else {
          this.props.setBotonSiguiente(false)
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        }
      }, false)
    }
  }



  render() {
    let {
      tipos_identificacion,
      tipo_identificacion,
      setTipo_identificacion,
      identificacion,
      onChange,
      primer_nombre,
      segundo_nombre,
      primer_apellido,
      segundo_apellido,
      correo,
      telefono1,
      celular1,
      adj_identificacion,
      adj_diploma,
      adj_saber,
      grupos,
      grupo,
      setGrupo,
      discapacidades,
      discapacidad,
      setDiscapacidad,
      adj_certificado,
      menor_edad,
      checked,
      modalMensajeValidacionId,
      mostrarModalMensajeValidacionId,
      validacion_doc,
      codigo_pruebas,
      puntaje_pruebas,
      // puntaje_sisben,
      // tiene_sisben,
      // opciones_sisben,
      // setOpcion_sisben,
      tipos_inscripcion_preg,
      tipo_inscripcion_preg,
      setTipoInscripcionPreg,
      habilitar_form,
      adj_docu_notas,
      nombre_acudiente,
      celular_acudiente,
      correo_acudiente,
      identificacion_saber,
      tipo_identificacion_saber,
      setTipo_identificacion_saber
    } = this.props.props_c;
    let { mensaje, cargando, datos } = this.state

    return (
      <React.Fragment>
        <MensajeValidacionId modal={modalMensajeValidacionId} mostrarmodal={mostrarModalMensajeValidacionId} mensaje={mensaje} cargando={cargando} />
        <Typography variant="h6" gutterBottom>
          Datos Personales
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} className='oculto'>
            <TextField
              ref={this.fileInput}
              required
              type="file"
              id="adj_identificacion"
              name="adj_identificacion"
              label="Adjuntar Identificación"
              fullWidth
              autoComplete="billing address-line1"
              onChange={onChange}
              disabled={!habilitar_form}
            />
            <TextField
              ref={this.fileInput}
              required
              type="file"
              id="adj_diploma"
              name="adj_diploma"
              label="Adjuntar Diploma"
              fullWidth
              autoComplete="billing address-line1"
              onChange={onChange}
              disabled={!habilitar_form}
            />
            <TextField
              ref={this.fileInput}
              required
              type="file"
              id="adj_saber"
              name="adj_saber"
              label="Adjuntar Saber_11"
              fullWidth
              autoComplete="billing address-line1"
              onChange={onChange}
              disabled={!habilitar_form}
            />
            <TextField
              ref={this.fileInput}
              required
              type="file"
              id="adj_certificado"
              name="adj_certificado"
              label="Adjuntar Certificado Medico de discapacidad o comunidad especial"
              fullWidth
              autoComplete="billing address-line1"
              onChange={onChange}
              disabled={!habilitar_form}
            />
            <TextField
              ref={this.fileInput}
              required
              type="file"
              id="adj_docu_notas"
              name="adj_docu_notas"
              label="Adjuntar Documento de Notas"
              fullWidth
              autoComplete="billing address-line1"
              onChange={onChange}
              disabled={!habilitar_form}
            />
          </Grid>

          <Grid item xs={12} style={{ display: 'none' }}>
            <BuscarSelect datos={tipos_inscripcion_preg} cambiarEstado={(value) => setTipoInscripcionPreg(value)} valor={tipo_inscripcion_preg} id='select-motivo-solicitud' nombre='¿Cuál es el motivo de tu solicitud?' placeholder='Seleccione el motivo' />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={menor_edad}
                  onChange={checked}
                  id="menor_edad"
                  name="menor_edad"
                  color="primary"
                  disabled={!habilitar_form}
                />
              }
              label="¿Eres Menor de edad?"
            />
          </Grid>
          <Grid item xs={12}>
            <BuscarSelect datos={tipos_identificacion} cambiarEstado={(value) => setTipo_identificacion(value)} valor={tipo_identificacion} id='select-tipo-identificacion' nombre='¿Cuál es tu tipo de identificación?' placeholder='Seleccione Tipo' habilitado={habilitar_form} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={identificacion}
              required
              type="number"
              id="identificacion"
              name="identificacion"
              label="¿Cuál es tu número de identificación?"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
              onBlur={this.validar_id}
              disabled={!habilitar_form}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={primer_nombre}
              required
              id="primer_nombre"
              name="primer_nombre"
              label="¿Cuál es tu primer nombre?"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
              disabled={!habilitar_form}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={segundo_nombre}
              id="segundo_nombre"
              name="segundo_nombre"
              label="¿Cuál es tu segundo nombre?"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
              disabled={!habilitar_form}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={primer_apellido}
              required
              id="primer_apellido"
              name="primer_apellido"
              label="¿Cuál es tu primer apellido?"
              fullWidth
              autoComplete="lname"
              onChange={onChange}
              disabled={!habilitar_form}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={segundo_apellido}
              id="segundo_apellido"
              name="segundo_apellido"
              label="¿Cuál es tu segundo apellido?"
              fullWidth
              autoComplete="lname"
              onChange={onChange}
              disabled={!habilitar_form}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={telefono1}
              required
              type="number"
              id="telefono1"
              name="telefono1"
              label="¿Como es tu número fijo de contacto?"
              fullWidth
              autoComplete="lname"
              onChange={onChange}
              disabled={!habilitar_form}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
          <TextField
            value={telefono2}
            id="telefono2"
            type="number"
            name="telefono2"
            label="#Telefono Alterno"
            fullWidth
            autoComplete="lname"
            onChange={onChange}
          />
        </Grid> */}
          <Grid item xs={12}>
            <TextField
              value={celular1}
              required
              id="celular1"
              type="number"
              name="celular1"
              label="¿Cuál es tu número de celular?"
              fullWidth
              autoComplete="lname"
              onChange={onChange}
              disabled={!habilitar_form}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
          <TextField
            value={celular2}
            id="celular2"
            type="number"
            name="celular2"
            label="#Celular Alterno"
            fullWidth
            autoComplete="lname"
            onChange={onChange}
          />
        </Grid> */}
          <Grid item xs={12}>
            <TextField
              value={correo}
              required
              type="email"
              id="correo"
              name="correo"
              label="Correo Personal"
              fullWidth
              autoComplete="billing address-line1"
              onChange={onChange}
              disabled={!habilitar_form}
            />
            <Grid item xs={12} md={12} className={`alert_info_titulo_sin_fondo`}>
              <Button variant="text" size="small" color="inherit" >
                <NotificationImportantIcon style={{ height: 15 }} /> Nota</Button>
            </Grid>
            <Grid item xs={12} md={12} className={`alert_info_sin_borde`}>
              <Typography variant="body2" component="p">Por favor verificar tu correo, ya que este es muy importante durante tu proceso.</Typography>
            </Grid>
          </Grid>

          {/* Informacion del acudiente */}

          <Grid item xs={12} sm={6}>
            <TextField
              value={nombre_acudiente}
              id="nombre_acudiente"
              name="nombre_acudiente"
              label="Nombre completo de tu acudiente"
              fullWidth
              autoComplete="lname"
              onChange={onChange}
              disabled={!habilitar_form}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              value={celular_acudiente}
              id="celular_acudiente"
              name="celular_acudiente"
              label="¿Cuál es el celular de tu acudiente?"
              type="number"
              fullWidth
              autoComplete="lname"
              onChange={onChange}
              disabled={!habilitar_form}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              value={correo_acudiente}
              id="correo_acudiente"
              name="correo_acudiente"
              label="Correo del acudiente"
              fullWidth
              autoComplete="lname"
              onChange={onChange}
              disabled={!habilitar_form}
            />
          </Grid>

          {/* Identifiacion con la que realizaste  la prueba SABER 11 */}
          <Grid item xs={12} sm={12} >
            <BuscarSelect datos={tipos_identificacion} cambiarEstado={(value) => setTipo_identificacion_saber(value)} valor={tipo_identificacion_saber} id='select-tipo-identificacion' nombre='¿Cuál es tu tipo de identificación con el que realizaste la prueba SABER 11??' placeholder='Seleccione Tipo' />
          </Grid>

          <Grid item xs={12} sm={12} >
            <TextField value={identificacion_saber} required type="number" id="identificacion_saber" name="identificacion_saber" label="¿Cuál es tu número de identificación con el que realizaste la prueba SABER 11?" fullWidth autoComplete="fname" onChange={onChange} />
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={codigo_pruebas}
              id="codigo_pruebas"
              name="codigo_pruebas"
              type="text"
              label="¿Cuál es tu código de registro de Pruebas Saber 11?"
              fullWidth
              autoComplete="lname"
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, ''); // Asegura que solo se ingresen números
                onChange({ target: { name: 'codigo_pruebas', value: `AC ${value}` } });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            // disabled={!habilitar_form}
            />

            <Grid item xs={12} md={12} className={`alert_info_titulo_sin_fondo`}>
              <Button variant="text" size="small" color="inherit" >
                <NotificationImportantIcon style={{ height: 15 }} /> Nota</Button>
            </Grid>
            <Grid item xs={12} md={12} className={`alert_info_sin_borde`}>
              <Typography variant="body2" component="p">Por favor verificar tu codigo, ya que este es muy importante durante tu proceso.</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={puntaje_pruebas}
              required
              type="number"
              id="puntaje_pruebas"
              name="puntaje_pruebas"
              label="¿Cuál es tu puntaje global en la Pruebas Saber 11?"
              fullWidth
              autoComplete="lname"
              onChange={onChange}
              inputProps={
                {
                  min: "1",
                  max: "500"
                }
              }
              validators={[`maxNumber:500`]}
              errorMessages={["El campo es requerido"]}
              disabled={!habilitar_form}
            />
          </Grid>

          {/* <Grid item xs={12}>
            <BuscarSelect datos={opciones_sisben} cambiarEstado={(value) => setOpcion_sisben(value)} valor={tiene_sisben} id='select-tipo-tiene-sisben' nombre='¿ Tienes Sisben ?' placeholder='Seleccione Opción' habilitado={habilitar_form} />
          </Grid>
          {tiene_sisben && tiene_sisben.value == 'si' &&
            <Grid item xs={12}>
              <TextField
                value={puntaje_sisben.toUpperCase()}
                required
                type="text"
                id="puntaje_sisben"
                name="puntaje_sisben"
                label="¿Cuál es tu puntaje de Sisben?"
                fullWidth
                autoComplete="lname"
                onChange={onChange}
                disabled={!habilitar_form}
              />
            </Grid>
          } */}
          <Grid item xs={12}>
            <BuscarSelect datos={grupos} cambiarEstado={(value) => setGrupo(value)} valor={grupo} id='select-tipo-grupo' nombre='¿Perteneces a alguno de estos grupos?' placeholder='Seleccione Comunidad Especial' habilitado={habilitar_form} />
          </Grid>
          {grupo.codigo === 'GRU_DIS' &&
            <Grid item xs={12}>
              <BuscarSelect datos={discapacidades} cambiarEstado={(value) => setDiscapacidad(value)} valor={discapacidad} id='select-tipo-discapacidad' nombre='¿Que tipo de discapacidad tienes?' placeholder='Seleccione Discapacidad' habilitado={habilitar_form} />
            </Grid>
          }
          <Grid item xs={12}>
            <InputFile label='Adjuntar Identificación (Ambas caras)' id='adj_identificacion' value={adj_identificacion} required={(validacion_doc[0].valora === '1') ? true : false} />
          </Grid>
          <Grid item xs={12}>
            <InputFile label='Adjuntar Diploma o Acta de Grado' id='adj_diploma' value={adj_diploma} required={(validacion_doc[2].valora === '1') ? true : false} />
          </Grid>
          <Grid item xs={12}>
            <InputFile label='Adjunta tu documento de resultado pruebas saber 11' id='adj_saber' value={adj_saber} required={(validacion_doc[2].valora === '1') ? true : false} />
          </Grid>
          {(grupo !== '' && grupo.codigo !== 'GRU_NIN' && grupo.codigo !== 'GRU_LGB') &&
            <Grid item xs={12}>
              <InputFile label='Adjuntar Certificado medico de Discapacidad o Comunidad Especial' id='adj_certificado' value={adj_certificado} required={(validacion_doc[3].valora === '1') ? true : false} />
            </Grid>
          }
          {tipo_inscripcion_preg && tipo_inscripcion_preg.value == TIPO_HOMOLOGACION &&
            <Grid item xs={12}>
              <InputFile label='Adjuntar Documento de Notas' id='adj_docu_notas' value={adj_docu_notas} required={(validacion_doc[4].valora === '1') ? true : false} />
            </Grid>
          }
        </Grid>
      </React.Fragment>
    );
  }
}
