import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  FormHelperText,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
// FUNCIONES GENERALES
import AppBarModal from "../../general/AppBarModal";
import TareasTerminadas from "../../general/TareasTerminadas";
import { BtnForm } from "../../general/BotonesAccion";
import { obtenerValoresFiltros } from "../../../global/js/funciones";
import { PLAN_ESTUDIO } from "../helper";
// IMAGENES
import emma_w from "../../../global/imagenes/emma_w.png";

class SisefAgregar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
      seleccion: "",
      planes: [],
      titulo_carg: "Cargando ..",
      codigo_departamento: { valor: "" },
      codigo_programa: { valor: "" },
      codigo_asignatura: { valor: "" },
      asignatura_nombre: { valor: "" },
      resumen_plan: { valor: "" },
      perfil_profesor: { valor: "" },
      valores_plan: { valor: "" },
      plan_syll: { valor: "" },
    };
  }

  async componentDidMount() {
    this.setState({ cargando: false });
    this.obtenerDatosIniciales();
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true });
    let planes = await obtenerValoresFiltros([
      { llave: "generica", valor: PLAN_ESTUDIO },
      { llave: "estado", valor: "1" },
    ]);
    this.setState({
      planes: planes.sort((a, b) => a.valora - b.valora),
      cargando: false,
    });
  }

  reiniciarEstado() {
    this.setState({
      cargando: true,
      seleccion: "",
    });
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: { valor: value },
    });
  };

  onSubmit = (e) => {
    // let { tipoSolicitud } = this.props;
    // let array=[{id: 1, nombre: "Matematica"}, {id: 2, nombre: "Competencias Ciudadanas"}, {id: 3, nombre: "Estructura de datos"}]
    let { asignaturas } = this.props;
    let {
      codigo_asignatura,
      plan_syll,
      valores_plan,
      resumen_plan,
      perfil_profesor,
      asignatura_nombre,
    } = this.state;
    let obligatorio = "Este campo es obligatorio";
    let numero = "Este campo debe ser un numero";
    let creditos = "Ingrese una numero entre 6 y 21";
    let error = false;

    if (!codigo_asignatura.valor) {
      codigo_asignatura.error = obligatorio;
      error = true;
    }
    if (!plan_syll.valor) {
      plan_syll.error = obligatorio;
      error = true;
    }
    if (!valores_plan.valor) {
      valores_plan.error = obligatorio;
      error = true;
    }
    if (!resumen_plan.valor) {
      resumen_plan.error = obligatorio;
      error = true;
    }
    if (!perfil_profesor.valor) {
      perfil_profesor.error = obligatorio;
      error = true;
    }
    if (!error) {
      asignatura_nombre.valor = asignaturas.filter(
        (item) => item.codigo_asignatura == codigo_asignatura.valor
      )[0].nombre_asignatura;
      this.enviarDatos();
    } else
      this.setState({
        codigo_asignatura,
        plan_syll,
        valores_plan,
        resumen_plan,
        perfil_profesor,
      });
  };

  enviarDatos() {
    let {
      codigo_asignatura,
      plan_syll,
      valores_plan,
      resumen_plan,
      perfil_profesor,
      asignatura_nombre,
    } = this.state;
    let { periodo_actual } = this.props;
    this.setState({ cargando: true, titulo_res: "Enviando.." });
    let data = {
      codigo_asignatura: codigo_asignatura.valor,
      plan_syll: plan_syll.valor,
      valores_plan: valores_plan.valor,
      resumen_plan: resumen_plan.valor,
      perfil_profesor: perfil_profesor.valor,
      asignatura_nombre: asignatura_nombre.valor,
      periodo: periodo_actual,
    };
    this.props.guardarSisef(data, () =>
      this.setState({ cargando: false, titulo_res: "Cargando.." })
    );
  }

  conError(valor) {
    return valor ? true : false;
  }

  render() {
    let { modalAgregarSisef, mostrarAgregarSisef, asignaturas } = this.props;
    let {
      cargando,
      titulo_carg,
      planes,
      plan_syll,
      codigo_asignatura,
      resumen_plan,
      perfil_profesor,
      valores_plan,
    } = this.state;
    // let array=[{id: 1, nombre: "Matematica"}, {id: 2, nombre: "Competencias Ciudadanas"}, {id: 3, nombre: "Estructura de datos"}]
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={modalAgregarSisef}
        onClose={() => mostrarAgregarSisef(false)}
      >
        <AppBarModal
          titulo={"¡ Plan de Asignatura !"}
          mostrarModal={() => mostrarAgregarSisef(false)}
          titulo_accion="Cerrar"
          accion={() => mostrarAgregarSisef(false)}
        />
        <DialogContent className="scroll">
          {!cargando ? (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl
                  className="form-control"
                  error={this.conError(codigo_asignatura.error)}
                >
                  <InputLabel id="id_asignatura_syll_label">
                    Asignatura
                  </InputLabel>
                  <Select
                    labelId="id_asignatura_syll_label"
                    id="codigo_asignatura"
                    name="codigo_asignatura"
                    inputProps={{ min: "0", step: "1" }}
                    fullWidth
                    onChange={this.onChange}
                  >
                    {asignaturas.map((arr) => (
                      <MenuItem
                        key={arr.codigo_asignatura}
                        value={arr.codigo_asignatura}
                      >
                        {arr.nombre_asignatura}
                      </MenuItem>
                    ))}
                  </Select>
                  {codigo_asignatura.error && (
                    <FormHelperText>{codigo_asignatura.error}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl
                  className="form-control"
                  error={this.conError(plan_syll.error)}
                >
                  <InputLabel id="plan_estudio_syll_label">
                    Plan de estudio
                  </InputLabel>
                  <Select
                    labelId="plan_estudio_syll_label"
                    id="plan_syll"
                    name="plan_syll"
                    inputProps={{ min: "0", step: "1" }}
                    fullWidth
                    onChange={this.onChange}
                  >
                    {planes.map((plan) => (
                      <MenuItem key={plan.id} value={plan.id}>
                        {plan.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                  {plan_syll.error && (
                    <FormHelperText>{plan_syll.error}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="valores_plan"
                    type="text"
                    name="valores_plan"
                    label="Valores"
                    multiline
                    defaultValue=""
                    onChange={this.onChange}
                    error={this.conError(valores_plan.error)}
                    helperText={valores_plan.error}
                  ></TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="perfil_profesor"
                    name="perfil_profesor"
                    label="Perfil del profesor"
                    multiline
                    defaultValue=""
                    onChange={this.onChange}
                    error={this.conError(perfil_profesor.error)}
                    helperText={perfil_profesor.error}
                  ></TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="resumen_plan"
                    name="resumen_plan"
                    label="Resumen"
                    multiline
                    onChange={this.onChange}
                    error={this.conError(resumen_plan.error)}
                    helperText={resumen_plan.error}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            <TareasTerminadas
              mensaje={titulo_carg}
              marginTop="7%"
              imagen={emma_w}
              widthImg="7%"
            />
          )}
        </DialogContent>
        <DialogActions>
          <BtnForm
            texto="CANCELAR"
            callback={() => mostrarAgregarSisef(false)}
          />
          {!cargando && <BtnForm texto="GUARDAR" callback={this.onSubmit} />}
        </DialogActions>
      </Dialog>
    );
  }

}

SisefAgregar.propTypes = {
  mostrarAgregarSisef: PropTypes.func.isRequired,
  modalAgregarSisef: PropTypes.bool.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
};

export default SisefAgregar;
