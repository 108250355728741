import React, { useEffect } from 'react';
import { Card, CardContent, Dialog, DialogActions, DialogContent, Grid, makeStyles, Typography } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { useSelector } from 'react-redux';
import { SolicitarInstitucion, SolicitarRedAcademica } from './SolicitarInstitucion';
import { BtnForm } from '../../general/BotonesAccion';
import { obtenerValoresFiltros } from '../../../global/js/funciones';
import { SUBTIPOS_SOLICITUD_CONVENIO } from '../administrar/helper';

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: '100%',
    height: '120px',
    cursor: 'pointer',
    borderRadius: '16px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.3)',
      backgroundColor: 'rgb(183, 36, 34)',
      '& $typography': {
        color: 'white',
      },
    },
  },
  typography: {
    transition: 'color 0.3s ease',
    color: '#333',
    fontWeight: 500,
  },
  title: {
    color: '#444',
    fontSize: '1.25rem',
    fontWeight: 400,
    marginBottom: '16px',
  },
}));

const InstitucionesPrincipal = ({ modalOpci, mostrarModalOpci, periodo, tipoSolicitud }) => {
  const { modalSolInsti, modalSolRed } = useSelector((state) => state.redInternacionalizacion);
  const usuario = useSelector(state => state.redGlobal.usuario);
  const [opcion, setOpcion] = React.useState('');
  const [opciones, setOpciones] = React.useState([]);
  const classes = useStyles();

  useEffect(async () => { 
    let subtipos_solicitud = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': SUBTIPOS_SOLICITUD_CONVENIO }, { 'llave': 'valora', 'valor': 1 }, { 'llave': 'estado', 'valor': 1 }]);
    setOpciones(subtipos_solicitud);
  }, []);

  useEffect(() => {
    if (!modalSolInsti && opcion === 'institucion_sol') {
      setOpcion('');
    }
    if (!modalSolRed && opcion === 'red_aca_sol') {
      setOpcion('');
    }
  }, [modalSolInsti, modalSolRed]);

  return (
    <>
      <Dialog fullWidth={true} maxWidth="sm" open={modalOpci} onClose={mostrarModalOpci} disableBackdropClick={true}>
        <AppBarModal titulo={'¡ Agregar Información !'} mostrarModal={mostrarModalOpci} />
        <DialogContent className="scroll" style={{ padding: '7% 4%' }}>
          <Typography variant="h5" align="center" className={classes.title}>
            ¡Hola <span style={{ color: '#B70E0C' }}>{usuario.primer_nombre}</span>!
          </Typography>
          <Typography variant="subtitle1" align="center" style={{ margin: '2% 0px', color: '#555' }}>
            Selecciona el tipo de solicitud que deseas realizar.
          </Typography>
          <Grid container spacing={3} justifyContent="center" style={{ marginTop: '5%' }}>
            {opciones.map((opc, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card
                  onClick={() => setOpcion(opc.codigo)}
                  className={classes.card}
                >
                  <CardContent>
                    <Typography variant="h6" className={classes.typography}>
                      {opc.descripcion}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CERRAR" callback={mostrarModalOpci} />
        </DialogActions>
      </Dialog>
      {opcion === 'institucion_sol' && <SolicitarInstitucion periodo={periodo} tipoSolicitud={tipoSolicitud} subtipoSolicitud={opcion} mostrarModalOpci={mostrarModalOpci} />}
      {opcion === 'red_aca_sol' && <SolicitarRedAcademica  periodo={periodo} tipoSolicitud={tipoSolicitud} subtipoSolicitud={opcion} mostrarModalOpci={mostrarModalOpci} />}
    </>
  );
};

export default InstitucionesPrincipal;
