import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  FormHelperText,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
// FUNCIONES GENERALES
import AppBarModal from "../../general/AppBarModal";
import TareasTerminadas from "../../general/TareasTerminadas";
import { BtnForm } from "../../general/BotonesAccion";
import { crear_form_data, formulario, mostrarError, obtenerValoresFiltros } from "../../../global/js/funciones";
import { PLAN_ESTUDIO } from "../helper";
// IMAGENES
import emma_w from "../../../global/imagenes/emma_w.png";
import { actualizarMensaje } from "../../../redux/actions/actGlobal";

class SisefModificar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
      seleccion: "",
      planes: [],
      titulo_carg: "Cargando ..",
      resumen_plan: { valor: "", error: false },
      perfil_profesor: { valor: "" },
      valores_plan: { valor: "" },
      plan_syll: { valor: "" ,error:'' },
    };
  }

  async componentDidMount() {
    this.setState({ cargando: false });
    this.obtenerDatosIniciales();
    this.cargarDatos()

  }
  // async componentDidUpdate({ modalModificarSisef }, { plan_syll_id }) {
  //   let { plan_syll_id: plan_syllNew } = this.state;
  //   let { modalModificarSisef: modalModificarSisefNew, cargarDatos } = this.props;
  //   if (modalModificarSisef !== modalModificarSisefNew || plan_syllNew !== plan_syll_id && cargarDatos) {
  //     this.setState({ cargando: true, cargarDatos: false,  })
  //     // this.obtenerDatos()
  //   }
  // }
  

  async obtenerDatosIniciales() {
    this.setState({ cargando: true });
    let planes = await obtenerValoresFiltros([
      { llave: "generica", valor: PLAN_ESTUDIO },
      { llave: "estado", valor: "1" },
    ]);
    this.setState({
      planes: planes.sort((a, b) => a.valora - b.valora),
      cargando: false,
    });
  }

  reiniciarEstado() {
    this.setState({
      cargando: true,
      seleccion: "",
    });
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: { valor: value },
    });
  };
  cargarDatos(){
    let{resumen_plan, perfil_profesor, valores_plan, plan_syll}=this.state
    let{data_syllabus}=this.props
    console.log(data_syllabus)
    resumen_plan={valor:data_syllabus.resumen_plan, error:false} 
    perfil_profesor.valor=data_syllabus.perfil_profesor 
    valores_plan.valor=data_syllabus.valores_plan
    plan_syll.valor=data_syllabus.plan_syll.id
   this.setState({resumen_plan, perfil_profesor, valores_plan, plan_syll})

  }

  onSubmit = (e) => {
    // let { tipoSolicitud } = this.props;
    // let array=[{id: 1, nombre: "Matematica"}, {id: 2, nombre: "Competencias Ciudadanas"}, {id: 3, nombre: "Estructura de datos"}]

    let {
      plan_syll,
      valores_plan,
      resumen_plan,
      perfil_profesor,
    } = this.state;
    let obligatorio = "Este campo es obligatorio";
    let numero = "Este campo debe ser un numero";
    let creditos = "Ingrese una numero entre 6 y 21";
    let error = false;

    if (!plan_syll.valor) {
      plan_syll.error = obligatorio;
      error = true;
    }
    if (!valores_plan.valor) {
      valores_plan.error = obligatorio;
      error = true;
    }
    if (!resumen_plan.valor) {
      resumen_plan.error = obligatorio;
      error = true;
    }
    if (!perfil_profesor.valor) {
      perfil_profesor.error = obligatorio;
      error = true;
    }
    if (!error) {
      this.enviarDatos();
    } else
      this.setState({
        plan_syll,
        valores_plan,
        resumen_plan,
        perfil_profesor,
      });
  };


// Editar
// EditarSyllabus = async (data) => {
//   let { id_syllabus} = this.state;
//   let {actualizarMensaje, mostrarModificarSisef,obtenerDatosIniciales}= this.props
//   let form = await crear_form_data(data)
//   formulario(
//     `api/v1.0/sisef/${id_syllabus}/editar_syllabus`,
//     form,
//     "patch",
//     (error, estado, resp) => {
//       let titulo = "Ha ocurrido un error, contacte con el administrador.",
//         tipo = "error";
//       if (!error) {
//         if (estado === 200) {
//           tipo = "success";
//           titulo = resp.titulo;
//           obtenerDatosIniciales()
//           mostrarModificarSisef(false)
//         } else {
//           tipo = "info";
//           titulo = resp.titulo ? resp.titulo : mostrarError(resp);
//         }
//       }
//       actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
//     }
//   );
// };

  enviarDatos() {
    let {
      plan_syll,
      valores_plan,
      resumen_plan,
      perfil_profesor,id_syllabus,
    } = this.state;
    let {EditarSyllabus}=this.props;
    this.setState({ cargando: true, titulo_res: "Enviando.." });
    let data = {
      plan_syll: plan_syll.valor,
      valores_plan: valores_plan.valor,
      resumen_plan: resumen_plan.valor,
      perfil_profesor: perfil_profesor.valor,
      // periodo: periodo_actual,
    };
    EditarSyllabus(data);
    
  }

  conError(valor) {
    return valor ? true : false;
  }

  render() {
    let { modalModificarSisef, mostrarModificarSisef } = this.props;
    let {
      cargando,
      titulo_carg,
      planes,
      plan_syll,
      resumen_plan,
      perfil_profesor,
      valores_plan,
    } = this.state;
    // let array=[{id: 1, nombre: "Matematica"}, {id: 2, nombre: "Competencias Ciudadanas"}, {id: 3, nombre: "Estructura de datos"}]
    return (
      
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={modalModificarSisef}
        onClose={() => mostrarModificarSisef(false)}
      >
        <AppBarModal
          titulo={"¡ Plan de Asignatura Modificar !"}
          mostrarModal={() => mostrarModificarSisef(false)}
          titulo_accion="Cerrar"
          accion={() => mostrarModificarSisef(false)}
        />
        <DialogContent className="scroll">
          {!cargando ? (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={this.conError(plan_syll.error)}
                >
                  <InputLabel id="plan_estudio_syll_label">
                    Plan de estudio
                  </InputLabel>
                  <Select
                    labelId="plan_estudio_syll_label"
                    id="plan_syll"
                    name="plan_syll"
                    value={plan_syll.valor}
                    inputProps={{ min: "0", step: "1" }}
                    fullWidth
                    onChange={this.onChange}
                  >
                    {planes.map((plan) => (
                      <MenuItem key={plan.id} value={plan.id}>
                        {plan.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                  {plan_syll.error && (
                    <FormHelperText>{plan_syll.error}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="valores_plan"
                    type="text"
                    name="valores_plan"
                    label="Valores"
                    multiline
                    defaultValue=""
                    value={valores_plan.valor}
                    onChange={this.onChange}
                    error={this.conError(valores_plan.error)}
                    helperText={valores_plan.error}
                  ></TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="perfil_profesor"
                    name="perfil_profesor"
                    label="Perfil del profesor"
                    multiline
                    defaultValue=""
                    value={perfil_profesor.valor}
                    onChange={this.onChange}
                    error={this.conError(perfil_profesor.error)}
                    helperText={perfil_profesor.error}
                  ></TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="resumen_plan"
                    name="resumen_plan"
                    label="Resumen"
                    value={resumen_plan.valor}
                    multiline
                    onChange={this.onChange}
                    error={this.conError(resumen_plan.error)}
                    helperText={resumen_plan.error}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            <TareasTerminadas
              mensaje={titulo_carg}
              marginTop="7%"
              imagen={emma_w}
              widthImg="7%"
            />
          )}
        </DialogContent>
        <DialogActions>
          <BtnForm
            texto="CANCELAR"
            callback={() => mostrarModificarSisef(false)}
          />
          {!cargando && <BtnForm texto="Modificar" callback={this.onSubmit} />}
        </DialogActions>
      </Dialog>
    );
  }

}

SisefModificar.propTypes = {
  mostarmodificarSisef: PropTypes.func.isRequired,
  modalModificarSisef: PropTypes.bool.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
};

export default SisefModificar;
