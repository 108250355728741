import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogActions, FormControl, List, Paper, Stepper, Step, StepLabel, Grid, InputLabel, Select, MenuItem, TextField, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import AppBarModal from '../general/AppBarModal';
import { BtnForm, BtnDescargar, BtnCargar } from '../general/BotonesAccion';
import EnviarMultiplesArchivos from "../general/EnviarMultiplesArchivos";
import { consulta, obtenerValores, transformar, api, obtenerValoresFiltros, obtenerPermisosValorAlt } from "../../global/js/funciones";
import TareasTerminadas from '../general/TareasTerminadas';
import AlertasSimple from '../general/AlertasSimple';
import BuscarSelect from "../general/BuscarSelect";
import InputBuscar from '../general/InputBuscar';
import BuscarPersona from "../general/BuscarPersona";
import emma_w from '../../global/imagenes/emma_w.png'; 
import {CARRERAS_CUC,TIPOS_BECAS,OBSERVACIONES_BECAS,EMPRESAS_VIGENTES_BECAS} from './helper'

const configStep = ['Datos Iniciales', 'Requisitos'];

function Form(component_props) {
  let { tipos, carreras, carrera, tipo, telefono, celular, handleChangeTipo, handleChangeCarrera, onChange, empresas, empresa, setearEstado, accepted, buscar, becado, mensaje, check_becado, checked, programas_pregrado, subtipo, subtipos, becasPrim, becaP } = component_props
  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '40px' }}>
        <Grid item xs={12} style={{padding : '0 12px'}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={check_becado}
                onChange={checked}
                id="check_becado"
                name="check_becado"
                color="primary"
              />
            }
            label="¿La beca es para otra persona?"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={telefono}
            required
            type="number"
            id="telefono"
            name="telefono"
            label="Telefono"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={celular}
            required
            type="number"
            id="celular"
            name="celular"
            label="Celular"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl className='form-control'>
            <InputLabel>Programa</InputLabel>
            <Select
              id='programa'
              value={carrera}
              onChange={handleChangeCarrera}
              name='programa'
            >
            { !check_becado ?
              carreras.map(({ relacion }) => (
                <MenuItem key={relacion.id} value={relacion.id}>
                  {relacion.nombre}
                </MenuItem>
              ))
                :
                programas_pregrado.map((relacion) => (
                <MenuItem key={relacion.id} value={relacion.id}>
                  {relacion.nombre}
                </MenuItem>
              ))
            }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} >
          <BuscarSelect datos={carrera ? tipos : [{'label' : <em>Para mostrar debes seleccionar el programa</em>}]} cambiarEstado={(value) => carrera && handleChangeTipo(value)} valor={tipo} id='tipo' nombre='Tipo de Beca' placeholder='Seleccione Tipo de Beca' />
        </Grid>

        {tipo && tipo.codigo == 'bec_psem' &&
          <Grid item xs={12}>
            <BuscarSelect datos={becasPrim} cambiarEstado={(value) => setearEstado({becaP : value})} valor={becaP} id='becaP' nombre='Beca' placeholder='Seleccione Beca' />
          </Grid>
        }

        {tipo && (tipo.codigo == 'bec_emp' && accepted || becaP.codigo == 'bec_emp') &&
          <Grid item xs={12}>
            <BuscarSelect datos={empresas} cambiarEstado={(value) => setearEstado({empresa : value})} valor={empresa} id='empresa' nombre='Empresa Convenio' placeholder='Seleccione Empresa con Convenio Vigente' />
          </Grid>
        }

        {tipo && tipo.valorb == 'si' && accepted &&
          <Grid item xs={12}>
            <InputBuscar
              placeholder='Seleccione Persona'
              label={'¿ Para quien será la beca ?'}
              id='becado'
              value={(becado && becado.nombre_completo) && becado.nombre_completo}
              callback={() => setearEstado({buscar: true})} />
          </Grid>
        }

        { tipo && tipo.codigo == 'bec_epo' &&
          <Grid item xs={12}>
            <FormControl className='form-control'>
              <InputLabel>Subtipo</InputLabel>
              <Select
                id='subtipo'
                value={subtipo}
                onChange={onChange}
                name='subtipo'
              >
                {
                  subtipos.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        }

    { tipo && tipo.codigo == 'bec_sem' &&
          <Grid item xs={12}>
            <FormControl className='form-control'>
              <InputLabel>Subtipo</InputLabel>
              <Select
                id='subtipo'
                value={subtipo}
                onChange={onChange}
                name='subtipo'
              >
                {
                  subtipos.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        }

        <Grid item xs={12} >
          <FormControl className="form-control">
            <TextField
              value={mensaje}
              id="observaciones"
              label={"Observaciones"}
              name="motivo"
              placeholder={"Escriba Observaciones"}
              multiline
              InputLabelProps={{
                shrink: true,
              }}
            onChange={({target : {value}}) => setearEstado({mensaje: value})}
            />
            </FormControl>
        </Grid>
      </Grid>
      <Dialog fullWidth={true} maxWidth="sm" open={buscar} onClose={() => setearEstado({buscar: false})}>
        <AppBarModal titulo={'¡ Buscar Persona !'} mostrarModal={() => setearEstado({buscar: false})} titulo_accion="CERRAR" accion={() => this.setState({ buscar: false })} />
        <DialogContent style={{ padding: '0' }} className='scroll'>
          <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => setearEstado({
            becado: {
              id,
              nombre_completo: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
            }, buscar: false
          })} />
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => setearEstado({buscar: false})} />
        </DialogActions>
      </Dialog>
    </>
  )
}

function AttachComponent(component_props) {
  let { requisitos, submit } = component_props

  const trasnformarDataAdj = (data) => {
    let r = [];
    data.map(({ secundario }) => r.push({ nombre: secundario.nombre, descripcion: secundario.descripcion, codigo: secundario.id, archivo: secundario.archivo }))
    return r;
  }

  const acciones = (data, callback) => {
    const onClickCargar = (data) => callback(data);
    const onClickDescargar = ({ archivo }) => {
      if (archivo) window.open(`${api}${archivo}`, '_blank')
    }
    let descargar = (data.archivo ) && <BtnDescargar callback={() => onClickDescargar(data)} color='#01579b' texto='Abrir' />
    let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />
    return <div>{descargar} {cargar}</div>;
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} square>
            <List component="nav" arial-label="main">
              <EnviarMultiplesArchivos
                acciones={acciones}
                enModal={false}
                enviarArchivos={(archivos) => submit(archivos)}
                limite={2}
                mostrarMensaje={false}
                enLista={true}
                lista={trasnformarDataAdj(requisitos)}
              />
            </List>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}


class BecasCrear extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tipo: '',
      carrera: '',
      initialTransition: true,
      accepted: false,
      activeStep: 0,
      archivos: [],
      concat: false,
      telefono: '',
      celular : '',
      requisitos: [],
      cargando: true,
      titulo_carg : 'Cargando ...',
      empresas : [],
      empresa : '',
      mensaje_validacion: '',
      buscar : false,
      becado : { id: 0, nombre_completo: '' },
      mensaje : '',
      check_becado: false,
      programas_pregrado : [],
      cargar: true,
      obs_gen : [],
      subtipo: '',
      subtipos: [],
      becaP: { value: 0, label: '', codigo: '' },
      becasPrim: []
    }
  }

  async componentDidMount() {
    let { obtenerTiposBecas, obtenerCarreras, telefono, celular } = this.props
    this.setState({ cargando: true })
    await obtenerTiposBecas()
    await obtenerCarreras()
    this.setState({ telefono, celular, cargando: false })
  }

  async componentDidUpdate(prevProps, { check_becado, becaP }) {
    let { carreras, telefono, celular, mensaje_beca } = this.props
    let { check_becado: check_becado_new, becaP: becaPNew } = this.state
    if((carreras && carreras !== prevProps.carreras) && carreras.length === 1) {
      this.setState({ carrera: carreras[0].relacion.id })
    }
    if(telefono && telefono !== prevProps.telefono) {
      this.setState({ telefono })
    }
    if(celular && celular !== prevProps.celular) {
      this.setState({ celular })
    }
    if (mensaje_beca && mensaje_beca !== prevProps.mensaje_beca) {
      const obs_gen = mensaje_beca.map((mensaje) => ({ item: mensaje }));
      this.setState({ obs_gen });
    }
    
    if(check_becado !== check_becado_new && this.state.cargar) {
      this.obtenerTodosProgramas()     
    }

    if(becaP.value !== becaPNew.value) {
      this.setState({ cargando: true });
      let requ = await this.obtenerRequisitos2(becaPNew.value)
      if(becaPNew.codigo == 'bec_emp') await this.obtenerEmpresas()
      this.setState({ cargando: false, requisitos: requ });
    }

  } 

  async obtenerTodosProgramas() {
    this.setState({ cargando: true })
    let programas_pregrado = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': CARRERAS_CUC}, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valorc', 'valor': 'pregrado'}]);
    this.setState({ programas_pregrado, cargar: false, cargando: false });
  }

  handleChangeTipo = async (valor) => {
    this.setState({ tipo : valor })
    let { carrera } = this.state
    let id_tipo = valor.value
    await this.obtenerRequisitos(id_tipo)
    await this.obtenerObservaciones(id_tipo)
    // await this.obtenerSubtipos(id_tipo)
    let validation = id_tipo && carrera ? await this.validarBeca() : false
    let { tipo } = this.state
    if (tipo.codigo === 'bec_emp' ) await this.obtenerEmpresas()
    if (tipo.codigo === 'bec_epo' ) await this.obtenerSubtipos(id_tipo)
    if (tipo.codigo === 'bec_psem' ) await this.obtenerBecasPrimer()
    if (tipo.codigo === 'bec_sem' ) await this.obtenerSubtipos(id_tipo)

    this.setState({
      initialTransition: false
    })

    setTimeout(() => {
      this.setState({ accepted: validation, initialTransition: true })
    }, 200);
  };

  handleChangeCarrera = async ({ target }) => {
    this.setState({ carrera: target.value, initialTransition: false })
    let { tipo, carrera } = this.state
    let validation = tipo && carrera ? await this.validarBeca() : false

    setTimeout(() => {
      this.setState({ accepted: validation, initialTransition: true })
    }, 200)
  }

  handleStep = direction => {
    let { actualizarMensaje } = this.props
    let { activeStep, carrera, tipo, telefono, celular, empresa, becado, subtipo, becaP } = this.state
    let sw = true
    
    if ((!carrera || !tipo || !telefono || !celular || (!empresa && (tipo.codigo === 'bec_emp' || becaP.codigo === 'bec_emp')) || (!subtipo && tipo.codigo === 'bec_epo') || (!subtipo && tipo.codigo === 'bec_sem')|| (!becado.id && tipo.valorb === 'si') || (!becaP.value && tipo.codigo === 'bec_psem')) && activeStep == 0) {
      actualizarMensaje({ titulo: 'Debe diligenciar todos los campos obligatorios para continuar', tipo: 'info', mostrar: true, tiempo: 6000 });
      sw = false
    }
    if(sw) this.setState({ activeStep: direction ? activeStep + 1 : activeStep - 1 })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  checked = ({ target }) => {
    this.setState({
      [target.name]: target.checked,
      carrera: ''
    })
  }

  obtenerRequisitos(tipo) {
    this.setState({ cargando: true });
    consulta(`api/v1.0/becas/${tipo}/requisitos`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ requisitos: resp });
    })
  }

  
  obtenerRequisitos2(tipo) {
    return new Promise(resolve => {
      consulta(`api/v1.0/becas/${tipo}/requisitos`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  obtenerObservaciones = async (tipo) => {
    this.setState({ cargando: true });
    let { mensaje_beca } = this.props;
    let mensajes = Array.isArray(mensaje_beca) ? mensaje_beca : mensaje_beca.split(". "); 
    let obs = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': tipo }, { 'llave': 'secundario__generica', 'valor': OBSERVACIONES_BECAS }]);
    let obs_gen = mensajes.map((mensaje) => ({ item: mensaje })); 
    obs.map(({ secundario }) => obs_gen.push({ item: secundario.nombre }));
    this.setState({ obs_gen });
  }

  async obtenerEmpresas() {
    const valores = await obtenerValores(EMPRESAS_VIGENTES_BECAS);
    const empresas = transformar(valores)
    this.setState({ empresas });
  }

  async obtenerSubtipos(tipo) {
    // let valores = await obtenerValores(67);
    // let subtipos = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': tipo }, { 'llave': 'secundario__generica', 'valor': 67 }])
    // let subtipos = transformar(valores)
    let valores = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': tipo }, { 'llave': 'secundario__generica', 'valor': 67 }])
    valores = valores.map(x => x.secundario)
    let subtipos = transformar(valores)
    console.log(tipo)
    this.setState({ subtipos });
    
  }

  async obtenerBecasPrimer() {
    const mi_transformar = data => {
      return data.map(e => ({
        value: e.id,
        label: e.nombre,
        codigo: e.codigo
      }));
    }

    let valores = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': 'bec_psem' }, { 'llave': 'secundario__generica', 'valor': TIPOS_BECAS}])
    valores = valores.map(x => x.secundario)
    let becasPrim = mi_transformar(valores)
    this.setState({ becasPrim });
  }

  // async obtener_Becas_subtipos() {
  //   const mi_transformar = data => {
  //     return data.map(e => ({
  //       value: e.id,
  //       label: e.nombre,
  //       codigo: e.codigo
  //     }));
  //   }

  //   let valores = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': 'bec_psem' }, { 'llave': 'secundario__generica', 'valor': 18 }])
  //   valores = valores.map(x => x.secundario)
  //   let becasPrim = mi_transformar(valores)
  //   this.setState({ becasPrim });
  // }

  validarBeca = () => {
    let { actualizarMensaje, mostrarModalValidacion } = this.props
    let { carrera, tipo : {value : tipo} } = this.state
    let data = { carrera, tipo }
    this.setState({ cargando: true, titulo_carg : 'Espere un momento. Estamos validando la informacion..' , mensaje_validacion : ''})
    return new Promise(resolve => {
      consulta(`api/v1.0/becas/validar`, data, "post", (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            titulo = resp.titulo;
            resolve(resp.result)
          } else {
            titulo = resp.titulo
            resolve(resp.result)
          }
          this.setState({ cargando: false, titulo_carg: 'Cargando...', mensaje_validacion: titulo })
          mostrarModalValidacion(true)
        } else {
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        }
      }
      )
    })
  }

  agregarArchivos = files => {
    let { actualizarMensaje } = this.props
    let { archivos, requisitos } = this.state
    files.map((archivo => {
      if (archivos.length === 10000) actualizarMensaje({ titulo: `Se ha superado el numero maximo de archivos ${requisitos.length}`, tipo: 'info', mostrar: true, tiempo: 6000 })
      else archivos.push(archivo)
    }))
    this.setState({ archivos })
  }

  eliminarArchivo = (i) => {
    let { actualizarMensaje } = this.props;
    let { archivos } = this.state;
    archivos.splice(i, 1)
    this.setState({ archivos })
    actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
  }

  reiniciarEstado() {
    let { mensaje_beca } = this.props
    this.setState({
      activeStep: 0, 
      tipo: '', 
      requisitos : [], 
      accepted: false,  
      cargando: false, 
      titulo_carg : 'Cargando...', 
      empresa : '', 
      mensaje: '', 
      becado : { id: 0, nombre_completo: '' }, 
      check_becado : false, 
      carrera: '',
      obs_gen : [{item : mensaje_beca}],
      subtipo: ''
    })
  }

  submit = (archivos) => {
    let { requisitos, telefono, carrera, tipo, celular, empresa, becado, mensaje, subtipo, becaP } = this.state
    let { guardarBeca, actualizarMensaje} = this.props
    let total = 0;
    requisitos.map(({ secundario  }) => { if (secundario.valorb === 'si') total++ })
    if (archivos.length >= total) {
      this.setState({ cargando: true, titulo_carg : 'Enviando...' })
      if(becaP.value){
        mensaje = mensaje + `(${becaP.label})`
      }
      let datos = { telefono, celular, programa: carrera, tipo_beca: tipo.value, total: archivos.length, empresa : empresa.value ? empresa.value : '', becado: becado.id, mensaje, subtipo }
      guardarBeca(archivos, datos, () => this.reiniciarEstado())
    } else actualizarMensaje({ titulo: 'Debe adjuntar un archivo por cada requisito obligatorio.', tipo: 'info', mostrar: true, tiempo: 6000 });    
  }

  configStepContent = () => {
    let { activeStep, tipo, carrera, initialTransition, accepted, archivos, telefono, celular, requisitos, empresas, empresa, buscar, becado, mensaje, check_becado, programas_pregrado, subtipo, subtipos, becasPrim, becaP } = this.state
    let { tipos, carreras, actualizarMensaje } = this.props

    switch (activeStep) {
      case 0:
        return (
          <Form
            {...{
              tipo,
              carrera,
              telefono,
              celular,
              initialTransition,
              accepted,
              tipos,
              carreras,
              empresas,
              empresa,
              buscar,
              becado,
              subtipo,
              subtipos,
              mensaje,
              check_becado,
              programas_pregrado,
              becasPrim,
              becaP,
              accepted,
              handleChangeTipo: this.handleChangeTipo,
              handleChangeCarrera: this.handleChangeCarrera,
              onChange: this.onChange,
              checked : this.checked,
              setearEstado : (estados) => this.setState(estados),
            }}
          />
        )
      case 1:
        return (
          <AttachComponent
            {...{
              initialTransition,
              requisitos,
              archivos,
              actualizarMensaje,
              eliminarArchivo: this.eliminarArchivo,
              agregarArchivoArray: this.agregarArchivoArray,
              agregarArchivos: this.agregarArchivos,
              submit : this.submit
            }}
          />
        )
      default:
        return <TareasTerminadas imagen={emma_w} mensaje='Esta bandeja esta vacía' widthImg='10%' marginTop='10%' />
    }
  }

  render() {
    let { modalAddBeca, mostrarModalAddBeca, modalValidacion, mostrarModalValidacion } = this.props
    let { accepted, activeStep, cargando, titulo_carg, mensaje_validacion, obs_gen  } = this.state

    return (
      <>
        <Dialog fullWidth={true} maxWidth="sm" open={modalAddBeca} onClose={() => mostrarModalAddBeca(false)}>
          <AppBarModal titulo={"¡ Nueva Solicitud !"} mostrarModal={() => mostrarModalAddBeca(false)} titulo_accion="Cerrar" accion={() => mostrarModalAddBeca(false)}/>
          <DialogContent className='scroll'>
            {
              !cargando ?
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Stepper activeStep={activeStep}>
                      {
                        configStep.map(label => (
                          <Step key={label}>
                            <StepLabel><p>{label}</p></StepLabel>
                          </Step>
                        ))
                      }
                    </Stepper>
                  </Grid>
                  <Grid item xs={12}>
                    {this.configStepContent()}
                  </Grid>
                </Grid> :
                <TareasTerminadas mensaje={titulo_carg} marginTop='7%' imagen={emma_w} widthImg="7%" />
            }
          </DialogContent>
          <DialogContent>
            <AlertasSimple
              tipo='info'
              titulo={'Estimado Estudiante, tener en cuenta: '}
              lista={obs_gen}
              margin = '0px'
            />
          </DialogContent>
          <DialogActions>
            {accepted ?
              activeStep > 0 ?
                <div>
                  <BtnForm texto="ATRAS" callback={() => this.handleStep(false)} />
                  <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_multi_archivo').click() }} />
                </div>
                : <BtnForm texto="SIGUIENTE" callback={() => this.handleStep(true)} type="submit" />
              : <div style= {{padding : '18px'}}></div>
            }
          </DialogActions>

        </Dialog >

        <Dialog open={modalValidacion} maxWidth="xs" fullWidth={true} onClose={() => mostrarModalValidacion(false)} >
          <AppBarModal titulo='¡ Mensaje !' mostrarModal={mostrarModalValidacion} titulo_accion='' />
          <DialogContent className='scroll' >
            <Typography variant="body1" color="textSecondary" component="p" align="justify">{mensaje_validacion}</Typography>
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => mostrarModalValidacion(false)} />
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

BecasCrear.propTypes = {
  modalAddBeca: PropTypes.bool.isRequired,
  obtenerTiposBecas: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  guardarBeca: PropTypes.func.isRequired,
}

export default BecasCrear
