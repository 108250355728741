// Producción
export const ESTADOS_INSCRIPCIONES = 43; 
export const GRUPOS_INSCRIPCIONES = 44; 
export const SEDES = 304;
export const DISCAPACIDADES_INSCRIPCIONES = 45; 
export const VALIDACION_DOCUMENTOS_INSCRIPCIONES = 48; 
export const TIPO_INSCRIPCIONES = 99; 
export const LINEAEDU = 100; 
export const JORNADAPREINSCRIPCION = 101; 
export const GENEROS = 69; 
// export const OPCIONES_ESTUDIOS = 102// local2045
export const AUTORIZACION_PREINSCRIPCION = 103;  
export const COLEGIOS_PREUNIVERSITARIO = 104; 
export const CALIFICACION_CONTACTO = 105; 
export const METODO_PAGO = 106; 
export const RESULTADOS_PREUNIVERSITARIO = 107;
export const COSTEAR_ESTUDIOS = 102; 
export const EPS = 267; 
export const EPS_OTRO = 24723; 
export const TIPO_PRIMER_SEMESTRE = 16879 
export const TIPO_HOMOLOGACION = 29522
export const TIPO_IDENTIFICACION = 2;

// Local
// export const PROCESOS_EXTERNOS = 4069;
// export const ESTADOS_INSCRIPCIONES = 4070;
// export const SEDES = 304;
// export const GRUPOS_INSCRIPCIONES = 4071;
// export const DISCAPACIDADES_INSCRIPCIONES = 4072;
// export const VALIDACION_DOCUMENTOS_INSCRIPCIONES = 4073;
// export const TIPO_INSCRIPCIONES = 4074; 
// export const LINEAEDU = 4075;
// export const JORNADAPREINSCRIPCION = 4076;
// export const GENEROS = 4077;
// // // export const OPCIONES_ESTUDIOS = 102// local2045
// export const AUTORIZACION_PREINSCRIPCION = 4078; 
// export const COLEGIOS_PREUNIVERSITARIO = 4079; 
// export const CALIFICACION_CONTACTO = 4080;
// export const METODO_PAGO = 4081;
// export const RESULTADOS_PREUNIVERSITARIO = 4082; 
// export const COSTEAR_ESTUDIOS = 4083;
// export const EPS = 4084; 
// export const EPS_OTRO = 7549;
// export const TIPO_PRIMER_SEMESTRE = 7514;
// export const TIPO_HOMOLOGACION = 7548;
// export const TIPO_IDENTIFICACION = 2;