import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
//Funciones
import ConveniosCrear from './convenios/ConveniosCrear';
import Registros from './registros/Registros';
import { MenuInternacionalizacion } from './administrar/MenuInternacionalizacion';
import { consulta, crear_form_data, formulario, generarFiltros, mostrarError, obtenerValoresFiltros } from '../../global/js/funciones';
import { mostrarModulo, actualizarMensaje } from '../../redux/actions/actGlobal';
import { mostrarModalAddConvenio, mostrarModalAddMovEstudiantil, mostrarModalAddRegistro, mostrarModalDetSolicitud, mostrarModalAdjuntarDocumentos,
  mostrarModalModAdjuntos, mostrarModalAprobarAdjunto, mostrarModalNegarAdjunto, mostrarModalCancelarSolicitud, mostrarModalListaUniversidades, mostrarModalAddFinanciaciones, mostrarModalSolInst } from '../../redux/actions/actInternacionalizacion';
import TituloAccion from '../general/TituloAccion';
import TareasTerminadas from '../general/TareasTerminadas';
import SolicitudActiva from './SolicitudActiva';
import { SolicitudDetalle } from './DetalleSolicitud';
import { BtnDetalle, BtnForm, BtnRegresar } from '../general/BotonesAccion';
import MovEstudiantiles from './movilidades/MovEstudiantiles';
import ListarDatos from '../general/ListarDatos';
import InternacionalizacionDetalle from './InternacionalizacionDetalle';
import AccionesRapidas from '../general/AccionesRapidas';
import BarraProceso from '../general/BarraProceso';
import AppBarModal from '../general/AppBarModal';
import CustomDropzone from '../general/CustomDropzone';
//Material Ui
import { Grid, Paper, Card, CardHeader, CardContent,
  Avatar, Typography, Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { red, blue, yellow } from '@material-ui/core/colors';
//Imágenes
import emma_s from '../../global/imagenes/emma_s1.png';
import emma_w from '../../global/imagenes/emma_w.png';
import revisar from '../../global/imagenes/revisar.png';
import study from '../../global/imagenes/study.png';
import pencil from '../../global/imagenes/pencil.png';
import file from '../../global/imagenes/file.png';
import { TIPOS_SOLICITUDES, TIPO_SOLICITUD_REGISTROS, PERFILES_ADMINISTRATIVOS, PERFIL_ESTUDIANTE, PERFIL_DOCENTE } from './administrar/helper';
import ConfirmarAccion from '../general/ConfirmarAccion';
import DescargarPdfRegistro from './registro/DownloadPdf';
import CardSolicitud from './ui/CardSol';
import Instituciones from './ies/Instituciones';
import InstitucionesPrincipal from './ies/Instituciones';

const obtenerColor = (codigo) => {
  const colores = {
      'Con_Int': { 'backgroundColor': blue[500] },
      'Mov_Est_Int': { 'backgroundColor':  red[500] },
      'Reg_Mov_Int': { 'backgroundColor':  yellow[500] },
  }
  return (colores[codigo])
}

export function AdjuntarDocumentos({ archivos, agregarArchivos, eliminarArchivo, modalAdjuntos, mostrarModalAdjuntos, onSubmit }) {
  return (
    <Dialog fullWidth = { true } maxWidth = "sm" open = { modalAdjuntos } onClose = { mostrarModalAdjuntos.bind(this, false) } aria-labelledby = "alert-dialog-title" aria-describedby="alert-dialog-description">
      <AppBarModal titulo = { '¿ Adjuntar Documentos ?' } mostrarModal = { mostrarModalAdjuntos } titulo_accion = "" accion = "" />
      <DialogContent style = { { padding: '1' } } className = 'scroll'>
        <CustomDropzone archivos = { archivos } addFunction = { agregarArchivos } deleteFunction = { eliminarArchivo } />
      </DialogContent>
      <DialogActions>
        <BtnForm texto = "CANCELAR" callback = { () => mostrarModalAdjuntos(false) } />
        <BtnForm texto = "CARGAR" callback = { onSubmit } />
      </DialogActions>
    </Dialog>
  )
}

class Internacionalizacion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      perfil_usuario: '',
      periodo: '',
      seleccion: 'nuevo',
      seleccionNew: 'inicio',
      tipoActual: '',
      tipoMovilidad: '',
      tipo_solicitud_seleccionada: '',
      titulo_res: 'Cargando..',
      vistaDetalle: 'detalle',
      id_solicitante: 0,
      cargando: true,
      detalleActual: null,
      enListado: true,
      procesoActual: null,
      solicitudActual: { id: 0 },
      solicitud_cancelada: false,
      archivos: [],
      historial: [],
      lineaEstados: [],
      listaSolicitudes: [],
      tiposSolicitudesInt: [],
      solicitudesRegistro: [],
      id_solicitud: 0,
      descargandopdf: false,
      info_registro_pdf: [],
      instructivo: [],
      // REGISTRO DE MOVILIDAD
      actividad_movilidad: [],
      modalOpci: false,
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta:'/internacionalizacion', nombre: 'Internacionalización' });
    this.obtenerDatosIniciales();
    let tiposSolicitudesInt = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_SOLICITUDES }, { 'llave': 'valorb', 'valor': 1 }, { 'llave': 'estado', 'valor': 1 }]);
    this.setState({ tiposSolicitudesInt });
    // this.obtenerSolicitudesRegistro(); //ok
  }

  async componentDidUpdate({modalAddConvenio}, { seleccion, solicitudActual, id_solicitud }) {
    let { seleccion: seleccionNew, solicitudActual: solicitudActualNew, id_solicitud: id_solicitudNew } = this.state;
    let {modalAddConvenio:modalAddConvenioNew} = this.props;
    if (solicitudActual != solicitudActualNew && seleccionNew === 'proceso' && solicitudActualNew != null) this.obtenerDataProcesoActual(solicitudActualNew.id);
    if (seleccion !== seleccionNew) {
      if (seleccionNew === 'proceso') this.obtenerDatosIniciales();
        else if (seleccionNew === 'historial') this.obtenerHistorial();
        // this.obtenerSolicitudesRegistro(); //ok
    }
    if(modalAddConvenio!==modalAddConvenioNew){
      if(!modalAddConvenioNew)this.obtenerDatosIniciales()
    }
    // if (id_solicitud !== id_solicitudNew) {
    //   if (id_solicitudNew !== 0) {
    //     let data = await this.obtenerInfoRegMov(id_solicitudNew);
    //     this.setState({ info_registro_pdf: data });
    //   }
    // }
  }

  crearBorradorSolicitud = async (data, callback, lista_opc = [], lista_opc2 = [], lista_opc3 = [], lista_opc4 = [], lista_opc5 = [],lista_opc6=[]) => {
    let { actualizarMensaje } = this.props;
    let { tipo_solicitud_seleccionada } = this.state;
    let form = await crear_form_data(data);
    form.append('tipo_solicitud', tipo_solicitud_seleccionada);
    if (tipo_solicitud_seleccionada == TIPO_SOLICITUD_REGISTROS) {
      lista_opc.forEach((id, index) => form.append(`evidencia${index}`, id));
      lista_opc2.forEach(({ id }, index) => form.append(`institucion${index}`, id));
      lista_opc3.forEach((id, index) => form.append(`fac_dep${index}`, id));
      lista_opc4.forEach((id, index) => form.append(`fac${index}`, id));
      lista_opc5.forEach((id, index) => form.append(`unidad_adm${index}`, id));
      lista_opc6.forEach((id, index) => form.append(`array_tipo${index}`, id));
      
    }
    formulario(`api/v1.0/internacionalizacion/solicitudes/crear_borrador`, form, 'post', (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error', solicitud = {}
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          solicitud = resp.solicitud;
          callback(true, solicitud);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          callback(false);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }

  guardarConvenio = async (rankings, data, callback) => {
    let { mostrarModalAddConvenio, actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    rankings.map(({id, posicion}, index) => {
      form.append(`ranking${index}`, id)
      form.append(`posicion${index}`, posicion)
      return true;
    });
    formulario(`api/v1.0/internacionalizacion/solicitudes/crear`, form, 'post', (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error'
      if (!error) {
        if (estado === 200) {
          tipo = 'success'
          titulo = resp.titulo
          mostrarModalAddConvenio(false)
          this.setState({ seleccion: 'proceso' })
        } else {
          tipo = 'info'
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
      callback();
    })
  }

  cancelarSolicitud = id => {
    let { actualizarMensaje, mostrarModalCancelarSolicitud } = this.props;
    consulta(`api/v1.0/internacionalizacion/solicitud/${id}/cancelar`, {}, "put", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalCancelarSolicitud(false);
          this.setState({ solicitud_cancelada: true });
          this.obtenerDatosIniciales();
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  async obtenerAdjuntos(id, tipo = 1) {
    let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': id }, { 'llave': 'tipo', 'valor': tipo }]);
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitud/adjuntos?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  async obtenerInfoRegMov(id) {
    // let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': id }]);
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitud/obtenerRegistro/${id}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  async obtenerDataProcesoActual(id = 0) {
    this.setState({ cargando: true });
    let datos = await this.obtenerSolicitud(id);
    if (datos) {
      this.setState({
        procesoActual: datos.solicitud,
        detalleActual: datos.mov_estudiantil,
        tipoActual: datos.solicitud.tipo_solicitud,
        tipoMovilidad: datos.solicitud.tipo_movilidad,
        lineaEstados: datos.linea,
        solicitud_renovacion: datos.convenio ? datos.convenio.solicitud_renovacion : '',
      });
    }
    this.setState({ cargando: false });
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true, enListado: true });
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Int' }, { 'llave': 'estado', 'valor': '1' }]);
    let listaSolicitudes = await this.obtenerSolicitudes([{ 'llave': 'periodo', 'valor': periodo.length > 0 ? periodo[0].nombre : '' }]);
    let instructivo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Inter_Instr' }, { 'llave': 'estado', 'valor': '1' }]);
    this.obtenerPerfil();
    this.setState({ periodo: periodo.length > 0 ? periodo[0].nombre : '', listaSolicitudes, cargando: false, instructivo });
  }

  async obtenerHistorial() {
    this.setState({ cargando: true });
    let historial = await this.obtenerSolicitudes();
    this.setState({ historial, cargando: false });
  }

  async obtenerParticipantesRegistro(registro = '', solicitud = '') {
    let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': solicitud }]);
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/registro/participantes?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  async obtenerFinanciacionesRegistro(registro = '', solicitud = '') {
    let f = await generarFiltros([{ 'llave': 'registro', 'valor': registro }, { 'llave': 'registro__solicitud_id', 'valor': solicitud }]);
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/registro/financiaciones?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  async obtenerSolicitud(id = 0) {
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitud/${id}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async obtenerSolicitudes(filtros = []) {
    let f = await generarFiltros(filtros)
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitudes/listar/0?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null)
      })
    })
  }

  async obtenerSolicitudesRegistro() {
    let solicitudesR = await this.obtenerSolicitudes();
    let aux=[];
    solicitudesR.forEach(async sol => {
      let solicitudesR_detallada = await this.obtenerSolicitudesDetalle([{ 'llave': 'solicitud__tipo_solicitud__codigo', 'valor': sol.tipo_solicitud.codigo }, { 'llave': 'solicitud__periodo', 'valor': sol.periodo }, {'llave': 'solicitud', 'valor': sol.id}], sol.tipo_solicitud.codigo);
      solicitudesR_detallada.forEach(solR => {
        aux.push({
          /* ...sol */
          /* , ...solR */
          //info solicitud
          'id': sol.id, //ok
          'solicitud': sol.id, //ok
          'periodo': sol.periodo, //ok
          'tipo_solicitud': sol.tipo_solicitud.codigo, //ok
          'estado': sol.estado, //ok
          'fecha_registro': sol.fecha_registro, //ok
          'fecha_elimina': sol.fecha_elimina, //ok
          'estado_actual': sol.estado_actual, //ok
          'solicitante': sol.solicitante, //ok
          'tipo_solicitud': sol.tipo_solicitud, //ok
          'titulo_tema': solR.titulo_tema,

          //info registro
          'fecha_inicio': solR.fecha_inicio, //ok
          'fecha_final': solR.fecha_final, //ok
          'tipo_movilidad': solR.tipo_movilidad, //ok
          'tipo_actividad': solR.tipo_actividad, //ok
          'otro_tipo_actividad': solR.otro_tipo_actividad, //ok
          'subtipo_actividad': solR.subtipo_actividad,
          'otro_subtipo_actividad': solR.otro_subtipo_actividad,
          'estado': solR.estado, //ok
        })
      })
    })
    this.setState({ solicitudesRegistro: aux });
  }

  agregarArchivos = (arc) => {
		let { archivos } = this.state;
		archivos = archivos.concat(arc);
		this.setState({ archivos });
	}

  cargarArchivos(id, archivos) {
		let { actualizarMensaje } = this.props;
		if (archivos.length === 0) return true;
		const data = new FormData();
		data.append(`total`, archivos.length);
    data.append(`tipo`, 0);
		archivos.map((file, index) => data.append(`archivo${index}`, file));
		formulario(`api/v1.0/internacionalizacion/solicitud/${id}/adjuntar`, data, 'post', (error, estado, resp) => {
			if (error) {
				const title = 'Ha ocurrido un error al adjuntar los archivos, contacte con el administrador.';
				const icon = 'error';
				actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000 });
			}
		});
	}

  eliminarArchivo = (i) => {
		let { archivos } = this.state;
		let { actualizarMensaje } = this.props;
		archivos.splice(i, 1);
		this.setState({ archivos });
		actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 });
	}

  enviarAdjuntos = () => {
    let { actualizarMensaje, mostrarModalAdjuntarDocumentos } = this.props;
    let { archivos, procesoActual } = this.state;
    this.setState({ cargando: true, titulo_res: 'Enviando..' });
    if (procesoActual && archivos.length === 0) {
      actualizarMensaje({ titulo: 'Para enviar por favor adjunte documentos', tipo: 'info', mostrar: true, tiempo: 6000 });
    } else {
      this.cargarArchivos(procesoActual.id, archivos);
      actualizarMensaje({ titulo: 'Archivos cargados correctamente', tipo: 'success', mostrar: true, tiempo: 6000 });
      mostrarModalAdjuntarDocumentos(false);
      this.setState({ archivos: [] })
    }
    this.setState({ cargando: false, titulo_res: 'Cargando..' });
  }

  getEstados() {
		return new Promise((resolve) => {
			consulta(`api/v1.0/internacionalizacion/solicitudes/estados`, null, null, (error, estado, resp) => {
				resolve(resp);
			});
		});
	}

  lineaDeEstados = () => {
		let { cargando, lineaEstados, procesoActual } = this.state;
		if (!cargando && procesoActual && lineaEstados.length > 0) {
      let activeStep = 0;
			let steps = [];
			lineaEstados.map(({ secundario: { id, nombre } } , i) => {
        if (id === procesoActual.estado_actual.id) activeStep = i;
        steps.push({ label: nombre });
      });
      activeStep = procesoActual.estado_actual.codigo === 'Est_Int_Fin' ? lineaEstados.length + 1 : activeStep;
			return <BarraProceso steps = { steps } activeStep = { activeStep } />;
		}
		return null;
	}

  obtenerPerfil() {
    let { usuario } = this.props;
    if (usuario && usuario.perfil) {
      this.setState({ perfil_usuario: usuario.perfil.codigo });
    }
  }

  pintarHistorial() {
    let { mostrarModalDetSolicitud } = this.props;
    let { historial, solicitudesRegistro} = this.state;
    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Est_Int_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Est_Int_Cor': { 'backgroundColor': '#ffc107', color },
        'Est_Int_Rec': { 'backgroundColor': '#b71c1c', color },
        'Est_Int_Hom': { 'backgroundColor': '#01519B', color },
        'Est_Int_Rev': { 'backgroundColor': '#32C79E', color },
        'Est_Int_Pos': { 'backgroundColor': '#6D32C7', color },
        'Est_Int_Doc': { 'backgroundColor': '#009688', color },
        'Est_Int_Ace': { 'backgroundColor': '#C0C732', color },
        'Est_Int_Fin': { 'backgroundColor': '#C74632', color },
        'Est_Int_Neg': { 'backgroundColor': '#C77332', color },
      }
      return (colores[codigo]);
    }
    const obtenerColorConv = (codigo) => {
      let color = 'white';
      const colores = {
        'Est_Int_Bor': { 'backgroundColor': '#EEEEEE', 'color': '#757575' },
        'Est_Int_Env': { 'backgroundColor': '#01579b', color },
        'Sol_aval_jefe': { 'backgroundColor': '#ffc107', color },
        'En_Revi_Con': { 'backgroundColor': '#ffc107', color },
        'Con_rev_sec': { 'backgroundColor': '#ffc107', color },
        'Con_corr': { 'backgroundColor': '#ffc107', color },
        'Con_rev_ins_vin': { 'backgroundColor': '#ffc107', color },
        'Est_Int_Can': { 'backgroundColor': '	#FF0000', color },
        'Con_rev_sec_gen_2': { 'backgroundColor': '#ffc107', color },
        'con_corr_2': { 'backgroundColor': '#ffc107', color },
        'Con_ava_sec_gen': { 'backgroundColor': '	#ffc107', color },
        'Rev_ges_conv_2': { 'backgroundColor': '#ffc107', color },
        'Fir_ins_vincu': { 'backgroundColor': '#ffc107', color },
        'Ges_fir_sg': { 'backgroundColor': '#ffc107', color },
        'Con_sus': { 'backgroundColor': '#ffc107', color },
        'En_corr_soli': { 'backgroundColor': '#ffc107', color },
        'Fir_cuc': { 'backgroundColor': '#ffc107', color },
        'Con_aval_juri_ins_vin': { 'backgroundColor': '#ffc107', color },
        'Con_regis': { 'backgroundColor': '#008000', color },
        'sin_conv_uni': { 'backgroundColor': '#EEEEEE', color },
        'Sol_rechazada': { 'backgroundColor': '#FF0000', color },
      }
      return (colores[codigo])
    }
    const acciones = (data) => {
      const onClickDetalle = solicitud => {
        this.setState({ solicitudActual: solicitud })
        mostrarModalDetSolicitud(true)
      }
      let detalle = <BtnDetalle callback = { () => onClickDetalle(data) } color='#01579b' texto='Abrir' />;
      return detalle;
    }
    
    return (
      <div>
        <ListarDatos
          id = "tbl_historial"
          titulo = 'Lista de Solicitudes'
          datos = { historial }
          buscar = { true }
          opciones = { true }
          sub_titulo = "Lista de solicitudes"
          avatar = {({ tipo_solicitud, estado_actual }) =>  tipo_solicitud.codigo === 'Con_Int' ? estado_actual.nombre.charAt(0).toUpperCase() :  estado_actual.valora.charAt(0).toUpperCase()}
          color_avatar = {({ tipo_solicitud, estado_actual: { codigo } }) => tipo_solicitud.codigo === 'Con_Int' ? obtenerColorConv(codigo) : obtenerColor(codigo) }
          acciones = { (row) => acciones(row) }
          fila_principal = { ({ tipo_solicitud }) => tipo_solicitud.nombre.toUpperCase( )}
          filas={[
            { 'id': 'actividad', 'enLista': true, 'mostrar': ({ actividad}) => `${actividad && actividad.tipo_actividad && actividad.tipo_actividad.nombre !== null ? `Tipo de Actividad: ${actividad.tipo_actividad.nombre}` : ''}` },
            // {'id': 'tipo_solicitud', 'enLista': true, 'mostrar': ({ tipo_solicitud }) =>  `Tipo de solicitud: ${tipo_solicitud.nombre} `},
            {'id': 'tipo_solicitud', 'enLista': true, 'mostrar': ({ tipo_solicitud }) => tipo_solicitud.codigo !== 'Reg_Mov_Int' ? `Tipo de solicitud: ${tipo_solicitud.nombre} ` : ''},
            { 'nombre': 'Fecha de inicio: ', 'id': 'fecha_inicio', 'mostrar': ({ fecha_inicio }) => moment(fecha_inicio).format('YYYY-MM-DD') },
            { 'nombre': 'Fecha de finalización: ', 'id': 'fecha_final', 'mostrar': ({ fecha_final }) => moment(fecha_final).format('YYYY-MM-DD') },
            { 'nombre': 'Estado: ', 'id': 'estado', 'mostrar': ({ estado_actual }) => estado_actual.nombre }, //new
          ] 
          }
        />
      </div>
    )
  }

  // Pinta la opción seleccionada en Nuevo.
  pintarNuevo() {
    let { seleccionNew } = this.state;
    return (
      <>
        { seleccionNew === 'inicio' && this.pintarNuevoInicio() }
        { seleccionNew !== 'inicio' && <div className = { 'regresar' } ><BtnRegresar callback={ () => this.setState({ seleccionNew: 'inicio' }) } /></div> }
      </>
    );
  }

  // Pintar tarjetas con opciones para nueva solicitud.
  pintarNuevoInicio() {
    let { tiposSolicitudesInt, perfil_usuario } = this.state;
    return (
      <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 40, height: '70vh' }}>
        {
          tiposSolicitudesInt.sort((a, b) => a.valorh - b.valorh ).map(tipo => {
            return (
              <CardSolicitud tipo={tipo} onCardClick={() => this.tipoSolicitudSeleccion(tipo)} />
            )
          })
        }
      </Grid>
    )
  }

  pintarSolicitudes() {
    let { mostrarModalCancelarSolicitud } = this.props;
    let { listaSolicitudes } = this.state;
    if (!listaSolicitudes || listaSolicitudes.length === 0) {
      return (
        <div className = 'pointer' onClick = { () => this.setState({ seleccion: 'nuevo' }) }>
          <TareasTerminadas
            mensaje = 'No tienes solicitudes activas, clic aquí para hacer una solicitud'
            marginTop = '7%'
            imagen = { emma_s }
            widthImg = '7%'
          />
        </div>
      )
    }
    return (
      <div style = { { padding: '2% 2% 0% 2%' } }>
        <Grid container direction = { 'row' } spacing = { 1 }>
          <TituloAccion principal = '' alterno = '¡ Estas son tus solicitudes para este periodo !' />
          {
            listaSolicitudes.map((solicitud, i) => {
              // listaSolicitudes.sort((a, b) => a.fecha_registro > b.fecha_registro);
                return (
                  <Grid item xs = { 12 } sm = { 6 } md = { 4 } lg = { 4 } key = { i }>
                    <Paper elevation = { 1 } onClick = { () => this.setState({ enListado: false, solicitudActual: solicitud }) } className = 'pointer'>
                      <SolicitudActiva
                        solicitud = { solicitud }
                        callback = { (solicitudActual) => this.setState({ solicitudActual }) }
                        descripcion = { `Tu solicitud para ${ solicitud.tipo_solicitud.nombre } se encuentra en estado
                                        ${ solicitud.estado_actual.nombre }. Clic aquí para obtener mas información.` }
                        mostrarModalCancelarSolicitud = { mostrarModalCancelarSolicitud }
                        mostrarModalContinuarSolicitud = { (tipo) => this.tipoSolicitudSeleccion(tipo) }
                      />
                    </Paper>
                  </Grid>
                )
            })
          }
        </Grid>
      </div>
    )
  }

  // Mostrar info de proceso seleccionado
  pintarSolicitudSeleccionada() {
    let { mostrarModalDetSolicitud } = this.props;
    let { procesoActual, detalleActual, descargandopdf } = this.state;
    if (procesoActual) {
      const onClickDetalle = vistaDetalle => {
        this.setState({ vistaDetalle });
        mostrarModalDetSolicitud(true);
      }
      const onClickDocumentos = vistaDetalle => {
        this.setState({ vistaDetalle });
        mostrarModalDetSolicitud(true);
      }
      const onClickEditar = () => {
        this.tipoSolicitudSeleccion(procesoActual.tipo_solicitud);
      }
      const onClickDescargarPdf = (id) => { 
        this.setState({id_solicitud: id, descargandopdf: true})
      }
      return (
        descargandopdf ?
          <TareasTerminadas
            mensaje='Descargando registro, por favor espere..'
            marginTop='7%'
            imagen={emma_s}
            widthImg='7%'
            cargando={true}
          />
          : 
        <div className = ''>
          <Button onClick = { () => this.setState({ enListado: true, solicitudActual: {} }) } style = { { padding: "0px 3px 0px 3px", marginTop: '1%' } } color = "secondary" >
            <ArrowBackIcon />
            <Typography variant = "body2" style = { { color: '#9e9e9e' } } component = "p" align = 'justify'> Atrás </Typography>
          </Button>
          <div style = { { padding: '0% 2% 0% 2%' } }>
            <Grid container direction = { 'row' } spacing = { 1 }>
              <Grid item xs = { 12 } sm = { 12 } md = { 6 }>
                <TituloAccion principal = '' alterno = '¡ Información Básica !' />
                <Paper elevation = { 0 } square className = "scroll">
                  <SolicitudDetalle solicitud = { procesoActual } detalle = { detalleActual } />
                </Paper>
              </Grid>
              <Grid item xs = { 12 } sm = { 12 } md = { 6 }>
                <TituloAccion principal='' alterno='¿ Qué deseas hacer ?' />
                <Grid container direction = { 'row' } spacing = { 2 } style = { { width: "100%" } }>
                  <Grid item xs = { 12 } sm = { 12 } md = { 12 }>
                    <Paper elevation = { 3 } style = { { border: '1px solid #e0e0e0' } } onClick = { () => onClickDetalle('detalle') }>
                      <AccionesRapidas imagen = { revisar } titulo = '¡ Más Información !' sub_titulo = 'Bien' des_sub_titulo = ', Aquí puedes ver toda la información actual.' />
                    </Paper>
                  </Grid>
                  <Grid item xs = { 12 } sm = { 12 } md = { 12 }>
                    <Paper elevation = { 3 } style = { { border: '1px solid #e0e0e0' } } onClick = { () => onClickDocumentos('documentos') }>
                      <AccionesRapidas imagen = { study } titulo = '¡ Mis Documentos !' sub_titulo = 'Listo' des_sub_titulo = ', Aquí puedes administrar tus documentos.' />
                    </Paper>
                  </Grid>
                  {
                    procesoActual.estado_actual.codigo == 'Est_Int_Bor' &&
                      <Grid item xs = { 12 } sm = { 12 } md = { 12 }>
                        <Paper elevation = { 3 } style = { { border: '1px solid #e0e0e0' } } onClick = { () => onClickEditar() }>
                          <AccionesRapidas imagen = { pencil } titulo = '¡ Continuar Solicitud !' sub_titulo = 'Bien' des_sub_titulo = ', Aquí puedes continuar dilingenciando tu solicitud.' />
                        </Paper>
                      </Grid>
                  }
                  {
                    procesoActual.tipo_solicitud.codigo == 'Reg_Mov_Int' &&
                      <Grid item xs = { 12 } sm = { 12 } md = { 12 }>
                        <Paper elevation = { 3 } style = { { border: '1px solid #e0e0e0' } } onClick = { () => onClickDescargarPdf(procesoActual.id) }>
                          <AccionesRapidas imagen = { file } titulo = '¡ Descargar Registro !' sub_titulo = 'Bien' des_sub_titulo = ', Aquí puedes descargar el registro de tu solicitud.' />
                        </Paper>
                      </Grid>
                  }
                </Grid>
              </Grid>
              {
                (procesoActual.estado_actual.codigo != 'Est_Int_Neg' && procesoActual.estado_actual.codigo != 'Est_Int_Bor' && procesoActual.estado_actual.codigo != 'Est_Int_Can') &&
                  <Grid item xs = { 12 } sm = { 12 } md = { 12 }>
                    <TituloAccion principal = '' alterno = '¿ Cómo va mi proceso ?' />
                    { this.lineaDeEstados() }
                  </Grid>
              }
            </Grid>
          </div>
      </div>
      )
    }
  }

  tipoSolicitudSeleccion = (tipoSolicitud) => {
    let { mostrarModalAddConvenio, mostrarModalAddRegistro, mostrarModalAddMovEstudiantil, mostrarModalSolInst } = this.props;
    let { id, codigo } = tipoSolicitud;
    this.setState({ tipo_solicitud_seleccionada: id });
    return (
      <>
        { codigo === 'Mov_Est_Int' && mostrarModalAddMovEstudiantil(true) }
        { codigo === 'Con_Int' && mostrarModalAddConvenio(true) }
        { codigo === 'Reg_Mov_Int' && mostrarModalAddRegistro(true) }
        { codigo === 'Ies_Int' &&  this.setState({modalOpci: true})}
        {/* { codigo === 'Ies_Int' &&  mostrarModalSolInst(true) } */}
      </>
    )
  }

  vista = () => {
    let { seleccion, enListado } = this.state;
    return (
      <>
        { seleccion === 'proceso' && (enListado ? this.pintarSolicitudes() : this.pintarSolicitudSeleccionada()) }
        { seleccion === 'nuevo' && this.pintarNuevo() }
        { seleccion === 'historial' && this.pintarHistorial() }
      </>
    );
  }

  render() {
    let { actualizarMensaje, modalAddConvenio, modalAddRegistro, modalModAdjuntos, modalAprobarAdjunto,
      modalNegarAdjunto, mostrarModalAddConvenio, mostrarModalAddRegistro, mostrarModalDetSolicitud, modalDetSolicitud,
      modalAdjuntarDocumentos, mostrarModalAdjuntarDocumentos, mostrarModalModAdjuntos, mostrarModalAprobarAdjunto, mostrarModalNegarAdjunto,
      modalAddMovEstudiantil, mostrarModalAddMovEstudiantil, mostrarModalCancelarSolicitud, modalCancelarSolicitud, modalListaUniversidades,
      mostrarModalListaUniversidades, perfil_usuario, mostrarModalSolInst, modalSolInsti } = this.props;
    let { seleccion, cargando, solicitudActual, vistaDetalle, archivos,
      solicitud_cancelada,listaSolicitudes,periodo, actividad_movilidad, id_solicitud, descargandopdf, info_registro_pdf, instructivo, modalOpci, tipo_solicitud_seleccionada } = this.state;
    return (
      <div>
        <MenuInternacionalizacion
          seleccionar={(seleccion) => this.setState({ seleccion })}
          sele={seleccion}
          instructivo={instructivo[0] ? instructivo[0].archivo : ''}
        />
        <div className = 'contenido_modulo'>
          {/* INICIADO */}
          <ConveniosCrear
            listaSolicitudes = {listaSolicitudes}
            periodo = {periodo}
            obtenerSolicitudes = {this.obtenerSolicitudes}
            obtenerDatosIniciales = {this.obtenerDatosIniciales}
            modalAddConvenio = { modalAddConvenio }
            mostrarModalAddConvenio = { mostrarModalAddConvenio }
            guardarConvenio = { this.guardarConvenio }
          />
          {/* SE MUESTRA ACTUAL */}
          <Registros
            modalAddRegistro = { modalAddRegistro }
            mostrarModalAddRegistro = { mostrarModalAddRegistro }
            crearBorradorSolicitud = { this.crearBorradorSolicitud }
            actualizarMensaje = { actualizarMensaje }
            obtenerSolicitud = { this.obtenerSolicitud }
            obtenerSolicitudes = { this.obtenerSolicitudes }
            mostrarModalCancelarSolicitud = { mostrarModalCancelarSolicitud }
            cancelo_solicitud = { solicitud_cancelada }
            set_lista_solicitudes = { lista => this.setState({ listaSolicitudes: lista }) }
            set_datos_solicitud = { solicitud => this.setState({ solicitudActual: solicitud }) }
            set_solicitud_cancelada = { valor => this.setState({ solicitud_cancelada: valor }) }
            actualizarDataProcesoActual = { () => this.obtenerDataProcesoActual(solicitudActual.id) }
            obtenerAdjuntos = { this.obtenerAdjuntos }
            obtenerParticipantesActividad = { this.obtenerParticipantesRegistro }
            obtenerFinanciacionesActividad = { this.obtenerFinanciacionesRegistro }
            modalAddFinanciaciones = { this.props.modalAddFinanciaciones }
            mostrarModalAddFinanciaciones={this.props.mostrarModalAddFinanciaciones}
            tipo_solicitud_seleccionada={this.state.tipo_solicitud_seleccionada}
            instructivo={instructivo[0] ? instructivo[0].archivo : ''}
          />
          {/* MOVILIDADES POR CONVOCATORIA */}
          <MovEstudiantiles
            modalAddMovEstudiantil = { modalAddMovEstudiantil }
            mostrarModalAddMovEstudiantil = { mostrarModalAddMovEstudiantil }
            mostrarModalCancelarSolicitud = { mostrarModalCancelarSolicitud }
            crearBorradorSolicitud = { this.crearBorradorSolicitud }
            actualizarMensaje = { actualizarMensaje }
            obtenerSolicitud = { this.obtenerSolicitud }
            obtenerSolicitudes = { this.obtenerSolicitudes }
            cancelo_solicitud = { solicitud_cancelada }
            set_datos_solicitud = { (solicitud) => this.setState({ solicitudActual: solicitud }) }
            set_lista_solicitudes = { (lista) => this.setState({ listaSolicitudes: lista }) }
            set_solicitud_cancelada = { (valor) => this.setState({ solicitud_cancelada: valor }) }
          />
          <InternacionalizacionDetalle
            actualizarMensaje = { actualizarMensaje }
            solicitud={solicitudActual ? solicitudActual : {}}
            mostrarModalDetSolicitud = { mostrarModalDetSolicitud }
            modalDetSolicitud = { modalDetSolicitud }
            vistaDetalle = { vistaDetalle }
            admin = { false }
            modalModAdjuntos = { modalModAdjuntos }
            modalAprobarAdjunto = { modalAprobarAdjunto }
            modalNegarAdjunto = { modalNegarAdjunto }
            modalListaUniversidades = { modalListaUniversidades }
            mostrarModalModAdjuntos = { mostrarModalModAdjuntos }
            mostrarModalAprobarAdjunto = { mostrarModalAprobarAdjunto }
            mostrarModalNegarAdjunto = { mostrarModalNegarAdjunto }
            mostrarModalAdjuntarDocumentos = { mostrarModalAdjuntarDocumentos }
            mostrarModalListaUniversidades = { mostrarModalListaUniversidades }
            obtenerAdjuntos = { this.obtenerAdjuntos }
            obtenerParticipantesRegistro = { this.obtenerParticipantesRegistro }
            obtenerFinanciacionesRegistro = { this.obtenerFinanciacionesRegistro }
          />
          <AdjuntarDocumentos
            archivos = { archivos }
            agregarArchivos = { this.agregarArchivos }
            eliminarArchivo = { this.eliminarArchivo }
            modalAdjuntos = { modalAdjuntarDocumentos }
            mostrarModalAdjuntos = { mostrarModalAdjuntarDocumentos }
            onSubmit = { this.enviarAdjuntos }
          />
          {
            solicitudActual &&
              <ConfirmarAccion
                mensaje1 = '¿Estás seguro(a) que deseas cancelar la solicitud?'
                ejecutarAccion = { this.cancelarSolicitud }
                mostrarModalConfAccion = { mostrarModalCancelarSolicitud }
                modalConAccion = { modalCancelarSolicitud }
                dato = { solicitudActual }
                titulo = { '¿ Cancelar Solicitud ?' }
              />
          }
          {
            !cargando ? this.vista() :
              <TareasTerminadas mensaje = 'Cargando..' marginTop = '7%' imagen = { emma_w } widthImg = '7%' />
          }
          {
            id_solicitud !== 0 &&
            <DescargarPdfRegistro id_solicitud={id_solicitud} no_cargando={()=> this.setState({descargandopdf: false, id_solicitud: 0})} />
          }

          {/* COMPONENTE DE INSTITUCIÓN */}
          <InstitucionesPrincipal
            modalOpci={modalOpci}
            mostrarModalOpci={() => this.setState({ modalOpci: false })}
            periodo={periodo}
            tipoSolicitud={tipo_solicitud_seleccionada}
            // modalSolInsti={modalSolInsti}
            // mostrarModalSolInst={mostrarModalSolInst}
            
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  let { usuario } = state.redGlobal;
  let { modalAddMovEstudiantil, modalAddConvenio, modalAddRegistro, modalDetSolicitud, modalCancelarSolicitud,
    modalAdjuntarDocumentos, modalModAdjuntos, modalAprobarAdjunto, modalNegarAdjunto, modalListaUniversidades, modalAddFinanciaciones, modalSolInsti } = state.redInternacionalizacion;
  return { modalAddMovEstudiantil, modalAddConvenio, modalAddRegistro, modalDetSolicitud, modalCancelarSolicitud,
    modalAdjuntarDocumentos, modalModAdjuntos, modalAprobarAdjunto, modalNegarAdjunto, modalListaUniversidades, usuario, modalAddFinanciaciones, modalSolInsti }
}

const mapDispatchToProps = { mostrarModalAddMovEstudiantil, mostrarModalAddConvenio, mostrarModalAddRegistro, mostrarModalDetSolicitud, mostrarModalCancelarSolicitud,
  mostrarModalAdjuntarDocumentos, actualizarMensaje, mostrarModulo, mostrarModalModAdjuntos, mostrarModalAprobarAdjunto,
  mostrarModalNegarAdjunto, mostrarModalListaUniversidades, mostrarModalAddFinanciaciones, mostrarModalSolInst }

Internacionalizacion.propTypes = {
  //Funciones
  mostrarModalAddMovEstudiantil: PropTypes.func.isRequired,
  mostrarModalAddConvenio: PropTypes.func.isRequired,
  mostrarModalAddRegistro: PropTypes.func.isRequired,
  mostrarModalDetSolicitud: PropTypes.func.isRequired,
  mostrarModalCancelarSolicitud: PropTypes.func.isRequired,
  mostrarModalAdjuntarDocumentos: PropTypes.func.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  mostrarModalModAdjuntos: PropTypes.func.isRequired,
  mostrarModalAprobarAdjunto: PropTypes.func.isRequired,
  mostrarModalNegarAdjunto: PropTypes.func.isRequired,
  mostrarModalListaUniversidades: PropTypes.func.isRequired,
  mostrarModalAddFinanciaciones: PropTypes.func.isRequired,
  mostrarModalSolInst: PropTypes.func.isRequired,
  //Variables
  modalAddMovEstudiantil: PropTypes.bool.isRequired,
  modalAddConvenio: PropTypes.bool.isRequired,
  modalAddRegistro: PropTypes.bool.isRequired,
  modalDetSolicitud: PropTypes.bool.isRequired,
  modalCancelarSolicitud: PropTypes.bool.isRequired,
  modalAdjuntarDocumentos: PropTypes.bool.isRequired,
  modalListaUniversidades: PropTypes.bool.isRequired,
  modalAddFinanciaciones: PropTypes.bool.isRequired,
  modalSolInsti: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Internacionalizacion);
