//Importaciones necesaria para poder utilizar react
import React from 'react'
import SemilleroActualizarForms from './SemilleroActualizarForms'
//Importacion para utilizar el AppBarModel modificado de Emma
import AppBarModal from '../../general/AppBarModal'
//Importaciones necesarias para que el modal del formulario de creacion de semilleros se pueda abrir, este es para que se habra el modal.
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
//Cards
import { Grid, Link, Paper, Typography, Card, CardHeader, CardContent, Avatar } from '@material-ui/core'
import assigment from '../../../global/imagenes/assigment.png';
import * as Funciones from '../../../global/js/funciones';
import moment from 'moment'
//Styles
const useStyles = makeStyles((theme)=>({
    color:{
        transition: 'all 0.3s',
        '&:hover': {
            transform: "scale(1.05)",
        },
    },
    center:{
        textAlign: 'center'
    },modalMinWith: {
        [theme.breakpoints.up('sm')]: {
            minWidth:'600px'
        },
    },
}));
//Componente funcional Semilleros Solicitudes. Padre de los componentes de form de la creacion de semilleros.

const SemilleroActualizar = (props) => {
    
    const classes = useStyles()
    
    //Estado para cambiar de step en el SemillerosStepper
    const [activeStep, setActiveStep] = React.useState(0);

    //Estados necesarios para guardar la informacion de los textfield
    const [nameSemi, setNameSemi] = React.useState("");
    const [siglaSemi, setSiglaSemi] = React.useState("");
    const [objGenSemi, setObjGenSemi] = React.useState("");
    const [misionSemi, setMisionSemi] = React.useState("");
    const [visionSemi, setVisionSemi] = React.useState("");
    const [objEspSemi, setObjEspSemi] = React.useState("");
    const [addObjEsp, setAddObjEsp] = React.useState([]);
    const [fechaDeCreacion, setFechaDeCracion] = React.useState(new Date());
    //Estados departamento y programa.
    const [addDepaPro, setAddDepaPro] = React.useState([]);
    //Estados para el select de grupos de investigacion.
    const [gruInves, setGruInves] = React.useState([]);
    //Estados para el select de areas de investigacion.
    const [areaInves, setAreaInves] = React.useState('');
    //Estados linea y sublinea de investigacion.
    const [lineaSubInves, setLineaSubInves] = React.useState([]);
    //Data final
    let data = {}
    //Variable que obtiene todos los titulos del step
    const steps = getSteps();
    //Array de grupos de investigacion.
    const [grupoInve, setGrupoInve] = React.useState([]);
    //Array de Area de investigacion.
    const [areas, setAreas] = React.useState([]);
    //Array de los responsable
    const [responsables, setResponsables] = React.useState([]);
    //Errores
    const [errores, setErrores] = React.useState([]);
    //Cargando
    const [cargando, setCargando] = React.useState(true)
    //ROl
    const [rol, setRol] = React.useState([]);
    const dispatch = useDispatch();

    //Funcion para el cierre del modal.
    const handleClose = () => {
        if (!cargando) {
        props.setOpen(false);
        }
        /*setNameSemi("");
        setObjGenSemi("");
        setMisionSemi("");
        setVisionSemi("");
        setObjEspSemi("");
        setAddObjEsp([]);
        setAddDepaPro([]);
        setGruInves('');
        setAreaInves('');
        setLineaSubInves([]);
        setSiglaSemi('');
        handleReset();
        setResponsables([]);
        setErrores([]);*/
        data = {};
    };

    const getError = (llave, errores) => {
        let existe = errores.find(e => e.llave === llave);
        return existe ? existe.mensaje : '';
    }

    const validateInputs = (campos = []) => {
        return new Promise((resolve) => {
          let errores = [];
          let date_now = new Date()
          campos.map(({ value, name, err, value2 }) => {
            if (err.empty && value.replace(/\s+/g, '') == "") errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` });
            if (err.empty == false && value.length == 0) errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
            /*if (err.numeric && isNaN(value)) errores.push({ llave: name, 'mensaje': `Este campo debe ser un numero` });
            if (err.email && !validarCorreo(value)) errores.push({ llave: name, 'mensaje': `Correo invalido` });
            if (err.before_today && value >= date_now) errores.push({ llave: name, 'mensaje': `Fecha futura invalida` });
            if (err.after_today && value <= date_now) errores.push({ llave: name, 'mensaje': `Fecha pasada invalida` });
            if (err.finish_date && value <= value2) errores.push({ llave: name, 'mensaje': `Fecha invalida` });
            if (err.range && (value < 1 || value > value2)) errores.push({ llave: name, 'mensaje': `El valor está fuera del rango permitido` });
            if (err.not_null && value == null) errores.push({ llave:name, 'mensaje': `Este campo es obligatorio` });
            if (err.not_decimal && value % 1 != 0) errores.push({ llave: name, 'mensaje': `El valor no puede ser decimal` });*/
          });
          
          resolve(errores);
        });
    }
    //Funcion para hacer un siguiente en el Step
    const handleNext = async () => {
        if(activeStep == 0){

            setErrores(await validateInputs([
                { value: nameSemi, name: 'nameSemi', err: { empty: true } },
                { value: objGenSemi, name: 'objGenSemi', err: { empty: true } },
                { value: misionSemi, name: 'misionSemi', err: { empty: true } },
                { value: visionSemi, name: 'visionSemi', err: { empty: true } },
                { value: addObjEsp, name: 'addObjEsp', err: { empty: false } },
            ]))
            
            if(nameSemi.replace(/\s+/g, '') == ""){

            }else if(objGenSemi.replace(/\s+/g, '') == ""){

            }else if(misionSemi.replace(/\s+/g, '') == ""){

            }else if(visionSemi.replace(/\s+/g, '') == ""){

            }else if(addObjEsp.length == 0){

            }else if(isNaN(fechaDeCreacion)) {
                
            }else{
                let fechaCreacion = moment(fechaDeCreacion).format('YYYY-MM-DD');
                let fechaActual = moment(new Date()).format('YYYY-MM-DD');
                if(addObjEsp.length != 3){

                    let titulo = "No pueden existir menos o mas de 3 objetivos especificos."
                    let tipo = "info"

                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                      );
                }else if(fechaCreacion > fechaActual){
                    let titulo = "La fecha no puede ser mayor a la actual."
                    let tipo = "info"

                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                      );
                }else{
                    data = {
                        idSemi:props.semiActual.id,
                        nameSemi:nameSemi,
                        siglaSemi:siglaSemi,
                        objGenSemi:objGenSemi,
                        objEspSemi:addObjEsp,
                        misionSemi:misionSemi,
                        visionSemi:visionSemi,
                        fechaCreacion:fechaCreacion,
                    }
    
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
            }
        }

        if(activeStep == 1){

            setErrores(await validateInputs([
                { value: addDepaPro, name: 'addDepaPro', err: { empty: false } },
                { value: gruInves, name: 'gruInves', err: { empty: false } },
                // { value: areaInves, name: 'areaInves', err: { empty: false } },
                { value: lineaSubInves, name: 'lineaSubInves', err: { empty: false } },
                { value: objEspSemi, name: 'objEspSemi', err: { empty: true } },
            ]))

            if(addDepaPro.length == 0){
                
            }else if(gruInves.length == 0){
                
            }else if(lineaSubInves.length == 0){
                
            }else{
                let fechaCreacion = moment(fechaDeCreacion).format('YYYY-MM-DD');
                data = {
                    nombre:nameSemi,
                    sigla:siglaSemi,
                    objetivo_general:objGenSemi,
                    objEspSemi:addObjEsp,
                    mision:misionSemi,
                    vision:visionSemi,
                    depaProg:addDepaPro,
                    gruInves:gruInves,
                    // areaInves:areaInves,
                    lineaSubLinea:lineaSubInves,
                    fechaCreacion:fechaCreacion,
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);

            }
        }
    };

    const SendDataToApi = async () =>{
        const Estudiantelider = responsables.some(estudiante => estudiante.rol.codigo === 'Semi_rol1');
        if(responsables.length == 0){
            let titulo = "Digite por lo menos 1 responsable."
            let tipo = "info"

            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
              );
        }else if( !Estudiantelider ){
            let titulo = "Digite por lo menos 1 Estudiante Lider."
            let tipo = "info"
            dispatch(
                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
            );
        }else{
            let fechaCreacion = moment(fechaDeCreacion).format('YYYY-MM-DD');
            data = {
                nombre:nameSemi,
                sigla:siglaSemi,
                objetivo_general:objGenSemi,
                objEspSemi:addObjEsp,
                mision:misionSemi,
                vision:visionSemi,
                depaProg:addDepaPro,
                gruInves:gruInves,
                // areaInves:areaInves,
                lineaSubLinea:lineaSubInves,
                lideres:responsables,
                solicitud_ant:props.semiActual.solicitud.id,
                fechaCreacion:fechaCreacion,
            }
            setCargando(true)
            let url = "api/v1.0/investigacion/crearSemilleros"
            const callback = (error, estado, resp) => {
                let titulo = "Ha ocurrido un error, contacte con el administrador."
                let tipo = "error"

                if(!error){
                    if(estado === 201){
                        tipo = "success"
                        titulo = resp.titulo
                        props.semilleronuevo()
                    }
                    handleClose()
                    setCargando(true)
                    props.setOpenModalInfoParticipante(true)
                }

                dispatch(
                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                  );
            }
            const msg = await Funciones.consulta(url, data, 'POST', callback)

            
        }
    }

    //Funcion para hacer un back en el Step
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //Funcion para hacer un reset en el Step
    const handleReset = () => {
        setActiveStep(0);
    };
    //Retorna el modal de los formularios de los semilleros.
    return(
        <div>

            <Dialog PaperProps={{style: {height: '80vh', overflow: 'auto',top: '30px', },}} open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">

                <AppBarModal titulo='Formulario Semillero' mostrarModal={handleClose} disabled={cargando == true} titulo_accion="" accion="" />
                {/* Contenido del modal del formulario de */}
                <DialogContent className={classes.modalMinWith} >
                    
                    <SemilleroActualizarForms 
                    hola={activeStep}
                    steps={steps}
                    handleReset={handleReset}
                    //TextFields
                    nameSemi={nameSemi}
                    setNameSemi={setNameSemi}
                    
                    objGenSemi={objGenSemi}
                    setObjGenSemi={setObjGenSemi}

                    misionSemi={misionSemi}
                    setMisionSemi={setMisionSemi}

                    visionSemi={visionSemi}
                    setVisionSemi={setVisionSemi}

                    objEspSemi={objEspSemi}
                    setObjEspSemi={setObjEspSemi}

                    addObjEsp={addObjEsp}
                    setAddObjEsp={setAddObjEsp}

                    siglaSemi={siglaSemi}
                    setSiglaSemi={setSiglaSemi}

                    addDepaPro={addDepaPro}
                    setAddDepaPro={setAddDepaPro}

                    gruInves={gruInves}
                    setGruInves={setGruInves}

                    areaInves={areaInves}
                    setAreaInves={setAreaInves}
                    
                    lineaSubInves={lineaSubInves}
                    setLineaSubInves={setLineaSubInves}

                    grupoInve={grupoInve}
                    setGrupoInve={setGrupoInve}

                    areas={areas}
                    setAreas={setAreas}

                    responsables={responsables}
                    setResponsables={setResponsables}
                    
                    getError={getError}
                    errores={errores}
                    setErrores={setErrores}

                    validateInputs={validateInputs}

                    cargando={cargando}
                    setCargando={setCargando}

                    rol={rol}
                    setRol={setRol}

                    semiActual={props.semiActual}
                    
                    fechaDeCreacion={fechaDeCreacion}
                    setFechaDeCracion={setFechaDeCracion}
                    />
                </DialogContent>

                <DialogActions>
                    {/* className={classes.backButton}*/}
                    <div>
                        <Button disabled={activeStep === 0 || cargando == true} onClick={handleBack} >
                            Atras
                        </Button>
                        {activeStep === steps.length - 1 ? <Button disabled={cargando == true} variant="contained" color="primary" style={{color: "#fff"}} onClick={SendDataToApi}>Enviar</Button> : <Button disabled={cargando == true} variant="contained" color="primary" style={{color: '#fff'}} onClick={handleNext}>Siguiente</Button>}
                    </div>

                </DialogActions>

            </Dialog>

        </div>
    )
}

//Nombre de las opciones de arriba del Stepper
function getSteps() {
    return ['Información del semillero', 'Departamento y Programa', 'Lideres'];
}

//Exporto el componente funcional. Para que pueda ser llamado
export default SemilleroActualizar 