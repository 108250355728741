import React from "react";
import {
  Table,
  Card,
  CardContent,
  Typography,
  Avatar,
  CardHeader,
  AccordionDetails,
  Accordion,
  AccordionSummary,
} from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { consulta } from "../../../global/js/funciones";

const useStyles = makeStyles((theme) => ({
  columna: {
    display: "flex",
  },
}));

export default function SisefTables({ plan }) {
  const isInitialMount = React.useRef(true);

  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  });

  let { asignatura_nombre, plan_syll: { nombre }, valores_plan } = plan[0];

  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="recipe"
                  style={{ backgroundColor: red[500] }}
                  variant="square"
                >
                  {" "}
                  {"IN"}{" "}
                </Avatar>
              }
              title={"Informacion Basica del plan de Asignatura"}
            />
          </Typography>
          <Table style={{ width: "1px solid #f5f5f5 " }}>
            <TableBody>
              <TableRow key={1}>
                <TableCell component="th" scope="row">
                  {" "}
                  Asignatura
                </TableCell>
                <TableCell align="left">{asignatura_nombre}</TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell component="th" scope="row">
                  Valores
                </TableCell>
                <TableCell align="left">{valores_plan}</TableCell>
              </TableRow>
              <TableRow key={3}>
                <TableCell component="th" scope="row">
                  Plan de estudio
                </TableCell>
                <TableCell align="left"> {`${nombre}`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
}

export function RubricasTables({ nombre }) {
  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="recipe"
                  style={{ backgroundColor: red[500] }}
                  variant="square"
                >
                  {" "}
                  {"RD"}{" "}
                </Avatar>
              }
              title={"Informacion Basica de la rubrica"}
            />
          </Typography>
          <Table style={{ width: "1px solid #f5f5f5 " }}>
            <TableBody>
              <TableRow key={1}>
                <TableCell component="th" scope="row">
                  {" "}
                  Asignatura
                </TableCell>
                <TableCell align="left">{nombre}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
}
