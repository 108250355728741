import React, { Component } from 'react';
// Material-UI
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  Grid,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  IconButton
} from '@material-ui/core/';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import 'date-fns';
// Components
import AppBarModal from '../../general/AppBarModal';
import {
  formulario,
  obtenerValores,
  mostrarError,
  crear_form_data,
  obtenerValoresFiltros, transformar, obtenerPermisosValorAlt,
  consulta
} from '../../../global/js/funciones';
import { PasosCrearSolicitud } from './MatriculasMenu';
import {
  TIPO_IDENTIFICACION,
  TIPOS_ESTUDIO,
  PROGRAMAS,
  DEPARTAMENTOS,
  CERTIFICADOS,
  TIPO_CERTIFICADOS,
  PERIODO_CERTIFICADO,
  EPSS
} from './helper';
import AlertasSimple from '../../general/AlertasSimple';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
import CustomDropzone from '../../general/CustomDropzone';
import moment from "moment";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { coloresEmma } from '../../../global/js/funciones';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';


const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 15,
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  list: {
    marginTop: 20,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  inline: {
    display: 'inline'
  }
}));

export default class ModalCrearSolicitudActualizarDatos extends Component {

  constructor(props) {
    super(props);
    this.formEndRef = React.createRef();
    this.state = {
      steps: ['Datos personales', 'Documentos'],
      activeStep: 0,
      tiposIdentificacion: [],
      tiposEstudio: [],
      archivos: [],
      cargando: false,
      programasAcad: [],
      departamentos: [],
      certificados: [],
      tiposCertificados:[],
      perioCertificados:[],
      epss:[],
      ciudades: [],
      tipo_modalidad:[],
      modalidad_virtual:[],
      Mensaje_Progra_Virtual:'',
      mensaje:'',
      Link_Prog_Academico:'',
      link:'',
      isChecked1: false,
      isChecked2: false,
      cargando: true,
      cargando_text: 'Cargando ..',
      errorMessage: '',
      openModalValidarCodigo: false,
    };
  }
  
  async componentDidUpdate({ open }, { departamento }) {
    const { misDatos, open: openNew } = this.props
    const { departamento: departamentoNew } = this.state
    if (open !== openNew) {
      if (openNew) {
        this.setState({cargando: true, cargando_text: 'Cargando ..'})
        obtenerValores(TIPO_IDENTIFICACION).then(tiposIdentificacion => this.setState({ tiposIdentificacion }));
        obtenerValores(TIPOS_ESTUDIO).then(tiposEstudio => this.setState({ tiposEstudio }));
        let programas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PROGRAMAS }, { 'llave': 'valorc', 'valor': 'pregrado' }, { 'llave': 'estado', 'valor': 1 }]);
        let departamentos = await obtenerValoresFiltros([{"llave": 'generica', 'valor': DEPARTAMENTOS}])
        let epss = await obtenerValoresFiltros([{"llave": 'generica', 'valor': EPSS}, { 'llave': 'valore', 'valor': 1 }])
        let certificados = await obtenerValoresFiltros([{"llave": 'generica', 'valor': CERTIFICADOS}])
        let tiposCertificados = await obtenerValoresFiltros([{"llave": 'generica', 'valor': TIPO_CERTIFICADOS}])
        let perioCertificados = await obtenerValoresFiltros([{"llave": 'generica', 'valor': PERIODO_CERTIFICADO}])
        if (misDatos && misDatos.persona) {
          this.setState({
            primer_nombre: `${misDatos.persona.primer_nombre}`,
            segundo_nombre: `${misDatos.persona.segundo_nombre}`,
            primer_apellido: `${misDatos.persona.primer_apellido}`,
            segundo_apellido: `${misDatos.persona.segundo_apellido}`,
            programa:  misDatos.programas ? misDatos.programas[0].relacion.id : '',
            eps:  misDatos.epss ? misDatos.epss[0].relacion.id : '',
            lugar:  `${misDatos.persona.lugar_expedicion}`,
            epss: transformar(epss),
            programasAcad: transformar(programas),
            departamentos: transformar(departamentos),
            certificados: transformar(certificados),
            tiposCertificados: transformar(tiposCertificados),
            perioCertificados: transformar(perioCertificados),
            numero_documento: misDatos.persona.identificacion,
            celular: misDatos.persona.celular,
            telefono: misDatos.persona.telefono,
            correo: misDatos.persona.correo_personal,
            tipoIdentificacion: misDatos.persona.tipo_identificacion,
            lugar_residencia: misDatos.persona.lugar_residencia,
            direccion: misDatos.persona.direccion,
            cargando: false
          })
        }
      }
    }

    if (departamentoNew && departamentoNew !== departamento) {
      this.setState({cargando: true})
      let ciudades = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': departamentoNew }, { 'llave': 'secundario__generica', 'valor': 8 }])
      ciudades = ciudades.map(({ secundario }) => ({
        value: secundario.id,
        label: secundario.nombre,
      }));
      this.setState({cargando: false, ciudades})
    }
  }

  handleNext = () => {
    const { steps, activeStep } = this.state
    const { crear_solicitud } = this
    this.setState({ cargando: true, cargando_text: 'Enviando código a WhatsApp...' })

    const {
      validar_datos_persoanles,
      validar_adjunto
    } = this

    if (activeStep == 0 && validar_datos_persoanles()) return;
    if (activeStep == 1 && validar_adjunto()) return;
    
    if (activeStep === steps.length - 1) {
      this.crear_solicitud();
    } else {
      this.setState({ activeStep: activeStep + 1 })
    }
  };

  setFechaNacimiento = (fecha_nacimiento) => {
    this.setState({
      fecha_nacimiento
    })
  }
  
  handleBack = () => {
    const { activeStep } = this.state
    this.setState({ activeStep: activeStep - 1 });
  };
  
  validar_estado_no_null = (nombre_estados) => {
    for (let index = 0; index < nombre_estados.length; index++) {
      const element = nombre_estados[index];
      if (
        this.state[element] === '' ||
        this.state[element] === undefined ||
        this.state[element] === null
      ) return true;
    }
    return false
  }

  onChangeStateForInput = async (event)  => {
    const {  target: { value, name } } = event

    this.setState({
      [name]: value
    })

  }

  onChangeStateForInputCelular = async (event) => {
    const {  target: { value, name } } = event
    // if (!this.validar_celular(value)) this.setState({ errorMessage: 'Número de celular no válido' });
    // else this.setState({ errorMessage: '' });
    this.setState({
      [name]: value
    })
  }

  handleCheckboxChange = async (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked });
    // this.setState({ isChecked: checked });
    if(name==='checked1') {
      this.setState({isChecked1:checked,isChecked2:false})
      // this.formEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }else if (name==='checked2') {
      this.setState({isChecked2:checked,isChecked1:false})
    }else{
      this.setState({isChecked1:false, isChecked2:false})
    }
  }

  validar_celular = (value) => { 
    const regex = /^\d{10}$/;
    return regex.test(value);
  }

  // Función para validar el cliente en SAGICC
  validar_cliente_sagicc = async () => { 
    const { 
      tipoIdentificacion, 
      numero_documento, 
      primer_apellido, 
      segundo_apellido,
      primer_nombre,
      segundo_nombre,
      celular,
    } = this.state

    let aux_data = {
      tipoIdentificacion,
      numero_documento,
      primer_apellido,
      segundo_apellido,
      primer_nombre,
      segundo_nombre,
      celular,
    }

    const data = await crear_form_data(aux_data);
    
    // Envio post al backend para validar el cliente en SAGICC
    formulario(`api/v1.0/integraciones/validar_cliente_sagicc`, data, 'post', async (error, estado, resp) => {
      if (estado == 200){
        localStorage.setItem('codigo_whatsapp', resp.codigo);
        // Llamo al componente de validación de código
        this.setState({ openModalValidarCodigo: true });
        this.setState({ cargando: false, cargando_text: 'Cargando ..' })
        return true;
      } else if (estado == 503){
        this.setState({ cargando: false, cargando_text: 'Cargando ..' })
        this.setState({ openModalValidarCodigo: false });
        return true;
      } else {
        return false;
      }
    });
  }

  DatosPersonales = () => {

    const {
      primer_nombre,
      segundo_nombre,
      primer_apellido,
      segundo_apellido,
      programasAcad,
      epss,
      ciudades,
      departamentos,
      ciudad,
      departamento,
      programa,
      lugar,
      certificados,
      certificado,
      tiposCertificados,
      tiposCertificado,
      perioCertificados,
      perioCertificado,
      anno,
      tiposIdentificacion,
      tipoIdentificacion,
      numero_documento,

      fecha_nacimiento = null,
      lugar_residencia,
      direccion,
      celular,
      telefono,
      correo,
      eps,
      autorizo,
      errorMessage
    } = this.state
    const { tipo } = this.props
    const { onChangeStateForInput, onChangeStateForInputCelular } = this

    return (
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <FormControl className='form-control'>
              <InputLabel>Primer Nombre</InputLabel>
              <Input
                  name='primer_nombre'
                  defaultValue={primer_nombre}
                  onChange={onChangeStateForInput}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl className='form-control'>
              <InputLabel>Segundo Nombre</InputLabel>
              <Input
                  name='segundo_nombre'
                  defaultValue={segundo_nombre}
                  onChange={onChangeStateForInput}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl className='form-control'>
              <InputLabel>Primer Apellido</InputLabel>
              <Input
                  name='primer_apellido'
                  defaultValue={primer_apellido}
                  onChange={onChangeStateForInput}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl className='form-control'>
              <InputLabel>Segundo Apellido</InputLabel>
              <Input
                  name='segundo_apellido'
                  defaultValue={segundo_apellido}
                  onChange={onChangeStateForInput}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className='form-control'>
                <InputLabel>Programa Académico</InputLabel>
                <Select
                    name='programa'
                    placeholder='Programa Académico'
                    value={programa}
                    onChange={onChangeStateForInput}
                >
                  {
                    programasAcad.map((programa) => (
                        <MenuItem key={programa.value} value={programa.value}> {programa.label} </MenuItem>
                    ))
                  }
                </Select>

            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className='form-control'>
              <InputLabel>Tipo de documento</InputLabel>
              <Select
                  name='tipoIdentificacion'
                  value={tipoIdentificacion}
                  onChange={onChangeStateForInput}
              >
                {
                  tiposIdentificacion.map((ti) => (
                      <MenuItem key={ti.id} value={ti.id}> {ti.nombre} </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className='form-control'>
              <InputLabel>Número de Documento de Identidad</InputLabel>
              <Input
                  name='numero_documento'
                  type="number"
                  defaultValue={numero_documento}
                  onChange={onChangeStateForInput}
              />
            </FormControl>
          </Grid>
          {tipo.codigo == 'Mat_Pro_Certi' &&
              <Grid item xs={6}>
                <FormControl className='form-control'>
                  <InputLabel>Lugar de Expedición</InputLabel>
                  <Input
                      name='lugar'
                      defaultValue={lugar}
                      onChange={onChangeStateForInput}
                  />
                </FormControl>
              </Grid>
          }
          {tipo.codigo == 'Mat_Pro_Certi' &&
              <Grid item xs={6}>
                <FormControl className='form-control'>
                  <InputLabel>Certificados</InputLabel>
                  <Select
                      name='certificado'
                      placeholder='Certificado'
                      value={certificado}
                      onChange={onChangeStateForInput}
                  >
                    {
                      certificados.map((certificado) => (
                          <MenuItem key={certificado.value} value={certificado.value}> {certificado.label} </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
          }
          {tipo.codigo == 'Mat_Pro_Certi' &&
              <Grid item xs={6}>
                <FormControl className='form-control'>
                  <InputLabel>Tipo de Certificado</InputLabel>
                  <Select
                      name='tiposCertificado'
                      placeholder='Tipo de Certificado'
                      value={tiposCertificado}
                      onChange={onChangeStateForInput}
                  >
                    {
                      tiposCertificados.map((tiposCertificado) => (
                          <MenuItem key={tiposCertificado.value} value={tiposCertificado.value}> {tiposCertificado.label} </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
          }
          {tipo.codigo == 'Mat_Pro_Certi' &&
              <Grid item xs={6}>
                <FormControl className='form-control'>
                  <InputLabel>AÑO</InputLabel>
                  <Input
                      name='anno'
                      defaultValue={anno}
                      onChange={onChangeStateForInput}
                  />
                </FormControl>
            </Grid>
          }
          {tipo.codigo == 'Mat_Pro_Certi' &&
              <Grid item xs={6}>
                <FormControl className='form-control'>
                  <InputLabel>PERIODO</InputLabel>
                  <Select
                      name='perioCertificado'
                      placeholder='PERIODO'
                      value={perioCertificado}
                      onChange={onChangeStateForInput}
                  >
                    {
                      perioCertificados.map((perioCertificado) => (
                          <MenuItem key={perioCertificado.value} value={perioCertificado.value}> {perioCertificado.label} </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
          }
          <Grid item xs={6}>
            <FormControl className='form-control'>
              <InputLabel>EPS *</InputLabel>
              <Select
                  name='eps'
                  placeholder='EPS'
                  value={eps}
                  onChange={onChangeStateForInput}
                  required
              >
                { epss.length > 0 ?
                  epss.map((eps) => (
                      <MenuItem key={eps.value} value={eps.value}> {eps.label} </MenuItem>
                  )) :
                  <MenuItem disabled key={0} value={0}><em>Para mostrar debes seleccionar el departamento</em></MenuItem>
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className='form-control'>
              <InputLabel>Departamento de ubicacion *</InputLabel>
              <Select
                  name='departamento'
                  placeholder='Departamento de ubicacion'
                  value={departamento}
                  onChange={onChangeStateForInput}
                  required
              >
                {
                  departamentos.map((departamento) => (
                      <MenuItem key={departamento.value} value={departamento.value}> {departamento.label} </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className='form-control'>
              <InputLabel>Ciudad de ubicacion *</InputLabel>
              <Select
                  name='ciudad'
                  placeholder='Ciudad de ubicacion'
                  value={ciudad}
                  onChange={onChangeStateForInput}
                  required
              >
                { ciudades.length > 0 ?
                  ciudades.map((ciudad) => (
                      <MenuItem key={ciudad.value} value={ciudad.value}> {ciudad.label} </MenuItem>
                  )) :
                  <MenuItem disabled key={0} value={0}><em>Para mostrar debes seleccionar el departamento</em></MenuItem>
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className='form-control'>
              <InputLabel>Dirección de residencia *</InputLabel>
              <Input
                  name='direccion'
                  defaultValue={direccion}
                  onChange={onChangeStateForInput}
                  required
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className='form-control' error={errorMessage}>
              <InputLabel>Celular</InputLabel>
              <Input
                  name='celular'
                  defaultValue={celular}
                  onChange={onChangeStateForInputCelular}
              />
              {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className='form-control'>
              <InputLabel>Teléfono</InputLabel>
              <Input
                  name='telefono'
                  defaultValue={telefono}
                  onChange={onChangeStateForInput}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className='form-control'>
              <InputLabel>Correo electrónico</InputLabel>
              <Input
                  name='correo'
                  defaultValue={correo}
                  onChange={onChangeStateForInput}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className='form-control'>
              <InputLabel>Autorizo</InputLabel>
              <Select
                  name='autorizo'
                  value={autorizo}
                  onChange={onChangeStateForInput}
              >
                <MenuItem key={1} value={1}> SI </MenuItem>
                <MenuItem key={0} value={0}> NO </MenuItem>
              </Select>
              {
                tipo.codigo != 'Mat_Pro_Certi' ?
                <FormHelperText>
                  Autorizo de manera expresa e inequívoca que mis datos personales
                  sean usados por la Corporación Universidad de la Costa “CUC”, dentro
                  de los términos previsto por la ley 1581 de 2012 y el decreto 1377
                  de 2013; para efectos que la Universidad considere pertinentes.
                </FormHelperText>
                :
                  <FormHelperText>
                    AUTORIZO: En cumplimiento de la Ley 1581 de 2012 y sus decretos reglamentarios en calidad de titular(es) 
                    de la información de manera libre, expresa e informada, autorizo a CORPORACIÓN UNIVERSIDAD DE LA COSTA - CUC  y/o  
                    a la persona natural o jurídica a quién este encargue, a recolectar, almacenar, utilizar, circular, suprimir y  
                    en general, a usar mis datos personales para el cumplimiento de las siguientes finalidades: (i) Gestión de PQR, (ii) 
                    publicidad y prospección comercial, (iii) Enseñanza universitaria o superior. Declaro que he conocido la Política de 
                    tratamiento de datos personales publicada en www.cuc.edu.co
                  </FormHelperText>
              }
            </FormControl>
          </Grid>
        </Grid>
    );

  }

  validar_datos_persoanles = () => {
    const { validar_estado_no_null, validar_cliente_sagicc } = this
    const { actualizarMensaje,tipo } = this.props
    const array_name_state = [
      'primer_nombre',
      'primer_apellido',
      'programa',
      'tipoIdentificacion',
      'numero_documento',
      'departamento',
      'ciudad',
      'direccion',
      'celular',
      'telefono',
      'correo',
      'autorizo',
      'eps'
    ]
    const array_name_state2 = [
      'primer_nombre',
      'primer_apellido',
      'programa',
      'tipoIdentificacion',
      'numero_documento',
      'departamento',
      'certificado',
      'tiposCertificado',
      'perioCertificado',
      'anno',
      'ciudad',
      'direccion',
      'celular',
      'telefono',
      'correo',
      'autorizo',
      'lugar',
      'eps'
    ]
    if(tipo.codigo != 'Mat_Pro_Certi' ){
      if (validar_estado_no_null(array_name_state)) {
        actualizarMensaje({
          titulo: "Campos sin digitar.",
          tipo: "info",
          mostrar: true,
          tiempo: 6000
        });
        return true
      }
    }else{
      if(validar_estado_no_null(array_name_state2)){
        actualizarMensaje({
          titulo: "Campos sin digitar.",
          tipo: "info",
          mostrar: true,
          tiempo: 6000
        });
        return true
      }
    }

    if(!validar_cliente_sagicc()) return true;

    return false;
  }
  eliminarArchivo = (i) => {
    const { archivos } = this.state
    const { actualizarMensaje } = this.props
    archivos.splice(i, 1);
    this.setState({ archivos })
    actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 });
  };

  agregarArchivos = (arc) => {
    let { archivos } = this.state
    archivos = archivos.concat(arc);
    this.setState({ archivos })
  };

  validar_adjunto = () =>{
    const { archivos} = this.state
    const { actualizarMensaje } = this.props
    if( archivos.length <= 0){
      actualizarMensaje({
        titulo: "Adjuntar documento.",
        tipo: "info",
        mostrar: true,
        tiempo: 6000
      });
      return true
    }
    return false;
  }
  Documentos = () => {
    const { archivos } = this.state
    const {
      agregarArchivos,
      eliminarArchivo
    } = this
    return (
        <div>
          <AlertasSimple
              tipo='info'
              titulo='Por favor adjunte los documentos que desea anexar a su solicitud de actualización de datos.'
              sub_titulo='Si necesitas subir varios archivos puedes comprimirlos en cualquier formato(rar, zip) y adjuntarlos.'
              lista={[{item: 'Cargue aqui su documento de identificacion.'}]}
          />
          <CustomDropzone
              archivos={archivos}
              addFunction={agregarArchivos}
              deleteFunction={eliminarArchivo}
          />
        </div>
    )
  }

  getStepContent(step) {
    const {
      DatosPersonales,
      Documentos
    } = this
    switch (step) {
      case 0:
        return <DatosPersonales />
      case 1:
        return <Documentos />
      default:
        return 'Unknown step';
    }
  }

  crear_solicitud = async () => {
    let { actualizarMensaje, tipo, setOpen } = this.props

    // Documentos
    let { archivos } = this.state

    // Datos Personales
    const {
      primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, programa,
      tipoIdentificacion, numero_documento, fecha_nacimiento,
      departamento, ciudad, direccion, celular, telefono, correo, autorizo,
      ciudad_expedicion,lugar,certificado,tiposCertificado,anno,perioCertificado,eps
    } = this.state

    let auxData = {
      ciudad_expedicion: ciudad_expedicion ? ciudad_expedicion : '',
      correo,
      celular,
      telefono,
      curso: '',
      tipo: tipo.id,
      tipoCode: tipo.codigo,
      misCursos: [],

      primer_nombre,
      segundo_nombre,
      primer_apellido,
      segundo_apellido,
      lugar,
      certificado,
      tiposCertificado,
      perioCertificado,
      anno,
      programa,

      numero_documento,
      departamento,
      ciudad,
      eps,
      fecha_nacimiento: fecha_nacimiento ? moment(fecha_nacimiento).format("YYYY-MM-DD") : "",

      tipoIdentificacion,
      autorizo,

      direccion,
    };



    const data = await crear_form_data(auxData);
    data.append(`total`, archivos.length);
    archivos.map((file, index) => data.append(`archivo${index}`, file));
    this.setState({ cargando: true, cargando_text: 'Enviando, por favor espere' })
    if(tipo.codigo != 'Mat_Pro_Certi'){
      formulario(`api/v1.0/matriculas/actualizar_datos`, data, 'post', async (error, estado, resp) => {
        let title = 'Ha ocurrido un error, contacte con el administrador.';
        let icon = 'error';
        if (!error) {
          if (estado === 200) {
            icon = 'success';
            title = resp.titulo;
            setOpen(false);
            this.setState({ activeStep: 0 });
          } else if (estado === 302) {
            icon = 'info';
            title = resp.titulo;
          } else {
            tipo = 'info';
            title = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000 });
        this.setState({ cargando: false })
      });
    }else{
      formulario(`api/v1.0/matriculas/certificados`, data, 'post', async (error, estado, resp) => {
        let title = 'Ha ocurrido un error, contacte con el administrador.';
        let icon = 'error';
        if (!error) {
          if (estado === 200) {
            icon = 'success';
            title = resp.titulo;
            setOpen(false);
            this.setState({ activeStep: 0 });
          } else if (estado === 302) {
            icon = 'info';
            title = resp.titulo;
          } else {
            tipo = 'info';
            title = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000 });
        this.setState({ cargando: false })
      });
    }
    
  }

  render() {
    const { open, setOpen, tipo } = this.props
    const { steps, activeStep, cargando,isChecked2, cargando_text } = this.state
    return (
        <div>
          <Dialog
              onClose={() => setOpen(false)}
              aria-labelledby='customized-dialog-title'
              open={open}
              fullWidth={true}
              maxWidth='md'
              className='scroll'
          >
            <AppBarModal
                titulo={`${tipo && tipo.nombre}`}
                mostrarModal={() => setOpen(false)}
                titulo_accion='Cerrar'
                accion={() => setOpen(false)}
            />
            <DialogContent>
              {cargando ? (
                  <TareasTerminadas mensaje={cargando_text} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true}/>
              ) : (
                  <div>
                    <PasosCrearSolicitud activeStep={activeStep} steps={steps} />
                    {this.getStepContent(activeStep)}
                  </div>
              )}
            </DialogContent>
            <DialogActions>
              {isChecked2 !== true ?
                  <Button
                      disabled={activeStep === 0}
                      onClick={cargando ? () => setOpen(false) : this.handleBack}
                  >
                    Atrás
                  </Button>

                  : ''}
              {!cargando && isChecked2 !== true ?
                  (
                      <Button
                          color='primary'
                          onClick={this.handleNext}
                      >
                        {activeStep === steps.length - 1 ? 'Guardar' : 'Siguiente'}
                      </Button>
                  ) :
                  <Button
                      color='primary'
                      onClick={() => setOpen(false)}
                  >   Cerrar
                  </Button>
              }
            </DialogActions>
          </Dialog>

          <ModalValidarCodigoCelular
              open={this.state.openModalValidarCodigo}
              setOpen={(openModalValidarCodigo) => this.setState({ openModalValidarCodigo })}
              actualizarMensaje={this.props.actualizarMensaje}
          />
        </div>
    );
  }

}

class ModalValidarCodigoCelular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codigo: '',
      cargando: false,
      cargando_text: 'Cargando ..',
      errorMessage: '',
    };
  }

  onChangeStateForInput = async (event)  => {
    const {  target: { value, name } } = event
    this.setState({
      [name]: value
    })
  }

  validar_codigo_whatsapp = async () => { 
    let { codigo } = this.state
    let { actualizarMensaje, setOpen } = this.props
    let { tipo } = this.props
    let aux_data = {
      codigo,
      codigo_whatsapp: localStorage.getItem('codigo_whatsapp')
    }
    if (!codigo) {
      actualizarMensaje({ titulo: 'Por favor digita el código que te enviamos por WhatsApp', tipo: 'info', mostrar: true, tiempo: 6000 });
      return;
    }
    const data = await crear_form_data(aux_data);
    this.setState({ cargando: true, cargando_text: 'Validando código, por favor espere' })
    formulario(`api/v1.0/integraciones/validar_codigo_whatsapp`, data, 'post', async (error, estado, resp) => {
      let title = 'Ha ocurrido un error, contacte con el administrador.';
      let icon = 'error';
      if (!error) {
        if (estado === 200) {
          icon = 'success';
          title = resp.titulo;
          setOpen(false);
          localStorage.removeItem('codigo_whatsapp');
        } else {
          tipo = 'info';
          title = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000 });
      this.setState({ cargando: false })
    });
   }

  render() {
    let { open, setOpen } = this.props
    let { codigo, cargando, cargando_text } = this.state
    let { onChangeStateForInput } = this
    return (
        <div>
          <Dialog
            aria-labelledby='customized-dialog-title'
            open={open}
            fullWidth={true}
            maxWidth='xs'
            className='scroll'
        >
          <AppBar style={{position: 'relative', backgroundColor: coloresEmma.secondarycolor,}} elevation={0}>
            <Toolbar>
              <IconButton edge="start" color="inherit" aria-label="close">
                <WhatsAppIcon />
              </IconButton>
              <Typography variant="h6" style={{flex: 1, color: 'white',}}>
                Código WhatsApp
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl className='form-control'>
                  <InputLabel>Digita el código que te enviamos por WhatsApp</InputLabel>
                  <Input
                      name='codigo'
                      type="number"
                      onChange={onChangeStateForInput}
                      defaultValue={codigo}
                      required
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
                color='primary'
                onClick={this.validar_codigo_whatsapp}
            >
              Validar
            </Button>
          </DialogActions>
        </Dialog>
        </div>
    );
  }
}