//Importaciones necesaria para poder utilizar react
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { mostrarModulo } from '../../redux/actions/actGlobal';
import TareasTerminadas from '../general/TareasTerminadas';
import emma_s from '../../global/imagenes/emma_s1.png';
import LogoCuc from '../../global/imagenes/logo_cuc3.png';
import imagen_certificado from '../../global/imagenes/background-certificado.png';
import firmavice from '../../global/imagenes/firma_vice.png';
import firmasemi from '../../global/imagenes/firma_semillero.png';
import firma_curso from '../../global/imagenes/firma_curso.png';
import * as Funciones from '../../global/js/funciones';
import TituloAccion from "../general/TituloAccion";
import { Grid, Paper, Button, Typography, Box, Tooltip, IconButton } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import revisar from '../../global/imagenes/revisar.png';
import AccionesRapidas from '../general/AccionesRapidas'
// import SemillerosListSemi from './Hijos/SemillerosListSemi'
// import SemilleroSolicitudesEst from './Hijos/SemilleroSolicitudesEst'
// import { SemilleroTableParticipante } from './Hijos/SemilleroTables'
import { Document, Image, Page, PDFDownloadLink, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import sourcebold from './../../global/fonts/PTSerif-Bold.ttf'
import source from './../../global/fonts/Roboto-Regular.ttf'
import sourcerobotobold from './../../global/fonts/Roboto-Bold.ttf'
import file from '../../global/imagenes/file.png'
import moment from 'moment';
// import Semilleros from './Semilleros'
import MenuCursosFormacion from './MenuCursosFormacion';
import SemilleroSolicitudesEst from './CursosSolicitudesEstudiantes';
import CardSolicitud from './Cursos/CardSolicitud';
import CursosList from './Cursos/CursosList';
import SolicitudTables from './Cursos/CursosTables';
import BarraProceso from '../general/BarraProceso';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  contenidoModulo: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: "90px !important"
    },
  }
}));
Font.register({ family: 'PTSerifBold', src: sourcebold });
Font.register({ family: 'Roboto', src: source });
Font.register({ family: 'RobotoBold', src: sourcerobotobold });
const styles = StyleSheet.create({
  body: {
    border: '1.1px solid #DFDDDD',
    width: '100%',
    height: '100%',
    paddingLeft: '45px',
    paddingRight: '45px',
  },
  imagen: {
    width: '100%',
    display: 'flex',
    margin: '20px 0 20px 0',
    justifyContent: 'center',
    alignItems: 'center'
  },
  img: {
    objectFit: 'cover',
    width: '200px'
  },
  titular: {
    fontSize: '12px',
    textAlign: 'center',
    margin: '10px 0 15px 0',
    fontFamily: 'PTSerifBold',

  },
  negrita: {
    fontSize: '12px',
    fontFamily: 'PTSerifBold',
    lineHeight: 1.5,
    textAlign: 'justify',
  },
  parrafo: {
    fontSize: '12px',
    margin: '20px 0 0 0',
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  parrafo1: {
    fontSize: '12px',
    margin: '70px 0 0 0',
    textAlign: 'justify',
  },
  firma: {
    paddingLeft: '10px',
    width: '100px',
    height: '135px',
    display: 'flex',
    margin: '35px 0 -80px -5px'
  },
  piefirma: {
    fontFamily: 'PTSerifBold',
    fontSize: '13px',
  },
  piefirma1: {
    fontSize: '13px',
    textAlign: 'justify',
    lineHeight: 1.3,
  },
  piepagina: {
    fontSize: '11px',
    padding: '50px',
  },
  informacion: {
    textAlign: 'justify',
    fontSize: '11px',
    color: 'lightcoral',
    margin: '70px 0 0 0',
  },
  linea: {
    margin: '-20px 0 0 0',
  },

  // certificado

  backgroundWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '792px',
    height: '612px'
  },

  titulo_ceritficado: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    lineHeight: 1.5,
    letterSpacing: 1,
    margin: '15px',
    textAlign: 'center',
    marginTop: '100px',
  },

  bold_certificado: {
    fontFamily: 'RobotoBold',
    fontSize: '18px',
  },


  contentWrapper: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'relative'
  },

  textWrapper: {
    paddingLeft: '160px',
    paddingRight: '160px'
  },

  certificaWrapper1: {
    paddingLeft: '115px',
    paddingRight: '115px',
    marginTop: '0px'
  },
  certificaWrapper: {
    paddingTop: '20px',
  },
  certifica:{
    color: '#AB7B35' ,
    fontFamily: 'RobotoBold',
    fontSize: '25px',
  },
  lineWrapper: {
    paddingTop: '10px',
  },
  titulo_nombre: {
    fontFamily: 'RobotoBold',
    fontSize: '25px',
    lineHeight: 1.5,
    letterSpacing: 1,
    margin: '15px',
    textAlign: 'center',
    marginTop: '10px'
  },
  titulo_cc: {
    marginTop: '-30px',
    fontFamily: 'RobotoBold'
  },
  parrafo_ceritficado: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: 1.2,
    letterSpacing: 1,
    marginTop: '15px',
    textAlign: 'center',
  },
  parrafo_ceritficado1: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: 1.2,
    letterSpacing: 1,
    marginTop: '15px',
  },
  bold_parrafo: {
    fontFamily: 'RobotoBold',
  },
  containergeneral:{
    display:'flex',
    flexDirection:'row',
    marginTop:'20px',
    justifyContent:'space-between',
    width:'90%'
  },
  containerfirmas: {
    marginRight:'5px',
    width: '30%',
    textAlign:'center',
  },
  lineacertificado: {
    borderBottom: '1px solid #AB7B35',
  },

  nombres: {
    fontFamily: 'RobotoBold',
    fontSize: '15px',
    color:'#AB7B35',
    lineHeight: 1.2,
    letterSpacing: 1,
  },
  nombrescargos: {
    fontFamily: 'RobotoBold',
    fontSize: '8.5px',
    color:'#561515',
    padding:'3px 10px 0',
    lineHeight: 1.2,
    letterSpacing: 1,

  },
  firmacertificado:{
    width: '150px',
    paddingLeft:'50px',

  }


});


const CursoEstudiante = () => {
  const usuario = useSelector(state => state.redGlobal.usuario);
  const [seleccion, setSeleccion] = React.useState('miproceso');
  const [mensaje, setMensaje] = React.useState(null);
  const [detalle, setDetalle] = React.useState(true)
  const [procesos, setProcesos] = React.useState([])
  const [data, setData] = React.useState({});
  const [openModalInfo, setOpenModalInfo] = React.useState(false);
  const [instructivo, setInstructivo] = React.useState([]);
  const classes = useStyles()
  const dispatchI = useDispatch();
  const isInitialMount = React.useRef(true);
  const [cargando, setCargando] = React.useState(false)
  const [solicitudActiva, setSolicitudActiva] = React.useState(false)
  const [periodoactual, setPeriodoActual] = React.useState('');
  const [cordinador, setCordinador] = React.useState('');
  const [firma_coordinador, setFirma_coordinador] = React.useState('');
  const [vicerrector, setVicerrector] = React.useState('');
  const [firma_Vicerrector, setFirma_Vicerrector] = React.useState('');
  const [unidadCientifica, setUnidadCientifica] = React.useState('');
  const [firma_UnidadCientifica, setFirma_UnidadCientifica] = React.useState('');


  React.useEffect(() => {
    if (usuario.perfil.codigo == 'Per_Nor' || usuario.perfil.codigo == 'Per_Adm' || usuario.perfil.codigo == 'Per_Est_Pos') {
      dispatchI(mostrarModulo({ ruta: '/curso_estu', nombre: 'Niveles de Formacion' }))
      obtenerInstructivo()
    } else {
      setMensaje('permiso')
    }
  }, []);
  React.useEffect(() => {
    //Obtengo los prceosos activos de la persona activa.
    if (isInitialMount.current) {
        isInitialMount.current = false
        obtenerPeriodo()
    }
})

  React.useEffect(() => {
    //Obtengo los prceosos activos de la persona activa.
    // if (isInitialMount.current) {
    //   isInitialMount.current = false
    const obtenerProcesos = async () => {
      let solicitud = await obtenerSolicitud();
      
      // setSolicitudesUsuario(solicitud)
      const existe = solicitud.some(solicitud => solicitud.estado_actual.codigo == "certificado_curso");
      console.log(solicitud)
      console.log(existe)
      // setSolicitudActiva(existe)
      const SolicitudPeriodoActual = solicitud.some(
        (solicitud) => solicitud.periodo == periodoactual
      );
      const puedeCrearNuevaSolicitud = existe || SolicitudPeriodoActual;
      setSolicitudActiva(puedeCrearNuevaSolicitud)
      // if(solicitud.length > 0){
      //     console.log(solicitud)
      //     setSolicitudActiva(true)
      // } 
      setCargando(false)
    }
    obtenerProcesos()
    obtenerNombreJefes()
    // }
  }, [seleccion])

  const obtenerSolicitud = async () => {
    let f = await Funciones.generarFiltros([{ 'llave': 'usuario_registro', 'valor': usuario.id }, { 'llave': 'estado', 'valor': '1' }]);
    return new Promise(resolve => {
      Funciones.consulta(`api/v1.0/cursoformacion/solicitud?${f}`, null, null, (error, estado, resp) => {
        console.log('Respuesta de la API:', resp);
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }
  const obtenerPeriodo = async () => {
    let periodo = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Cur' }]);
    setPeriodoActual(periodo)
    setPeriodoActual(periodo[0].nombre)

}
  const obtenerInstructivo = async () => {
    let instructivo_estudiante = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Est_inst_curso' }]);
    setInstructivo(instructivo_estudiante)
  }

  const obtenerNombreJefes = async () => {
    let nombrejefe = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'firma_coordinador' }]);
    let nombrevice = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'firma_vicerrector' }]);
    let nombreunidad = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'firma_unidad_cientifica' }]);
    setCordinador(nombrejefe[0].valora)
    setFirma_coordinador(nombrejefe[0].archivo)
    setVicerrector(nombrevice[0].valora)
    setFirma_Vicerrector(nombrevice[0].archivo)
    setUnidadCientifica(nombreunidad[0].valora)
    setFirma_UnidadCientifica(nombreunidad[0].archivo)
  }

  const vista = () => {

    switch (seleccion) {
      case 'miproceso':
        return (
          <div>
            {detalle == true ? <PintarSolicitudes
              procesos={procesos}
              setProcesos={setProcesos}

              detalle={detalle}
              setDetalle={setDetalle}

              data={data}
              setData={setData}

              seleccion={seleccion}
              setSeleccion={setSeleccion}

              usuario={usuario}
            /> : <PintarSolicitudSeleccionada
              detalle={detalle}
              setDetalle={setDetalle}
              firma_coordinador={firma_coordinador}
              cordinador={cordinador}
              vicerrector={vicerrector}
              firma_Vicerrector={firma_Vicerrector}
              unidadCientifica={unidadCientifica}
              firma_UnidadCientifica={firma_UnidadCientifica}
              data={data}
              setData={setData}

              openModalInfo={openModalInfo}
              setOpenModalInfo={setOpenModalInfo}
            />}
          </div>
        )
      case 'nuevo':
        return (
          <>
            {solicitudActiva ?
              <div onClick={() => setSeleccion('miproceso')} className='pointer'><TareasTerminadas mensaje='Hola, solo puedes tener un proceso activo. Clic aquí para ver tu proceso actual' marginTop='7%' imagen={emma_s} widthImg="7%" /></div>
              : <SemilleroSolicitudesEst usuario={usuario} />

            }
          </>

        )
      default:
        break;
    }
  }

  return (
    <div>
      {mensaje ? (
        <div>
          <Link to="/semilleros">
            <ArrowBackIcon style={{ fontSize: '35px', color: '#c79e32', marginTop: '5px' }} />
          </Link>
          <TareasTerminadas mensaje='No tienes permiso para este modulo' marginTop='7%' imagen={emma_s} widthImg="7%" />
        </div>
      ) : (
        <>
          <MenuCursosFormacion instructivo_estudiante={instructivo[0] ? instructivo[0].archivo : ''} sele={seleccion} seleccionar={(seleccion) => setSeleccion(seleccion)} vistaEstudiante={true} />
          <div className={classes.contenidoModulo}>
            {vista()}
          </div>
        </>
      )}
    </div>
  )
}


const PintarSolicitudes = (props) => {

  const isInitialMount = React.useRef(true);
  const [cargando, setCargando] = React.useState(true);


  React.useEffect(() => {
    //Obtengo los prceosos activos de la persona activa.
    if (isInitialMount.current) {
      isInitialMount.current = false
      const obtenerProcesos = async () => {
        await obtenerSolicitud();
        setCargando(false)
      }
      obtenerProcesos()
    }
  })



  const obtenerSolicitud = async () => {
    let f = await Funciones.generarFiltros([{ 'llave': 'usuario_registro', 'valor': props.usuario.id }, { 'llave': 'estado', 'valor': '1' }]);
    Funciones.consulta(`api/v1.0/cursoformacion/solicitud?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) props.setProcesos(resp)
    })
  }

  if (props.procesos.length == 0) {
    return (
      <div className="pointer" onClick={() => props.setSeleccion("nuevo")}>
        <TareasTerminadas
          mensaje="No te has inscrito a ningún curso de formación del programa de semilleros de investigación, clic aquí para inscribirte"
          marginTop="7%"
          imagen={emma_s}
          widthImg="7%"
        />
      </div>
    );
  }

  const handleClick = (proceso) => {
    props.setDetalle(false)
    props.setData(proceso)
  };

  return (
    <div>
      {cargando && <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />}

      {
        !cargando && props.procesos.length == 0 ?
          <div className="pointer" onClick={() => props.setSeleccion("nuevo")}>
            <TareasTerminadas
              mensaje="No te has inscrito a ningún curso de formación del programa de semilleros de investigación, clic aquí para inscribirte"
              marginTop="7%"
              imagen={emma_s}
              widthImg="7%"
            />
          </div>

          :
          <div style={{ padding: '2% 2% 0% 2%' }}>
            <>
              <Grid container direction={'row'} spacing={1}>
                <TituloAccion principal="" alterno="¡ Lista de Solicitudes !" />
                {props.procesos.map((proceso, i) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                      <Paper elevation={1} onClick={() => handleClick(proceso)} className="pointer">
                        <CardSolicitud
                          id={proceso.id}
                          tipo={proceso.tipo}
                          fecha={proceso.fecha_registro}
                          descripcion={`Esta es su solicitud de inscripción al curso ${proceso.tipo.valora} se encuentra en estado ${proceso.estado_actual.nombre}. Clic aquí para obtener más formación.`}
                          estado={proceso.estado_actual}
                        //handleClickDelete={handleClickDelete}
                        />
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          </div>

      }

    </div>
  )

}

const PintarSolicitudSeleccionada = (props) => {

  const isInitialMount = React.useRef(true);
  const [pos, setPos] = React.useState('detalle')
  const [cargando, setCargando] = React.useState(true);
  const [lineaEstado, setLineaEstado] = React.useState([]);
  const [iniciocurso, setIniciocurso] = React.useState('') 
  const [fincurso, setFincurso] = React.useState('') 
  const [horas, setHoras] = React.useState('')
  const [periodo, setPeriodo] = React.useState('')

  React.useEffect(() => {
    //Obtengo los prceosos activos de la persona activa.
    if (isInitialMount.current) {
      isInitialMount.current = false
      const obtenerProcesos = async () => {
        let linea_estado = await obtenerLineaEstados();
        setLineaEstado(linea_estado)
        setCargando(false)
      }
      obtenerProcesos()
      FechasCertificados()
    }
  })

  const FechasCertificados = async () => {
    let fechas = await Funciones.obtenerValoresFiltros([
      { llave: "codigo", valor: "Fechas_niveles_formacion" },
      ]);
      if (fechas) {
        setIniciocurso(fechas[0].valora)
        setFincurso(fechas[0].valorb)
        setHoras(fechas[0].valorc)
        setPeriodo(fechas[0].valord)
      }
  }
  const handleClick = () => {
    props.setDetalle(true)
  };

  const openModalInfo = () => {
    props.setOpenModalInfo(true)
  }

  const obtenerMes = (mes) => {
    const meses = {
      '1': 'Enero',
      '2': 'Febrero',
      '3': 'Marzo',
      '4': 'Abril',
      '5': 'Mayo',
      '6': 'Junio',
      '7': 'Julio',
      '8': 'Agosto',
      '9': 'Septiembre',
      '10': 'Octubre',
      '11': 'Noviembre',
      '12': 'Diciembre'
    }
    return (meses[mes])
  }
  const generarPDF = () => {
    let fecha_actual = moment()
    let hoy = `${fecha_actual.format('D')} de ${obtenerMes(fecha_actual.format('M'))} de ${fecha_actual.format('YYYY')}`
    let data = props.data
    let nombre = data.usuario_registro.primer_nombre + ' ' + data.usuario_registro.segundo_nombre + ' ' + data.usuario_registro.primer_apellido + ' ' + data.usuario_registro.segundo_apellido
    let cedula = data.usuario_registro.identificacion
    let programa = data.solicitud_curso_formacion[0].programa.nombre
    let firma = props.cordinador
    let imgfirma= props.firma_coordinador


    return (
      <Document>
        <Page size="LETTER" >
          <View style={styles.body}>
            <View style={styles.imagen}>
              <Image style={styles.img} src={LogoCuc} alt="images" />
            </View>
            <Text style={styles.titular}>CERTIFICADO INSCRITO: </Text>
            <Text style={styles.parrafo}>
              El suscrito Coordinador de semilleros de investigación e innovación de la Corporación Universidad de
              la Costa, certifica que <Text style={styles.negrita}>{nombre}</Text> identificado(a) con la Cédula de Ciudadanía No.<Text style={styles.negrita}>{cedula}</Text>, el estudiante del programa <Text style={styles.negrita}>{programa}</Text> se encuentra <Text style={styles.negrita}>INSCRITO</Text> en el curso de formación "Seminario de investigación Adjunto {periodo}" ofrecido en el programa semillero de investigación e innovación.
            </Text>
            <Text style={styles.parrafo}><Text style={styles.negrita}>NOTA:</Text> El presente certificado solo es válido para el semestre {periodo}. En caso tal desee vincularse a un grupo de semillero en el transcurso que se está impartiendo el curso, podrá hacerlo siempre y cuando tenga aval del profesor líder y se comprometa a culminar y certificarse en el curso.</Text>
            <Text style={styles.parrafo}>Se expide el presente certificado en Barranquilla, Colombia el {hoy}</Text>
            <Text style={styles.parrafo1}>Atentamente, </Text>

            <View style={styles.firma}>
              <Image src={firma_curso} alt="images" />
            </View>
            <Text style={styles.linea}>_______________</Text>
            <Text style={styles.piefirma}>{firma}</Text>
            <Text style={styles.piefirma1}> Coordinador de semilleros de investigación e innovación. </Text>
            <Text style={styles.piefirma1}> Corporación Universidad de la Costa </Text>
            <Text style={styles.informacion}>
              Calle 58 N° 55-66 – Teléfonos: (5) 336 2200 – 3441974 – Fax: 3442670 – A.A. 50366 – www.cuc.edu.co
            </Text>
          </View>
        </Page>
      </Document>
    )
  }

  const generarPDF_certificado = () => {
    let fecha_actual = moment()
    let hoy = `${fecha_actual.format('D')} dias del mes de ${obtenerMes(fecha_actual.format('M'))} de ${fecha_actual.format('YYYY')}`
    let data = props.data
    let nombre = data.usuario_registro.primer_nombre + ' ' + data.usuario_registro.segundo_nombre + ' ' + data.usuario_registro.primer_apellido + ' ' + data.usuario_registro.segundo_apellido
    let cedula = data.usuario_registro.identificacion
    // let programa = data.solicitud_curso_formacion[0].programa.nombre
    let firma = props.cordinador
    let imgfirma= props.firma_coordinador
    let firmavicerector = props.vicerrector
    let imgfirmavice=props.firma_Vicerrector
    let firmaunidad = props.unidadCientifica
    let imgfirmaunidad=props.firma_UnidadCientifica

    return (
      <Document>
        <Page size="LETTER" orientation="landscape">
          <View>
            <Image src={imagen_certificado} style={styles.backgroundWrapper} alt="background" />
          </View>

          <View style={styles.contentWrapper}>
            <View style={styles.textWrapper}>
              <Text style={styles.titulo_ceritficado}>La <Text style={styles.bold_certificado}>Universidad de la Costa</Text> por intermedio de la Vicerrectoría de Ciencia, Tecnología e Innovación</Text>
            </View>
            <View style={styles.certificaWrapper}>
              <Text style={styles.certifica}>CERTIFICA QUE:</Text>
            </View>
            <View style={styles.certificaWrapper}>
              <Text style={styles.titulo_nombre} >{nombre}</Text>
            </View>
            <View style={styles.certificaWrapper}>
              <Text style={styles.titulo_cc}>CC. {cedula}</Text>
            </View>
            <View style={styles.certificaWrapper}>
              <Text style={{ borderBottom: '2px solid #AB7B35', width: '550px' }}></Text>
            </View>
            <View style={styles.certificaWrapper1}>
              <Text style={styles.parrafo_ceritficado}>
                Cursó y aprobó el <Text style={styles.bold_parrafo}>Seminario de Investigación Adjunto </Text>ofrecido por el<Text style={styles.bold_parrafo}> Programa de Semilleros de Investigación e Innovación, </Text> realizado {iniciocurso} {fincurso}, con una intensidad de<Text style={styles.bold_parrafo}> {horas} horas.</Text>
              </Text>
            </View>
            <View>
              <Text style={styles.parrafo_ceritficado1}>Dado en Barranquilla, Colombia, a los {hoy}</Text>
            </View>
            <View style={styles.containergeneral}>
              <View style={styles.containerfirmas}>
                <Image src={firmavice} style={styles.firmacertificado} />
                <Text style={styles.lineacertificado}></Text>
                <Text style={styles.nombres}>{firmavicerector}</Text>
                <Text style={styles.nombrescargos}>VICERRECTOR DE CIENCIA, TECNOLOGÍA E INNOVACIÓN</Text>
              </View>
              <View style={styles.containerfirmas}>
                <Image src={firmasemi} style={styles.firmacertificado}  />
                <Text style={styles.lineacertificado}></Text>
                <Text style={styles.nombres}>{firmaunidad}</Text>
                <Text style={styles.nombrescargos}>JEFA UNIDAD DE FORMACION Y COMUNICACIÓN CIENTÍFICA</Text>
              </View>
              <View style={styles.containerfirmas}>
                <Image  src={firma_curso} style={styles.firmacertificado}  />
                <Text style={styles.lineacertificado}></Text>
                <Text style={styles.nombres}>{firma}</Text>
                <Text style={styles.nombrescargos}>COORDINADOR(A) SEMILLEROS DE INVESTIGACIÓN E INNOVACIÓN </Text>
              </View>
            </View>
          </View>

        </Page>
      </Document>
    )
  }

  const obtenerLineaEstados = async () => {
    return new Promise(resolve => {
      Funciones.consulta(`api/v1.0/cursoformacion/linedeestado/${props.data.id}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  const lineaDeEstados = () => {
    if (!cargando && lineaEstado.length > 0) {
      let activeStep = 0;
      let steps = [];
      if (props.data.estado_actual.codigo === "retirado_curso") {
        
      }else if(props.data.estado_actual.codigo === "no_culmi_curso"){
        
      } else {
        lineaEstado.map(({ secundario: { id, nombre } }, i) => {
          if (id === props.data.estado_actual.id) activeStep = i;
          steps.push({ label: nombre });
        });
      }

      activeStep = props.data.estado_actual.codigo === 'certificado_curso' ? lineaEstado.length + 1 : activeStep;
      return <BarraProceso steps={steps} activeStep={activeStep} />;
    }
    return null;
  }

  return (
    <div className='' >
      <Button onClick={() => handleClick()} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Atras </Typography> </Button>
      <div style={{ padding: '0% 2% 0% 2%' }} >
        <Grid container direction={'row'} spacing={1} >
          <Grid item xs={12} sm={12} md={6} >
            <TituloAccion principal='' alterno='¡ Información Básica !' />
            <Paper elevation={0} square className="scroll">
              <SolicitudTables data={props.data} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
            <Grid container direction={'row'} spacing={2} style={{ width: "100%" }}>
              <Grid item xs={12} sm={12} md={12} >

                <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => openModalInfo()}>
                  <AccionesRapidas imagen={revisar} titulo='¡ Más Información !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes ver toda la información actual.' />
                </Paper>
              </Grid>
              {props.data.estado_actual.codigo == 'inscrito_curso' ?
                <Grid item xs={12} sm={12} md={12} >
                  <PDFDownloadLink style={{ textDecoration: 'none' }} document={generarPDF(props.participante)} fileName={`CerificadoCursoFormacion.pdf`}>
                    {
                      () => (

                        <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} >
                          <AccionesRapidas imagen={file} titulo='¡ Descargar Certificado !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes Descargar tu certificado de participacion en pdf.' />
                        </Paper>

                      )
                    }
                  </PDFDownloadLink>
                </Grid>
                : ''}
              {props.data.estado_actual.codigo == 'certificado_curso' ?
                <Grid item xs={12} sm={12} md={12} >
                  <PDFDownloadLink style={{ textDecoration: 'none' }} document={generarPDF_certificado(props.participante)} fileName={`Cerificado.pdf`}>
                    {
                      () => (

                        <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} >
                          <AccionesRapidas imagen={file} titulo='¡ Descargar Certificado curso!' sub_titulo='Bien' des_sub_titulo=', Aquí puedes Descargar tu certificado de participacion en pdf.' />
                        </Paper>

                      )
                    }
                  </PDFDownloadLink>
                </Grid>
                : ''}
            </Grid>
          </Grid>

          {props.data.estado_actual.codigo != "no_culmi_curso" && props.data.estado_actual.codigo != "retirado_curso"&&
            // (procesoActual.estado_actual.codigo != 'Est_Int_Neg' && procesoActual.estado_actual.codigo != 'Est_Int_Bor' && procesoActual.estado_actual.codigo != 'Est_Int_Can') &&
            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '1.5%' }}>
              <TituloAccion principal='' alterno='¿ Cómo va mi proceso ?' />
              {lineaDeEstados()}
            </Grid>
          }
        </Grid>
      </div>
      {props.openModalInfo == true ? <CursosList
        openModalInfo={props.openModalInfo}
        setOpenModalInfo={props.setOpenModalInfo}
        id_solicitud={props.data.id}
        pos={pos}
        setPos={setPos}
      /> : ''}

    </div>
  )
}

export default CursoEstudiante 