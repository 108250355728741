import Swal from 'sweetalert2';

//export const api = "http://127.0.0.1:8000";
export const api = "https://backend.cuc.edu.co";
// export const api = "https://backendtestapache.cuc.edu.co";
export const rutaImg = "https://agil.cuc.edu.co/Fotos/";

export const consulta = (url, data = null, method = null, callback, authorization = true) => {
    let parametros = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    if (method === 'patch') method = 'PATCH'
    if (method) parametros.method = method;
    if (authorization) parametros.headers.Authorization = `JWT ${localStorage.getItem('token')}`;
    if (data) parametros.body = JSON.stringify(data);

    fetch(`${api}/${url}`, parametros)
        .then(async response => {
            const estado = response.status;
            if (estado === 401) {
                window.location.href = "/sin_sesion";
            } else {
                const resp = await response.json();
                callback(null, estado, resp)
            }
        }).catch(error => callback(error));
}

export const mostrarError = (errores) => {
    for (var k in errores) {
        let r = errores[k];
        let mensaje = '';
        r.forEach((element) => {
            mensaje = `${mensaje} ${element}`;
        });
        return `${k} : ${mensaje}`;
    }
};

export const formulario = (url, data, method, callback, authorization = true) => {
    let headers = {};
    if (authorization) headers.Authorization = `JWT ${localStorage.getItem('token')}`;
    if (method === 'patch') method = 'PATCH'

    fetch(`${api}/${url}`, {
        method,
        cache: 'no-cache',
        mode: 'cors',
        body: data,
        headers,
    }).then(async response => {
        const estado = response.status;
        if (estado === 401) {
            window.location.href = "/sin_sesion";
        } else {
            const resp = await response.json();
            callback(null, estado, resp);
        }
    }).catch(error => callback(error));
}

export const crear_form_data = (data) => {
    return new Promise((resolve) => {
        let key = Object.keys(data);
        let formData = new FormData();
        key.forEach((key) => formData.append(key, data[key]));
        resolve(formData);
    });
};

export const copiarPortaPapeles = (texto, container) => {
    let aux = document.createElement('input');
    let cont = document.getElementById(container);
    cont.appendChild(aux);
    aux.setAttribute('value', texto);
    aux.select();
    document.execCommand('copy');
    cont.removeChild(aux);
};

export const traerServidor = () => {
    let protocolo = window.location.protocol;
    let host = window.location.hostname;
    let puerto = window.location.port ? `:${window.location.port}` : '';
    return `${protocolo}//${host}${puerto}`;
};

export const ingresarApp = (data) => {
    return new Promise((resolve) => {
        fetch(`${api}/api/v1.0/validar_credenciales`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then(async(response) => {
                const estado = response.status;
                const resp = await response.json();
                resolve({ estado, resp });
            })
            .catch((error) => {
                resolve({ error });
            });
    });
};

export const ingresarAppRegister = (data) => {
    return new Promise((resolve) => {
        fetch(`${api}/api/v1.0/validar_datos_sicuc`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then(async(response) => {
                const estado = response.status;
                const resp = await response.json();
                resolve({ estado, resp });
            })
            .catch((error) => {
                resolve({ error });
            });
    });
};

export const validarUsuarioApp = () => {
    return new Promise((resolve) => {
        if (localStorage.getItem('token')) {
            fetch(`${api}/api/v1.0/current_user`, {
                    headers: {
                        Authorization: `JWT ${localStorage.getItem('token')}`
                    }
                })
                .then(async(response) => {
                    const estado = response.status;
                    const resp = await response.json();
                    resolve({ estado, resp });
                })
                .catch((error) => {
                    resolve({ error });
                });
        } else {
            resolve({ error: 'No cuenta con un token' });
        }
    });
};

export const CerrarApp = () => {
    return new Promise((resolve) => {
        localStorage.removeItem('token');
        localStorage.removeItem('fotoMs');
        resolve(true);
    });
};

export const obtenerIdiomaTabla = () => {
    return {
        textLabels: {
            body: {
                noMatch: 'No se encontraron registros',
                toolTip: 'Ordenar'
            },
            pagination: {
                next: 'Siguiente Pagina',
                previous: 'Pagina Anterior',
                rowsPerPage: 'Filas por pagina:',
                displayRows: 'de'
            },
            toolbar: {
                search: 'Buscar',
                downloadCsv: 'Descargar CSV',
                print: 'Imprimir',
                viewColumns: 'Ver Columnas',
                filterTable: 'Tabla de Filtros'
            },
            filter: {
                all: 'All',
                title: 'FILTROS',
                reset: 'LIMPIAR'
            },
            viewColumns: {
                title: 'Mostrar Columnas',
                titleAria: 'Mostrar/Ocultar Columnas de tabla'
            },
            selectedRows: {
                text: 'fila(s) seleccionadas',
                delete: 'Eliminar',
                deleteAria: 'Eliminar Filas Seleccionadas'
            }
        }
    };
};

export const validateInputs = (campos = []) => {
    return new Promise((resolve) => {
        let errores = [];
        let date_now = new Date()
        campos.map(({ value, name, err, value2, value3 }) => {
            if (err.empty && value.length == 0) errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` });
            if (err.numeric && isNaN(value)) errores.push({ llave: name, 'mensaje': `Este campo debe ser un numero` });
            if (err.email && !validarCorreo(value)) errores.push({ llave: name, 'mensaje': `Correo invalido` });
            if (err.phone_number && !validarCelular(value)) errores.push({ llave: name, 'mensaje': `Número telefónico invalido` });
            if (err.before_today && value >= date_now) errores.push({ llave: name, 'mensaje': `Fecha futura invalida` });
            if (err.after_today && value <= date_now) errores.push({ llave: name, 'mensaje': `Fecha pasada invalida` });
            if (err.finish_date && value <= value2) errores.push({ llave: name, 'mensaje': `Fecha invalida` });
            if (err.range && (value < 1 || value > value2)) errores.push({ llave: name, 'mensaje': `El valor está fuera del rango permitido` });
            if (err.range2 && (value < value2 || value > value3)) errores.push({ llave: name, 'mensaje': `El valor está fuera del rango permitido` });
            if (err.not_null && value == null) errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` });
            if (err.not_decimal && value % 1 != 0) errores.push({ llave: name, 'mensaje': `El valor no puede ser decimal` });
        });
        resolve(errores);
    });
}

export const getError = (llave, errores) => {
    let existe = errores.find(e => e.llave === llave);
    return existe ? existe.mensaje : '';
}

export const obtenerValores = (generica) => {
    return new Promise(resolve => {
        consulta(`api/v1.0/generica/${generica}/valores`, null, null,
            (error, estado, resp) => {
                resolve(resp)
            }, false
        );
    })
}

export const guardarValorGenerica = async(generica, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo) => {
    let data = await crear_form_data({ generica, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo });
    return new Promise(resolve => {
        formulario("api/v1.0/valores/crear", data, "post",
            (error, estado, resp) => {
                resolve({ error, estado, resp })
            }
        );
    })
}

export const modificarValorGenerica = async(id, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, valorh, valori, archivo) => {
    let data = await crear_form_data({ codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, valorh, valori, archivo });
    return new Promise(resolve => {
        formulario(`api/v1.0/valores/${id}`, data, "PATCH",
            (error, estado, resp) => {
                resolve({ error, estado, resp })
            }
        );
    })
}
export const modificarValorGenericaAlter = async(id, campos = {}) => {
    let data = await crear_form_data(campos);
    return new Promise(resolve => {
        formulario(`api/v1.0/valores/${id}`, data, "PATCH",
            (error, estado, resp) => {
                resolve({ error, estado, resp })
            }
        );
    })
}

export const guardarPermisoValorGenerica = async(principal, secundario) => {
    return new Promise(resolve => {
        consulta("api/v1.0/permisos/crear", { principal, secundario }, "post",
            (error, estado, resp) => {
                resolve({ error, estado, resp })
            }
        );
    })
}

export const obtenerValoresFiltros = (filtros = []) => {
    let f = '';
    filtros.map(({ llave, valor }) => (f = `${f}&${llave}=${valor}`));
    return new Promise((resolve) => {
        consulta(
            `api/v1.0/genericas/valores/buscar?${f}`,
            null,
            null,
            (error, estado, resp) => {
                resolve(resp);
            },
            false
        );
    });
};

export const obtenerValoresFiltrosContenido = (dato, filtros = []) => {
    let f = '';
    filtros.map(({ llave, valor }) => (f = `${f}&${llave}=${valor}`));
    return new Promise((resolve) => {
        consulta(
            `api/v1.0/genericas/valores/buscar/contenido/${dato}?${f}`,
            null,
            null,
            (error, estado, resp) => {
                resolve(resp);
            },
            false
        );
    });
};

export const obtenerPermisosValorAltContenido = (dato, filtros = []) => {
    let f = '';
    filtros.map(({ llave, valor }) => (f = `${f}&${llave}=${valor}`));
    return new Promise((resolve) => {
        consulta(
            `api/v1.0/genericas/permisos/buscar/contenido/${dato}?${f}`,
            null,
            null,
            (error, estado, resp) => {
                resolve(resp);
            },
            false
        );
    });
};

export const obtenerTodoValores = () => {
    return new Promise((resolve) => {
        consulta(`api/v1.0/valores`, null, null, (error, estado, resp) => {
            resolve(resp);
        });
    });
};

export const obtenerMisDatos = (pk = 0) => {
    return new Promise((resolve) => {
        consulta(`api/v1.0/personas/${pk}/datos`, null, null, (error, estado, resp) => {
            resolve(resp);
        });
    });
};

export const obtenerPermisosValor = (id) => {
    return new Promise(async(resolve) => {
        let data = await obtenerPermisosValorAlt([{ llave: 'principal', valor: id }]);
        resolve(data);
    });
};

export const obtenerPermisosValorAlt = (filtros = []) => {
    let f = '';
    filtros.map(({ llave, valor }) => (f = `${f}&${llave}=${valor}`));
    return new Promise((resolve) => {
        consulta(
            `api/v1.0/valores/permisos?${f}`,
            null,
            null,
            (error, estado, resp) => {
                resolve(resp);
            },
            false
        );
    });
};

export const obtenerValorsPermisos = (id, filtros = []) => {
    let f = '';
    filtros.map(({ llave, valor }) => (f = `${f}&${llave}=${valor}`));
    return new Promise((resolve) => {
        consulta(
            `api/v1.0/valores/${id}/permisos/valores?${f}`,
            null,
            null,
            (error, estado, resp) => {
                resolve(resp);
            },
            false
        );
    });
};


export const obtenerEstadosProceso = (id, filtros = []) => {
    let f = '';
    filtros.map(({ llave, valor }) => (f = `${f}&${llave}=${valor}`));
    return new Promise((resolve) => {
        consulta(
            `api/v1.0/estados/${id}?${f}`,
            null,
            null,
            (error, estado, resp) => {
                resolve(resp);
            },
            false
        );
    });
};

export const obtenerActividadesPerfil = (persona) => {
    return new Promise((resolve) => {
        consulta(`api/v1.0/personas/${persona}/genericas/permiso`, null, null, (error, estado, resp) => {
            resolve(resp);
        });
    });
};

export const personaSesionGenericas = (tipo) => {
    return new Promise((resolve) => {
        consulta(`api/v1.0/personas/genericas/${tipo}`, null, null, (error, estado, resp) => {
            resolve(resp);
        });
    });
};

export const valoresToSelect = valores => {
    return new Promise(resolve => {
        let data = valores.map((item) => ({
            value: item.id,
            label: item.nombre
        }))
        resolve(data)
    })
}

export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250
        }
    }
};

export const validarCorreo = (valor) => {
    let valido = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
        valor
    );
    return valido;
};

export const validarCelular = (valor) => {
    let valido = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(valor);
    return valido;
};

export const validarNIT = (nitCompleto) => {
    return true;
    let data = nitCompleto.split('-');
    if (data.length === 2) {
        let nit = data[0];
        let codigo = data[1];
        const ceros = '000000';
        const li_peso = [71, 67, 59, 53, 47, 43, 41, 37, 29, 23, 19, 17, 13, 7, 3];
        let ls_str_nit = ceros + nit;
        let li_suma = 0;
        let i = 0;
        for (i = 0; i < 15; i++) {
            li_suma += ls_str_nit.substring(i, i + 1) * li_peso[i];
        }
        let digito_chequeo = li_suma % 11;
        if (digito_chequeo >= 2) digito_chequeo = 11 - digito_chequeo;
        if (parseInt(codigo) === parseInt(digito_chequeo)) return true;
    }
    return false;
};

export const MaysPrimera = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const generarFiltros = (filtros = []) => {
    return new Promise((resolve) => {
        let f = '';
        filtros.map(({ llave, valor }) => (f = `${f}&${llave}=${valor}`));
        resolve(f);
    });
};

export const transformar = (data, id = 'id', nombre = 'nombre') => {
    return data.map((e) => ({
        value: e[id],
        label: e[nombre]
    }));
};
export const transformarEnviado = (data) => {
    return data.map(e => ({
        value: e.id,
        label: e.nombre,
        codigo: e.codigo,
    }));
};
export const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
});

export const valor_peso = (valor) =>
    new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(valor);

export const obtenerValoresPermisoPersonaTipo = async(persona, tipo, filtros = []) => {
    return new Promise((resolve) => {
        let f = '';
        filtros.map(({ llave, valor }) => (f = `${f}&${llave}=${valor}`));
        consulta(`api/v1.0/personas/${persona}/genericas/${tipo}/permiso?${f}`, null, null, (error, estado, resp) => {
            resolve(resp);
        });
    });
};

export const coloresEmma = {
    'primarycolor': '#900807',
    'secondarycolor': '#B70E0C',
    'iconscolor': '#F44336',
    'fondocolor': '#fcd0d0',
    'serchover': '#FFE6E6',
    'colorblanco': '#F8F6F6',
    'colornegro': '#000',
    'gray':'#62727b',
    'gray_ligth':'#aaaab2',
    'tarjetaloguin': '#D9D9D9',
    'select':'#fff1f0',

    //colores estados generales
    'filtrotodos':'#0d7fd9',
    'solicitudactiva': '#ae74ff',
    'solicitudenrevision':'#fcd116',
    'solicitudfinalizada': '#078030',
    'solicitudcancelada': '#933b27',
    'SilverGray':'#CCCCCC',
    'ReemplazaroReenviar': '#C9A227',
    'solicitudInicada': '#0d7fd9',
    'solicitudAceptada':'#9CDBA6',
    'solicitudnoseleccionada': '#FF6994',

    //modulo de  consultas
    'colorsemestre': '#F07256',
    'colorcreditos': '#efb4ab' 
}


export const getFotoMs = () => {
    return new Promise(resolve => {
        consulta(`api/v1.0/personas/fotoMS`, null, 'get',
            (error, estado, resp) => {
                resolve(resp)
            }
        );
    })
}