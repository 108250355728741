
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { DialogActions, DialogContent, Dialog, Button, Grid, Checkbox, Switch } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from "../../general/BotonesAccion";

class ModificarFecha extends Component {

  constructor(props) {
    super(props)
    this.state = {
      mensaje: '',
      opcional: '',
      checked: true,
      checked0: false,
      fecha: null,
    }
    this.fileInput = React.createRef();
  }

  componentDidUpdate({ modal }) {
    let { modal: modalAddNew } = this.props;
    if (modalAddNew !== modal) {
      this.setState({ mensaje: '', opcional: '', fecha: null, checked: true, checked0: false });
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }


  onSubmit = e => {
    let { mensaje, opcional, checked } = this.state;
    this.props.enviar(mensaje, opcional, checked);
    e.preventDefault();
  }

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    this.setState({
      [name]: checked,
      checked: checked
    }, () => {
      console.log(`El checkbox "${name}" está ahora ${checked ? 'marcado (sí)' : 'desmarcado (no)'}.`);
    });
  };

  handleSwicthChange = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked }, () => {
      console.log(`El checkbox "${name}" está ahora ${checked ? 'marcado (sí)' : 'desmarcado (no)'}.`);
    });
  };

  render() {
    let { modal, mostrarModal, titulo, label, type, tipo, opcional } = this.props;
    let { mensaje, checked, checked0 } = this.state;
    return (
      <div>
        <Dialog open={modal} fullWidth={true} maxWidth="xs" onClose={mostrarModal.bind(this, false)} aria-labelledby="form-dialog-title">
          <AppBarModal titulo={titulo} mostrarModal={mostrarModal} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            <ValidatorForm onSubmit={this.onSubmit}>
              <Grid container spacing={1} alignItems="flex-end">
              </Grid>
              <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                <TextValidator
                  autoFocus
                  margin="dense"
                  label={label}
                  type={type}
                  fullWidth
                  name="mensaje"
                  value={mensaje}
                  validators={["required"]}
                  errorMessages={["El campo es requerido"]}
                  onChange={this.onChange}
                />
              </Grid>
              {tipo == "Mat_Pro_Sol_Rein_Tran" &&
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <TextValidator
                    margin="dense"
                    label={'Periodo Virtual'}
                    type={'text'}
                    fullWidth
                    name="opcional"
                    value={opcional}
                    onChange={this.onChange}
                  />
                </Grid>
              }
              {tipo == "Mat_Pro_Cor_Nota" &&
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                    {/* <Checkbox
                      edge="start"
                      checked={checked0}
                      onChange={this.handleCheckboxChange}
                      color="primary"
                      name='checked0'
                      value={checked0}
                    /> */}
                    {/* <span>¿Desea desastivar/activar Correción de notas?</span> */}
                    {/* <br/> */}
                    {/* {checked0 && (
                    <>
                      <Switch
                        edge="start"
                        checked={checked}
                        onChange={this.handleSwicthChange}
                        color="primary"
                        name='checked'
                        value={checked}
                      />
                      <span>Desactivar/Activar - Correción de notas</span>
                    </>
                  )} */}
                  <Switch
                        edge="start"
                        checked={checked}
                        onChange={this.handleSwicthChange}
                        color="primary"
                        name='checked'
                        value={checked}
                      />
                      <span>Desactivar/Activar - Correción de notas</span>
                  </Grid>
                </Grid>
              }
              <Button color="primary" type="submit" id='enviar_form_mensaje_fecha' className="oculto"> Enviar </Button>
            </ValidatorForm>
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModal(false)} />
            <BtnForm texto="ACEPTAR" callback={() => { document.getElementById('enviar_form_mensaje_fecha').click() }} />
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

ModificarFecha.propTypes = {
  //variables
  modal: PropTypes.bool.isRequired,
  titulo: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  //funciones
  enviar: PropTypes.func.isRequired,
  mostrarModal: PropTypes.func.isRequired,
}


export default ModificarFecha;
