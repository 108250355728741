import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '100%',
    height: '280px',
    cursor: 'pointer',
    borderRadius: '16px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease',
    overflow: 'hidden',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.3)',
    },
  },
  cardHeader: {
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#000',
  },
  cardImage: {
    width: 'auto',
    // width: '8vw',
    height: '100%',
    objectFit: 'cover',
    // [theme.breakpoints.up('xs')]: {
    //   width: '30%', // Ajustar al 80% del contenedor en pantallas pequeñas
    // },
    // [theme.breakpoints.up('sm')]: {
    //   width: '50%', // Ajustar al 50% del contenedor en pantallas medianas o grandes
    // },
    // [theme.breakpoints.up('md')]: {
    //   width: '30%', // Ajustar al 30% en pantallas más grandes
    // },
    // [theme.breakpoints.up('lg')]: {
    //   width: '45%', // Ajustar al 25% en pantallas más grandes
    // },
  },
  cardDescription: {
    fontSize: '0.9rem',
    lineHeight: '1.5',
    color: '#555',
    margin: '5px 10px 0px 10px',
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  }
}));

const CardSolicitud = ({
  tipo,
  onCardClick,
  gridProps = { xs: 12, sm: 6, md: 6, lg: 4 },
  style = { padding: '2% 3% 0% 0%' },
  gestion = false
}) => {
  const classes = useStyles();

  return (
    <Grid item {...gridProps} style={style} key={tipo.codigo} alignContent="center">
      <Paper style={{ borderRadius: '16px' }}>
        <Card className={classes.card} onClick={() => onCardClick(tipo)}>
          <CardHeader
            title={gestion ? "Gestión de " + tipo.nombre : tipo.nombre}
            titleTypographyProps={{
              className: classes.cardHeader,
            }}
          />
          {/* <CardContent> */}
            <Grid container direction="row" justifyContent="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  textAlign: 'center',
                  height: '130px',
                }}
              >
                <img
                  src={tipo.archivo}
                  className={classes.cardImage}
                  alt="Imagen Nuevo"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '3%' }}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  align="justify"
                  className={classes.cardDescription}
                >
                  {
                    gestion ? tipo.valord : tipo.descripcion
                  }
                </Typography>
              </Grid>
            </Grid>
          {/* </CardContent> */}
        </Card>
      </Paper>
    </Grid>
  );
};

CardSolicitud.propTypes = {
  tipo: PropTypes.shape({
    codigo: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
    archivo: PropTypes.string.isRequired,
    descripcion: PropTypes.string.isRequired,
  }).isRequired,
  onCardClick: PropTypes.func.isRequired,
  gridProps: PropTypes.object,
  style: PropTypes.object,
};

export default CardSolicitud;