import React, { Component } from 'react'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns';

import { makeStyles } from '@material-ui/core/styles';
import {
  DialogActions,
  Grid,
  Dialog,
  DialogContent,
  ListSubheader,
  Stepper,
  Step,
  StepLabel,
  Paper,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel
} from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';


import { BtnForm, BtnCargar } from "../../general/BotonesAccion";
import EnviarMultiplesArchivos from "../../general/EnviarMultiplesArchivos";
import AppBarModal from '../../general/AppBarModal';
import { obtenerEstadosProceso, obtenerPermisosValorAlt, obtenerValores, obtenerValoresFiltros } from "../../../global/js/funciones";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import AlertasSimple from '../../general/AlertasSimple';
import InputBuscar from '../../general/InputBuscar';
import BuscarPersona from "../../general/BuscarPersona";
import { MOTIVOS_NEGACION, ESTADOS, REQUISITOS, CARRERAS } from '../Helpers'

const configStep = ['Datos Iniciales', 'Requisitos'];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  const classes = useStyles();
  return (
    <List className={classes.root} subheader={
      <ListSubheader component="div" >
        Seleccione Estado
      </ListSubheader>
    }>
      {estados_siguiente.map(({ secundario }) => {
        let { codigo, nombre, id } = secundario;
        return (
          <ListItem key={codigo} role={undefined} dense button onClick={() => cambiarSeleccion({ codigo, id })} >
            <ListItemIcon>
              <Checkbox edge="start" checked={estado === codigo} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={codigo} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}

function RenderMotivos({ motivos, motivo, cambiarSeleccion }) {
  const classes = useStyles();
  return (
    <List className={classes.root} subheader={
      <ListSubheader component="div" >
        Seleccione Motivo
      </ListSubheader>
    }>
      {motivos.map(({ id, nombre, codigo }) => {
        return (
          <ListItem key={id} role={undefined} dense button onClick={() => cambiarSeleccion({ id: id, codigo: codigo })} >
            <ListItemIcon>
              <Checkbox edge="start" checked={motivo.id === id} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={id} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}

function AttachComponent(component_props) {
  let { requisitos, submit } = component_props

  const trasnformarDataAdj = (data) => {
    let r = [];
    data.map(({ secundario }) => r.push({ nombre: secundario.nombre, descripcion: secundario.descripcion, codigo: secundario.id, archivo: secundario.archivo }))
    return r;
  }

  const acciones = (data, callback) => {
    const onClickCargar = (data) => callback(data);

    let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />
    return <div>{cargar}</div>;
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} square>
            <List component="nav" arial-label="main">
              <EnviarMultiplesArchivos
                acciones={acciones}
                enModal={false}
                enviarArchivos={(archivos) => submit(archivos)}
                limite={2}
                mostrarMensaje={false}
                enLista={true}
                lista={trasnformarDataAdj(requisitos)}
              />
            </List>
          </Paper>
        </Grid>
      </Grid>

    </React.Fragment>

  )
}

class ValidacionesGestionar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      titulo_res: 'Cargando..',
      estado: { codigo: '', id: '' },
      observaciones: '',
      motivo: 0,
      motivos: [],
      estados_siguiente: [],
      seleccion: 'gestion',
      activeStep: 0,
      requisitos: [],
      buscarCoordinador: false,
      coordinador: {
        id: 0,
        nombre_completo: '',
      },
      fecha_examen: null,
      hora_examen: null,
      lugar_examen: '',
      duracionExamen: '',
      url: '',
      buscarProfesor: false,
      profesor: {
        id: 0,
        nombre_completo: '',
      },
      programa_asignado: '',
      programas: '',
      programaEstudiante: false,
      coordinador_profesor: false,
      examen_virtual: false,
      examen_presencial: false,
    }
  }

  async componentDidUpdate({ modalGestionValidacion }, { estado, programaEstudiante }) {
    let { modalGestionValidacion: modalGestionValidacionesNew, validacion } = this.props;
    let { estado: estadoNew, programaEstudiante: programaEstudianteNew } = this.state;

    if (estadoNew.codigo !== estado.codigo && estadoNew.codigo) {

      this.obtenerRequisitos()
      let acuerdo_validaciones = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Val_Vol_Liq' }]);
      let acuerdo_validaciones_examen_cal = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Val_Exm_Eva' }]);
      let acuerdo_validacione_pestudio = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Val_Obs_Pes' }])
      let acuerdo_validaciones_acta_examen = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Val_Act_Exa' }])

      this.setState({
        motivo: '',
        acuerdo_validaciones: acuerdo_validaciones.length > 0 ? acuerdo_validaciones[0].descripcion : '',
        acuerdo_validaciones_examen_cal: acuerdo_validaciones_examen_cal.length > 0 ? acuerdo_validaciones_examen_cal[0].descripcion : '',
        acuerdo_validacione_pestudio: acuerdo_validacione_pestudio.length > 0 ? acuerdo_validacione_pestudio[0].descripcion : '', acuerdo_validaciones_acta_examen: acuerdo_validaciones_acta_examen.length > 0 ? acuerdo_validaciones_acta_examen[0].descripcion : '',
      });
    }

    if (estadoNew.codigo !== estado.codigo && estadoNew.codigo === 'Val_Neg') {
      this.setState({ cargando: true, titulo_res: 'Cargando..' });
      let motivos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': MOTIVOS_NEGACION }, { 'llave': 'estado', 'valor': 1 }]);
      this.setState({ motivos, motivo: '', cargando: false });
    }

    if (programaEstudianteNew !== programaEstudiante) {
      if (!programaEstudianteNew) {
        this.setState({ programa_asignado: '' });
      }
    }

    if (modalGestionValidacion !== modalGestionValidacionesNew) {
      if (modalGestionValidacionesNew) {
        this.reiniciarEstado();
        let estados_siguiente = await obtenerEstadosProceso(validacion.tipo.id, [{ 'llave': 'principal', 'valor': validacion.estado_actual.id }, { 'llave': 'secundario__generica', 'valor': ESTADOS }]);
        let programas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': CARRERAS }, { 'llave': 'valorc', 'valor': 'pregrado' }]);


        let acuerdo_validaciones_estado_solicitado = validacion.estado_actual.codigo == 'Val_Rec' ? await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Val_Obs_Sol' }]) : []

        let acuerdo_validaciones_estado_revisado = validacion.estado_actual.codigo == 'Val_Rev' ? await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Val_Obs_Rec' }]) : []


        this.setState({ estados_siguiente, porcentaje: validacion && validacion.porcentaje ? validacion.porcentaje : '', cargando: false, acuerdo_validaciones_solicitado: acuerdo_validaciones_estado_solicitado.length > 0 ? acuerdo_validaciones_estado_solicitado[0].descripcion : '', acuerdo_validaciones_revisado: acuerdo_validaciones_estado_revisado.length > 0 ? acuerdo_validaciones_estado_revisado[0].descripcion : '', programas: this.transformarData(programas), });

      }
    }
  }

  // Obtner requisitos
  obtenerRequisitos = async () => {
    this.setState({ cargando: true })
    let { estado } = this.state
    let requisitos = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': estado.id }, { 'llave': 'secundario__generica', 'valor': REQUISITOS }, { 'llave': 'secundario__valora', 'valor': 'gestor' }])
    this.setState({ cargando: false, requisitos })
  }


  configStepContent = () => {
    let { actualizarMensaje } = this.props
    let { activeStep, initialTransition, archivos, requisitos, acuerdo_validaciones, estado, acuerdo_validacione_pestudio, acuerdo_validaciones_acta_examen, acuerdo_validaciones_examen_cal } = this.state
    let mensaje_validaciones = [{ item: acuerdo_validaciones }]
    let mensaje_validaciones_examen_cal = [{ item: acuerdo_validaciones_examen_cal }]
    let mensaje_validaciones_pestudio = [{ item: acuerdo_validacione_pestudio }]
    let mensaje_acta_examen = [{ item: acuerdo_validaciones_acta_examen }]

    switch (activeStep) {
      case 0:
        return (
          this.pintar()
        );
      case 1:
        return (
          <>
            <AttachComponent
              {...{
                initialTransition,
                requisitos,
                archivos,
                actualizarMensaje,
                eliminarArchivo: this.eliminarArchivo,
                agregarArchivos: this.agregarArchivos,
                submit: this.submit
              }}
            />

            {
              estado.codigo == 'Val_Adm' &&
              <React.Fragment>
                <AlertasSimple
                  tipo='info'
                  titulo={'Estimado gestor, tener en cuenta: '}
                  lista={mensaje_validaciones}
                  margin='0px'
                />
              </React.Fragment>
            }

            {
              estado.codigo == 'Val_Ecr' &&
              <React.Fragment>
                <AlertasSimple
                  tipo='info'
                  titulo={'Estimado gestor, tener en cuenta: '}
                  lista={mensaje_validaciones_examen_cal}
                  margin='0px'
                />
              </React.Fragment>
            }

            {
              estado.codigo == 'Val_Env' &&
              <React.Fragment>
                <AlertasSimple
                  tipo='info'
                  titulo={'Estimado gestor, tener en cuenta: '}
                  lista={mensaje_validaciones_pestudio}
                  margin='0px'
                />
              </React.Fragment>
            }

            {
              estado.codigo == 'Val_Ate' &&
              <React.Fragment>
                <AlertasSimple
                  tipo='info'
                  titulo={'Estimado gestor, tener en cuenta: '}
                  lista={mensaje_acta_examen}
                  margin='0px'
                />
              </React.Fragment>
            }



          </>
        )
      default:
        return <TareasTerminadas imagen={emma_w} mensaje='Esta bandeja esta vacía' widthImg='10%' marginTop='10%' />
    }
  }



  obtenerObservaciones = async () => {
    this.setState({ cargando: true });
    let { acuerdo_validaciones } = this.state
    let obs = await obtenerPermisosValorAlt([{ 'llave': 'codigo', 'valor': 'Val_Vol_Can' }])
    let acu_validaciones = [{ item: acuerdo_validaciones }]
    obs.map(({ secundario }) => acu_validaciones.push({ item: secundario.nombre }));
    this.setState({ acu_validaciones });
  }

  submit = (archivos) => {
    let { estado, observaciones, motivo, porcentaje, coordinador, fecha_examen, hora_examen, duracionExamen, lugar_examen, url, profesor, otro_motivo, requisitos, programa_asignado, coordinador_profesor, examen_presencial } = this.state;
    let { validacion, gestionarValidaciones } = this.props;
    fecha_examen = fecha_examen ? moment(fecha_examen).format('YYYY-MM-DD') : '';
    hora_examen = hora_examen ? moment(hora_examen).format('HH:mm') : '';

    let data = { mensaje: observaciones, motivo: motivo.id, estado: estado.codigo, porcentaje, total: archivos.length, coordinador: coordinador.id, fecha_examen, hora_examen, duracionExamen, lugar_examen, url, profesor: coordinador_profesor ? validacion.coordinador.id : profesor.id, otro_motivo, programa_asignado, examen_presencial }

    gestionarValidaciones(validacion.id, data, archivos, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }), requisitos);
  }

  agregarArchivos = files => {
    let { actualizarMensaje } = this.props
    let { archivos, requisitos } = this.state
    files.map((archivo => {
      if (archivos.length === 10000) actualizarMensaje({ titulo: `Se ha superado el numero maximo de archivos ${requisitos.length}`, tipo: 'info', mostrar: true, tiempo: 6000 })
      else archivos.push(archivo)
    }))
    this.setState({ archivos })
  }

  eliminarArchivo = (i) => {
    let { actualizarMensaje } = this.props;
    let { archivos } = this.state;
    archivos.splice(i, 1)
    this.setState({ archivos })
    actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
  }


  reiniciarEstado() {
    this.setState({
      cargando: true,
      titulo_res: 'Cargando..',
      estado: { codigo: '', id: '' },
      motivo: '',
      estados_siguiente: [],
      seleccion: 'gestion',
      requisitos: [],
      acuerdo_validaciones_solicitado: '',
      acuerdo_validaciones_revisado: '',
      activeStep: 0,
      buscarCoordinador: false,
      coordinador: {
        id: 0,
        nombre_completo: '',
      },
      fecha_examen: null,
      lugar_examen: '',
      url: '',
      buscarProfesor: false,
      profesor: {
        id: 0,
        nombre_completo: '',
      },
    })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  onCheck = ({ target }) => {
    let { name, checked } = target;
    this.setState({
      [name]: checked
    })
  }

  handleStep = direction => {
    let { actualizarMensaje } = this.props
    let { activeStep, estado } = this.state
    let sw = true

    if (!estado.codigo && activeStep == 0) {
      actualizarMensaje({ titulo: 'Debe diligenciar todos los campos obligatorios para continuar', tipo: 'info', mostrar: true, tiempo: 6000 });
      sw = false
    }
    if (sw) this.setState({ activeStep: direction ? activeStep + 1 : activeStep - 1 })
  }

  transformarData = (data) => {
    return data.map((item) => ({
      value: item.id,
      label: item.nombre,
      codigo: item.codigo,
    }));
  };

  checked = ({ target }) => {
    this.setState({
      [target.name]: target.checked,
    });
  };


  pintarEstados() {
    let { validacion } = this.props;
    let { estado, estados_siguiente, observaciones, motivo, coordinador, motivos, buscarCoordinador, fecha_examen, hora_examen, duracionExamen, lugar_examen, url, profesor, buscarProfesor, otro_motivo, programa_asignado, programas, programaEstudiante, coordinador_profesor, examen_virtual, examen_presencial } = this.state;

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
            <RenderEstados cambiarSeleccion={(estado) => this.setState({ estado })} estado={estado.codigo} estados_siguiente={estados_siguiente} />
          </Grid>
          {estado.codigo && (
            <>
              {
                estado.codigo === 'Val_Neg' &&
                <Grid item xs={12} md={12}>
                  <RenderMotivos cambiarSeleccion={(motivo) => this.setState({ motivo })} motivo={motivo} motivos={motivos} />
                </Grid>
              }

              {
                motivo.codigo === 'Otr_Neg' &&
                <Grid item xs={12} md={12} style={{ marginBottom: 5, width: '100%' }}>
                  <FormControl className="form-control" utils={DateFnsUtils} >
                    <TextField
                      value={otro_motivo}
                      label="Motivo"
                      name="otro_motivo"
                      placeholder="Escriba otro motivo"
                      onChange={this.onChange}
                    />
                  </FormControl>
                </Grid>
              }

              {
                estado.codigo == 'Val_Env' &&
                <>
                  <Grid item xs={12} md={12}>
                    <InputBuscar
                      placeholder='Seleccione Coordinador'
                      label={'Coordinador'}
                      id='coordinador'
                      value={(coordinador.nombre_completo)}
                      callback={() => this.setState({ buscarCoordinador: true })} />
                  </Grid>
                </>
              }
              {
                estado.codigo == 'Val_Not' &&
                <>
                  {
                    coordinador_profesor !== true &&
                    <Grid item xs={12} md={12}>
                      <InputBuscar
                        placeholder='Seleccione Profesor'
                        label={'Profesor'}
                        id='profesor'
                        value={(profesor.nombre_completo)}
                        callback={() => this.setState({ buscarProfesor: true })} />
                    </Grid>

                  }

                  <Grid item xs={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={coordinador_profesor}
                          onChange={this.checked}
                          id="coordinador_profesor"
                          name="coordinador_profesor"
                          color="primary"
                        />
                      }
                      label="¿ El coordinador es la misma persona que aplicara el examen ?"
                    />
                  </Grid>
                </>
              }
              {
                (estado.codigo == 'Val_Pag' && validacion.estado_actual.codigo == 'Val_Pag') &&
                <>
                  <Grid item xs={12} md={12}>

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={programaEstudiante}
                          onChange={this.onCheck}
                          name="programaEstudiante"
                          color="primary"
                        />
                      }
                      label="¿ La solicitud requiere cambio de programa?"
                    />
                  </Grid>
                  {(programaEstudiante && programas) &&
                    <Grid item xs={12} md={12}>
                      <FormControl className="form-control">
                        <InputLabel>Asignar programa</InputLabel>
                        <Select
                          id='programa_asignado'
                          value={programa_asignado}
                          name='programa_asignado'
                          onChange={this.onChange}
                        >
                          {
                            programas.map(({ value, label, codigo }) => (
                              <MenuItem key={value} value={value}>
                                {label}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                  }
                </>
              }
              {
                (estado.codigo == 'Val_Asi' || estado.codigo == 'Val_Rep') &&
                <>
                  <Grid item xs={12} md={12}>
                    <TableContainer>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Asignaciones</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {validacion.coordinador &&
                            <TableRow>
                              <TableCell align="left">{`${validacion.coordinador.primer_nombre} ${validacion.coordinador.primer_apellido} ${validacion.coordinador.segundo_apellido}`}</TableCell>
                              <TableCell align="left">Coordinador</TableCell>
                            </TableRow>
                          }
                          {validacion.profesor &&
                            <TableRow>
                              <TableCell align="left">{`${validacion.profesor.primer_nombre} ${validacion.profesor.primer_apellido} ${validacion.profesor.segundo_apellido}`}</TableCell>
                              <TableCell align="left">Profesor</TableCell>
                            </TableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  {
                    examen_presencial !== true &&
                    <>
                      <Grid item xs={12} md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={examen_virtual}
                              onChange={this.checked}
                              id="examen_virtual"
                              name="examen_virtual"
                              color="primary"
                            />
                          }
                          label="¿ El examen es virtual ?"
                        />
                      </Grid>

                      {
                        examen_virtual !== false && 
                        <>
                          Revisar Calendario en Teams
                        </>
                      }
                    </>
                  }


                  {
                    examen_virtual !== true &&
                    <>
                      <Grid item xs={12} md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={examen_presencial}
                              onChange={this.checked}
                              id="examen_presencial"
                              name="examen_presencial"
                              color="primary"
                            />
                          }
                          label="¿ El examen es presencial ?"
                        />
                      </Grid>

                      {
                        examen_presencial !== false &&
                        <Grid item xs={12} sm={12} >
                          <FormControl className="form-control" utils={DateFnsUtils} >
                            <TextField
                              value={lugar_examen}
                              id="txLugarexamen"
                              label="Lugar del examen"
                              name="lugar_examen"
                              required={true}
                              onChange={this.onChange}
                            />
                          </FormControl>
                        </Grid>
                      }
                    </>
                  }

                  <Grid item xs={12} sm={12} >
                    <FormControl className='form-control'>
                      <TextField
                        type='number'
                        id='txtDuracionExamen'
                        label='Duración del examen'
                        name='duracionExamen'
                        required={true}
                        InputProps={{
                          endAdornment: (<InputAdornment position="end">Minutos</InputAdornment>)
                        }}
                        value={duracionExamen}
                        onChange={this.onChange} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                      <KeyboardDatePicker
                        required={true}
                        style={{ marginTop: 3, width: '100%' }}
                        minDate={Date("YYYY-MM-dd")}
                        margin="normal"
                        label="Fecha del examen"
                        format="yyyy-MM-dd"
                        value={fecha_examen}
                        onChange={(fecha_examen) => this.setState({ fecha_examen })}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
                        invalidDateMessage='Formato Invalido'
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                      <Grid container>
                        <KeyboardTimePicker
                          required={true}
                          style={{ marginTop: 3, width: '100%' }}
                          margin="normal"
                          label="Hora del examen"
                          value={hora_examen}
                          onChange={(hora_examen) => this.setState({ hora_examen })}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          invalidDateMessage='Formato Invalido'
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                </>
              }
              {
                estado.codigo != 'Val_Neg' &&
                <Grid item xs={12} md={12}>
                  <FormControl className="form-control">
                    <TextField
                      value={observaciones}
                      id="txtMotivoNegado"
                      label="Observaciones"
                      name="observaciones"
                      placeholder="Escriba Observaciones"
                      multiline
                      onChange={this.onChange}
                    />
                  </FormControl>
                </Grid>
              }
            </>
          )}
        </Grid>

        <Dialog fullWidth={true} maxWidth="sm" open={buscarCoordinador} onClose={() => this.setState({ buscarCoordinador: false })}>
          <AppBarModal titulo={'¡ Buscar Coordiandor !'} mostrarModal={() => this.setState({ buscarCoordinador: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarCoordinador: false })} />
          <DialogContent style={{ padding: '0' }} className='scroll'>

            <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
              coordinador: {
                id,
                nombre_completo: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              }, buscarCoordinador: false,
            })} />

          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarCoordinador: false })} />
          </DialogActions>
        </Dialog>

        <Dialog fullWidth={true} maxWidth="sm" open={buscarProfesor} onClose={() => this.setState({ buscarProfesor: false })}>
          <AppBarModal titulo={'¡ Buscar Profesor !'} mostrarModal={() => this.setState({ buscarProfesor: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarProfesor: false })} />
          <DialogContent style={{ padding: '0' }} className='scroll'>

            <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
              profesor: {
                id,
                nombre_completo: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              }, buscarProfesor: false
            })} />

          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarProfesor: false })} />
          </DialogActions>
        </Dialog>
      </>


    )
  }

  pintar() {
    let { seleccion } = this.state;
    return (<div>
      {seleccion === 'gestion' && this.pintarEstados()}
    </div>)
  }

  render() {
    let { modalGestionValidacion, mostrarGestionValidacion } = this.props;
    let { cargando, titulo_res, activeStep, requisitos, acuerdo_validaciones_solicitado, acuerdo_validaciones_revisado } = this.state;
    let mensaje_validaciones = acuerdo_validaciones_solicitado ? [{ item: acuerdo_validaciones_solicitado }] : []
    let mensaje_validaciones_rev = acuerdo_validaciones_revisado ? [{ item: acuerdo_validaciones_revisado }] : []


    return (
      <Dialog fullWidth={true} maxWidth="sm" open={modalGestionValidacion} onClose={() => mostrarGestionValidacion(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo={'¿ Gestionar Validación ?'} mostrarModal={mostrarGestionValidacion} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          {
            !cargando ?
              <Grid container>
                <Grid item xs={12} md={12}>
                  {
                    requisitos && requisitos[0] &&
                    <Stepper activeStep={activeStep}>
                      {
                        configStep.map(label => (
                          <Step key={label}>
                            <StepLabel><p>{label}</p></StepLabel>
                          </Step>
                        ))
                      }
                    </Stepper>
                  }
                </Grid>
                {
                  <Grid item xs={12} style={{ marginTop: "30px" }}>
                    {this.configStepContent()}
                    {
                      mensaje_validaciones && mensaje_validaciones[0] &&
                      <AlertasSimple
                        tipo='info'
                        titulo={'Estimado gestor, tener en cuenta: '}
                        lista={mensaje_validaciones}
                      />
                    }
                    {
                      mensaje_validaciones_rev && mensaje_validaciones_rev[0] &&
                      <AlertasSimple
                        tipo='info'
                        titulo={'Estimado gestor, tener en cuenta: '}
                        lista={mensaje_validaciones_rev}
                      />
                    }
                  </Grid>
                }
              </Grid> :
              <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </DialogContent>
        <DialogActions>
          {
            requisitos && requisitos[0] && !cargando ?
              activeStep > 0 ?
                <div>
                  <BtnForm texto="ATRAS" callback={() => this.handleStep(false)} />
                  <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_multi_archivo').click() }} />
                </div>
                : <BtnForm texto="SIGUIENTE" callback={() => this.handleStep(true)} type="submit" />
              :
              <div>
                {
                  !cargando &&
                  <BtnForm texto="GUARDAR" callback={() => this.submit([])} />
                }
              </div>
          }

        </DialogActions>
      </Dialog>
    )
  }
}

export default ValidacionesGestionar;
