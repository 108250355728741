import React, { Component } from "react";
import PropTypes from "prop-types";
import { BtnPermiso, BtnDetalle, BtnForm, BtnRegresar, BtnDescargar } from "../general/BotonesAccion";
import ListarDatos from "../general/ListarDatos";
import moment from "moment";
import { consulta, rutaImg, crear_form_data, formulario, mostrarError, obtenerValoresFiltros } from "../../global/js/funciones";
import { actualizarMensaje, mostrarModulo } from "../../redux/actions/actGlobal";
import { connect } from "react-redux";
import { Checkbox, Dialog, DialogActions, DialogContent, Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import AppBarModal from "../general/AppBarModal";
import { SeguimientoMenu } from "./sisef/MenuSisef";
import TareasTerminadas from "../general/TareasTerminadas";
import emma_w from "../../global/imagenes/emma_w.png";
import Sisef from "./Sisef";
import { makeStyles } from '@material-ui/core/styles';
import { ExcelComponent, ExportarVersionSyllabus } from "./sisef/ExportarVersionSyllabus";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  const classes = useStyles();
  return (
    <List className={classes.root} subheader={
      <ListSubheader component="div" >
        Seleccione Estado
      </ListSubheader>
    }>
      {estados_siguiente.map(({ secundario }) => {
        let { id, nombre } = secundario;
        return (
          <ListItem key={id} role={undefined} dense button onClick={() => cambiarSeleccion(id)} >
            <ListItemIcon>
              <Checkbox edge="start" checked={estado === id} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={id} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}
class Sisef_Seguimiento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seleccion: "detalle",
      cargando: false,
      relacion: {},
      cambios: false,
      detalle: false,
      gestionar: false,
      data: {},
      periodo_actual: null,
      estado_actual: null,
      observacion: "",
      asignaturas: [],
      docentes: [],
      versiones: [],
      historial: [],
      historial_estados: [],
      estados: [],
      syllabus: new Array(),
    };
  }

  async componentDidMount() {
    this.ProcesosIniciales();
  }

  async obtenerAsignaturas(periodo) {
    return new Promise((resolve) => {
      consulta(`api/v1.0/sisef/docente_asignaturas${periodo}/${0}`, null, null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null)
        }
      );
    });
  }

  async BuscarSyllabus(periodo) {
    return new Promise((resolve) => {
      consulta(`api/v1.0/sisef/syllabus_all/${periodo}`, null, null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async Obtener_VersionSyllabus(asignatura, periodo) {
    return new Promise((resolve) => {
      consulta(`api/v1.0/sisef/ListarSyllabusVersion/${periodo}/${asignatura}`, null, null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async OrdenarSyllabus(periodo, estados) {
    let data = estados.filter((est) => est.codigo == "syll_creado");
    let syll = await this.BuscarSyllabus(periodo);
    let sylla = [];
    console.log(syll)
    if (syll.length != 0) {
      syll.map(async (item) => {
        item.tipo = "SYLLABUS";
        item.estado_actual =
          item.estado_actual == null ? data[0] : item.estado_actual;
        let aux = item;
        sylla.push(aux);
      });
    }
    this.setState({ syllabus: sylla });
  }

  async ProcesosIniciales() {
    let { syllabus } = this.state;
    let data_periodo = await obtenerValoresFiltros([
      { llave: "codigo", valor: "Per_Sisef" },  { llave: 'estado', valor: '1' }
    ]);
    let estados_data = await obtenerValoresFiltros([
      { llave: "valora", valor: "seguimiento" },  { llave: 'estado', valor: '1' }
    ]);
    data_periodo = data_periodo[0].nombre;
    this.OrdenarSyllabus(data_periodo, estados_data);
    this.setState({ periodo_actual: data_periodo });
  }

  async BuscarDocentes(codigo) {
    let { periodo_actual } = this.state;
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/buscar_docentes_xasig/${periodo_actual}/${codigo}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async BuscarHistorial(syllabus, tipo) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/buscar_historial/${syllabus}/${tipo}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }


  async BuscarDatos(codigo, syllabus) {
    let { periodo_actual } = this.state
    let docentes = await this.BuscarDocentes(codigo);
    let versiones = await this.Obtener_VersionSyllabus(codigo, periodo_actual);
    let historial = await this.BuscarHistorial(syllabus, 1); //Cuando pasa el valor 1, le traera los regitros de tipo historial
    let historial_estados = await this.BuscarHistorial(syllabus, 2); //Cuando pasa el valor 2, le traera los regitros de tipo estados
    this.setState({ docentes: docentes, historial: historial, historial_estados, versiones: versiones });
  }

  async Estado_Siguiente(id_solicitud) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/estado_siguiente/${id_solicitud}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });;
  }

  acciones(rowData) {
    const Gestionar = async (row) => {
      let estados_siguientes = await this.Estado_Siguiente(row.estado_actual.id);
      this.setState({
        gestionar: true,
        data: row,
        estados: estados_siguientes,
        estado_actual: row.estado_actual.id,
      });
    };

    const Detalle = (row) => {
      this.BuscarDatos(row.codigo_asignatura, row.id);
      this.setState({ detalle: true, data: row });
    };

    return rowData.estado_actual.codigo != "syll_actualizado" ? (
      <div>
        <BtnDetalle texto="Detalle" callback={() => Detalle(rowData)} />
        <BtnPermiso texto="Gestionar" callback={() => Gestionar(rowData)} />
      </div>
    ) : (
      <div>
        <BtnDetalle texto="Detalle" callback={() => Detalle(rowData)} />
      </div>
    );
  }

  pintarInicio() {
    return <Sisef />;
  }

  ModificarEstado = async () => {
    let { actualizarMensaje } = this.props;
    let { data, estado_actual } = this.state;
    let info = {
      data: data,
      id: data.id,
      estado_actual: estado_actual,
    };
    let form = await crear_form_data(info);
    formulario(
      `api/v1.0/sisef/${data.id}/modificar_estado_syllabus`,
      form,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            this.ProcesosIniciales();
            this.setState({
              gestionar: false,
            });
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  render() {
    let {
      syllabus, versiones, detalle, seleccion, cargando, data, docentes, historial, historial_estados, gestionar, estados, estado_actual } = this.state;
    return seleccion != "inicio" ? (
      <>
        <div className={"regresar"}>
          <BtnRegresar
            callback={() => this.setState({ seleccion: "inicio" })}
          />
        </div>
        <ListarDatos
          id="tbl_lista_seguimiento"
          datos={syllabus}
          opciones={true}
          titulo="Seguimiento"
          buscar={true}
          filtrar={true}
          acciones={(row) => this.acciones(row)}
          fila_principal={({ asignatura_nombre }) =>
            `${asignatura_nombre}`.toUpperCase()
          }
          filas={[
            { mostrar: ({ tipo }) => `${tipo}`, id: "tipo", enLista: true },
            {
              mostrar: ({ fecha_registro }) =>
                moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
              id: "fecha_registro",
              enLista: true,
            },
            {
              mostrar: ({ estado_actual }) => `${estado_actual.nombre}`,
              id: "estado_actual",
              enLista: true,
            },
          ]}
        />
        {detalle &&
          <Detallle
            Modal={detalle}
            data={data}
            docentes={docentes}
            versiones={versiones}
            historial={historial}
            historial_estados={historial_estados}
            seleccion={seleccion}
            cargando={cargando}
            cambiar_seleccion={(resp) => this.setState({ seleccion: resp })}
            cambiar_cargando={(resp) => this.setState({ cargando: resp })}
            cerrarModal={(resp) => this.setState({ detalle: resp })}
          />
        }
        {gestionar &&
          <Dialog
            fullWidth={true}
            maxWidth="xs"
            open={gestionar}
            onClose={() => this.setState({ gestionar: false })}
            aria-labelledby="form-dialog-title"
          >
            <AppBarModal
              titulo={"Cambiar Estado"}
              mostrarModal={() => this.setState({ gestionar: false })}
              titulo_accion="Modificar Actividad"
              accion=""
            />
            <DialogContent className="scroll">
              {/* <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl className="form-control">
                      <InputLabel htmlFor="parametro">Estados</InputLabel>
                      <Select
                        id="estado_actual"
                        value={estado_actual}
                        onChange={(e) =>
                          this.setState({ estado_actual: e.target.value })
                        }
                        name="estado_actual"
                      >
                        {estados?.map(({ nombre, id }) => (
                          <MenuItem key={id} value={id}>
                            {`${nombre}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid> */}
              <Grid container spacing={2}>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <RenderEstados cambiarSeleccion={(estado_actual) => this.setState({ estado_actual })} estado={estado_actual} estados_siguiente={estados} />
                </Grid>

              </Grid>
            </DialogContent>
            <DialogActions>
              <BtnForm
                texto="CANCELAR"
                callback={() => this.setState({ gestionar: false })}
              />
              <BtnForm texto="MODIFICAR" callback={this.ModificarEstado} />
            </DialogActions>
          </Dialog>
        }

      </>
    ) : (
      this.pintarInicio()
    );
  }
}

class Detallle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exportarVersion: false,
      modal: false,
      dataSyllabus: null,
      id_syllabus: null,
      cargando: false,
    };
  }

  pintar = () => {
    let { seleccion, cargando } = this.props;

    if (cargando)
      return (
        <TareasTerminadas
          mensaje="Cargando.."
          marginTop="7%"
          imagen={emma_w}
          widthImg="7%"
        />
      );
    return (
      <div>
        {seleccion === "detalle" && this.pintarDetalle()}
        {seleccion === "docentes" && this.pintarDocentes()}
        {seleccion === "historial" && this.pintarHistorial()}
        {seleccion === "estados" && this.pintarEstados()}
        {seleccion === "versiones" && this.pintarversion()}
      </div>
    );
  };

  pintarDetalle() {
    let { data } = this.props;
    return (
      <Table style={{ width: "1px solid #f5f5f5 " }}>
        <TableBody>
          <TableRow key={1}>
            <TableCell component="th" scope="row">
              Asignatura
            </TableCell>
            <TableCell align="left">{data.asignatura_nombre}</TableCell>
          </TableRow>
          <TableRow key={2}>
            <TableCell component="th" scope="row">
              Valores
            </TableCell>
            <TableCell align="left">{data.valores_plan}</TableCell>
          </TableRow>
          <TableRow key={3}>
            <TableCell component="th" scope="row">
              Plan de estudio
            </TableCell>
            <TableCell align="left">{data.plan_syll.nombre}</TableCell>
          </TableRow>
          <TableRow key={4}>
            <TableCell component="th" scope="row">
              Resumen
            </TableCell>
            <TableCell align="left">{data.resumen_plan}</TableCell>
          </TableRow>
          <TableRow key={5}>
            <TableCell component="th" scope="row">
              Fecha de creación
            </TableCell>
            <TableCell align="left">
              {moment(data.fecha_registro).format("YYYY-MM-DD, h:mm a")}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  pintarDocentes() {
    let { docentes } = this.props;
    return (
      <div>
        <>
          <ListarDatos
            id="tbl_lista_docentes"
            datos={docentes}
            opciones={false}
            titulo="Profesor"
            buscar={true}
            filtrar={false}
            avatarImg={true}
            avatar={({ docente }) => `${rutaImg}${docente.identificacion}.jpg`}
            fila_principal={({ docente }) =>
              `${docente.primer_nombre} ${docente.segundo_nombre} ${docente.primer_apellido} ${docente.segundo_apellido}`.toUpperCase()
            }
            filas={[
              {
                mostrar: ({ docente }) => `${docente.identificacion}`,
                id: "identificacion",
                enLista: true,
              },
            ]}
          />
        </>
      </div>
    );
  }

  ObtenerInfoSyllabus = async (id) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/obtener_info_syllabus/${id}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  exportarVersion = async (id) => {
    this.setState({ cargando: true })
    let [syllabus] = await this.ObtenerInfoSyllabus(id)
    console.log(syllabus)

    const commonStyle = {
      font: { sz: "11", bold: true, name: 'Arial Narrow' },
      alignment: { vertical: "center", horizontal: "center", wrapText: true }
    };

    let datos = [], titulos = [
      { title: "", width: { wch: 1.5 }, style: commonStyle },
      // { title: "NOMBRE DE ASIGNATURA", width: { wch: 23 }, style: {...commonStyle, ...fillStyle[0]} },
      { title: "NOMBRE DE ASIGNATURA", width: { wch: 23 }, style: commonStyle },
      { title: "CODIGO DE ASIGNATURA", width: { wch: 13 }, style: commonStyle },
      { title: "VALORES", width: { wch: 13 }, style: commonStyle },
      { title: "PLAN", width: { wch: 8 }, style: commonStyle },
      { title: "RESUMEN", width: { wch: 13 }, style: commonStyle },
      { title: "PERFIL DEL PROFESOR", width: { wch: 13 }, style: commonStyle },
      { title: "PERIODO", width: { wch: 10 }, style: commonStyle },
      { title: "VERSIÓN", width: { wch: 10 }, style: commonStyle },
      { title: "USUARIO REGISTRA", width: { wch: 20 }, style: commonStyle },
      { title: "FECHA DE CREACIÓN", width: { wch: 20 }, style: commonStyle },
    ]

    // PREGUNTAS LABEL
    syllabus.preguntas.map((pregunta, index) => {
      titulos.push({ title: `PREGUNTA_${index + 1}`, width: { wch: 20 }, style: commonStyle })
    })

    // COMP GENERICA LABEL
    syllabus.genericas.map((comp, index) => {
      titulos.push({ title: `COMPETENCIA_GENERICA_${index + 1}`, width: { wch: 13 }, style: commonStyle })
    })

    // COMP ESPECIFICA LABEL
    syllabus.especificas.map((comp, index) => {
      titulos.push({ title: `COMPETENCIA_ESPECIFICA_${index + 1}`, width: { wch: 13 }, style: commonStyle })
    })

    // UNI FORMACION LABEL
    syllabus.unidades.map((unidad, index) => {
      titulos.push({ title: `UNIDAD_${index + 1}`, width: { wch: 13 }, style: commonStyle })
      titulos.push({ title: 'RESULTADO DE APRENDIZAJE', width: { wch: 13 }, style: commonStyle })
      titulos.push({ title: 'HORAS DE TRABAJO PRESENCIAL', width: { wch: 13 }, style: commonStyle })
      titulos.push({ title: 'HORAS DE TRABAJO INDEPENDIENTE', width: { wch: 13 }, style: commonStyle })
      titulos.push({ title: 'CORTE', width: { wch: 13 }, style: commonStyle })
      let indicadores = 0, contenido = 0
      // INDICADORES LABEL
      unidad.indicadores.map((indicador, index) => {
        titulos.push({
          title: indicador.tipo.codigo == 'ind_dsp' ? `INDICADOR_${indicadores + 1}` : `CONTENIDO_${contenido + 1}`, width: { wch: 13 }, style: commonStyle
        })
      })
      // ESTRATEGIAS LABEL
      unidad.estrategias.map((estrategia, index) => {
        titulos.push({
          title: estrategia.tipo.codigo == 'est_sincr' ? 'ESTRATEGIA TRABAJO PRESENCIAL' : estrategia.tipo.codigo == 'est_asincr' ? 'ESTRATEGIA TRABAJO INDEPENDIENTE' : 'ESTRATEGIA EVALUATIVA', width: { wch: 13 }, style: commonStyle
        })
        titulos.push({ title: 'NIVEL', width: { wch: 13 }, style: commonStyle })
      })
      // RECURSOS LABEL
      unidad.recursos.map((recurso, index) => {
        titulos.push({ title: `RECURSO EDUCATIVO ${index + 1}`, width: { wch: 13 }, style: commonStyle })
        titulos.push({ title: 'TIPO', width: { wch: 13 }, style: commonStyle })
      })
      // REFERENCIAS LABEL
      unidad.referencias.map((referencia, index) => {
        titulos.push({ title: `REFERENCIA ${index + 1}`, width: { wch: 13 }, style: commonStyle })
        titulos.push({ title: 'TIPO', width: { wch: 13 }, style: commonStyle })
      })
    })


    datos.push([
      { value: "" },
      { value: syllabus.asignatura_nombre },
      { value: syllabus.codigo_asignatura },
      { value: syllabus.valores_plan },
      { value: syllabus.plan_syll.nombre },
      { value: syllabus.resumen_plan },
      { value: syllabus.perfil_profesor },
      { value: syllabus.periodo },
      { value: syllabus.version },
      { value: syllabus.usuario_registro.primer_nombre + " " + syllabus.usuario_registro.segundo_nombre + " " + syllabus.usuario_registro.primer_apellido + " " + syllabus.usuario_registro.segundo_apellido },
      { value: moment(syllabus.fecha_registro).format("YYYY-MM-DD, h:mm a") },
    ])

    // DATA PREGUNTAS
    syllabus.preguntas.map((pregunta, index) => {
      datos[0].push({ value: pregunta.pregunta })
    })

    // DATA COMPETENCIAS GENERICAS
    syllabus.genericas.map((comp, index) => {
      datos[0].push({ value: comp.competencia.nombre })
    })

    // DATA COMPETENCIAS ESPECIFICAS
    syllabus.especificas.map((comp, index) => {
      datos[0].push({ value: comp.competencia })
    })

    // DATA UNIDADES DE FORMACION
    syllabus.unidades.map((unidad, index) => {
      datos[0].push({ value: unidad.nombre })
      datos[0].push({ value: unidad.resul_aprendizaje })
      datos[0].push({ value: unidad.hrs_presenciales })
      datos[0].push({ value: unidad.hrs_trabajo_indp })
      datos[0].push({ value: unidad.corte.nombre })
      // INDICADORES DATA
      unidad.indicadores.map((indicador, index) => {
        datos[0].push({ value: indicador.info })
      })
      // ESTRATEGIAS DATA
      unidad.estrategias.map((estrategia, index) => {
        datos[0].push({ value: estrategia.nombre })
        datos[0].push({ value: estrategia.nivel.nombre })
      })
      // RECURSOS DATA
      unidad.recursos.map((recurso, index) => {
        datos[0].push({ value: recurso.recurso })
        datos[0].push({ value: recurso.tipo.nombre })
      })
      // REFERENCIAS DATA
      unidad.referencias.map((referencia, index) => {
        datos[0].push({ value: referencia.descripcion })
        datos[0].push({ value: referencia.tipo.nombre })
      })
    })

    const multiDataSet = [{
      columns: titulos,
      data: datos
    }];

    this.setState({ cargando: false, dataSyllabus: multiDataSet })
  }

  acciones(rowData) {
    const exportar = (row) => {
      console.log(row)
      this.setState({ modal: true, exportarVersion: true, id_syllabus: row.id });
      this.exportarVersion(row.id)
    }
    return (
      <div>
        <BtnDescargar titulo="Exportar versión" callback={() => exportar(rowData)} />
      </div>
    );

  }

  pintarversion() {

    let { versiones } = this.props;
    // console.log(versiones)
    return (
      <div>
        <>
          <ListarDatos
            id="tbl_lista_versiones"
            datos={versiones}
            opciones={false}
            titulo="versiones"
            buscar={true}
            filtrar={false}
            avatarImg={true}
            avatar={({ asignatura_nombre }) => asignatura_nombre.toUpperCase().charAt(0)}
            acciones={(row) => this.acciones(row)}
            color_avatar={() => ({
              backgroundColor: "#6200ea",
              color: "white",
            })}
            fila_principal={({ asignatura_nombre }) =>
              `${asignatura_nombre}`.toUpperCase()
            }
            filas={[
              {
                mostrar: ({ version }) => `Versión ${version}`,
                id: "version",
                enLista: true,
              },
              {
                mostrar: ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'),
                id: "fecha",
                enLista: true,
              },
            ]}
          />
        </>
      </div>
    );
  }

  pintarHistorial() {
    let { historial } = this.props;
    return (
      <div>
        <>
          <ListarDatos
            id="tbl_lista_historial"
            datos={historial}
            opciones={false}
            titulo="Historial"
            buscar={true}
            filtrar={false}
            avatar={({ accion }) => accion.nombre.toUpperCase().charAt(0)}
            color_avatar={() => ({
              backgroundColor: "#6200ea",
              color: "white",
            })}
            fila_principal={({ accion }) =>
              `${accion.nombre}`.toUpperCase()
            }
            filas={[
              {
                mostrar: ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.segundo_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`.toUpperCase(),
                id: "usuario",
                enLista: true,
              },
              {
                mostrar: ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'),
                id: "fecha",
                enLista: true,
              },
            ]}
          />
        </>
      </div>
    );
  }

  pintarEstados() {
    let { historial_estados } = this.props;
    return (
      <div>
        <>
          <ListarDatos
            id="tbl_lista_historial"
            datos={historial_estados}
            opciones={false}
            titulo="Historial"
            buscar={true}
            filtrar={false}
            avatar={({ accion }) => accion.nombre.toUpperCase().charAt(0)}
            color_avatar={() => ({
              backgroundColor: "#6200ea",
              color: "white",
            })}
            fila_principal={({ accion }) =>
              `${accion.nombre}`.toUpperCase()
            }
            filas={[
              {
                mostrar: ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.segundo_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`.toUpperCase(),
                id: "usuario",
                enLista: true,
              },
              {
                mostrar: ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'),
                id: "fecha",
                enLista: true,
              },
            ]}
          />
        </>
      </div>
    );
  }

  render() {
    let { Modal, cerrarModal, cambiar_seleccion, seleccion } = this.props;
    let { dataSyllabus, modal, id_syllabus, cargando } = this.state;
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={Modal}
          onClose={() => cerrarModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <AppBarModal
            titulo="¡ Detalle Seguimiento !"
            mostrarModal={() => cerrarModal(false)}
            titulo_accion="Detalle calificación"
            accion=""
          />
          <SeguimientoMenu sele={seleccion} seleccionar={cambiar_seleccion} />
          <DialogContent className="scroll">{this.pintar()}</DialogContent>
          <DialogActions>
            <BtnForm texto="CERRAR" callback={() => cerrarModal(false)} />
          </DialogActions>
        </Dialog>


        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={modal}
          onClose={() => this.setState({ modal: false })}
          aria-labelledby="form-dialog-title"
        >
          <AppBarModal
            titulo={`¡ Descargar Syllabus ! Versión `}
            mostrarModal={() => this.setState({ modal: false })}
            titulo_accion=""
            accion=""
          />
          <DialogContent className="scroll">
            {cargando && <TareasTerminadas mensaje="Cargando.." marginTop="7%" imagen={emma_w} widthImg="7%" />}
            {
              !cargando && dataSyllabus && dataSyllabus.length > 0 &&
              <ExcelComponent dataSet={dataSyllabus} />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CERRAR" callback={() => this.setState({ modal: false })} />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let { usuario } = state.redGlobal;
  let { ModalAdd } = state.redSisef;
  return { usuario, ModalAdd };
};

const mapDispatchToProps = {
  mostrarModulo,
  actualizarMensaje,
};

Sisef_Seguimiento.propTypes = {
  //Funciones
  ModalAdd: PropTypes.bool.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sisef_Seguimiento);