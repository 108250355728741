import React from 'react';
import { BtnDetalle } from '../general/BotonesAccion';
import { Link, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import ListarDatos from '../general/ListarDatos';
import {
  CODIGOS_MODALIDADES_VIRTUALES, CODIGOS_DURACION_ACTIVIDADES, CODIGO_PLATAFORMA_OTRO,
  TIPOS_ACTIVIDADES_HORAS, CODIGO_SUBTIPO_ACTIVIDAD_OTRO, CODIGOS_SUBTIPOS_ACTIVIDADES,
  CODIGO_TIPO_ACTIVIDAD_OTRO
} from './administrar/helper';
// Sin uso
import { api, valor_peso } from '../../global/js/funciones';
import moment from 'moment';

export function SolicitudDetalle({ solicitud, detalle }) {
  let { solicitante: { primer_nombre, primer_apellido, segundo_apellido }, tipo_solicitud, periodo, estado_actual } = solicitud;
  return (
    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Solicitante</TableCell>
          <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">Solicitud</TableCell>
          <TableCell align="left">{tipo_solicitud.nombre}</TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell component="th" scope="row">Período</TableCell>
          <TableCell align="left">{periodo}</TableCell>
        </TableRow>
        <TableRow key={4}>
          <TableCell component="th" scope="row">Estado Actual</TableCell>
          <TableCell align="left">{estado_actual.nombre}</TableCell>
        </TableRow>
        {
          tipo_solicitud.codigo === 'Mov_Est_Int' &&
          <>
            <TableRow key={1}>
              <TableCell component="th" scope="row">Convocatoria</TableCell>
              <TableCell align="left">{detalle.convocatoria.nombre_convocatoria}</TableCell>
            </TableRow>
          </>
        }
      </TableBody>
    </Table>
  )
}

export function MovEstudiantilDetalle({ solicitud }) {
  let {
    convocatoria,
    programa_academico,
    semestre_actual,
    promedio_acumulado,
    universidad_1,
    universidad_2,
    universidad_3,
    universidad_4,
    acudiente,
    parentesco,
    correo_acudiente,
    celular_acudiente,
    direccion_residencia_acudiente,
    ubicacion_residencia_acudiente,
    lugar_residencia_acudiente,
    direccion,
    usuario_registro: { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, correo,
      correo_personal, identificacion, indicativo_celular, celular, telefono, fecha_nacimiento }
  } = solicitud;
  return (
    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Nombre completo</TableCell>
          <TableCell align="left">{`${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
        </TableRow>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Número de identificación</TableCell>
          <TableCell align="left">{identificacion}</TableCell>
        </TableRow>
        {/* { correo == 'moviliadad'+identificacion+'@cuc.edu.co' ?
          <TableRow key={ 1 }>
            <TableCell component="th" scope="row">Correo institucional</TableCell>
            <TableCell align="left">No ingreso correo</TableCell>
          </TableRow>:
          <TableRow key={ 1 }>
            <TableCell component="th" scope="row">Correo institucional</TableCell>
            <TableCell align="left">{ correo }</TableCell>
          </TableRow>
        } */}
        <TableRow key={1}>
          <TableCell component="th" scope="row">Correo institucional</TableCell>
          <TableCell align="left">{correo}</TableCell>
        </TableRow>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Correo institucional</TableCell>
          <TableCell align="left">{correo}</TableCell>
        </TableRow>
        {correo_personal === null ? null : /* only shows if correo personal is different from null */
          <TableRow key={1}>
            <TableCell component="th" scope="row">Correo Personal</TableCell>
            <TableCell align="left">{correo_personal ? correo_personal : 'Sin diligenciar'}</TableCell>
          </TableRow>
        }
        {celular && indicativo_celular &&
          <TableRow key={1}>
            <TableCell component="th" scope="row">Indicativo celular</TableCell>
            <TableCell align="left">+{indicativo_celular}</TableCell>
          </TableRow>
        }
        <TableRow key={1}>
          <TableCell component="th" scope="row">Número de celular</TableCell>
          <TableCell align="left">{celular ? celular : 'Sin diligenciar'}</TableCell>
        </TableRow>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Número de teléfono</TableCell>
          <TableCell align="left">{telefono ? telefono : 'Sin diligenciar'}</TableCell>
        </TableRow>
        {fecha_nacimiento &&
          <TableRow key={1}>
            <TableCell component="th" scope="row">Fecha de nacimiento</TableCell>
            <TableCell align="left">{fecha_nacimiento}</TableCell>
          </TableRow>
        }
        <TableRow key={4}>
          <TableCell component="th" scope="row">Convocatoria</TableCell>
          <TableCell align="left">{convocatoria.nombre_convocatoria ? convocatoria.nombre_convocatoria : 'Sin diligenciar'}</TableCell>
        </TableRow>
        <TableRow key={5}>
          <TableCell component="th" scope="row">Programa académico</TableCell>
          <TableCell align="left">{programa_academico ? programa_academico.nombre : 'Sin diligenciar'}</TableCell>
        </TableRow>
        <TableRow key={6}>
          <TableCell component="th" scope="row">Semestre actual</TableCell>
          <TableCell align="left">{semestre_actual ? semestre_actual : 'Sin diligenciar'}</TableCell>
        </TableRow>
        <TableRow key={7}>
          <TableCell component="th" scope="row">Promedio acumulado</TableCell>
          <TableCell align="left">{promedio_acumulado ? promedio_acumulado : 'Sin diligenciar'}</TableCell>
        </TableRow>
        <TableRow key={8}>
          <TableCell component="th" scope="row">Postulación a</TableCell>
          <TableCell align="left">{universidad_1 ? universidad_1.nombre : 'Sin diligenciar'}</TableCell>
        </TableRow>
        {
          universidad_2 &&
          <TableRow key={9}>
            <TableCell component="th" scope="row">Postulación #2 a</TableCell>
            <TableCell align="left">{universidad_2.nombre}</TableCell>
          </TableRow>
        }
        {
          universidad_3 &&
          <TableRow key={10}>
            <TableCell component="th" scope="row">Postulación #3 a</TableCell>
            <TableCell align="left">{universidad_3.nombre}</TableCell>
          </TableRow>
        }
        {
          universidad_4 &&
          <TableRow key={11}>
            <TableCell component="th" scope="row">Postulación #4 a</TableCell>
            <TableCell align="left">{universidad_4.nombre}</TableCell>
          </TableRow>
        }
        <TableRow key={12}>
          <TableCell component="th" scope="row">Nombre completo del acudiente</TableCell>
          <TableCell align="left">{acudiente ? acudiente : 'Sin diligenciar'}</TableCell>
        </TableRow>
        <TableRow key={13}>
          <TableCell component="th" scope="row">Parentesco</TableCell>
          <TableCell align="left">{parentesco ? parentesco : 'Sin diligenciar'}</TableCell>
        </TableRow>
        <TableRow key={14}>
          <TableCell component="th" scope="row">Correo del acudiente</TableCell>
          <TableCell align="left">{correo_acudiente ? correo_acudiente : 'Sin diligenciar'}</TableCell>
        </TableRow>
        <TableRow key={15}>
          <TableCell component="th" scope="row">Celular del acudiente</TableCell>
          <TableCell align="left">{celular_acudiente ? celular_acudiente : 'Sin diligenciar'}</TableCell>
        </TableRow>
        <TableRow key={16}>
          <TableCell component="th" scope="row">Dirección de residencia del acudiente</TableCell>
          <TableCell align="left">{direccion_residencia_acudiente ? direccion_residencia_acudiente : 'Sin diligenciar'}</TableCell>
        </TableRow>
        <TableRow key={17}>
          <TableCell component="th" scope="row">Ciudad de residencia del acudiente</TableCell>
          <TableCell align="left">{ubicacion_residencia_acudiente ? ubicacion_residencia_acudiente : 'Sin diligenciar'}</TableCell>
        </TableRow>
        <TableRow key={17}>
          <TableCell component="th" scope="row">Departamento de residencia del acudiente</TableCell>
          <TableCell align="left">{lugar_residencia_acudiente ? lugar_residencia_acudiente : 'Sin diligenciar'}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export function RegistroMovilidadDetalle({ solicitud, actividad_movilidad, cargando }) {
  // export function RegistroMovilidadDetalle({ solicitud, mostrarModalListaInstituciones, mostrarModalFacultadesDepartamentos, mostrarModalProgramas, mostrarModalUnidadesAdm, mostrarModalFinanciaciones }) {
  let { tipo_actividad, subtipo_actividad, otro_tipo_actividad, otro_subtipo_actividad, fecha_inicio, fecha_final, titulo_evento, resp_actividad, unidades_adm_resp, departamento_resp, facultad_resp, descripcion_actividad, descripcion_resultados, institucion_actividad, info_complementaria } = actividad_movilidad
  let { solicitante: { primer_nombre, primer_apellido, segundo_apellido }, tipo_solicitud, periodo, estado_actual, departamento_docente, programa_docente } = solicitud;
  return (
    cargando == false && (
      <Table>
        <TableBody>
          {
            departamento_docente && (
              <TableRow key={1}>
                <TableCell component="th" scope="row">Departamento del solicitante</TableCell>
                <TableCell align="left">{departamento_docente.nombre}</TableCell>
              </TableRow>
            )
          }
          {
            programa_docente && (
              <TableRow key={2}>
                <TableCell component="th" scope="row">Programa al que tributará la movilidad</TableCell>
                <TableCell align="left">{programa_docente.nombre}</TableCell>
              </TableRow>
            )
          }
          <TableRow key={3}>
            <TableCell component="th" scope="row">Tipo de Actividad</TableCell>
            <TableCell align="left">{tipo_actividad.nombre}</TableCell>
          </TableRow>
          {otro_tipo_actividad && (
            <TableRow key={4}>
              <TableCell component="th" scope="row">Otro tipo de actividad</TableCell>
              <TableCell align="left">{otro_tipo_actividad}</TableCell>
            </TableRow>
          )}
          {subtipo_actividad && (
            <TableRow key={5}>
              <TableCell component="th" scope="row">Subtipo de Actividad</TableCell>
              <TableCell align="left">{subtipo_actividad.nombre}</TableCell>
            </TableRow>
          )}
          {
            otro_subtipo_actividad && (
              <TableRow key={6}>
                <TableCell component="th" scope="row">Otro subtipo de actividad</TableCell>
                <TableCell align="left">{otro_subtipo_actividad}</TableCell>
              </TableRow>
            )
          }
          {
            titulo_evento && (
              <TableRow key={7}>
                <TableCell component="th" scope="row">Título del evento</TableCell>
                <TableCell align="left">{titulo_evento}</TableCell>
              </TableRow>
            )
          }
          {
            descripcion_actividad && (
              <TableRow key={8}>
                <TableCell component="th" scope="row">Descripción de la actividad</TableCell>
                <TableCell align="left">{descripcion_actividad}</TableCell>
              </TableRow>
            )
          }
          {
            descripcion_resultados && (
              <TableRow key={9}>
                <TableCell component="th" scope="row">Descripción de los resultados</TableCell>
                <TableCell align="left">{descripcion_resultados}</TableCell>
              </TableRow>
            )
          }
          {
            institucion_actividad && institucion_actividad.length > 0 && (
              <TableRow key={10}>
                <TableCell component="th" scope="row">Instituciones</TableCell>
                <TableCell align="left">
                  {institucion_actividad.map((item, i) => (
                    <TableRow scope="row">
                      · {item?.institucion}
                      {item?.pais_vinculante?.nombre && ` - ${item.pais_vinculante.nombre}`}
                      {item?.ciudad_vinculante && ` - ${item.ciudad_vinculante}`}
                    </TableRow>
                  ))}
                </TableCell>
              </TableRow>
            )
          }
          {
            fecha_inicio && (
              <TableRow key={11}>
                <TableCell component="th" scope="row">Fecha de inicio</TableCell>
                <TableCell align="left">{fecha_inicio}</TableCell>
              </TableRow>
            )
          }
          {
            fecha_final && (
              <TableRow key={12}>
                <TableCell component="th" scope="row">Fecha de finalización</TableCell>
                <TableCell align="left">{fecha_final}</TableCell>
              </TableRow>
            )
          }
          {
            resp_actividad && resp_actividad.length > 0 && (
              <TableRow key={13}>
                <TableCell component="th" scope="row">Responsable de la actividad</TableCell>
                <TableCell align="left">
                  {resp_actividad.map((item, i) => (
                    <TableRow scope="row">{item.nombre}</TableRow>
                  ))}
                </TableCell>
              </TableRow>
            )
          }
          {
            unidades_adm_resp && unidades_adm_resp.length > 0 && (
              <TableRow key={14}>
                <TableCell component="th" scope="row">Unidades administrativas responsables</TableCell>
                <TableCell align="left">
                  {unidades_adm_resp.map((item, i) => (
                    <TableRow scope="row">{item.nombre}</TableRow>
                  ))}
                </TableCell>
              </TableRow>
            )
          }
          {
            departamento_resp && departamento_resp.length > 0 && (
              <TableRow key={15}>
                <TableCell component="th" scope="row">Departamento responsable</TableCell>
                <TableCell align="left">
                  {departamento_resp.map((item, i) => (
                    <TableRow scope="row">{item.nombre}</TableRow>
                  ))}
                </TableCell>
              </TableRow>
            )
          }
          {
            facultad_resp && facultad_resp.length > 0 && (
              <TableRow key={16}>
                <TableCell component="th" scope="row">Facultad responsable</TableCell>
                <TableCell align="left">
                  {facultad_resp.map((item, i) => (
                    <TableRow scope="row">{item.nombre}</TableRow>
                  ))}
                </TableCell>
              </TableRow>
            )
          }
          {
            info_complementaria && (
              <TableRow key={17}>
                <TableCell component="th" scope="row">Información complementaria</TableCell>
                <TableCell align="left">
                  <Link href={`${api}${info_complementaria}`} target='_blank'>
                    Ver
                  </Link>
                </TableCell>
              </TableRow>
            )
          }
          <TableRow key={18}>
            <TableCell component="th" scope="row">Solicitante</TableCell>
            <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
          </TableRow>
          <TableRow key={19}>
            <TableCell component="th" scope="row">Tipo de solicitud</TableCell>
            <TableCell align="left">{tipo_solicitud.nombre}</TableCell>
          </TableRow>
          <TableRow key={20}>
            <TableCell component="th" scope="row">Período</TableCell>
            <TableCell align="left">{periodo}</TableCell>
          </TableRow>
          <TableRow key={21}>
            <TableCell component="th" scope="row">Estado Actual</TableCell>
            <TableCell align="left">{estado_actual.nombre}</TableCell>
          </TableRow>
          <TableRow key={22}>
            <TableCell component="th" scope="row">Fecha de Registro</TableCell>
            <TableCell align="left">{moment(solicitud.fecha_registro).format('DD/MM/YYYY')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  )
}

export function DetalleInstituciones({ datos, tipo_paso_infoconveni, pasocumplimientorequisito }) {
  let {
    usuario_registro,
    programa_facultad,
    institucion,
    rector,
    representacion_legal,
    pais_vinculante,
    ciudad_vinculante,
    direccion_vinculante,
    direccion_Web,
    encargado_interna,
    correo_interna,
    cargo_interna,
    telefono_interna,
    objeto_del_convenio,
    vigencia,
    otro_tipo_convenio,
    otro_subtipo_convenio,
    otro_idioma_minuta,
    otra_fuente_financiacion,
    programa_facultad_3,
    solicitud_excepcion,
    cargo_enlace_Uni,
    email_enlace_Uni,
    nombre_enlace_Uni,
    telefono_enlace_Uni,
    unidad_enlace_Uni,
    nombre_enlace_vin,
    telefono_enlace_vin,
    unidad_enlace_vin,
    cargo_enlace_vin,
    email_enlace_vin,
    codigo_convenio,
    fecha_inicio,
    fecha_finalizacion,
    renovacion,
    estado_convenio,
    act_objeto,
    solicitud_renovacion,
    subtipo_solicitud
  } = datos;
  let {
    Subtipo_convenio,
    TipoConvenio,
    fuenteFinanciacion_convenio,
    Idioma_minuta,
  } = tipo_paso_infoconveni;
  let arraysubtipo = [];
  let subtiponombre = [];
  let tipoconvenionombre = [];
  let fuentefinanciacionconvenionombre = [];
  let helpidioma_minuta = [];
  let { Ranking, Acreditacion_prog, Acreditacion_inst } =
    pasocumplimientorequisito;
  let helpacreditainst = [];
  let helpacreditapro = [];
  let helpranking = [];
  let helptipo_actividad = [];
  if (Subtipo_convenio) {
    arraysubtipo = Subtipo_convenio.filter((item, index) => {
      return Subtipo_convenio.indexOf(item) === index;
    });
    arraysubtipo.map(({ sub_tipo_convenio }, i) => {
      subtiponombre.push(sub_tipo_convenio.nombre);
    });
  }
  if (TipoConvenio) {
    TipoConvenio.map(({ tipo_convenio_convenio }, i) => {
      tipoconvenionombre.push(tipo_convenio_convenio.nombre);
    });
  }
  if (fuenteFinanciacion_convenio) {
    fuenteFinanciacion_convenio.map(({ fuentefinanciacion_convenio }, i) => {
      fuentefinanciacionconvenionombre.push(fuentefinanciacion_convenio.nombre);
    });
  }
  if (Idioma_minuta) {
    Idioma_minuta.map(({ idioma_minuta_convenio }, i) => {
      helpidioma_minuta.push(idioma_minuta_convenio.nombre);
    });
  }
  if (Acreditacion_prog) {
    Acreditacion_prog.map(({ acreditacion_prog }, i) => {
      helpacreditapro.push(acreditacion_prog.nombre);
    });
  }
  if (Acreditacion_inst) {
    Acreditacion_inst.map(({ acreditacion_ins }, i) => {
      helpacreditainst.push(acreditacion_ins.nombre);
    });
  }
  if (Ranking) {
    Ranking.map(({ posicion, ranking, posicion_dos }, i) => {
      if (posicion_dos !== null) {
        helpranking.push({ posicion: posicion, ranking: ranking.nombre, posicion_dos: posicion_dos });
      } else {
        helpranking.push({ posicion: posicion, ranking: ranking.nombre });
      }
    });
  }
  let key = 0;
  return (
    <Table>
      <TableBody>
        {
          subtipo_solicitud && (
            <TableRow key={key++}>
              <TableCell component="th" scope="row">
                Subtipo de solicitud
              </TableCell>
              <TableCell align="left">{subtipo_solicitud.nombre}</TableCell>
            </TableRow>
          )
        }
        {
          usuario_registro && (
            <TableRow key={key++}>
              <TableCell component="th" scope="row">
                Nombre solicitante
              </TableCell>
              <TableCell align="left">
                {usuario_registro.primer_nombre +
                  " " +
                  usuario_registro.primer_apellido +
                  " " +
                  usuario_registro.segundo_apellido}
              </TableCell>
            </TableRow>
          )
        }
        {usuario_registro && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Telefono del solicitante
            </TableCell>
            <TableCell align="left">{usuario_registro.telefono}</TableCell>
          </TableRow>
        )}
        {
          usuario_registro && (
            <TableRow key={key++}>
              <TableCell component="th" scope="row">
                Correo del solicitante
              </TableCell>
              <TableCell align="left">{usuario_registro.correo}</TableCell>
            </TableRow>
          )
        }
        {institucion && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Nombre de la institución vinculante
            </TableCell>
            <TableCell align="left">{institucion}</TableCell>
          </TableRow>
        )}
        {pais_vinculante && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Pais
            </TableCell>
            <TableCell align="left">{pais_vinculante.nombre}</TableCell>
          </TableRow>
        )}
        {subtiponombre.length > 0 && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Funciones misionales que impactan
            </TableCell>
            <TableCell align="left">
              {subtiponombre.map((index, i) => (
                <TableRow scope="row">{index}</TableRow>
              ))}
            </TableCell>
          </TableRow>
        )}
        {otro_subtipo_convenio && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Otra funciones misionales que impactan
            </TableCell>
            <TableCell align="left">{otro_subtipo_convenio}</TableCell>
          </TableRow>
        )}
        {helpacreditainst.length > 0 && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Acreditación institucional
            </TableCell>
            <TableCell align="left">
              {helpacreditainst.map((index, i) => (
                <TableRow scope="row">{index}</TableRow>
              ))}
            </TableCell>
          </TableRow>
        )}
        {helpranking.length > 0 && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Ranking
            </TableCell>
            <TableCell align="left">
              {helpranking.map((item, i) => (
                item.posicion_dos ? (
                  <TableRow scope="row">
                    {item.ranking + " : " + item.posicion + " - " + item.posicion_dos}
                  </TableRow>
                ) : (<TableRow scope="row">
                  {item.ranking + " : " + item.posicion}
                </TableRow>)

              ))}
            </TableCell>
          </TableRow>
        )}
        {helpacreditapro.length > 0 && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Acreditación programa
            </TableCell>
            <TableCell align="left">
              {helpacreditapro.map((index, i) => (
                <TableRow scope="row">{index}</TableRow>
              ))}
            </TableCell>
          </TableRow>
        )}
        {programa_facultad_3 && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Programa o facultad
            </TableCell>
            <TableCell align="left">{programa_facultad_3}</TableCell>
          </TableRow>
        )}
        {codigo_convenio && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Codigo del convenio
            </TableCell>
            <TableCell align="left">{codigo_convenio}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export function DetalleRed({ datos, tipo_paso_infoconveni, pasocumplimientorequisito }) {
  let {
    usuario_registro,
    programa_facultad,
    institucion,
    rector,
    representacion_legal,
    paises_red_academica,
    ciudad_vinculante,
    direccion_vinculante,
    direccion_Web,
    encargado_interna,
    correo_interna,
    cargo_interna,
    telefono_interna,
    objeto_del_convenio,
    vigencia,
    otro_tipo_convenio,
    otro_subtipo_convenio,
    otro_idioma_minuta,
    otra_fuente_financiacion,
    programa_facultad_3,
    solicitud_excepcion,
    cargo_enlace_Uni,
    email_enlace_Uni,
    nombre_enlace_Uni,
    telefono_enlace_Uni,
    unidad_enlace_Uni,
    nombre_enlace_vin,
    telefono_enlace_vin,
    unidad_enlace_vin,
    cargo_enlace_vin,
    email_enlace_vin,
    codigo_convenio,
    fecha_inicio,
    fecha_finalizacion,
    renovacion,
    estado_convenio,
    act_objeto,
    solicitud_renovacion,
    subtipo_solicitud,
    programas_red_academica,
  } = datos;
  let {
    Subtipo_convenio,
    TipoConvenio,
    fuenteFinanciacion_convenio,
    Idioma_minuta,
  } = tipo_paso_infoconveni;
  let arraysubtipo = [];
  let subtiponombre = [];
  let tipoconvenionombre = [];
  let fuentefinanciacionconvenionombre = [];
  let helpidioma_minuta = [];
  let { Ranking, Acreditacion_prog, Acreditacion_inst } =
    pasocumplimientorequisito;
  let helpacreditainst = [];
  let helpacreditapro = [];
  let helpranking = [];
  let helptipo_actividad = [];
  if (Subtipo_convenio) {
    arraysubtipo = Subtipo_convenio.filter((item, index) => {
      return Subtipo_convenio.indexOf(item) === index;
    });
    arraysubtipo.map(({ sub_tipo_convenio }, i) => {
      subtiponombre.push(sub_tipo_convenio.nombre);
    });
  }
  let key = 0;
  return (
    <Table>
      <TableBody>
        {subtipo_solicitud && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Subtipo de solicitud
            </TableCell>
            <TableCell align="left">{subtipo_solicitud.nombre}</TableCell>
          </TableRow>
        )}
        {institucion && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Nombre de la red académica
            </TableCell>
            <TableCell align="left">{institucion}</TableCell>
          </TableRow>
        )}
        {subtiponombre.length > 0 && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Funciones misionales que impactan
            </TableCell>
            <TableCell align="left">
              {subtiponombre.map((index, i) => (
                <TableRow scope="row">{index}</TableRow>
              ))}
            </TableCell>
          </TableRow>
        )}
        {otro_subtipo_convenio && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Otra funciones misionales que impactan
            </TableCell>
            <TableCell align="left">{otro_subtipo_convenio}</TableCell>
          </TableRow>
        )}
        {paises_red_academica.length > 0 && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Paises
            </TableCell>
            <TableCell align="left">
              {paises_red_academica.map((pais, i) => (
                <TableRow scope="row">{pais?.nombre}</TableRow>
              ))}
            </TableCell>
          </TableRow>
        )}
        {objeto_del_convenio && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Objeto de la red académica
            </TableCell>
            <TableCell align="left">{objeto_del_convenio}</TableCell>
          </TableRow>
        )}
        {programas_red_academica.length > 0 && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Programas
            </TableCell>
            <TableCell align="left">
              {programas_red_academica.map((programa, i) => (
                <TableRow scope="row">{programa?.nombre}</TableRow>
              ))}
            </TableCell>
          </TableRow>
        )}
        {nombre_enlace_vin && (
          <TableRow key={27}>
            <TableCell component="th" scope="row">
              Información de contacto de la red académica
            </TableCell>
            <TableCell align="left">
              <TableRow scope="row">{"Nombre: " + nombre_enlace_vin}</TableRow>
              <TableRow scope="row">{"Telefono: " + telefono_enlace_vin}</TableRow>
              <TableRow scope="row">{"Email: " + email_enlace_vin}</TableRow>
            </TableCell>
          </TableRow>
        )}
        {usuario_registro && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Nombre solicitante
            </TableCell>
            <TableCell align="left">
              {usuario_registro.primer_nombre +
                " " +
                usuario_registro.primer_apellido +
                " " +
                usuario_registro.segundo_apellido}
            </TableCell>
          </TableRow>
        )}
        {usuario_registro && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Telefono del solicitante
            </TableCell>
            <TableCell align="left">{usuario_registro.telefono}</TableCell>
          </TableRow>
        )}
        {usuario_registro && (
          <TableRow key={key++}>
            <TableCell component="th" scope="row">
              Correo del solicitante
            </TableCell>
            <TableCell align="left">{usuario_registro.correo}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
