import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { consulta, crear_form_data, formulario, generarFiltros, mostrarError, obtenerValoresFiltros } from '../../../global/js/funciones';
import { actualizarMensaje, mostrarModulo } from '../../../redux/actions/actGlobal';
import {
  actualizarDataConvocatoria, actualizarDataInstitucion, actualizarDataRequisito,
  mostrarModalAddConvenio,
  mostrarModalAddFiltros,
  mostrarModalAddPermisosDep,
  mostrarModalAprobarAdjunto,
  mostrarModalDetSolicitud,
  mostrarModalEdiConvenio,
  mostrarModalGestionarSolicitud,
  mostrarModalListaUniversidades,
  mostrarModalModAdjuntos,
  mostrarModalModInstituciones,
  mostrarModalNegarAdjunto,
  mostrarModalEdiIes
} from '../../../redux/actions/actInternacionalizacion';
import AccionesRapidas from '../../general/AccionesRapidas';
import { BtnBuscar2, BtnDescargar, BtnDetalle, BtnEditar, BtnFolder, BtnForm, BtnGestionar, BtnRegresar } from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
import TareasTerminadas from '../../general/TareasTerminadas';
import TituloAccion from '../../general/TituloAccion';
// Material ui
import { Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Tooltip } from '@material-ui/core';
import { blue, red, yellow } from '@material-ui/core/colors';
// Componentes del módulo
import InternacionalizacionDetalle from '../InternacionalizacionDetalle';
import ConveniosCrear from '../convenios/ConveniosCrear';
import Convocatorias from '../convocatorias/Convocatorias';
import InternacionalizacionPermisos from './InternacionalizacionPermisos';
import { MenuGestionInter } from './MenuInternacionalizacion';
import SolicitudFiltros from './SolicitudFiltros';
import ConvenioGestionar from './gestionar/ConvenioGestionar';
import SolicitudesGestionar from './gestionar/SolicitudesGestionar';
import InternacionalizacionInstituciones from './instituciones/InternacionalizacionInstituciones';
import InternacionalizacionRequisitos from './requisitos/InternacionalizacionRequisitos';
// Imágenes e íconos
import FilterListIcon from '@material-ui/icons/FilterList';
import ReactExport from 'react-data-export';
import fechaIcon from '../../../global/imagenes/calendar.png';
import download from '../../../global/imagenes/download.png';
import emma_s1 from '../../../global/imagenes/emma_s1.png';
import emma_w from '../../../global/imagenes/emma_w.png';
import permisosIcon from '../../../global/imagenes/lightbulb.png';
import permisosDependenciasIcon from '../../../global/imagenes/permission.png';
import requisitosIcon from '../../../global/imagenes/test.png';
import institucionesIcon from '../../../global/imagenes/work.png';
import AppBarModal from '../../general/AppBarModal';
import InternacionalizacionPermisosDepen from './InternacionalizacionPermisosDepen';
import { ID_JF, TIPOS_SOLICITUDES } from './helper';
import Instituciones from './instituciones/InstitucionesCrear';
import reportIcon from '../../../global/imagenes/report.png';
import InformesRegistro from '../registro/InformesRegistro';
import CardSolicitud from '../ui/CardSol';
import BannerInter from '../ui/BannerInter';
import AgregarValor from '../ui/AgregarValor';
import RedCrear from '../ies/RedCrear';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export class AdministrarInternacionalizacion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      periodo: '',
      seleccion: 'procesos',
      seleccionAdm: 'inicio',
      seleccionProc: 'inicio',
      tipoSolicitud: '',
      vistaDetalle: 'detalle',
      convocatoriaActual: 0,
      administrador: false,
      cargando: true,
      cargarFiltroP: true,
      cargandoPeriodo: true,
      filtrarPeriodo: false,
      periodo_filtro: null,
      solicitud: [],
      filtros_solicitudes: [],
      estados_procesos: [],
      solicitudes_detallada: [],
      tipos_solicitudes: [],
      periodos: [],
      solicitudes: [],
      solicitudesRegistro: [],
      estados_total: [],
      estado_conveniolist: [],
      opcion: 'ok',
      convenio: [],
      instituciones_list: [],
      id_institucionActual: '',
      filtros: false,
      filtrosLideres: false,
      lider: false,
      nombreUniversidadBuscar: '',
      buscarUniversidad: false,
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/internacionalizacion_adm', nombre: 'Gestión Internacionalización' });
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Int' }]);
    let tipos_solicitudes = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_SOLICITUDES }, { 'llave': 'valorb', 'valor': 1 }, { 'llave': 'estado', 'valor': 1 }]);
    this.setState({ 'periodo': periodo[0].nombre, 'periodo_filtro': periodo[0].nombre, tipos_solicitudes });
    // this.obtenerEstadoSolicitudes(periodo[0].nombre); // ! Cambio
    this.esAdministrador();
    this.setState({ cargando: false })
  }

  async componentDidUpdate({ modalGestionarSolicitud }, { seleccionProc, seleccionAdm }) {
    let { seleccionProc: seleccionProcNew, periodo_filtro, tipoSolicitud, seleccionAdm: seleccionAdmNew, filtros } = this.state;
    let { modalGestionarSolicitud: modalGestionarSolicitudNew } = this.props
    if (seleccionProc !== seleccionProcNew) {
      if (seleccionProcNew !== 'inicio') {
        if (seleccionProcNew === 'Reg_Mov_Int' && !filtros) {
          this.setState({ solicitudes: [], cargando: true });
          // await this.obtenerSolicitudesRegistro2(); 
          // await this.actualizarDataSolicitudes([{ 'llave': 'tipo_solicitud__codigo', 'valor': seleccionProcNew }, { 'llave': 'periodo', 'valor': periodo_filtro }]);
          let solicitudes_detallada = await this.obtenerSolicitudesDetalle([{ 'llave': 'tipo_solicitud__codigo', 'valor': tipoSolicitud }, { 'llave': 'periodo', 'valor': periodo_filtro }], tipoSolicitud);
          // this.actualizarDataSolicitudes([{ 'llave': 'tipo_solicitud__codigo', 'valor': seleccionProcNew }, { 'llave': 'periodo', 'valor': periodo_filtro }]);
          this.setState({ solicitudes_detallada, cargando: false });
        }
        if (seleccionProcNew === 'Con_Int' || seleccionProcNew === 'Ies_Int') {
          this.setState({ cargando: true })
          let estados_total = await this.obtenerEstados(tipoSolicitud)
          this.setState({ estados_total, cargando: false })
        }
      }
      if (seleccionProcNew !== 'inicio' && seleccionProcNew !== 'Con_Int' && seleccionProcNew !== 'Reg_Mov_Int' && seleccionProcNew !== 'reporte' && seleccionProcNew !== 'Ies_Int') {
        this.setState({ cargando: true });
        let estado_conveniolist = await this.obtenerSolicitudesDetalle([{ 'llave': 'solicitud__tipo_solicitud__codigo', 'valor': tipoSolicitud }, { 'llave': 'solicitud__estado_actual__codigo', 'valor': seleccionProcNew }], this.state.tipoSolicitud);
        this.setState({ estado_conveniolist, cargando: false })
      }
    }

    if (modalGestionarSolicitud !== modalGestionarSolicitudNew) {
      if (modalGestionarSolicitud) {
        this.setState({ cargando: true });
        // this.obtenerSolicitudesRegistro2([{ 'llave': 'tipo_solicitud__codigo', 'valor': seleccionProcNew }, { 'llave': 'periodo', 'valor': periodo_filtro }]); //ok
        // await this.obtenerSolicitudesRegistro2(); //ok
        if (seleccionProc === "Reg_Mov_Int") {
          // await this.obtenerSolicitudesRegistro2()
          await this.actualizarDataSolicitudes([{ 'llave': 'tipo_solicitud__codigo', 'valor': seleccionProc }, { 'llave': 'periodo', 'valor': periodo_filtro }]);
        }
        else if (seleccionProc === "Con_Int") {
          await this.actualizarDataSolicitudes([{ 'llave': 'tipo_solicitud__codigo', 'valor': seleccionProc }, { 'llave': 'periodo', 'valor': periodo_filtro }]);
        }
        else if (seleccionProc === "Ies_Int") {
          await this.actualizarDataSolicitudes([{ 'llave': 'tipo_solicitud__codigo', 'valor': seleccionProc }, { 'llave': 'periodo', 'valor': periodo_filtro }]);
        }
        // let solicitudes_detallada = await this.obtenerSolicitudesDetalle([{ 'llave': 'solicitud__tipo_solicitud__codigo', 'valor': tipoSolicitud }, { 'llave': 'solicitud__periodo', 'valor': periodo_filtro }], tipoSolicitud);
        // this.obtenerSolicitudesRegistro([{ 'llave': 'tipo_solicitud__codigo', 'valor': seleccionProc }, { 'llave': 'periodo', 'valor': periodo_filtro }]); //ok
        this.setState({ cargando: false });
      }
    }
    // SI SE INGRESA A LAS INSTITUCIONES
    if (seleccionAdm !== seleccionAdmNew) {
      if (seleccionAdmNew === 'instituciones') {
        await this.obtenerInstituciones()
      }
    }
  }

  obtenerInstituciones = async () => {
    // let { obtenerSolicitudesDetalle} = this.props
    this.setState({ cargando: true });
    let instituciones_list = await this.obtenerSolicitudesDetalle([{ 'llave': 'solicitud__estado_actual__codigo', 'valor': 'sin_conv_uni' }], 'Con_Int')
    this.setState({ cargando: false, instituciones_list });
  }

  async actualizarDataSolicitudes(filtros = [{ 'llave': 'estado_actual__valore', 'valor': 'si' }, { 'llave': 'periodo', 'valor': this.state.periodo }, { 'llave': 'tipo_solicitud__codigo', 'valor': this.state.tipoSolicitud }]) {
    this.setState({ cargando: true, filtros: true });
    let solicitudes = await this.obtenerSolicitudes(filtros);
    this.setState({ cargando: false, filtros_solicitudes: filtros, solicitudes_detallada: solicitudes });
  }

  gestionarSolicitud = (id, data) => {
    let { mostrarModalGestionarSolicitud, actualizarMensaje } = this.props;
    let { filtros_solicitudes, tipoSolicitud } = this.state;
    this.setState({ cargando: true });
    return new Promise((resolve) => {
      consulta(`api/v1.0/internacionalizacion/solicitud/${id}/gestionar`, data, "put", (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            // this.actualizarDataSolicitudes(filtros_solicitudes);
            mostrarModalGestionarSolicitud(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        this.setState({  cargando: false });
        if (!error) if (tipoSolicitud == 'Con_Int' || tipoSolicitud == 'Ies_Int') this.setState({ seleccionProc: tipoSolicitud});
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        resolve(resp);
      });
    });
  }

  modalFiltroPeriodo = async () => {
    let { cargarFiltroP } = this.state;
    this.setState({ filtrarPeriodo: true });
    if (cargarFiltroP) {
      this.setState({ cargandoPeriodo: true });
      let periodos = await this.obtenerPeriodos();
      this.setState({ periodos, cargandoPeriodo: false, cargarFiltroP: false });
    }
  }

  async obtenerAdjuntos(id, tipo = 1) { //ok
    let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': id }, { 'llave': 'tipo', 'valor': tipo }]); //ok
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitud/adjuntos?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }
  async obtenerEstados(tipo_solicitud) { //ok
    let f = await generarFiltros([{ 'llave': 'no_cancelado', 'valor': 'Est_Int_Can' }, { 'llave': 'no_borrador', 'valor': 'Est_Int_Bor' }, { 'llave': 'fil_tipo_soli', 'valor': tipo_solicitud }]); //ok
    return await new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitud/estadosTipoSolicitud?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  async obtenerDatosIniciales() {
    let { tipoSolicitud } = this.state;
    this.setState({ cargando: true });
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Int' }]);
    let filtros = periodo.length > 0 ? [{ 'llave': 'estado_actual__valore', 'valor': 'si' }, { 'llave': 'periodo', 'valor': periodo[0].nombre }] : [];
    this.actualizarDataSolicitudes([{ 'llave': 'periodo', 'valor': periodo.length > 0 ? periodo[0].nombre : '' }, { 'llave': 'tipo_solicitud__codigo', 'valor': tipoSolicitud }]);
    this.setState({ periodo: periodo.length > 0 ? periodo[0].nombre : '', cargando: false, filtros_solicitudes: filtros });
  }

  async obtenerInfoSolicitud(id, tipo_solicitud) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': id }]);
    if (tipo_solicitud === 'Mov_Est_Int') {
      consulta(`api/v1.0/internacionalizacion/solicitud/mov_estudiantil?${f}`, null, null, (error, estado, resp) => {
        if (estado === 200) this.setState({ cargando: false, datos: resp, tipo_solicitud: tipo_solicitud });
      });
    } else if (tipo_solicitud === 'Reg_Mov_Int') {
      consulta(`api/v1.0/internacionalizacion/solicitud/registro?${f}`, null, null, (error, estado, resp) => {
        if (estado === 200) this.setState({ cargando: false, datos: resp, tipo_solicitud: tipo_solicitud });
      });
    }
  }

  async obtenerParticipantesRegistro(registro = '', solicitud = '') {
    let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': solicitud }]);
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/registro/participantes?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  // async obtenerInfoSolicitud(id, tipo_solicitud) {
  //   this.setState({ cargando: true });
  //   let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': id }]);
  //   if (tipo_solicitud === 'Mov_Est_Int') {
  //     consulta(`api/v1.0/internacionalizacion/solicitud/mov_estudiantil?${f}`, null, null, (error, estado, resp) => {
  //       if (estado === 200) this.setState({ cargando: false, datos: resp, tipo_solicitud: tipo_solicitud });
  //     });
  //   } else if (tipo_solicitud === 'Reg_Mov_Int') {
  //     consulta(`api/v1.0/internacionalizacion/solicitud/registro?${f}`, null, null, (error, estado, resp) => {
  //       if (estado === 200) this.setState({ cargando: false, datos: resp, tipo_solicitud: tipo_solicitud });
  //     });
  //   }
  // }

  // async obtenerParticipantesRegistro(registro = '', solicitud = '') {
  //   let f = await generarFiltros([{ 'llave': 'registro', 'valor': registro }, { 'llave': 'registro__solicitud_id', 'valor': solicitud }]);
  //   return new Promise(resolve => {
  //     consulta(`api/v1.0/internacionalizacion/registro/participantes?${f}`, null, null, (error, estado, resp) => {
  //       resolve(estado === 200 && !error ? resp : null);
  //     });
  //   });
  // }

  async obtenerSolicitudes(filtros = []) {
    let f = await generarFiltros(filtros)
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitudes/listar/1?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  buscarUniversidades = async (dato_buscar = null) => {
    let { nombreUniversidadBuscar, id_institucionActual } = this.state;
    let f = await generarFiltros([{ 'llave': 'nombre', 'valor': (dato_buscar !== null && dato_buscar !== '') ? dato_buscar : dato_buscar == '' ? nombreUniversidadBuscar : nombreUniversidadBuscar }, { 'llave': 'id', 'valor': id_institucionActual }]);
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/buscar-institucion?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }
  

  async obtenerSolicitudesDetalle(filtros = [], tipo_solicitud) {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      if (tipo_solicitud === 'Mov_Est_Int') {
        consulta(`api/v1.0/internacionalizacion/movilidades?${f}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        });
      } else if (tipo_solicitud === 'Reg_Mov_Int') {
        consulta(`api/v1.0/internacionalizacion/registros?${f}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        });
      } else if (tipo_solicitud === 'Con_Int') {
        consulta(`api/v1.0/internacionalizacion/solicitud/convenio?${f}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        });
      } else if (tipo_solicitud === 'Ies_Int') {
        consulta(`api/v1.0/internacionalizacion/solicitud/convenio?${f}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        });
      }
    });
  }

  async obtenerSolicitudesRegistro(filtro = []) {
    // let f = await generarFiltros(filtro)
    let solicitudesR = await this.obtenerSolicitudes();
    let aux = [];
    solicitudesR.forEach(async sol => {
      let solicitudesR_detallada = await this.obtenerSolicitudesDetalle([{ 'llave': 'solicitud__tipo_solicitud__codigo', 'valor': sol.tipo_solicitud.codigo }, { 'llave': 'solicitud__periodo', 'valor': sol.periodo }, { 'llave': 'solicitud', 'valor': sol.id }], sol.tipo_solicitud.codigo);

      solicitudesR_detallada.forEach(solR => {
        aux.push({
          /* ...sol */
          /* , ...solR */
          //info solicitud
          'id': sol.id, //ok
          'solicitud': sol.id, //ok
          'periodo': sol.periodo, //ok
          'tipo_solicitud': sol.tipo_solicitud.codigo, //ok
          'estado': sol.estado, //ok
          'fecha_registro': sol.fecha_registro, //ok
          'fecha_elimina': sol.fecha_elimina, //ok
          'estado_actual': sol.estado_actual, //ok
          'solicitante': sol.solicitante, //ok
          'tipo_solicitud': sol.tipo_solicitud, //ok
          'titulo_tema': solR.titulo_tema,

          //info registro
          'fecha_inicio': solR.fecha_inicio, //ok
          'fecha_final': solR.fecha_final, //ok
          'tipo_movilidad': solR.tipo_movilidad, //ok
          'tipo_actividad': solR.tipo_actividad, //ok
          'otro_tipo_actividad': solR.otro_tipo_actividad, //ok
          'subtipo_actividad': solR.subtipo_actividad,
          'otro_subtipo_actividad': solR.otro_subtipo_actividad,
          'estado': solR.estado, //ok
        })
      })

    }
    )
    this.setState({ solicitudesRegistro: aux });
  }

  async obtenerSolicitudesRegistro2(filtro = []) {
    // let f = await generarFiltros(filtro)
    let solicitudesR = await this.obtenerSolicitudes([[{ 'llave': 'tipo_solicitud__codigo', 'valor': this.state.seleccionProc }, { 'llave': 'periodo', 'valor': this.state.periodo_filtro }]]);
    let aux = [];
    solicitudesR.forEach(async sol => {
      let solicitudesR_detallada = await this.obtenerSolicitudesDetalle([{ 'llave': 'solicitud__tipo_solicitud__codigo', 'valor': sol.tipo_solicitud.codigo }, { 'llave': 'solicitud__periodo', 'valor': sol.periodo }, { 'llave': 'solicitud', 'valor': sol.id }], sol.tipo_solicitud.codigo);

      solicitudesR_detallada.forEach(solR => {
        aux.push({
          /* ...sol */
          /* , ...solR */
          //info solicitud
          'id': sol.id, //ok
          'solicitud': sol.id, //ok
          'periodo': sol.periodo, //ok
          'tipo_solicitud': sol.tipo_solicitud.codigo, //ok
          'estado': sol.estado, //ok
          'fecha_registro': sol.fecha_registro, //ok
          'fecha_elimina': sol.fecha_elimina, //ok
          'estado_actual': sol.estado_actual, //ok
          'solicitante': sol.solicitante, //ok
          'tipo_solicitud': sol.tipo_solicitud, //ok
          'titulo_tema': solR.titulo_tema,

          //info registro
          'fecha_inicio': solR.fecha_inicio, //ok
          'fecha_final': solR.fecha_final, //ok
          'tipo_movilidad': solR.tipo_movilidad, //ok
          'tipo_actividad': solR.tipo_actividad, //ok
          'otro_tipo_actividad': solR.otro_tipo_actividad, //ok
          'subtipo_actividad': solR.subtipo_actividad,
          'otro_subtipo_actividad': solR.otro_subtipo_actividad,
          'estado': solR.estado, //ok
        })
      })

    }
    )
    this.setState({ solicitudesRegistro: aux });
  }

  async obtenerSolicitudesRegistro2(filtro = []) {
    // let f = await generarFiltros(filtro)
    let solicitudesR = await this.obtenerSolicitudes([[{ 'llave': 'tipo_solicitud__codigo', 'valor': "Reg_Mov_Int" }, { 'llave': 'periodo', 'valor': this.state.periodo_filtro }]]);
    let aux = [];
    solicitudesR.forEach(async sol => {
      if (sol.tipo_solicitud.codigo !== "Con_Int") {
        let solicitudesR_detallada = await this.obtenerSolicitudesDetalle([{ 'llave': 'solicitud__tipo_solicitud__codigo', 'valor': sol.tipo_solicitud.codigo }, { 'llave': 'solicitud__periodo', 'valor': sol.periodo }, { 'llave': 'solicitud', 'valor': sol.id }], sol.tipo_solicitud.codigo);
        solicitudesR_detallada.forEach(solR => {
          aux.push({
            /* ...sol */
            /* , ...solR */
            //info solicitud
            'id': sol.id, //ok
            'solicitud': sol.id, //ok
            'periodo': sol.periodo, //ok
            'tipo_solicitud': sol.tipo_solicitud.codigo, //ok
            'estado': sol.estado, //ok
            'fecha_registro': sol.fecha_registro, //ok
            'fecha_elimina': sol.fecha_elimina, //ok
            'estado_actual': sol.estado_actual, //ok
            'solicitante': sol.solicitante, //ok
            'tipo_solicitud': sol.tipo_solicitud, //ok
            'titulo_tema': solR.titulo_tema,

            //info registro
            'fecha_inicio': solR.fecha_inicio, //ok
            'fecha_final': solR.fecha_final, //ok
            'tipo_movilidad': solR.tipo_movilidad, //ok
            'tipo_actividad': solR.tipo_actividad, //ok
            'otro_tipo_actividad': solR.otro_tipo_actividad, //ok
            'subtipo_actividad': solR.subtipo_actividad,
            'otro_subtipo_actividad': solR.otro_subtipo_actividad,
            'estado': solR.estado, //ok
          })
        })
      }
    })
    this.setState({ solicitudesRegistro: aux });
  }


  esAdministrador() {
    let { usuario } = this.props;
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Per_Adm_N1")) {
      this.setState({ administrador: true });
    } else if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Int_N1")) {
      this.setState({ lider: true });
    }
  }

  filtrarEstadoSolicitudes = () => {
    let { periodo_filtro } = this.state;
    this.setState({ filtrarPeriodo: false, cargando: true })
    this.obtenerEstadoSolicitudes(periodo_filtro)
  }

  obtenerEstadoSolicitudes = (periodo) => {
    consulta(`api/v1.0/internacionalizacion/solicitudes/estados/${periodo}`, null, null, (error, estado, resp) => {
      if (estado === 200) {
        this.setState({ estados_procesos: resp, cargando: false });
      }
    });
  }

  obtenerPeriodos = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitudes/periodos`, null, null,
        (error, estado, resp) => {
          resolve(resp)
        }
      );
    });
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({ [name]: value });
  }

  pintarAdministrar() {
    let { seleccionAdm, administrador, lider } = this.state;
    let { mostrarModalAddPermisosDep, modalAddPermisosDep, actualizarMensaje } = this.props
    if (!administrador && !lider) return <TareasTerminadas imagen={emma_s1} mensaje='No tiene los permisos asignados' widthImg='7%' marginTop='7%' />
    return (
      <>
        {(administrador || lider) && seleccionAdm === 'inicio' && this.pintarInicioAdm()}
        {(administrador || lider) && seleccionAdm !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ seleccionAdm: 'inicio' })} /></div>}
        {administrador && seleccionAdm === 'convocatorias' && this.renderConvocatorias()}
        {(administrador || lider) && seleccionAdm === 'instituciones' && this.renderInstituciones()}
        {administrador && seleccionAdm === 'requisitos' && this.renderRequisitos()} {/* Requisitos */}
        {administrador && seleccionAdm === 'permisos' && <InternacionalizacionPermisos />}
        {administrador && seleccionAdm === 'dependencias' && <InternacionalizacionPermisosDepen mostrarModalAddPermisosDep={mostrarModalAddPermisosDep} modalAddPermisosDep={modalAddPermisosDep} />}
        {administrador && seleccionAdm === 'reportes' && <InformesRegistro actualizarMensaje={actualizarMensaje} lideres={false} />}
      </>
    )
  }

  pintarInicioAdm = () => {
    let { administrador, lider } = this.state;
    return (
      <div style={{ padding: '2% 0% 0% 2%' }}>
        <Grid container direction={'row'} spacing={1} justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <TituloAccion principal='' alterno='¿ Qué deseas hacer ?' />
            <Paper elevation={0} square className='scroll contenido_fijo'>
              <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                {(lider && !administrador) && (
                  <Grid item xs={6} sm={6} md={6} lg={6} >
                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ seleccionAdm: 'instituciones' })}>
                      <AccionesRapidas imagen={institucionesIcon} titulo='¡ Instituciones !' sub_titulo='' des_sub_titulo='Crea, modifica y elimina instituciones.' />
                    </Paper>
                  </Grid>
                )}
                {administrador && (
                  <>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                      <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ seleccionAdm: 'convocatorias' })}>
                        <AccionesRapidas imagen={fechaIcon} titulo='¡ Gestionar Convocatorias !' sub_titulo='' des_sub_titulo='Crea, modifica y elimina convocatorias.' />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                      <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ seleccionAdm: 'instituciones' })}>
                        <AccionesRapidas imagen={institucionesIcon} titulo='¡ Instituciones !' sub_titulo='' des_sub_titulo='Crea, modifica y elimina instituciones.' />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                      <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ seleccionAdm: 'requisitos' })}>
                        <AccionesRapidas imagen={requisitosIcon} titulo='¡ Requisitos !' sub_titulo='' des_sub_titulo='Crea, modifica y elimina requisitos.' />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                      <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ seleccionAdm: 'permisos' })}>
                        <AccionesRapidas imagen={permisosIcon} titulo='¡ Controlar Permisos !' sub_titulo='' des_sub_titulo='Verifica, asigna y retira permisos a los gestores.' />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                      <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ seleccionAdm: 'dependencias' })}>
                        <AccionesRapidas imagen={permisosDependenciasIcon} titulo='¡ Dependencias Permisos !' sub_titulo='' des_sub_titulo='Verifica, asigna y retira permisos a los gestores.' />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                      <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ seleccionAdm: 'reportes' })}>
                        <AccionesRapidas imagen={reportIcon} titulo='¡ Informes !' sub_titulo='' des_sub_titulo='Genera reportes de los procesos de internacionalización.' />
                      </Paper>
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  pintarInicioProcesos() {
    let { periodos, periodo_filtro, filtrarPeriodo, cargandoPeriodo, tipos_solicitudes } = this.state;
    let { usuario } = this.props;
    const obtenerColorTipo = (codigo) => {
      const colores = {
        'Con_Int': { 'backgroundColor': blue[500] },
        'Mov_Est_Int': { 'backgroundColor': red[500] },
        'Reg_Mov_Int': { 'backgroundColor': yellow[500] },
      }
      return (colores[codigo])
    }
    // const obtenerColorEstado = (codigo) => {
    //   let color = 'white';
    //   const colores = {
    //     'Est_Int_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
    //     'Est_Int_Cor': { 'backgroundColor': '#ffc107', color },
    //     'Est_Int_Rec': { 'backgroundColor': '#b71c1c', color },
    //     'Est_Int_Hom': { 'backgroundColor': '#01519B', color },
    //     'Est_Int_Rev': { 'backgroundColor': '#32C79E', color },
    //     'Est_Int_Pos': { 'backgroundColor': '#6D32C7', color },
    //     'Est_Int_Doc': { 'backgroundColor': '#009688', color },
    //     'Est_Int_Ace': { 'backgroundColor': '#C0C732', color },
    //     'Est_Int_Fin': { 'backgroundColor': '#C74632', color },
    //     'Est_Int_Neg': { 'backgroundColor': '#C77332', color },
    //     'Est_Int_Can': { 'backgroundColor': '#D88443', color },
    //   }
    //   return (colores[codigo]);
    // }
    return (
      <>
        <div style={{ padding: '2% 2% 2% 2%', justifyContent: 'center' }}>
          {/* <Grid container direction = { 'row' } spacing = { 0 } justifyContent = { 'center' }> */}
          {/* <Grid container direction = { 'row' } alignItems = "center" spacing = { 1 } style = {{ width: "100%" }}>
                <Grid item xs = { 6 } sm = { 6 } md = { 6 } lg = { 6 }>
                  <TituloAccion principal = '' alterno = 'Seleccione el estado a gestionar: ' />
                </Grid>
                <Grid item xs = { 6 } sm = { 6 } md = { 6 } lg = { 6 }>
                  <Tooltip title = 'Filtrar' aria-label = "add" onClick = { () => this.modalFiltroPeriodo() }>
                    <IconButton edge = { false } color = "secondary" style = {{ float: 'right' }}><FilterListIcon /></IconButton>
                  </Tooltip>
              </Grid> */}
          <Paper elevation={0} square className='scroll contenido_fijo'>
            {
              tipos_solicitudes.length == 0 ?
                <TareasTerminadas mensaje='Aún no hay solicitudes disponibles.' marginTop='7%' imagen={emma_s1} widthImg="7%" />
                : <Grid container direction={'row'} spacing={0} style={{ width: "100%" }} justifyContent={'center'}>
                  <BannerInter nombre={usuario.primer_nombre}/>
                  {
                    tipos_solicitudes.sort((a, b) => a.valorh - b.valorh).map((tipo, index) => {
                      return (
                        <CardSolicitud gestion={true} tipo={tipo} onCardClick={() => this.setState({ seleccionProc: tipo.codigo, tipoSolicitud: tipo.codigo })} />
                        // <Grid  key= { index }  item xs = { 12 } sm = { 12 } md = { 6 } lg = { 6 }>
                        //   <Paper elevation = { 1 } style = {{ border: '1px solid #e0e0e0' }} onClick = { () => this.setState({ seleccionProc: codigo, tipoSolicitud: codigo }) }>
                        //     <AccionesRapidas imagen = '' titulo = {`¡ ${ nombre } !`} sub_titulo = '' des_sub_titulo = '' avatar = { obtenerColorTipo(codigo) } avatar_name = { inicial.charAt(0).toUpperCase() }/>
                        //   </Paper>
                        // </Grid>
                      );
                    })
                  }
                </Grid>
            }
          </Paper>
          {/* </Grid> */}
        </div>

        <Dialog fullWidth={true} maxWidth="xs" open={filtrarPeriodo} onClose={() => this.setState({ filtrarPeriodo: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => this.setState({ filtrarPeriodo: false })} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            {
              !cargandoPeriodo ?
                <Grid container spacing={3} className='margin_cont'>
                  <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                    <FormControl className='form-control'>
                      <InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
                      <Select inputProps={{ name: 'periodo_filtro', id: 'periodo_filtro' }} value={periodo_filtro} onChange={this.onChange} required={true}>
                        {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                : <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' cargando={true} />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ filtrarPeriodo: false })} />
            <BtnForm texto="FILTRAR" callback={() => this.filtrarEstadoSolicitudes()} />
          </DialogActions>
        </Dialog>
      </>
    )
  }

  renderConvocatorias() {
    let { actualizarMensaje, actualizarDataConvocatoria, convocatoria } = this.props;
    return (
      <div>
        <Convocatorias
          actualizarMensaje={actualizarMensaje}
          actualizarDataConvocatoria={actualizarDataConvocatoria}
          convocatoria={convocatoria}
        />
      </div>
    )
  }

  renderInstituciones() {
    let { actualizarMensaje, actualizarDataInstitucion, institucion } = this.props;
    let { administrador, instituciones_list, lider } = this.state
    return (
      <div>
        <InternacionalizacionInstituciones
          obtenerSolicitudesDetalle={this.obtenerSolicitudesDetalle}
          actualizarMensaje={actualizarMensaje}
          actualizarDataInstitucion={actualizarDataInstitucion}
          institucion={institucion}
          administrador={administrador || lider}
          instituciones_list={instituciones_list}
          obtenerInstituciones={this.obtenerInstituciones}
        />
      </div>
    )
  }

  renderProcesos() {
    let { seleccionProc, estado_conveniolist, tipoSolicitud, filtrosLideres } = this.state;
    let { actualizarMensaje } = this.props
    return (
      <>
        {seleccionProc === 'inicio' ? this.pintarInicioProcesos() : seleccionProc !== 'reporte' && this.renderSolicitudes()}
        {filtrosLideres && <InformesRegistro actualizarMensaje={actualizarMensaje} lideres={true} reset={() => this.setState({ lideres: false, filtrosLideres: false })} />}
        {/* { seleccionProc === 'reporte' && this.Reporte() } */}
        {seleccionProc !== 'inicio' && estado_conveniolist.length == 0 ? <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ seleccionProc: 'inicio', tipoSolicitud: '', filtros: false })} /></div> : tipoSolicitud ? <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ seleccionProc: tipoSolicitud, estado_conveniolist: [] })} /></div> : ''}
        {seleccionProc !== 'inicio' && seleccionProc !== 'reporte' && <div className={'descargar'} ><BtnDescargar callback={() => this.setState({ filtrosLideres: true })} /></div>}
      </>
    )
  }

  editarInstitucion = async (data, id, callback) => {
    let { actualizarMensaje, mostrarModalModInstituciones } = this.props; //edi
    let { tipoSolicitud, seleccionProc } = this.state;
    let datos = await crear_form_data(data);
    if (tipoSolicitud == 'Ies_Int') datos.append('tipo_solicitud', tipoSolicitud);
    formulario(`api/v1.0/internacionalizacion/convenios/${id}/modificar_inst_sin_conv`, datos, "post", async (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          // this.setState({ cargando: true });
          // this.obtenerInstituciones();
          mostrarModalModInstituciones(false)
          // let estado_conveniolist = await this.obtenerSolicitudesDetalle([{ 'llave': 'solicitud__tipo_solicitud__codigo', 'valor': tipoSolicitud }, { 'llave': 'solicitud__estado_actual__codigo', 'valor': seleccionProc }], tipoSolicitud);
          // this.setState({ estado_conveniolist, cargando: false });
          if (tipoSolicitud === 'Ies_Int' || tipoSolicitud === 'Con_Int') this.setState({ seleccionProc: tipoSolicitud});
        } else {
          tipo = 'info'
          titulo = resp.titulo
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
      callback();
    })
  }

  editarRedAcademica = async (datos, callback) => {
    let { actualizarMensaje, mostrarModalEdiIes } = this.props;
    let { tipoSolicitud } = this.state;
    let data = await crear_form_data(datos);
    formulario(
      `api/v1.0/internacionalizacion/convenios/solicitar_universidad`,
      data,
      "put",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            mostrarModalEdiIes(false);
          if (tipoSolicitud === 'Ies_Int' || tipoSolicitud === 'Con_Int') this.setState({ seleccionProc: tipoSolicitud});
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
        callback();
      }
    )
  }

  renderRequisitos() {
    let { actualizarMensaje, actualizarDataRequisito, requisito } = this.props;
    return (
      <div>
        <InternacionalizacionRequisitos
          actualizarMensaje={actualizarMensaje}
          actualizarDataRequisito={actualizarDataRequisito}
          requisito={requisito}
        />
      </div>
    )
  }
  obtenerColor = (codigo) => {
    let color = 'white';

    const colores = {
      'Est_Int_Bor': { 'backgroundColor': '#EEEEEE', 'color': '#757575' },
      'Est_Int_Env': { 'backgroundColor': '#01579b', color },
      'Apro_Inst': { 'backgroundColor': '#008000', color },
      'Mod_Inst': { 'backgroundColor': '#ffc107', color },
      'Sol_aval_jefe': { 'backgroundColor': '#ffc107', color },
      'En_Revi_Con': { 'backgroundColor': '#ffc107', color },
      'Con_rev_sec': { 'backgroundColor': '#ffc107', color },
      'Con_corr': { 'backgroundColor': '#ffc107', color },
      'Con_rev_ins_vin': { 'backgroundColor': '#ffc107', color },
      'Est_Int_Can': { 'backgroundColor': '	#FF0000', color },
      'Con_rev_sec_gen_2': { 'backgroundColor': '#ffc107', color },
      'con_corr_2': { 'backgroundColor': '#ffc107', color },
      'Con_ava_sec_gen': { 'backgroundColor': '	#ffc107', color },
      'Rev_ges_conv_2': { 'backgroundColor': '#ffc107', color },
      'Fir_ins_vincu': { 'backgroundColor': '#ffc107', color },
      'Ges_fir_sg': { 'backgroundColor': '#ffc107', color },
      'Con_sus': { 'backgroundColor': '#ffc107', color },
      'En_corr_soli': { 'backgroundColor': '#ffc107', color },
      'Fir_cuc': { 'backgroundColor': '#ffc107', color },
      'Con_aval_juri_ins_vin': { 'backgroundColor': '#ffc107', color },
      'sin_conv_uni': { 'backgroundColor': '#EEEEEE', color },
      'Con_regis': { 'backgroundColor': '#008000', color },
      'Sol_rechazada': { 'backgroundColor': '#FF0000', color },
    }
    return (colores[codigo])
  }

  renderSolicitudes() {
    let { actualizarMensaje, modalModAdjuntos, modalAprobarAdjunto, modalNegarAdjunto, modalDetSolicitud, modalGestionarSolicitud, modalAddFiltros, modalListaUniversidades, mostrarModalModAdjuntos, mostrarModalAprobarAdjunto, mostrarModalNegarAdjunto, mostrarModalDetSolicitud, mostrarModalGestionarSolicitud, mostrarModalAddFiltros, mostrarModalListaUniversidades, mostrarModalAddConvenio, modalAddConvenio, modalEdiConvenio, mostrarModalEdiConvenio, usuario, mostrarModalModInstituciones, modalModInstituciones, modalEdiIes, mostrarModalEdiIes } = this.props;
    let { solicitud, vistaDetalle, administrador, id_institucionActual, tipoSolicitud, solicitudesRegistro, seleccionProc, estados_total, estado_conveniolist, opcion, convenio, solicitudes_detallada } = this.state;
    if (seleccionProc === 'Reg_Mov_Int') {
      const acciones = (data) => {
        let { estado_actual: { codigo } } = data;
        const onClickDetalle = solicitud => {
          this.setState({ solicitud: solicitud, vistaDetalle: 'detalle' });
          mostrarModalDetSolicitud(true);
        }
        const onClickAdjuntos = solicitud => {
          this.setState({ solicitud: solicitud, vistaDetalle: 'documentos' });
          mostrarModalDetSolicitud(true);
        }
        const onClickGestionar = solicitud => {
          this.setState({ solicitud: solicitud });
          mostrarModalGestionarSolicitud(true);
        }
        let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
        let adjuntos = <BtnFolder callback={() => onClickAdjuntos(data)} color='#ffd600' />;
        let gestionar = (codigo !== 'Est_Int_Fin' || codigo !== 'Est_Int_Neg') && <BtnGestionar callback={() => onClickGestionar(data)} color='#00bfa5' texto='Gestionar' />;
        // return administrador ? <div>{ detalle }{/* { adjuntos } */}{ gestionar }</div> : <div>{ detalle }{/* { adjuntos } */}</div>;
        return <div>{detalle}{gestionar}</div>;
      }
      const obtenerColorEstado = (codigo) => {
        let color = 'white';
        const colores = {
          'Est_Int_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
          'Est_Int_Cor': { 'backgroundColor': '#ffc107', color },
          'Est_Int_Rec': { 'backgroundColor': '#b71c1c', color },
          'Est_Int_Hom': { 'backgroundColor': '#01519B', color },
          'Est_Int_Rev': { 'backgroundColor': '#32C79E', color },
          'Est_Int_Pos': { 'backgroundColor': '#6D32C7', color },
          'Est_Int_Doc': { 'backgroundColor': '#009688', color },
          'Est_Int_Ace': { 'backgroundColor': '#C0C732', color },
          'Est_Int_Fin': { 'backgroundColor': '#C74632', color },
          'Est_Int_Neg': { 'backgroundColor': '#C77332', color },
        }
        return (colores[codigo]);
      }

      return (
        <div>
          <InternacionalizacionDetalle
            actualizarMensaje={actualizarMensaje}
            solicitud={solicitud ? solicitud : {}}
            mostrarModalDetSolicitud={mostrarModalDetSolicitud}
            modalDetSolicitud={modalDetSolicitud}
            vistaDetalle={vistaDetalle}
            admin={true}
            modalModAdjuntos={modalModAdjuntos}
            modalAprobarAdjunto={modalAprobarAdjunto}
            modalNegarAdjunto={modalNegarAdjunto}
            modalListaUniversidades={modalListaUniversidades}
            mostrarModalModAdjuntos={mostrarModalModAdjuntos}
            mostrarModalAprobarAdjunto={mostrarModalAprobarAdjunto}
            mostrarModalNegarAdjunto={mostrarModalNegarAdjunto}
            mostrarModalListaUniversidades={mostrarModalListaUniversidades}
            obtenerAdjuntos={this.obtenerAdjuntos}
            obtenerParticipantesRegistro={this.obtenerParticipantesRegistro}
          />
          <SolicitudesGestionar
            modalGestionarSolicitud={modalGestionarSolicitud}
            mostrarModalGestionarSolicitud={mostrarModalGestionarSolicitud}
            solicitud={solicitud ? solicitud : {}}
            gestionarSolicitud={this.gestionarSolicitud}
            actualizarMensaje={actualizarMensaje}
          // actualizarDataSolicitudes = { () => this.actualizarDataSolicitudes() }
          />
          <ListarDatos
            datos={solicitudes_detallada}
            titulo='Lista de Solicitudes Internacionalización'
            id='tbl_solicitudes'
            opciones={true}
            filtrar={true}
            refrescar={true}
            actfiltrar={() => mostrarModalAddFiltros(true)}
            actRefrescar={() => this.obtenerDatosIniciales()}
            acciones={(data) => acciones(data)}
            buscar={true}
            avatar={({ estado_actual }) => estado_actual?.valora}
            color_avatar={({ estado_actual }) => obtenerColorEstado(estado_actual?.codigo)}
            fila_principal={({ usuario_registro }) => {
              const { primer_nombre = '', primer_apellido = '', segundo_apellido = '' } = usuario_registro || {};
              return `${primer_nombre} ${primer_apellido} ${segundo_apellido}`;
            }}
            filas={[
              { nombre: 'Identificación: ', id: 'identificacion', mostrar: ({ usuario_registro }) => usuario_registro?.identificacion || '' },
              { nombre: 'Tipo de solicitud: ', id: 'tipo_solicitud', mostrar: ({ tipo_solicitud }) => tipo_solicitud?.nombre || '' },
              { nombre: 'Tipo de movilidad: ', id: 'tipo_movilidad', mostrar: ({ tipo_movilidad }) => tipo_movilidad || '' },
              { nombre: 'Tipo de actividad: ', id: 'tipo_actividad', mostrar: ({ actividad }) => actividad?.tipo_actividad?.nombre || '' },
              { nombre: 'Otro tipo de actividad: ', id: 'otro_tipo_actividad', mostrar: ({ otro_tipo_actividad }) => otro_tipo_actividad || '' },
              { nombre: 'Titulo de la actividad: ', id: 'titulo_tema', mostrar: ({ actividad }) => actividad?.titulo_evento || '' },
              { nombre: 'Fecha de inicio: ', id: 'fecha_inicio', mostrar: ({ actividad }) => actividad?.fecha_inicio ? moment(actividad.fecha_inicio).format('YYYY-MM-DD') : '' },
              { nombre: 'Fecha de finalización: ', id: 'fecha_final', mostrar: ({ actividad }) => actividad?.fecha_final ? moment(actividad.fecha_final).format('YYYY-MM-DD') : '' },
              { nombre: 'Estado: ', id: 'estado', mostrar: ({ estado_actual }) => estado_actual?.nombre || '' },
              { nombre: 'Fecha de registro: ', id: 'fecha_registro', mostrar: ({ fecha_registro }) => fecha_registro ? moment(fecha_registro).format('YYYY-MM-DD') : '' },
            ]}
          />
          <SolicitudFiltros
            tipoSolicitud={tipoSolicitud}
            actualizarMensaje={actualizarMensaje}
            modalFiltro={modalAddFiltros}
            mostrarModalFiltros={mostrarModalAddFiltros}
            filtrar={(filtros) => this.actualizarDataSolicitudes(filtros)}
            limpiar={() => this.actualizarDataSolicitudes()}
          />
        </div>
      )
    } else if (seleccionProc === 'Con_Int' || seleccionProc === 'Ies_Int') {
      return (

        <div style={{ padding: '2% 2% 0% 2%' }}>
          <Grid container direction={'row'} spacing={1} justify={'center'} >
            <Grid item xs={12} sm={12} md={12} lg={10} >
              <Grid container direction={'row'} alignItems="center" spacing={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  <TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
                </Grid>
                {/* <Grid item xs={6} sm={6} md={6} lg={6} >
                
                
                  <Tooltip title='Filtrar' aria-label="add" onClick={() => this.ModalfiltroPeriodo()} >
                    <IconButton edge={false} color="secondary" style={{ float: 'right' }}><FilterListIcon /></IconButton>
                  </Tooltip>
                  <Tooltip title='Buscar' aria-label="search" onClick={() => this.props.mostrarModalFiltroId(true)} >
                    <IconButton edge={false} color="secondary" style={{ float: 'right' }}><SearchIcon /></IconButton>
                  </Tooltip>
                </Grid> */}
              </Grid>
              <Grid>
                <Paper elevation={0} square className="scroll contenido_fijo">
                  {(estados_total.length > 0) ?
                    <Grid container direction={'row'} spacing={1} >
                      {estados_total.map((item, i) => {
                        return (
                          <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                            <Paper elevation={1} onClick={() => this.setState({ seleccionProc: item.solicitud__estado_actual__codigo })} >
                              <AccionesRapidas imagen='' titulo={`¡ ${item ? item.solicitud__estado_actual__nombre : ''} !`} sub_titulo='' des_sub_titulo='' avatar={Object.entries(item).length > 0 ? this.obtenerColor(item.solicitud__estado_actual__codigo) : ''} list_desc={Number.isInteger(item.num_sol) ? [`Numero de Solicitudes : ${item.num_sol}`] : ''} avatar_name={Object.entries(item).length > 0 ? item.solicitud__estado_actual__nombre.charAt(0).toUpperCase() : ''} />
                            </Paper>
                          </Grid>
                        )
                      })}
                    </Grid>
                    :
                    <TareasTerminadas mensaje='Aún no hay solicitudes para este periodo.' marginTop='7%' imagen={emma_s1} widthImg="7%" />
                  }
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>

      )
    } else if (estado_conveniolist.length > 0 && seleccionProc !== 'Con_Int' && seleccionProc !== 'Reg_Mov_Int' && seleccionProc !== 'reporte' && seleccionProc !== 'Ies_Int') {
      const acciones = (data) => {
        let { solicitud: { estado_actual: { codigo } } } = data;

        const onClickDetalle = solicitud => {
          this.setState({ solicitud: solicitud.solicitud, vistaDetalle: 'detalle' });
          mostrarModalDetSolicitud(true);
        }

        const onClickAdjuntos = solicitud => {
          this.setState({ solicitud, vistaDetalle: 'documentos' });
          // mostrarModalDetSolicitud(true);
        }

        const onClickGestionar = solicitud => {
          let help = solicitud.solicitud
          this.setState({ solicitud: help });
          mostrarModalGestionarSolicitud(true);
        }

        const onClickEditar = solicitud => {
          if (solicitud.solicitud.estado_actual.codigo == 'sin_conv_uni' || (solicitud.solicitud.tipo_solicitud.codigo == 'Ies_Int' && solicitud.subtipo_solicitud.codigo == 'institucion_sol')) {
            mostrarModalModInstituciones(true);
            this.setState({ convenio: solicitud, id_institucionActual: solicitud.solicitud.id })
          } else if (solicitud.solicitud.estado_actual.codigo == 'sin_conv_uni' || (solicitud.solicitud.tipo_solicitud.codigo == 'Ies_Int' && solicitud.subtipo_solicitud.codigo == 'red_aca_sol')) {
            mostrarModalEdiIes(true)
            this.setState({ convenio: solicitud, id_institucionActual: solicitud.id })
          } else {
            this.setState({ opcion: 'editar', convenio: solicitud })
            mostrarModalEdiConvenio(true)
          }
        }

        const onClickBuscar = solicitud => {
          this.setState({ solicitud: solicitud.solicitud, nombreUniversidadBuscar: solicitud.institucion, buscarUniversidad: true, id_institucionActual: solicitud.solicitud.id });
        }

        let val = (tipoSolicitud == 'Ies_Int') && <BtnBuscar2 callback={() => onClickBuscar(data)} />;
        let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
        let editar = ((tipoSolicitud == 'Con_Int' || tipoSolicitud == 'Ies_Int' ) && administrador) ? <BtnEditar callback={() => onClickEditar(data)} color='#01579b' texto='Editar'></BtnEditar> : ''
        // let adjuntos = <BtnFolder callback={ () => onClickAdjuntos(data) } color='#ffd600' />;
        // let gestionar = (codigo !== 'Est_Int_Fin' || codigo !== 'Est_Int_Neg') && <BtnGestionar callback = { () => onClickGestionar(data) } color='#00bfa5' texto='Gestionar' />;

        let gestionar = (codigo === 'Con_regis' || codigo === 'Sol_rechazada' || codigo === 'sin_conv_uni' ? '' : (codigo !== 'Est_Int_Fin' || codigo !== 'Est_Int_Neg') && <BtnGestionar callback={() => onClickGestionar(data)} color='#00bfa5' texto='Gestionar' />)
        return administrador ? <div>{val}{detalle}{editar}{gestionar}</div> : <div>{detalle}{val}</div>;
      }

      return (
        <>
          <InternacionalizacionDetalle
            actualizarMensaje={actualizarMensaje}
            solicitud={solicitud ? solicitud : {}}
            mostrarModalDetSolicitud={mostrarModalDetSolicitud}
            modalDetSolicitud={modalDetSolicitud}
            vistaDetalle={vistaDetalle}
            admin={true}
            modalModAdjuntos={modalModAdjuntos}
            modalAprobarAdjunto={modalAprobarAdjunto}
            modalNegarAdjunto={modalNegarAdjunto}
            modalListaUniversidades={modalListaUniversidades}
            mostrarModalModAdjuntos={mostrarModalModAdjuntos}
            mostrarModalAprobarAdjunto={mostrarModalAprobarAdjunto}
            mostrarModalNegarAdjunto={mostrarModalNegarAdjunto}
            mostrarModalListaUniversidades={mostrarModalListaUniversidades}
            obtenerAdjuntos={this.obtenerAdjuntos}
            obtenerParticipantesRegistro={this.obtenerParticipantesRegistro}
          />
          <ListarDatos
            datos={estado_conveniolist}
            titulo={`Lista de solicitudes`}
            id='tbl_solicitudes'
            opciones={true}
            filtrar={true}
            refrescar={true}
            // actfiltrar = { () => mostrarModalAddFiltros(true) }
            // actRefrescar = { () => this.obtenerDatosIniciales() }
            acciones={(data) => acciones(data)}
            buscar={true}
            avatar={({ solicitud }) => solicitud.estado_actual.nombre.charAt(0).toUpperCase()}
            color_avatar={({ solicitud }) => this.obtenerColor(solicitud.estado_actual.codigo)}
            fila_principal={({ institucion }) => institucion}
            filas={[
              { 'nombre': 'Subtipo de solicitud: ', 'id': 'subtipo_solicitud', 'mostrar': ({ subtipo_solicitud }) => subtipo_solicitud?.nombre || '' },
              { 'nombre': 'Nombre del solicitante: ', 'id': 'identificacion', 'mostrar': ({ solicitud: { solicitante: { primer_nombre, primer_apellido, segundo_apellido } } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
              { 'nombre': 'Correo electronico del solicitante: ', 'id': 'correo', 'mostrar': ({ solicitud: { solicitante: { correo } } }) => correo },
              { 'nombre': 'Estado actual: ', 'id': 'estado_actual', 'mostrar': ({ solicitud: { estado_actual: { nombre } } }) => nombre },
              // { 'nombre': 'Programa: ', 'id': 'programa_academico', 'mostrar': ({ solicitante: { programa_academico } }) => programa_academico },
              // { 'nombre': 'Tipo de solicitud: ', 'id': 'tipo_solicitud', 'mostrar': ({ tipo_solicitud }) => tipo_solicitud.nombre },
              // { 'nombre': 'Tipo de movilidad: ', 'id': 'tipo_movilidad', 'mostrar': ({ tipo_movilidad }) => tipo_movilidad.nombre },
              // { 'nombre': 'Tipo de actividad: ', 'id': 'tipo_actividad', 'mostrar': ({ tipo_actividad }) => tipo_actividad.nombre },
              // { 'nombre': 'Otro tipo de actividad: ', 'id': 'otro_tipo_actividad', 'mostrar': ({ otro_tipo_actividad }) =>otro_tipo_actividad },
              // { 'nombre': 'Titulo de la actividad: ', 'id': 'titulo_tema', 'mostrar': ({ titulo_tema }) =>titulo_tema },
              // { 'nombre': 'Fecha de inicio: ', 'id': 'fecha_inicio', 'mostrar': ({ fecha_inicio }) => moment(fecha_inicio).format('YYYY-MM-DD') },
              // { 'nombre': 'Fecha de finalización: ', 'id': 'fecha_final', 'mostrar': ({ fecha_final }) => moment(fecha_final).format('YYYY-MM-DD') },
              // // { 'nombre': 'Periodo: ', 'id': 'periodo', 'mostrar': ({ periodo }) => periodo },
              // { 'nombre': 'Estado: ', 'id': 'estado', 'mostrar': ({ estado_actual }) => estado_actual.nombre }, //new
            ]}
          />

          <ConvenioGestionar
            modalGestionarSolicitud={modalGestionarSolicitud}
            mostrarModalGestionarSolicitud={mostrarModalGestionarSolicitud}
            solicitud={solicitud}
            gestionarSolicitud={this.gestionarSolicitud}
            actualizarMensaje={actualizarMensaje}
            persona={usuario}
          // actualizarDataSolicitudes = { () => this.actualizarDataSolicitudes() }
          />

          <ConveniosCrear
            modalEdiConvenio={modalEdiConvenio}
            mostrarModalEdiConvenio={mostrarModalEdiConvenio}
            opcion={opcion}
            convenio_adm={convenio}
          />

          <Instituciones
            actualizarMensaje={actualizarMensaje}
            modalModInstituciones={modalModInstituciones}
            mostrarModalModInstituciones={() => mostrarModalModInstituciones(false)}
            tituloEdi={'Editar Institución'}
            editarInstitucion={this.editarInstitucion}
            // opcion={opcion}
            id_institucionActual={id_institucionActual}
            institucionActual={convenio}
            obtenerInstituciones={this.obtenerInstituciones}
            opcion={'editar'}
            multiple={false}
          />

          <RedCrear modalEdiIes={modalEdiIes} handleCloseModal={() => mostrarModalEdiIes(false)} titulo={'Editar red académica'} editarRedAcademica={this.editarRedAcademica} id_institucionActual={id_institucionActual} editar={true}/>

        </>
      )
    }



  }

  Reporte = () => {
    let { cargando, solicitudes_detallada, tipoSolicitud } = this.state;
    let datos = [], titulos = [];
    if (tipoSolicitud == 'Mov_Est_Int') {
      titulos = [
        { title: "", width: { wch: 1.5 } },
        { title: "Identificación", width: { wch: 11 } },
        { title: "Apellidos", width: { wch: 17 } },
        { title: "Nombre", width: { wch: 13 } },
        { title: "Correo", width: { wch: 20 } },
        { title: "Correo personal", width: { wch: 22 } },
        { title: "Celular", width: { wch: 11 } },
        { title: "Fecha de nacimiento", width: { wch: 10 } },
        { title: "Sexo", width: { wch: 9 } },
        { title: "Dirección de residencia", width: { wch: 19 } },
        { title: "Ciudad de residencia", width: { wch: 17 } },
        { title: "Nacionalidad", width: { wch: 11 } },
        { title: "Convocatoria", width: { wch: 87 }, },
        { title: "Tipo de movilidad", width: { wch: 21 }, },
        { title: "Programa académico", width: { wch: 26 }, },
        { title: "Semestre actual", width: { wch: 8 } },
        { title: "Promedio acumulado", width: { wch: 9 } },
        { title: "Universidad opción 1", width: { wch: 46 } },
        { title: "Universidad opción 2", width: { wch: 46 } },
        { title: "Universidad opción 3", width: { wch: 10 } },
        { title: "Universidad opción 4", width: { wch: 10 } },
      ]
      solicitudes_detallada.map(item => {
        datos.push([
          { value: "" },
          { value: item.usuario_registro.identificacion },
          { value: `${item.usuario_registro.primer_apellido} ${item.usuario_registro.segundo_apellido}` },
          { value: `${item.usuario_registro.primer_nombre} ${item.usuario_registro.segundo_nombre}` },
          { value: item.usuario_registro.correo },
          { value: item.usuario_registro.correo_personal },
          { value: item.usuario_registro.celular },
          { value: item.usuario_registro.fecha_nacimiento },
          { value: item.usuario_registro.genero.nombre },
          { value: item.usuario_registro.direccion },
          { value: item.usuario_registro.lugar_residencia },
          { value: item.usuario_registro.nacionalidad },
          { value: item.convocatoria.nombre_convocatoria },
          { value: item.tipo_movilidad.nombre },
          { value: item.programa_academico.nombre },
          { value: item.semestre_actual },
          { value: item.promedio_acumulado },
          { value: item.universidad_1.nombre },
          { value: item.universidad_2 ? item.universidad_2.nombre : 'N/A' },
          { value: item.universidad_3 ? item.universidad_3.nombre : 'N/A' },
          { value: item.universidad_4 ? item.universidad_4.nombre : 'N/A' },
        ]);
      });
    } else if (tipoSolicitud == 'Reg_Mov_Int') {
      let instituciones = solicitudes_detallada.map(solicitud => solicitud.instituciones.map(institucion => institucion.nombre).join(', '));
      titulos = [
        { title: "", width: { wch: 1.5 } },
        { title: "Identificación", width: { wch: 11 } },
        { title: "Apellidos", width: { wch: 17 } },
        { title: "Nombre", width: { wch: 13 } },
        { title: "Correo", width: { wch: 20 } },
        { title: "Tipo de movilidad", width: { wch: 27 } },
        { title: "Modalidad", width: { wch: 9 } },
        { title: "Tipo de actividad", width: { wch: 17 } },
        { title: "Instituciones", width: { wch: 85 } },
        { title: "Duración (horas)", width: { wch: 13 } },
        { title: "Fecha inicial", width: { wch: 10 } },
        { title: "Fecha final", width: { wch: 10 } },
        { title: "Facultad o departamento responsable", width: { wch: 28 } },
        { title: "Nivel educativo", width: { wch: 20 } },
        // { title: "Programa responsable", width: {wch: 22} },
        { title: "Ud. administrativa responsable", width: { wch: 25 } },
        { title: "Titulo del evento o tema tratado", width: { wch: 25 } },
        { title: "Descripción de la actividad", width: { wch: 24 } },
        { title: "Descripción de los resultados obtenidos", width: { wch: 22 } },
        { title: "¿Hubo financiación de la actividad?", width: { wch: 10 } },
      ]
      solicitudes_detallada.map((item, i) => {
        datos.push([
          { value: "" },
          { value: item.usuario_registro.identificacion },
          { value: `${item.usuario_registro.primer_apellido} ${item.usuario_registro.segundo_apellido}` },
          { value: `${item.usuario_registro.primer_nombre} ${item.usuario_registro.segundo_nombre}` },
          { value: item.usuario_registro.correo },
          { value: item.tipo_movilidad.nombre },
          { value: item.modalidad.nombre },
          { value: item.tipo_actividad.nombre },
          { value: instituciones[i] ? instituciones[i] : 'Sin instituciones' },
          { value: item.horas },
          { value: item.fecha_inicio },
          { value: item.fecha_final },
          { value: item.facultad_departamento.nombre },
          { value: item.nivel_educativo ? item.nivel_educativo.nombre : 'N/A' },
          // { value: item.programa_responsable ? item.programa_responsable.nombre : 'N/A' },
          { value: item.ud_administrativa_responsable ? item.ud_administrativa_responsable.nombre : 'N/A' },
          { value: item.titulo_tema ? item.titulo_tema : '' },
          { value: item.descripcion_actividad ? item.descripcion_actividad : '' },
          { value: item.resultados_descripcion ? item.resultados_descripcion : '' },
          { value: item.es_financiado == 1 ? 'SÍ' : 'NO' },
        ]);
      });
    }
    titulos.map(item => item.style = { font: { sz: "11", bold: true, name: 'Arial Narrow' }, alignment: { vertical: "center", horizontal: "center", wrapText: true } });
    const multiDataSet = [{
      columns: titulos,
      data: datos
    }];
    return (
      <div>
        {
          cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true} /> :
            <ExcelFile
              filename={tipoSolicitud == 'Mov_Est_Int' ? "Movilidades Estudiantiles" : tipoSolicitud == 'Reg_Mov_Int' && "Registro de Movilidades"}
              element={<TareasTerminadas mensaje={`Descargar Informe`} marginTop='7%' imagen={download} widthImg="7%" />}
            >
              <ExcelSheet
                dataSet={multiDataSet}
                name={tipoSolicitud == 'Mov_Est_Int' ? "Movilidades Estudiantiles" : tipoSolicitud == 'Reg_Mov_Int' && "Registro de Movilidades"}
              />
            </ExcelFile>
        }
      </div>
    );
  }

  vista = () => {
    let { seleccion } = this.state;
    return (
      <>
        {seleccion === 'procesos' && this.renderProcesos()}
        {seleccion === 'administrar' && this.pintarAdministrar()}
      </>
    );
  }

  render() {
    let { administrador, cargando, seleccion, lider, buscarUniversidad, nombreUniversidadBuscar } = this.state;
    return (
      <div>
        <MenuGestionInter administrador={administrador || lider} seleccionar={((seleccion) => this.setState({ seleccion }))} sele={seleccion} />
        <div className='contenido_modulo'>
          {!cargando ? this.vista() : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />}
        </div>
        {
          buscarUniversidad && nombreUniversidadBuscar &&
          <AgregarValor
            modalOpen={buscarUniversidad}
            mostrarModal={(modal) => this.setState({ buscarUniversidad: modal })}
            nombreModal="Universidades"
            datos={[]}
            opciones={true}
            buscar={true}
            fila_principal="nombre"
            listaSeleccionados={[]}
            seleccionarVarios={false}
            seleccionar={() => { }}
            quitar={() => { }}
            obtenerDatos={this.buscarUniversidades}
            listMode={true}
          />
        }

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let { usuario } = state.redGlobal;
  let { convocatoria, institucion, modalAddFiltros, modalModAdjuntos, modalAprobarAdjunto,
    modalNegarAdjunto, modalDetSolicitud, modalGestionarSolicitud, requisito, modalListaUniversidades, modalAddConvenio, modalEdiConvenio, modalAddPermisosDep, modalModInstituciones, modalEdiIes } = state.redInternacionalizacion;
  return {
    usuario, convocatoria, institucion, requisito, modalGestionarSolicitud,
    modalDetSolicitud, modalAddFiltros, modalModAdjuntos, modalAprobarAdjunto, modalNegarAdjunto,
    modalListaUniversidades, modalAddConvenio, modalEdiConvenio, modalAddPermisosDep, modalModInstituciones, modalEdiIes
  }
}

const mapDispatchToProps = {
  mostrarModalAddConvenio, actualizarMensaje, mostrarModulo, actualizarDataConvocatoria, actualizarDataInstitucion, actualizarDataRequisito,
  mostrarModalGestionarSolicitud, mostrarModalDetSolicitud, mostrarModalAddFiltros, mostrarModalModAdjuntos, mostrarModalAprobarAdjunto,
  mostrarModalNegarAdjunto, mostrarModalListaUniversidades, mostrarModalEdiConvenio, mostrarModalAddPermisosDep, mostrarModalModInstituciones, mostrarModalEdiIes
}

AdministrarInternacionalizacion.propTypes = {
  // variables
  convocatoria: PropTypes.object.isRequired,
  institucion: PropTypes.object.isRequired,
  requisito: PropTypes.object.isRequired,
  modalListaUniversidades: PropTypes.bool.isRequired,
  modalAddConvenio: PropTypes.bool.isRequired,
  modalEdiConvenio: PropTypes.bool.isRequired,
  modalModInstituciones: PropTypes.bool.isRequired,

  // funciones
  mostrarModulo: PropTypes.func.isRequired,
  actualizarDataConvocatoria: PropTypes.func.isRequired,
  actualizarDataInstitucion: PropTypes.func.isRequired,
  actualizarDataRequisito: PropTypes.func.isRequired,
  mostrarModalDetSolicitud: PropTypes.func.isRequired,
  mostrarModalAddFiltros: PropTypes.func.isRequired,
  mostrarModalModAdjuntos: PropTypes.func.isRequired,
  mostrarModalAprobarAdjunto: PropTypes.func.isRequired,
  mostrarModalNegarAdjunto: PropTypes.func.isRequired,
  mostrarModalListaUniversidades: PropTypes.func.isRequired,
  mostrarModalAddConvenio: PropTypes.func.isRequired,
  mostrarModalEdiConvenio: PropTypes.func.isRequired,
  mostrarModalAddPermisosDep: PropTypes.func.isRequired,
  mostrarModalModInstituciones: PropTypes.func.isRequired,
  mostrarModalEdiIes: PropTypes.func.isRequired,

}

export default connect(mapStateToProps, mapDispatchToProps)(AdministrarInternacionalizacion);
