import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Grow,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import {
  actualizarMensaje,
  mostrarModulo,
} from "../../../redux/actions/actGlobal";
import AppBarModal from "../../general/AppBarModal";
import { BtnForm, BtnArchivo } from "../../general/BotonesAccion";
import { consulta, obtenerValoresFiltros } from "../../../global/js/funciones";
import { ESTADOS_GENERALES, ITEMS_PROCESOS } from "../helper";
import TituloAccion from "../../general/TituloAccion";
import ListarActividades from "./ListarActividades";
import emma_w from "./../../../global/imagenes/emma_w.png";
import emma_s1 from "./../../../global/imagenes/emma_s1.png";
import TareasTerminadas from "../../general/TareasTerminadas";
import cargando from "../../general/app/Cargando";


class SisefFiltroDesempeno extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seleccion: "inicio",
      titulo: "inicio",
      categoria: "",
      tipo: "",
      id_corte: "",
      grupos: [],
      unidades: [],
      asignaturas: [],
      indicadores: [],
      actividades: [],
      unidades: [],
      estados_estudiantes: [],
      items_procesos: [],
      periodo_actual: {},
      estado_est: "",
      asignatura: "",
      grupo: "",
      unidad: "",
      indicador: "",
      tipo_calificacion: "",
      cargando: true,
      grid_indicador: false,
      modal_filtro: false,
      sw: false,
      sw_calificado: false,
    };
  }

  async componentDidMount() {
    this.setState({ cargando: false });
    this.obtenerDatosIniciales();
  }

  async componentDidUpdate({}, { unidad }) {
    //  if (unidad !== this.state.unidad && this.state.unidad) this.cargarIndicadores(unidad);
  }

  async BuscarSyllabus(asignatura) {
    let { periodo_actual } = this.state;
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/syllabus/${asignatura}/${periodo_actual}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async BuscarUnidades(id) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/listar_und_formacion/${id}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async BuscarIndicadores(id) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/listar_indicadores/${id}/ind_dsp`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async cargarUnidades(id) {
    let syllabus = await this.BuscarSyllabus(id);
    if (syllabus.length != 0) {
      console.log(syllabus[0]);
      let unidades = await this.BuscarUnidades(syllabus[0].id);
      if (unidades.length != 0) {
        this.setState({ unidades: unidades });
      } else {
        this.setState({ unidades: [], unidad: {} });
      }
    } else this.setState({ unidades: [], unidad: {}, grid_indicador: false });
  }

  async cargarIndicadores(id) {
    let ind = await this.BuscarIndicadores(id);
    if (ind.length != 0) {
      this.setState({ indicadores: ind, indicador: "", grid_indicador: true });
    } else {
      this.setState({ indicadores: [], indicador: "", grid_indicador: false });
    }
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true });
    let mensaje = "Usted aún no cuenta con un proceso";
    let data_periodo = await obtenerValoresFiltros([
      { llave: "codigo", valor: "Per_Sisef" },  { llave: 'estado', valor: '1' }
    ]);
    let periodo_actual = data_periodo[0].nombre;
    let estados_estudiantes = await obtenerValoresFiltros([
      { llave: "generica", valor: ESTADOS_GENERALES },
      { llave: "valora", valor: "calificacion" },
      { llave: "estado", valor: "1" },
    ]);
    console.log(estados_estudiantes);
    let items_procesos = await obtenerValoresFiltros([
      { llave: "generica", valor: ITEMS_PROCESOS },
      { llave: "estado", valor: "1" },
    ]);
    this.obtenerProcesos(periodo_actual);
    this.setState({
      mensaje,
      cargando: false,
      periodo_actual: periodo_actual,
      estados_estudiantes,
      items_procesos,
    });
  }

  async ObtenerAsignaturas(periodo) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/docente_asignaturas/${0}/${periodo}/${0}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async obtenerActividades(periodo, asignatura) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/listar_actividades_all/${asignatura}/${periodo}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async obtenerActividadesFiltro(
    grupo,
    unidad,
    indicador,
    asignatura,
    periodo,
    cargando,
  ) {
    return new Promise((resolve) => {
      this.setState({ cargando: false });
      consulta(
        `api/v1.0/sisef/listar_actividades_filtro/${grupo}/${unidad}/${indicador}/${asignatura}/${periodo}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async obtenerGrupos(data_asignatura, periodo) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/grupos/${data_asignatura.codigo}/${periodo}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async BuscarUnidadesAll(asignatura, periodo) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/unidades_formacion_all/${asignatura}/${periodo}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  CargarActividades = async (periodo, asignatura) => {
    let actividades = await this.obtenerActividades(periodo, asignatura);
    this.setState({ actividades: actividades });
  };

  async obtenerProcesos(periodo) {
    this.setState({ cargando: false });
    let { data_asignatura } = this.props;
    let extra = await obtenerValoresFiltros([
      { llave: "codigo", valor: "sisef_todos" },  { llave: 'estado', valor: '1' }
    ]);
    let todos = {
      codigo: extra[0].codigo,
      nombre: extra[0].nombre,
    };

    this.CargarActividades(periodo, data_asignatura.codigo);
    let unidades = await this.BuscarUnidadesAll(
      data_asignatura.codigo,
      periodo
    );
    let data_grupos = await this.obtenerGrupos(data_asignatura, periodo);
    if (data_grupos.grupos.length > 1) {
      data_grupos.grupos.push(todos);
    }
    this.setState({
      grupos: data_grupos.grupos,
      unidades: unidades,
    });
  }

  RefrescarActividades = async () => {
    let { data_asignatura } = this.props;
    let { periodo_actual } = this.state;
    let actividades = await this.obtenerActividades(
      periodo_actual,
      data_asignatura.codigo
    );
    console.log(actividades);
    this.setState({
      actividades: actividades,
    });
  };

  getError(valor) {
    return valor ? true : false;
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    if (name == "asignatura") {
      this.cargarUnidades(value);
      this.setState({ asignatura: value, unidad: {} });
    } else if (name == "unidad") {
      let { unidades } = this.state;
      this.cargarIndicadores(value);
      let unidad_actual = unidades.filter((und) => und.id == value);
      let corte = unidad_actual[0].corte.codigo;
      console.log(corte);
      this.setState({
        unidad: value,
        id_corte: corte,
      });
    } else if (name == "estado_est") {
      if (value == "sisef_valorados") {
        this.setState({ sw_calificado: true, estado_est: value });
      } else {
        this.setState({
          sw_calificado: false,
          estado_est: value,
          tipo_calificacion: "",
        });
      }
    }
  };

  Filtrar = async (e) => {
    let { grupo, unidad, indicador, periodo_actual } = this.state;
    let { data_asignatura, actualizarMensaje } = this.props;
    grupo = grupo == "" ? 0 : grupo;
    unidad = unidad == "" ? 0 : unidad;
    indicador = indicador == "" ? 0 : indicador;
    if (grupo == 0 && unidad == 0 && indicador == 0) {
      let titulo = "Seleccione alguno de los campos";
      let tipo = "info";
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    } else {
      let activity = await this.obtenerActividadesFiltro(
        grupo,
        unidad,
        indicador,
        data_asignatura.codigo,
        periodo_actual
      );
      this.setState({
        actividades: activity,
        modal_filtro: false,
        grupo: "",
        unidad: "",
        indicador: "",
      });
    }
  };

  FiltrarEst = async (e) => {
    this.setState({ cargando: true });
    let { grupo, tipo_calificacion, estado_est } = this.state;
    let { cerrarFiltroEstudiante, actualizarMensaje, filtrar_estudiantes } =
      this.props;
    grupo = grupo == "" ? 0 : grupo;
    tipo_calificacion = tipo_calificacion == "" ? 0 : tipo_calificacion;
    estado_est = estado_est == "" ? 0 : estado_est;
    if (grupo == 0 && estado_est == 0) {
      let titulo = "Seleccione alguno de los campos";
      let tipo = "info";
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    } else {
      filtrar_estudiantes(grupo, tipo_calificacion, estado_est);
    }
  };

  handleRadioChange = ({ target }) => {
    this.setState({
      indicador: target.value,
      sw: true,
    });
  };

  render() {
    let {
      periodo_actual,
      asignatura,
      grupo,
      unidades,
      grupos,
      indicador,
      indicadores,
      actividades,
      sw,
      id_corte,
      modal_filtro,
      estados_estudiantes,
      sw_calificado,
      items_procesos,
      cargando,
    } = this.state;
    let {
      Modal,
      FiltroEstudiantes,
      cerrarModal,
      cerrarFiltroEstudiante,
      actualizarMensaje,
      listar_estudiantes,
      data_asignatura,
    } = this.props;
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={Modal}
          onClose={() => cerrarModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <AppBarModal
            titulo={
              "ACTIVIDADES DE " + `${data_asignatura.nombre.toUpperCase()}`
            }
            mostrarModal={() => cerrarModal(false)}
            titulo_accion="Califilicaciones"
            accion=""
          />
          <DialogContent className="scroll">
          {
            cargando ? 
            <TareasTerminadas 
            mensaje='Cargando..' 
            marginTop='7%' imagen={emma_w}
            widthImg="7%" /> :
            <ListarActividades
              indicador={indicador}
              periodo={periodo_actual}
              grupo={grupo}
              id_corte={id_corte}
              asignatura={asignatura}
              actividades_data={actividades}
              actualizarMensaje={actualizarMensaje}
              RefrescarActividades={this.RefrescarActividades}
              CargarActividades={this.CargarActividades}
              ModalFiltro={sw}
              SetModalFiltro={(resp) => this.setState({ modal_filtro: resp })}
              listar_estudiantes={listar_estudiantes}
            />
          }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CERRAR" callback={() => cerrarModal(false)} />
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={FiltroEstudiantes}
          onClose={() => cerrarFiltroEstudiante(false)}
          aria-labelledby="form-dialog-title"
        >
          <AppBarModal
            titulo={"FILTRO DE ESTUDIANTES "}
            mostrarModal={() => cerrarFiltroEstudiante(false)}
            titulo_accion="Estudiantes"
            accion=""
          />
          <DialogContent className="scroll">
            {
          cargando ? 
          <TareasTerminadas mensaje='Cargando..'
           marginTop='7%' 
           imagen={emma_w} 
           widthImg="7%" /> :

            <ValidatorForm>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel id="select_grupo">Grupo</InputLabel>
                    <Select
                      labelId="select_grupo"
                      id="grupo"
                      fullWidth
                      inputProps={{ min: "0", step: "1" }}
                      onChange={(e) => this.setState({ grupo: e.target.value })}
                      name="grupo"
                    >
                      {grupos?.map(({ nombre, codigo }) => (
                        <MenuItem key={codigo} value={codigo}>
                          {`${nombre}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    className="form-control" /*error={this.conError(programa.error)}*/
                  >
                    <InputLabel htmlFor="parametro">Estado</InputLabel>
                    <Select
                      id="estado_est"
                      onChange={this.onChange}
                      name="estado_est"
                    >
                      {estados_estudiantes?.map(({ nombre, id, codigo }) => (
                        <MenuItem key={id} value={codigo}>
                          {`${nombre}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {sw_calificado ? (
                    <FormControl className="form-control">
                      <InputLabel htmlFor="parametro">Calificación</InputLabel>
                      <Select
                        id="tipo_calificacion"
                        onChange={(e) =>
                          this.setState({ tipo_calificacion: e.target.value })
                        }
                        name="tipo_calificacion"
                      >
                        {items_procesos?.map(({ nombre, codigo, id }) => (
                          <MenuItem key={id} value={nombre}>
                            {`${nombre}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </ValidatorForm>
            }
          </DialogContent>
          <DialogActions>
            <BtnForm
              texto="CERRAR"
              callback={() => cerrarFiltroEstudiante(false)}
            />
            <BtnForm texto="FILTRAR" callback={this.FiltrarEst} />
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={modal_filtro}
          onClose={() =>
            this.setState({
              modal_filtro: false,
              grupo: "",
              unidad: "",
              indicador: "",
            })
          }
          aria-labelledby="form-dialog-title"
        >
          <AppBarModal
            titulo="FILTRO DE ACTIVIDADES"
            mostrarModal={() =>
              this.setState({
                modal_filtro: false,
                grupo: "",
                unidad: "",
                indicador: "",
              })
            }
            titulo_accion="Calificaciones"
            accion=""
          />
          <DialogContent className="scroll">
            {
          cargando ? 
          <TareasTerminadas 
           mensaje='Cargando..'
           marginTop='7%' 
           imagen={emma_w} 
           widthImg="7%" /> :

            <ValidatorForm>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel id="select_grupo">Grupo</InputLabel>
                    <Select
                      labelId="select_grupo"
                      id="grupo"
                      fullWidth
                      inputProps={{ min: "0", step: "1" }}
                      onChange={(e) => this.setState({ grupo: e.target.value })}
                      name="grupo"
                    >
                      {grupos?.map(({ nombre, codigo }) => (
                        <MenuItem key={codigo} value={codigo}>
                          {`${nombre}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    className="form-control" /*error={this.conError(programa.error)}*/
                  >
                    <InputLabel htmlFor="parametro">Unidad</InputLabel>
                    <Select id="unidad" onChange={this.onChange} name="unidad">
                      {unidades?.map(({ nombre, id, corte }) => (
                        <ListItem button key={id} value={id}>
                          <ListItemText
                            primary={`${nombre}`}
                            secondary={`${corte.nombre}`}
                          />
                        </ListItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="parametro">Indicador</InputLabel>
                    <Select
                      id="indicador"
                      onChange={(e) =>
                        this.setState({ indicador: e.target.value })
                      }
                      name="indicador"
                    >
                      {indicadores?.map(({ info, id }) => (
                        <MenuItem key={id} value={id}>
                          {`${info}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </ValidatorForm>
            }
          </DialogContent>
          <DialogActions>
            <BtnForm
              texto="CERRAR"
              callback={() =>
                this.setState({
                  modal_filtro: false,
                  grupo: "",
                  unidad: "",
                  indicador: "",
                })
              }
            />
            <BtnForm texto="FILTRAR" callback={this.Filtrar} />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let { usuario } = state.redGlobal;
  let { ModalAdd } = state.redSisef;
  return { usuario, ModalAdd };
};

const mapDispatchToProps = {
  mostrarModulo,
  actualizarMensaje,
};

SisefFiltroDesempeno.propTypes = {
  //Funciones
  ModalAdd: PropTypes.bool.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SisefFiltroDesempeno);
