import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemSecondaryAction, ListItem, ListItemText,Checkbox, ListItemAvatar, Typography, Avatar, List, AppBar, Toolbar, IconButton, Fab, InputBase, ListSubheader, Button, Badge, Hidden, Tooltip, Grid } from '@material-ui/core'
import ReactExport from "react-export-excel";
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s1 from '../../global/imagenes/emma_s1.png';
import user from '../../global/imagenes/user_list.png';
import TareasTerminadas from './TareasTerminadas';
import { BtnAcciones } from './BotonesAccion';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SearchIcon from '@material-ui/icons/Search';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import HelpIcon from '@material-ui/icons/Help';
import GetAppIcon from '@material-ui/icons/GetApp';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import FilterListIcon from '@material-ui/icons/FilterList';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Popover from '@material-ui/core/Popover';
import { coloresEmma } from '../../global/js/funciones';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import moment from 'moment'
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(theme => ({
  appBar: {
    width: '100%',
    backgroundColor: 'white',
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'fixed',
    // bottom: "70px",
    left: "45%",
    zIndex: 1000,
    top: '83%',
    [theme.breakpoints.up('sm')]: {
      top: '89%',
      left: "50%",
    },
    // margin: '0 auto',

  },
  title: {
    margin: '0 auto',
    color: '#757575',
    display: 'none',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    height: 40,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: coloresEmma.searchcolor,
    '&:hover': {
      backgroundColor: coloresEmma.serchover,
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: 40,
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: coloresEmma.secondarycolor,
  },
  inputRoot: {
    // color: '#9e9e9e',
    // fontWeight: 'none',
    height: 30,
  },
  inputInput: {
    height: 40,
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  IconButton: {
    marginLeft: 5,
  }

}));
 



function Opciones({ titulo, opcionAdicional, actDescargar ,dato_buscar, setBuscar, descargar, alertas, filtrar, actfiltrar, id, refrescar, buscar,checkbox, actcheckbox, cambiarestado, actcambiarestado, mostrarcheckboxes,actmostrarcheckboxes, actRefrescar, actAlertas, NumAlertas, accionesList, ayuda, actAyuda, opcionCalificacion, actCalificar, }) {
  const classes = useStyles();
  let isFunction = (functionToCheck) => functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'; 
  return (
    <AppBar position='static' className={classes.appBar} elevation={0} id='appBar_listar_datos'>
      <Toolbar>
        <Typography variant="subtitle2" className={classes.title}>
          {titulo}
        </Typography>
        <div className={classes.grow} />
        {
          buscar &&
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase

              name='dato_buscar'
              value={dato_buscar}
              onChange={({ target }) => setBuscar(target.value)}
              placeholder="Buscar"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        }
         {cambiarestado&&
         <Tooltip title='Cambiar estados' aria-label="add" onClick={() => actcambiarestado()} >
           <IconButton edge="end" color="secondary" >
              <CachedRoundedIcon />
            </IconButton>
         </Tooltip>
        }
{
          mostrarcheckboxes&&
          <Tooltip title='Seleccionar' aria-label="add" onClick={() => actmostrarcheckboxes()} >
            <IconButton edge="end" color="secondary" >
              <PlaylistAddCheckIcon />
            </IconButton>
          </Tooltip>
        }
        {
          refrescar &&
          <Tooltip title='Refrescar' aria-label="add" onClick={() => actRefrescar()} >
            <IconButton edge="end" color="secondary" >
              <RotateLeftIcon />
            </IconButton>
          </Tooltip>
        }
        {
          filtrar &&
          <Tooltip title='Filtrar' aria-label="add" onClick={() => actfiltrar()} >
            <IconButton edge="end" color="secondary" > <FilterListIcon /></IconButton>
          </Tooltip>
        }
        {
          alertas &&
          <Tooltip title='Alertas' aria-label="add" onClick={() => actAlertas()} >
            <Badge badgeContent={NumAlertas} color="secondary">
              <IconButton edge="end" color="secondary" >
                <NotificationImportantIcon />
              </IconButton>
            </Badge>
          </Tooltip>
        }
        {
          (descargar && document.getElementById(id)) &&
          <Tooltip title='Descargar' aria-label="add" onClick={() =>  document.getElementById(id).click()}  >
            <IconButton edge="end" color="secondary">
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        }
        {
        checkbox&&
        <Tooltip title="Seleccionar todos" aria-label="select"onClick={() => actcheckbox()} >
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon />}       
          checkedIcon={<CheckBoxIcon />}       
          color="secondary"                     
        />
      </Tooltip>
        }
        {  
          isFunction(accionesList) && accionesList()
        }
        {
          ayuda &&
          <Tooltip title='Ayuda' aria-label="add" onClick={() => actAyuda()} >
            <Badge badgeContent={NumAlertas} color="secondary">
              <IconButton edge="end" color="secondary" >
                <HelpIcon />
              </IconButton>
            </Badge>
          </Tooltip>
        }
        {
          opcionAdicional &&
          <Tooltip title='Mi Gestion' aria-label="add" onClick={() => actDescargar()} >
            <IconButton edge="end" color="secondary" > 
              <FilterNoneIcon/>
            </IconButton>
          </Tooltip>
        }
        {opcionCalificacion && (
          <Tooltip
            title="Calificar selección"
            aria-label="add"
            onClick={() => actCalificar()}
          >
            <IconButton edge="end" color="secondary">
              <CheckCircleIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    </AppBar>
  )
}

function AccionesApp({ acciones }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <BtnAcciones callback={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div>
          {acciones()}
        </div>
      </Popover>
    </div>
  );
}

function Agregar({ actAgregar, iconoFab }) {
  const classes = useStyles();
  return (
    <Fab color="secondary" aria-label="add" className={classes.fabButton} onClick={() => actAgregar()}>
      {iconoFab()}
    </Fab>

  )
}

class ListarDatos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dato_buscar: '',
      registros: [],
      seleccionado : -1
    }
  }

  componentDidMount() {
    let { datos } = this.props;
    this.setState({ registros: datos })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  descargar = () => {
    let { registros } = this.state;
    let { descargar: name, filas, filasDescarga, id } = this.props;
    // let datos = await this.data_descargar();
    return (
      <ExcelFile filename={`${name}-${moment().format('YYYY-MM-DD, h:mm a')}`} element={<button className='oculto' id={id} />}>
        <ExcelSheet data={registros} name='datos'>
          {filas.map(({ mostrar = '', nombre = '', id, descargar = true }, i) => descargar && <ExcelColumn key={i} label={nombre ? nombre : id} value={(row) => mostrar ? mostrar(row) : row[id]} />)}
        </ExcelSheet>
      </ExcelFile>
    );
  }


  buscar = row => {
    let { dato_buscar } = this.state;
    let { filas, fila_principal } = this.props;
    let resp = 0;
    let dato = this.isFunction(fila_principal) ? fila_principal(row) : row[fila_principal];
    let existe = this.convertir(dato).normalize('NFD').includes(this.convertir(dato_buscar).normalize('NFD'));
    // let existe = dato.normalize('NFD').includes(dato_buscar.normalize('NFD').toUpperCase());
    existe ? resp++ :
      filas.map(({ mostrar = '', id, enLista = true }) => {
        if (enLista) {
          dato = mostrar ? mostrar(row) : row[id];
          existe = this.convertir(dato).normalize('NFD').includes(this.convertir(dato_buscar).normalize('NFD'));
          // existe = dato.normalize('NFD').includes(dato_buscar.normalize('NFD').toUpperCase());
          existe && resp++;
        }
        return true;
      });

    return resp;
  }

  data_encontrados = datos => {
    let total = 0;
    datos.map((row) => { total += this.buscar(row) })
    return total;
  }

  convertir = dato => typeof dato === 'string' || dato instanceof String ? dato.toLowerCase() : `${dato}`;

  data_descargar = () => {
    return new Promise(resolve => {
      let { dato_buscar } = this.state;
      let { datos } = this.props;
      let encontrados = [];
      datos.map((row, i) => {
        let existe = true;
        if (dato_buscar) existe = this.buscar(row);
        if (existe) encontrados.push(row)
        return true;
      })
      resolve(encontrados);
    });

  } 

  isFunction = (functionToCheck) => functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';

  ClickRow = (row, i) => {
    let { accion_general } = this.props
    this.setState({seleccionado: i})
    accion_general(row, i)
  }


  render() {
    let { actDescargar, actCargar, opcionAdicional, iconoFab, accionApp, cambiarestado, actcambiarestado, checkbox, actcheckbox, mostrarcheckboxes, actmostrarcheckboxes, refrescar, actRefrescar, NumAlertas, actAlertas, alertas, cargando, actBuscar, datoBuscarAlt, avatarImg, actAgregar, agregar, opciones, actfiltrar, filtrar, descargar, datos, titulo, buscar, fila_principal, filas, avatar, color_avatar, acciones, css, accion_general, mensajeVacio, id, ayuda, actAyuda, accionesList, espacioTexto, opcionCalificacion, actCalificar, } = this.props;
    let { dato_buscar, seleccionado } = this.state;
    let encontrado = false;
    let encontrados = (dato_buscar) ? this.data_encontrados(datos) : datos.length

    return (
      <div id='contenido_listar_datos'>
        {
          <>
            {descargar ? this.descargar() : ''}
            <List style={css.list && css.list} className='scroll' subheader={<ListSubheader component="div" style={{ backgroundColor: 'white', borderBottom: '1px solid #eeeeee' }}>
              <div>
                {opciones && <Opciones opcionAdicional={opcionAdicional} actDescargar={ actDescargar} cambiarestado= {cambiarestado} actcambiarestado = {actcambiarestado} checkbox ={checkbox} actcheckbox= {actcheckbox} mostrarcheckboxes= {mostrarcheckboxes}  actmostrarcheckboxes={actmostrarcheckboxes} refrescar={refrescar} actRefrescar={actRefrescar} titulo={`${titulo} - Mostrando ${encontrados} registros de un total de ${datos.length}.`} NumAlertas={NumAlertas} actAlertas={actAlertas} alertas={alertas} css={css} actAgregar={actAgregar} buscar={buscar} setBuscar={(dato_buscar) => this.isFunction(actBuscar) ? actBuscar(dato_buscar) : this.setState({ dato_buscar })} dato_buscar={this.isFunction(actBuscar) ? datoBuscarAlt : dato_buscar} total={datos.length} descargar={descargar} id={id} filtrar={filtrar} actfiltrar={actfiltrar} agregar={agregar} ayuda={ayuda} actAyuda={actAyuda} accionesList={accionesList} opcionCalificacion={opcionCalificacion} actCalificar={actCalificar} />}
              </div>
            </ListSubheader>}>
              {datos.length === 0 || cargando ? <TareasTerminadas imagen={cargando ? emma_w : emma_s1} mensaje={!cargando ? mensajeVacio : 'Cargando..'} widthImg='7%' marginTop='7%' cargando={cargando} /> :
                <div> {datos.map((row, i) => {
                  let existe = true;
                  if (dato_buscar) existe = this.buscar(row);
                  if (existe) {
                    encontrado = true;
                    return (
                      <div key={i}>
                        <ListItem onClick={() => this.ClickRow(row, i)} style={ seleccionado == i ? { backgroundColor: coloresEmma.select, borderBottom: '1px solid #eeeeee' } : { backgroundColor: 'white', borderBottom: '1px solid #eeeeee' }}>                          {
                            avatar &&
                            <ListItemAvatar>
                              {avatarImg ? <Avatar src={avatar(row, i)} onError={ (e) => { e.target.src = user }}/>:
                                <Avatar style={this.isFunction(color_avatar) ? color_avatar(row, i) : color_avatar}>{this.isFunction(avatar) ? avatar(row, i) : row[avatar]}</Avatar>
                              }
                            </ListItemAvatar>
                          }
                          <ListItemText primary={''}
                            secondary={
                              <React.Fragment>
                                <Typography key={-1} style={{ color: 'black' }} component="span" variant="body2" color="textSecondary" >{this.isFunction(fila_principal) ? fila_principal(row) : row[fila_principal]}<br /></Typography>
                                {filas.map(({ mostrar = '', nombre = '', id, enLista = true }, j) => (enLista && (mostrar ? mostrar(row, i) : row[id])) && <Typography key={j} component="span" variant="body2" color="textSecondary" >{nombre && `${nombre}`}{mostrar ? mostrar(row, i) : row[id]}<br /></Typography>)}
                              </React.Fragment>
                            }
                          />
                          <ListItemSecondaryAction onClick={() => this.setState({seleccionado: i})}>
                            {accionApp ? <>
                              {this.isFunction(acciones) && <Hidden xsDown>{acciones(row, i)}</Hidden>}
                              {this.isFunction(acciones) && <Hidden smUp><AccionesApp acciones={() => acciones(row, i)} /></Hidden>}
                            </> : this.isFunction(acciones) && acciones(row, i)}
                          </ListItemSecondaryAction>
                        </ListItem>
                      </div>
                    )
                  }
                  return true;
                })}
                  {!encontrado && dato_buscar ? <TareasTerminadas imagen={emma_s1} mensaje='No se encontraron datos' widthImg='7%' marginTop='7%' /> : ''}
                </div>}
              {
                agregar && <Agregar actAgregar={actAgregar} iconoFab={iconoFab} />
              }
            </List>
          </>
        }
      </div>
    )

  }
}

ListarDatos.propTypes = {
  //variables
  datos: PropTypes.array.isRequired,
  titulo: PropTypes.string,
  id: PropTypes.string.isRequired,
  sub_titulo: PropTypes.string,
  descargar: PropTypes.string,
  buscar: PropTypes.bool,
  checkbox:PropTypes.bool,
  cambiarestado:PropTypes.bool,
  mostrarcheckboxes:PropTypes.bool,
  filas: PropTypes.array.isRequired,
  color_avatar: PropTypes.func,
  css: PropTypes.object,
  accion_general: PropTypes.func,
}


ListarDatos.defaultProps = {
  titulo: '',
  sub_titulo: '',
  avatar: '',
  NumAlertas: 0,
  filtrar: false,
  alertas: false,
  ayuda: false,
  agregar: false,
  refrescar: false,
  checkbox:false,
  cambiarestado:false,
  mostrarcheckboxes:false,
  cargando: false,
  accionApp: true,
  opcionAdicional: false,
  actDescargar: () => '',
  actCargar: () => "",
  actfiltrar: () => '',
  actAlertas: () => '',
  actAyuda: () => '',
  actBuscar: '',
  datoBuscarAlt: '',
  actcambiarestado: () => '',
  actcheckbox: () => '',
  actmostrarcheckboxes: () => '',
  actAgregar: () => '',
  actRefrescar: () => '',
  iconoFab: () => <AddIcon />,
  opciones: false,
  avatarImg: false,
  datos: [],
  descargar: '',
  mensajeVacio: 'Esta bandeja esta vacía',
  buscar: true,
  color_avatar: () => ({ 'backgroundColor': coloresEmma.fondocolor, 'color': '#757575' }),
  css: {
    list: { padding: '0px 0px 0px 0px' },
    appBar: { padding: '0px 0px 0px 0px' },
  },
  acciones: '',
  accionesList: '',
  accion_general: () => '',
  espacioTexto: 12,
  opcionCalificacion: false,
  actCalificar: () => "",
};

/*
descargar :  Se le pasa el nombre que quiere que tome el archivo, si no se le pasa nombre se deshabilita la opción de descargar
datos :  Los datos que se mostraran 
titulo :  El titulo principal del contenedor, si no se le especifica oculta el contenedor del titulo
sub_titulo :  El titulo alterno
buscar :  Especifica si esta habilitado la opción de buscar, por defecto True
fila_principal :  El primer campo que sera mostrado en la lista(si requiere que la fila principal salga en el exporte debe agregarlo en la lista de filas con el item enLista = false)
filas : { 
Descripcion : array de campos que se quieren mostrar, en el orden que se agreguen seran listado, cada item es un JSON con la siguiente información { mostrar = '', nombre = '', id, enLista = true , descargar = true }
campos : {
  mostrar : cuando lo que se quiere mostrar no es una columna de la data primaria , se le deba pasar una funcion con lo que se quiere mostrar. la funcion recibe los datos por cada fila. por defecto = ''
  nombre : cuando se quiere mostrar el nombre de lo que se esta pintando en la lista se debe especificar. por defecto = ''. Ademas toma este nombre al descargar la info en caso contrario el id  
  id : nombre de la columna a pintar (obligatorio)
  enLista : especifica si se quiere mostrar en la lista o no
  descargar = especifica si el campo sera exportado. por defecto = true (trabajando en esta accion)
  }
}
avatar : especifica lo que se quiere mostrar en el avatar, puede ser el nombre de una columna o una funcion que pinte algo dependiendo de la data
color_avatar : una funcion que retorna el json con el estilo del avatar
acciones : la acciones que se mostraran, puede se una funcion que recibe una fila
css : el estilo del contenedor principal
*/


export default ListarDatos
