import { Dialog, DialogActions, DialogContent, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import React, { Component } from "react";
import AppBarModal from "../../general/AppBarModal";
import { BtnForm, BtnFormPage } from "../../general/BotonesAccion";
import { consulta, formulario, generarFiltros, getError, obtenerValoresFiltros, validateInputs } from "../../../global/js/funciones";
import { ACREDITACIONES, CODIGO_SUBTIPO_CONVENIO_OTRO, PAISES, PROGRAMAS, SUBTIPOS_CONVENIOS } from "../administrar/helper";
import { ValidatorForm } from "react-material-ui-form-validator";
import { use } from "react";
import InputBuscar from "../../general/InputBuscar";
import AgregarValor from "../ui/AgregarValor";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import { actualizarMensaje } from "../../../redux/actions/actGlobal";
import { Alert } from "@material-ui/lab";

class RedCrear extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cargando: false,
      errores: [],
      paises: [],
      subtipos: [],
      programas: [],
      nombre_red: "",
      pais_red: [],
      paises_sele: [],
      subtipo_convenio: [],
      otro_subtipo_convenio: "",
      objeto_red: "",
      telefono_enlace_vin: "",
      programas_red: [],
      programas_sele: [],
      modal_agg_pais: false,
      modal_agg_programa: false,
      dataConvenio: [],
      nombre_enlace_vin: "",
      email_enlace_vin: ""
    }
  }

  async componentDidMount() {
    this.setState({ cargando: true });
    await this.obtenerGenericas()
    this.setState({ cargando: false });
  }

  async componentDidUpdate({ modalAddRed, modalEdiIes }, { }) {
    let { modalAddRed: modalAddRedNew, modalEdiIes: modalEdiIesNew, id_institucionActual } = this.props;
    if (modalAddRed !== modalAddRedNew) {
      if (!modalAddRedNew) {
        this.setState({ nombre_red: "", pais_red: [], subtipo_convenio: [], otro_subtipo_convenio: "", programas_red: [], objeto_red: "" });
      }
    }
    if (modalEdiIes !== modalEdiIesNew) {
      if (modalEdiIesNew) {
        this.setState({ cargando: true });
        let inf_conevnio = [], convenio = [];
        convenio = await this.obtenerInfoSolicitud(id_institucionActual);
        inf_conevnio = await this.obtenerBorradorInfConvenio(id_institucionActual);
        this.cargarDatosBorrador(convenio, inf_conevnio)
        this.setState({ cargando: false });
      }
    }

  }

  obtenerGenericas = async () => {
    let paises = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PAISES }]);
    let subtipos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': SUBTIPOS_CONVENIOS }]);
    let programas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PROGRAMAS }]);
    this.setState({ subtipos, paises, programas });
  }

  async obtenerBorradorInfConvenio(id_convenio) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/internacionalizacion/solicitudes/${id_convenio}/obtener_pasoInfConvenio`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async obtenerInfoSolicitud(id) {
    return new Promise(async (resolve) => {
      let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
      consulta(
        `api/v1.0/internacionalizacion/solicitud/convenio?${f}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp[0] : null);
        }
      );
    });
  }


  async cargarDatosBorrador(datos, inf_conevnio) {
    let { institucion, otro_subtipo_convenio, pais_vinculante, programa_facultad_3, paises_red_academica, objeto_del_convenio, programas_red_academica, telefono_enlace_vin, email_enlace_vin, nombre_enlace_vin } = datos;
    let { TipoConvenio, Subtipo_convenio } = inf_conevnio;
    let helpTipoConvenio = [], helpSubtipo_convenio = [], helpPaises = [], helpProgramas = [];

    this.setState({
      nombre_red: institucion ? institucion : "",
      pais_red: pais_vinculante ? [{ id: pais_vinculante.id, nombre: pais_vinculante.nombre }] : [],
      otro_subtipo_convenio: otro_subtipo_convenio ? otro_subtipo_convenio : "",
      objeto_red: objeto_del_convenio ? objeto_del_convenio : "",
      telefono_enlace_vin: telefono_enlace_vin ? telefono_enlace_vin : "",
      email_enlace_vin: email_enlace_vin ? email_enlace_vin : "",
      nombre_enlace_vin: nombre_enlace_vin ? nombre_enlace_vin : ""
    });
    if (TipoConvenio.length > 0) {
      TipoConvenio.map((item) => {
        helpTipoConvenio.push(item.tipo_convenio_convenio.id);
      });
      this.setState({ tipo_convenio: helpTipoConvenio });
    }
    if (Subtipo_convenio.length > 0) {
      Subtipo_convenio.map((item) => {
        helpSubtipo_convenio.push(item.sub_tipo_convenio.id);
      });
      this.setState({ subtipo_convenio: helpSubtipo_convenio });
    }
    if (paises_red_academica.length > 0) {
      let selec = []
      paises_red_academica.map((item) => {
        helpPaises.push({ id: item.id, nombre: item.nombre });
        selec.push(item.id)
      });
      this.setState({ pais_red: helpPaises, paises_sele: selec });
    }
    if (programas_red_academica.length > 0) {
      let selec = []
      programas_red_academica.map((item) => {
        helpProgramas.push({ id: item.id, nombre: item.nombre });
        selec.push(item.id)
      });
      this.setState({ programas_red: helpProgramas, programas_sele: selec });
    }
  }

  handleChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  formularioRed = () => {
    const CHARACTER_LIMIT = 750;
    let { errores, paises, subtipos, modal_agg_pais, modal_agg_programa, programas, nombre_red, pais_red, subtipo_convenio, otro_subtipo_convenio, programas_red, objeto_red, paises_sele, programas_sele, telefono_enlace_vin, nombre_enlace_vin, email_enlace_vin } = this.state;

    return (
      <>
        <ValidatorForm>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "1%", marginBottom: "1%" }}
          >
            <Grid item xs={12}>
              <TextField
                value={nombre_red}
                required
                error={getError("nombre_red", errores).length > 0}
                helperText={getError("nombre_red", errores)}
                type="text"
                id="nombre_red"
                name="nombre_red"
                label="Nombre de la red académica"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth error={getError('pais_red', errores).length > 0}>
                <InputBuscar
                  label="País"
                  placeholder={"Seleccione el país"}
                  id="pais_red"
                  value={
                    pais_red.length > 0
                      ? pais_red
                        .map((r) => r.nombre)
                        .join(", ")
                      : ""
                  }
                  // callback={() => this.setState({ modal_agg_pais: true })}
                  callback={() => {
                    this.setState({ modal_agg_pais: true }, () => {
                      if (errores && errores.length > 0) {
                        this.setState({ errores: errores.filter(er => er.llave !== "pais_red") });
                      }
                    });
                  }}
                  icon_default={false}
                />
              </FormControl>
              <FormHelperText style={{ color: "#FF2D00" }}>{getError('pais_red', errores)}</FormHelperText>
              <AgregarValor
                modalOpen={modal_agg_pais}
                mostrarModal={(modal) => this.setState({ modal_agg_pais: modal })}
                nombreModal="Paises"
                datos={paises}
                opciones={true}
                buscar={true}
                fila_principal="nombre"
                listaSeleccionados={pais_red}
                seleccionarVarios={true}
                seleccionar={({ id, nombre }) => {
                  this.setState({
                    pais_red: [...pais_red, { id, nombre }],
                    paises_sele: [...paises_sele, id]
                  });
                }}
                quitar={({ id }) => {
                  this.setState({
                    pais_red: pais_red.filter((pais) => pais.id !== id),
                    paises_sele: paises_sele.filter((p) => p !== id)
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl
                className="form-control"
                required
                error={getError("subtipo_convenio", errores).length > 0}
              >
                <InputLabel id="subtipo_convenio_label">
                  Funciones misionales que impacta
                </InputLabel>
                <Select
                  labelId="subtipo_convenio_label"
                  id="subtipo_convenio"
                  name="subtipo_convenio"
                  value={subtipo_convenio}
                  fullWidth
                  multiple
                  onChange={this.handleChange}
                >
                  {subtipos
                    .sort(
                      ({ id: previousID }, { id: currentID }) =>
                        previousID - currentID
                    )
                    .map((tipo) => (
                      <MenuItem key={tipo.id} value={tipo.id}>
                        {tipo.nombre}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  {getError("subtipo_convenio", errores)}
                </FormHelperText>
              </FormControl>
            </Grid>
            {subtipo_convenio.includes(CODIGO_SUBTIPO_CONVENIO_OTRO) && (
              <Grid item xs={12}>
                <TextField
                  value={otro_subtipo_convenio}
                  id="otro_subtipo_convenio"
                  required
                  error={getError("otro_subtipo_convenio", errores).length > 0}
                  helperText={getError("otro_subtipo_convenio", errores)}
                  name="otro_subtipo_convenio"
                  label="Otro subtipo de convenio"
                  fullWidth
                  onChange={this.handleChange}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth error={getError('programas_red', errores).length > 0}>
                <InputBuscar
                  label="Programas"
                  placeholder={"Seleccione el programa"}
                  id="programas_red"
                  value={
                    programas_red.length > 0
                      ? programas_red
                        .map((r) => r.nombre)
                        .join(", ")
                      : ""
                  }
                  // callback={() => this.setState({ modal_agg_programa: true })}
                  callback={() => {
                    this.setState({ modal_agg_programa: true }, () => {
                      if (errores && errores.length > 0) {
                        this.setState({ errores: errores.filter(er => er.llave !== "programas_red") });
                      }
                    });
                  }}
                  icon_default={false}
                />
              </FormControl>
              <FormHelperText style={{ color: "#FF2D00" }}>{getError('programas_red', errores)}</FormHelperText>
              <AgregarValor
                modalOpen={modal_agg_programa}
                mostrarModal={(modal) => this.setState({ modal_agg_programa: modal })}
                nombreModal="Programas"
                datos={programas}
                opciones={true}
                buscar={true}
                fila_principal="nombre"
                listaSeleccionados={programas_red}
                seleccionarVarios={true}
                seleccionar={({ id, nombre }) => {
                  this.setState({
                    programas_red: [...programas_red, { id, nombre }],
                    programas_sele: [...programas_sele, id]
                  });
                }}
                quitar={({ id }) => {
                  this.setState({
                    programas_red: programas_red.filter((programa) => programa.id !== id),
                    programas_sele: programas_sele.filter((p) => p !== id)
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={objeto_red}
                multiline
                required
                error={getError('objeto_red', errores).length > 0}
                helperText={`${objeto_red.length}/${CHARACTER_LIMIT}`}
                rows={4}
                id='objeto_red'
                name='objeto_red'
                label='Objeto de la red académica'
                fullWidth
                onChange={this.handleChange}
                inputProps={{ maxLength: CHARACTER_LIMIT }}
              />
            </Grid>
            <Grid item xs={12}>
              <Alert severity="info">
                (i) A continuación debes incluir la información del contacto de la red académica.
              </Alert>
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={nombre_enlace_vin}
                required
                error={getError("nombre_enlace_vin", errores).length > 0}
                helperText={getError("nombre_enlace_vin", errores)}
                type="text"
                id="nombre_enlace_vin"
                name="nombre_enlace_vin"
                label="Nombre contacto de red académica"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={email_enlace_vin}
                required
                error={getError("email_enlace_vin", errores).length > 0}
                helperText={getError("email_enlace_vin", errores)}
                type="text"
                id="email_enlace_vin"
                name="email_enlace_vin"
                label="Email contacto de red académica"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={telefono_enlace_vin}
                required
                error={getError("telefono_enlace_vin", errores).length > 0}
                helperText={getError("telefono_enlace_vin", errores)}
                type="number"
                id="telefono_enlace_vin"
                name="telefono_enlace_vin"
                label="Teléfono de red académica"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
        </ValidatorForm>
      </>
    );
  };

  resetState = () => {
    this.setState({ nombre_red: "", pais_red: [], subtipo_convenio: [], otro_subtipo_convenio: "", programas_red: [], objeto_red: "", cargando: false, telefono_enlace_vin: "", email_enlace_vin: "", nombre_enlace_vin: "" });
  }

  onSubmit = async () => {
    let { errores, nombre_red, pais_red, subtipo_convenio, otro_subtipo_convenio, programas_red, objeto_red, paises_sele, programas_sele, telefono_enlace_vin, email_enlace_vin, nombre_enlace_vin } = this.state;
    let { guardarRedAcademica } = this.props;

    errores = await validateInputs([
      { value: nombre_red, name: "nombre_red", err: { empty: true } },
      { value: pais_red, name: "pais_red", err: { empty: true } },
      { value: subtipo_convenio, name: "subtipo_convenio", err: { empty: true } },
      { value: programas_red, name: "programas_red", err: { empty: true } },
      { value: objeto_red, name: "objeto_red", err: { empty: true } },
      { value: telefono_enlace_vin, name: "telefono_enlace_vin", err: { empty: true } },
      { value: email_enlace_vin, name: "email_enlace_vin", err: { empty: true } },
      { value: nombre_enlace_vin, name: "nombre_enlace_vin", err: { empty: true } },
    ]);

    if (subtipo_convenio.includes(CODIGO_SUBTIPO_CONVENIO_OTRO)) {
      if (!otro_subtipo_convenio) {
        errores.push({
          llave: "otro_subtipo_convenio",
          mensaje: `Este campo es obligatorio`,
        });
      }
    }

    if (errores.length > 0) {
      this.setState({ errores });
      actualizarMensaje({ titulo: 'Debes llenar los campos obligatorios.', tipo: 'info', mostrar: true, tiempo: 6000 });
    }

    if (errores.length === 0) {
      this.setState({ cargando: true });
      let data = { nombre_red, pais_red: paises_sele, subtipo_convenio, otro_subtipo_convenio, programas_red: programas_sele, objeto_red, telefono_enlace_vin, email_enlace_vin, nombre_enlace_vin };
      await guardarRedAcademica(data, () => this.resetState());

    }
    // this.setState({ cargando: false });
  }

  onSubmitEditar = async () => {

    let { nombre_red, paises_sele, programas_sele, objeto_red, errores, subtipo_convenio, otro_subtipo_convenio, telefono_enlace_vin, pais_red, programas_red, email_enlace_vin, nombre_enlace_vin } = this.state
    let { id_institucionActual, multiple, editarRedAcademica } = this.props

    errores = await validateInputs([
      { value: nombre_red, name: "nombre_red", err: { empty: true } },
      { value: pais_red, name: "pais_red", err: { empty: true } },
      { value: subtipo_convenio, name: "subtipo_convenio", err: { empty: true } },
      { value: programas_red, name: "programas_red", err: { empty: true } },
      { value: objeto_red, name: "objeto_red", err: { empty: true } },
      { value: telefono_enlace_vin, name: "telefono_enlace_vin", err: { empty: true } },
      { value: email_enlace_vin, name: "telefono_enlace_vin", err: { empty: true } },
      { value: nombre_enlace_vin, name: "telefono_enlace_vin", err: { empty: true } },
    ]);

    if (subtipo_convenio.includes(CODIGO_SUBTIPO_CONVENIO_OTRO)) {
      if (!otro_subtipo_convenio) {
        errores.push({
          llave: "otro_subtipo_convenio",
          mensaje: `Este campo es obligatorio`,
        });
      }
    }

    if (errores.length > 0) {
      this.setState({ errores });
      actualizarMensaje({ titulo: 'Debes llenar los campos obligatorios.', tipo: 'info', mostrar: true, tiempo: 6000 });
    }

    if (errores.length === 0) {
      this.setState({ cargando: true });
      let data = { nombre_red, pais_red: paises_sele, subtipo_convenio, otro_subtipo_convenio, programas_red: programas_sele, objeto_red, id: id_institucionActual, telefono_enlace_vin, email_enlace_vin, nombre_enlace_vin };
      await editarRedAcademica(data, () => this.resetState())

      // this.setState({ cargando: true })
    }
  }

  render() {
    let { modalAddRed, handleCloseModal, titulo, editar, modalEdiIes } = this.props;
    let { cargando } = this.state;
    return (
      <Dialog
        open={editar ? modalEdiIes : modalAddRed}
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBarModal
          titulo={titulo}
          mostrarModal={handleCloseModal}
          titulo_accion=""
          accion={() => { }}
        />
        <DialogContent className="scroll">
          {cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
            : this.formularioRed()}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CERRAR" callback={handleCloseModal} />
          {cargando ? null : <BtnFormPage texto="GUARDAR" callback={editar ? this.onSubmitEditar : this.onSubmit} type="submit" />}
        </DialogActions>
      </Dialog>
    )
  }
}

export default RedCrear;
