//Importaciones necesaria para poder utilizar react
import { makeStyles } from '@material-ui/core/styles';
import MenuSemilleros from './Hijos/MenuSemilleros'
import TareasTerminadas from '../general/TareasTerminadas';
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s from '../../global/imagenes/emma_s1.png';
import * as Funciones from '../../global/js/funciones';
import TituloAccion from "../general/TituloAccion";
import { Grid, Link, Paper, Button, Typography, Card, CardHeader, CardContent, Avatar, Grow, Slide, createMuiTheme, Tooltip } from '@material-ui/core'
import SemilleroCardSolicitud from './Hijos/SemilleroCardSolicitud';
import SemillerosSolicitudes from './SemillerosSolicitudes';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SemilleroTables from './Hijos/SemilleroTables'
import revisar from '../../global/imagenes/revisar.png';
import noti3 from '../../global/imagenes/noti3.png';
import tap from '../../global/imagenes/tap.png';
import AccionesRapidas from '../general/AccionesRapidas'
import SemillerosListSemi from './Hijos/SemillerosListSemi'
import SemilleroActualizar from './Hijos/SemilleroActualizar'
import ListarDatos from '../general/ListarDatos';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AppBarModal from '../general/AppBarModal'
import { coloresEmma } from '../../global/js/funciones';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
//Constantes del Helper
import * as Helper from './Hijos/Helper';
import assigment from '../../global/imagenes/assigment.png';
import LinearProgress from '@material-ui/core/LinearProgress';
import React, { Fragment, useState } from "react";
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import TextField from '@material-ui/core/TextField';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import BuscarSelect from '../general/BuscarSelect';
import { obtenerValores, obtenerValoresFiltros, validarCorreo, obtenerPermisosValor, formulario, mostrarError, crear_form_data,obtenerMisDatos } from "../../global/js/funciones";
import { BtnCargar, BtnEliminar,BtnSeleccion,BtnVer } from "../general/BotonesAccion";
import SemilleroAddParti from './Hijos/SemilleroAddParti';
import EditIcon from '@material-ui/icons/Edit';
import { BtnForm } from '../general/BotonesAccion';
import InputBuscar from '../general/InputBuscar';
import BuscarPersona from '../general/BuscarPersona';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import PublishIcon from '@material-ui/icons/Publish';
import { useDispatch , useSelector} from 'react-redux';
import { actualizarMensaje, mostrarModulo } from '../../redux/actions/actGlobal';
import SemilleroMessage from './Hijos/SemilleroMessage';
import userSemillero from '../../global/imagenes/userSemillero.png'
import moreInformation from '../../global/imagenes/more-information.png'
import { red } from '@material-ui/core/colors';
import { ContactlessOutlined, Label } from '@material-ui/icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'
import VisibilityIcon from '@material-ui/icons/Visibility';
import NotificaIcon from '../../global/imagenes/noti4.png';
import InformeSemillerosTutores from './Hijos/InformeSemillerosTutores';
import { Document, Image, Page, PDFDownloadLink, Text, View,StyleSheet,Font } from '@react-pdf/renderer';
import file from '../../global/imagenes/file.png'
import sourcebold from './../../global/fonts/PTSerif-Bold.ttf'
import LogoCuc from '../../global/imagenes/logo_cuc3.png';
import firma_curso from '../../global/imagenes/firma_curso.png';
import PaticipantesListaSemi from './Hijos/ParticipantesListaSemi';
import InputFile from '../general/InputFile';
import {  Link as RouterLink } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    contenidoModulo: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: "90px !important"
        },
    },
    formControl: {
        margin: "0",
      },
    selectEmpty: {
        marginTop: theme.spacing(2),
      },
    colorEmmma: {
        color: coloresEmma.secondarycolor,
        transition: 'all 0.3s',
        '&:hover': {
          color: "#fff",
          backgroundColor: coloresEmma.secondarycolor,
          transform: "scale(1.3)",
        }
    },
    color:{
        transition: 'all 0.3s',
        borderRadius: "20px",
        '&:hover': {
            transform: "scale(1.01)",
            borderRadius: "0px",

        },
    },
    center:{
        textAlign: 'center'
    },
    colorEdit: {
        color: "#0074C5",
        transition: "all 0.3s",
        '&:hover': {
          color: "#fff",
          backgroundColor: "#0074C5",
          transform: "scale(1.3)",
        },
    },
      colorDelete: {
        color: "#d11a2a",
        transition: "all 0.3s",
        '&:hover': {
          color: "#fff",
          backgroundColor: "#d11a2a",
          transform: "scale(1.3)",
        },
      },
      colorCheck: {
        color: "#2ECC71",
        transition: "all 0.3s",
        '&:hover': {
          color: "#fff",
          backgroundColor: "#2ECC71",
          transform: "scale(1.2)",
        },
      },
      modalMinWith: {
            [theme.breakpoints.up('sm')]: {
                minWidth:'500px'
            },
        },
        dialogCustomizedWidth: {
            'max-width': '800px'
          },
    escalar: {
        transition: "all 0.3s",
        border: '1px solid #e0e0e0',
        [theme.breakpoints.up('sm')]: {
            '&:hover': {
                transform: "scale(1.05)",
                border: `1px solid ${coloresEmma.secondarycolor}`,
            },

        },
    },
    girar360: {
        transition: "all 0.3s",
        [theme.breakpoints.up('sm')]: {
            '&:hover': {
                transform: "rotate(1turn)",
            },

        },
    }
}));
Font.register({ family: 'PTSerifBold', src: sourcebold });
const styles = StyleSheet.create({
    body: {
        border: '1.1px solid #DFDDDD',
        width: '100%',
        height: '100%',
        padding: 'auto 45px auto 45px'
       
    },
    imagen: {
        width: '100%',
        display: 'flex',
        padding: '0 0 20px 0',
        justifyContent: 'center',
        alignItems: 'center',
       
    },
    titular:{
        fontSize: '12px',
        textAlign: 'center',
        marginTop: '30px',
        fontFamily: 'PTSerifBold',
        
    },
    negrita:{
        fontFamily: 'PTSerifBold',
        lineHeight: 1.3,
        textAlign: 'justify',
    },
    parrafo:{
        fontSize: '12px',
        margin: '50px 0 0 0',
        textAlign: 'justify',
        lineHeight: 1.3,
    },
    parrafo1:{
        fontSize: '12px',
        margin: '70px 0 0 0',
        textAlign: 'justify',
    },
    imagenfirma: {
        paddingLeft:'10px',
        width: '100px',
        height:'135px',
        display: 'flex',
        margin: '35px 0 -10px -5px'
   
    },
    piefirma:{
        fontFamily: 'PTSerifBold',
        fontSize: '13px',
        margin: '15px 0 0 0'
    },
    piefirma1:{
        fontSize: '13px',
        textAlign: 'justify',
        margin: '10px 0 0 0'
    },
    informacion:{
        textAlign: 'justify',
        fontSize: '11px',
        color: 'lightcoral',
        margin: '130px 0 6px 0',
    },linea:{
        margin: '-15px 0 0 0',
    }
  });

const Semilleros = (props) => {

    const usuario = useSelector(state => state.redGlobal.usuario);
    const [seleccion, setSeleccion] = React.useState('miproceso');
    const [mensaje, setMensaje] = React.useState(null);
    const [detalle, setDetalle] = React.useState(true)
    const [procesos, setProcesos] = React.useState([])
    const [notificaciones, setNotificaciones] = React.useState([]);
    const [semiActual, setSemiActual] = React.useState({});
    const [openModalInfo, setOpenModalInfo] = React.useState(false);
    const [openModalInfoParticipante, setOpenModalInfoParticipante] = React.useState(false);
    const classes = useStyles()
    const [cargandoProcesos, setCargandoProcesos] = React.useState(true);
    const [mdlIntegrantes, setmdlIntegrantes] = React.useState(false);
    const [liderAutomatico, setLiderAutomatico] = React.useState([]);
    const [instructivo, setInstructivo] = React.useState([]);
    const dispatchI = useDispatch();
    const [permisoGestionarSemilleros, setPermisoGestionarSemilleros] = React.useState(false);
    const [cordinador, setCordinador] = React.useState('');
    const [firma_coordinador, setFirma_coordinador] = React.useState('');

    React.useEffect(() => {
        console.log('Perfil: ', usuario.perfil)
        if(usuario.perfil.codigo !== 'Per_Nor' && (usuario.perfil.codigo=='Per_Doc'||usuario.perfil.codigo=='Per_Adm')){
            setPermisoGestionarSemilleros(true);
            dispatchI(mostrarModulo({ ruta: '/semilleros_profesor', nombre: 'Semilleros Tutores' }))
            obtenerAlerta()
            obtenerUsuario()
            obtenerNombreJefes()
        }else{
                console.log('Cuarta condicion dentro del else')
                const obtenerProcesos = async () => {
                    const data = await obtenerSemillerosSolicitudesLider()
                    if (data.length === 0 && usuario.perfil.codigo === 'Per_Nor') {
                        setMensaje('permiso')
                    } else {
                        setPermisoGestionarSemilleros(true);
                        console.log('PermisoGestionarSemilleros: ', permisoGestionarSemilleros)
                    }
                }
                obtenerProcesos();
        }
    }, []);

    const obtenerSemillerosSolicitudesLider = async () =>{
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/semillerosListLideres`, null, null, (error, estado, resp) => {
                resolve(estado === 200 && !error ? resp : null);
            })
        })
    }
    const obtenerNombreJefes = async () => {
        let nombrejefe = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'firma_coordinador' }]);
        setCordinador(nombrejefe[0].valora)
        setFirma_coordinador(nombrejefe[0].archivo)
      }

    const obtenerUsuario= async()=>{
       let data = await obtenerMisDatos()
       let instructivo_docente = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Sem_Inst' }]);
       setLiderAutomatico(data)
       setInstructivo(instructivo_docente)
    }
      const obtenerAlerta= async()=>{
        setCargandoProcesos(true)
        let dato_noti = await ObtenerNotificaciones()
        let helpnoti
        let da=[]
        let notiguardar=[]

        const accionesSolicitudes = (data) => {
            const onClickAbrir = async () => {

                setSeleccion('miproceso')
                setDetalle(false)
                setSemiActual(data.semillero)
                setmdlIntegrantes(true)
                }

            return (
            <>
            <BtnVer  callback={() =>{onClickAbrir(data)} }texto='Ver' />
            </>
            )

        }

        dato_noti.map((data)=>{
            let { primer_apellido,primer_nombre, segundo_apellido } = data.usuario_registro;
            let { nombre } = data.semillero;
            helpnoti = {
                      icono: NotificaIcon,
                      titulo: `¡ Solicitud Participacion a Semillero !`,
                      mensaje: `El estudiante ${primer_nombre} ${primer_apellido} ${segundo_apellido} hizo una solicitud al semillero ${nombre}.`,
                      tipo: 'mensaje',
                      acciones: () => accionesSolicitudes(data),
                }
           notiguardar.push(helpnoti)
        })

        setNotificaciones(notiguardar)
        setCargandoProcesos(false)

    }

    const vista = () => {

        switch (seleccion) {
          case 'miproceso':
            return (
                <div>
                    {detalle == true ?
                    <PintarSolicitudes
                    procesos = {procesos}
                    setProcesos = {setProcesos}

                    seleccion = {seleccion}
                    setSeleccion = {setSeleccion}

                    detalle = {detalle}
                    setDetalle = {setDetalle}

                    semiActual={semiActual}
                    setSemiActual={setSemiActual}

                    vistaEstudiante={props.vistaEstudiante}
                    />  : <PintarSolicitudSeleccionada
                    semiActual={semiActual}
                    setSemiActual={setSemiActual}
                    firma_coordinador={firma_coordinador}
                    cordinador={cordinador}
                    detalle = {detalle}
                    setDetalle = {setDetalle}
                    mdlIntegrantes={mdlIntegrantes}
                    setmdlIntegrantes={setmdlIntegrantes}
                    openModalInfo={openModalInfo}
                    setOpenModalInfo={setOpenModalInfo}
                    vistaEstudiante={props.vistaEstudiante}
                    openModalInfoParticipante={openModalInfoParticipante}
                    setOpenModalInfoParticipante={setOpenModalInfoParticipante}

                    />}
                </div>
            )
          case 'nuevo':
            return (
                <SemillerosSolicitudes
                liderAutomatico={liderAutomatico}
                setLiderAutomatico={setLiderAutomatico}

                />
            )
          case 'historial':
            return (
                <div>
                    <PintarHistorial
                        procesos = {procesos}
                        setProcesos = {setProcesos}

                        seleccion = {seleccion}
                        setSeleccion = {setSeleccion}

                        detalle = {detalle}
                        setDetalle = {setDetalle}

                        semiActual={semiActual}
                        setSemiActual={setSemiActual}
                    />
                </div>
            )
            case 'Informe':
            return (
                <InformeSemillerosTutores
                />
            )
          default:
            break;
        }
    }

    return (
        <div>
            {mensaje ? (
            <div>
            <RouterLink to="/semilleros">
                <ArrowBackIcon style={{ fontSize: '35px', color: '#900807', marginTop: '5px' }} />
            </RouterLink>
            <TareasTerminadas mensaje='No tienes permiso para este módulo' marginTop='7%' imagen={emma_s} widthImg="7%" />
            </div>
        ) : (
            props.vistaEstudiante != true ? (
            <div>
            <MenuSemilleros
            notificaciones={notificaciones}
            sele={seleccion}
            cargandoProcesos={cargandoProcesos}
            mdlIntegrantes={mdlIntegrantes}
            instructivo_docente={instructivo[0] ? instructivo[0].archivo : ''}
            seleccionar={
                (seleccion)  =>{
                if(seleccion!='notificacion'){

                setSeleccion(seleccion)
                }else {
                    obtenerAlerta()
                }
            }}
            />
        </div>

            ) : '')}
            {
                (permisoGestionarSemilleros === true) ? (
                    <div className={classes.contenidoModulo}>
                        {vista()}
                    </div>
                ) : ''
            }
        </div>
    )
}

const ObtenerNotificaciones = async () => {
    return new Promise(resolve => {
        Funciones.consulta(`api/v1.0/investigacion/notificaciones?estado_actual__codigo=Semi_par_env`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
    })
}

const PintarSolicitudes = (props) => {

    const isInitialMount = React.useRef(true);
    const [cargando, setCargando] = React.useState(true);


    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                let data
                if(props.vistaEstudiante != true){
                    data = await obtenerSemillerosSolicitudes();
                }else{
                    data = await obtenerSemillerosSolicitudesLider();
                }

                props.setProcesos(data)
                setCargando(false)
            }
            obtenerProcesos()
        }
    })
    //Obtener semilleros solicitudes
    const obtenerSemillerosSolicitudes = async () => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/semillerosList`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    const obtenerSemillerosSolicitudesLider = async () =>{
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/semillerosListLideres`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

        if (props.procesos.length == 0) {
            if(props.vistaEstudiante != true){
                return (
                    <div className="pointer" onClick={() => props.setSeleccion("nuevo")}>
                      <TareasTerminadas
                        mensaje="No tienes solicitudes activas, clic aquí para crear un Semillero"
                        marginTop="7%"
                        imagen={emma_s}
                        widthImg="7%"
                      />
                    </div>
                  );
            }else{
                return (
                    <div>
                      <TareasTerminadas
                        mensaje="No eres lider de ningún Semillero"
                        marginTop="7%"
                        imagen={emma_s}
                        widthImg="7%"
                      />
                    </div>
                  );
            }
        }

    const handleClick = (semi) => {
        props.setDetalle(false)
        props.setSemiActual(semi)
    };

    return (
        <div>
            {cargando == true ? <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />
            : <div style={{ padding: '2% 2% 0% 2%' }}>
                <>
                <Grid container direction={'row'} spacing={1}>
                    <TituloAccion principal="" alterno="¡ Lista de Semilleros !" />
                    {props.procesos.map((semi, i) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                        <Paper elevation={1} onClick={() => handleClick(semi)} className="pointer">
                            <SemilleroCardSolicitud
                            id={semi.id}
                            tipo={semi}
                            fecha={semi.fecha_registro}
                            descripcion={`Este es el semillero ${semi.nombre}
                            se encuentra en estado ${semi.solicitud.estado_actual.nombre}. Clic aquí para obtener mas información.`}
                            estado={semi.solicitud.estado_actual}
                            //handleClickDelete={handleClickDelete}
                            />
                        </Paper>
                        </Grid>
                    );
                    })}
                </Grid>
                </>
			</div>}
        </div>
    )

}

const PintarSolicitudSeleccionada = (props) =>{
    const isInitialMount = React.useRef(true);
    const [pos, setPos] = React.useState('Información del Semillero')
    const [openModalGestion, setOpenModalGestion] = React.useState(false)
    const [openModalIntegrantes, setOpenModalIntegrantes] = React.useState(false)
    const [lider, setLider] = React.useState([])
    const [semilleronuevo, setSemilleronuevo] = React.useState([]);
    const [profesorLider, setProfesorLider] = React.useState('');
    const usuario = useSelector(state => state.redGlobal.usuario);
    //Estado open para abrir y cerrar el modal.
    const [open, setOpen] = React.useState(false);
    const [cargando, setCargando] = React.useState(true);
    const classes = useStyles()

    React.useEffect(() => {
        if(isInitialMount.current){
            isInitialMount.current = false
            obtenerLider()
            setCargando(false)
         if(props.mdlIntegrantes==true){
            setOpenModalIntegrantes(true)
        }

      }
    })

    const obtenerLider= async()=>{
        let data = await obtenerliderSemillero()
        data.lideres.map((l)=>{
            if(l.rol.codigo=='Semi_rol3'){
            setProfesorLider(l.usuario_registro)
            } 
        })
        setLider(data)
     }
    const obtenerliderSemillero = async () => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/listalideres/${props.semiActual.id}`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }
    
    const handleClick = () => {
        props.setDetalle(true)
        props.setSemiActual({})
        setPos('Información del Semillero')
    };

    const openModalInfo = () =>{
        props.setOpenModalInfo(true)
    }

    const openModalInfoWithParticipantes = () =>{
        props.setOpenModalInfo(true)
        setPos('Solicitudes')
    }

    const handleClickOpenModalGestion = () =>{
        setOpenModalGestion(true)
    }

    const handleClickOpenModalIntegrantes = () =>{
        setOpenModalIntegrantes(true)
    }
    //Funciones para que abra y cierre el modal del formulario.
    const handleClickOpen = () => {
        setOpen(true);
    };
    const obtenerMes = (mes) => {
        const meses = {
          '1': 'Enero',
          '2': 'Febrero',
          '3': 'Marzo',
          '4': 'Abril',
          '5': 'Mayo',
          '6': 'Junio',
          '7': 'Julio',
          '8': 'Agosto',
          '9': 'Septiembre',
          '10': 'Octubre',
          '11': 'Noviembre',
          '12': 'Diciembre'
        }
        return (meses[mes])
      }
    const generarPDF= () => {
        let fecha_actual = moment()
        let hoy = `${fecha_actual.format('D')} de ${obtenerMes(fecha_actual.format('M'))} de ${fecha_actual.format('YYYY')}`
        let data = props.semiActual
        let datalider=lider.lideres
        let firma = props.cordinador
        let imgfirma= props.firma_coordinador
        let grupo = ''
        let semillero=data.nombre
        let nombre=data.usuario_registro.primer_nombre + ' ' + data.usuario_registro.primer_apellido+ ' ' + data.usuario_registro.segundo_apellido
        let estado=data.solicitud.estado_actual.nombre.toUpperCase()
        let part=''
        data.semillero_grupos_investigacion.map((g)=>{
            if(g.estado==1){
                grupo= grupo +' '+ g.nombreGrupo + ','
            }

        })
        if(datalider){
            datalider.map((p)=>{
                part=part+' '+ p.nombre + ' - ' +' '+ p.rol.nombre.toUpperCase() + ','
            })
        }


        return (
          <Document>
            <Page size="A4">
              <View style={styles.body}>
                <View style={styles.imagen}>
                  <Image src={LogoCuc} alt="images" />
                </View>
                <Text style={styles.titular}>A QUIEN INTERESE</Text>
                <Text style={styles.parrafo}>
                 El suscrito Coordinador Semilleros De Investigación e Innovación de la Corporación Universidad de la Costa, certifica que el Grupo de Semillero de investigación <Text style={styles.negrita}>{semillero} </Text> 
                  adscrito al Grupo de Investigación<Text style={styles.negrita}>{grupo}</Text> actualmente se encuentra en estado<Text style={styles.negrita}> {estado} </Text>y los Lideres son: <Text style={styles.negrita}>{part}</Text> 
                 {"\n\n"}Se expide el presente certificado en Barranquilla, Colombia el {hoy}
                </Text>
                <Text style={styles.parrafo1}></Text>
                <Text style={styles.parrafo}>Atentamente, </Text>

                <View style={styles.imagenfirma} >
                  <Image src={firma_curso} alt="images" />
                </View>
                <Text style={styles.linea}>_____________________</Text>
                <Text style={styles.piefirma}>{firma}  </Text>
                <Text style={styles.piefirma1}> Coordinador (a) Semilleros de Investigación e Innovación </Text>
                <Text style={styles.piefirma1}> Corporación Universidad de la Costa </Text>
                <View style={styles.informacion}>
                <Text>
                  Calle 58 N° 55-66 – Teléfonos: (5) 336 2200 – 3441974 – Fax: 3442670 – A.A. 50366 – www.cuc.edu.co
                </Text>
                </View>
            </View>
            </Page>
          </Document>
        )
      }
    const semilleroNuevoParticipantes=async ()=>{

        let f = await Funciones.generarFiltros([{ 'llave': 'solicitud_ant', 'valor': props.semiActual.solicitud.id }]);
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/ObtenerSemillero3?${f}`, null, null, (error, estado, resp) => {
                setSemilleronuevo(resp)
            })
        })
      }


    return (
        <div className='' >
            
            <Button  onClick={() => handleClick()} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon className={classes.girar360}/> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> </Typography> </Button>
            {cargando == true ? <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />
             :
            <div style={{ padding: '0% 2% 0% 2%' }} >
                <Grid container direction={'row'} spacing={1} >
                    <Grid item xs={12} sm={12} md={6} >
                        <TituloAccion principal='' alterno='¡ Información Básica !' />
                        <Paper elevation={0} square className="scroll">
                            <SemilleroTables semi={props.semiActual} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
                        <Grid container direction={'row'} spacing={2} style={{ width: "100%" }}>
                            
                            <Grid item xs={12} sm={12} md={12} >
                                {/**/}
                                <Paper className={classes.escalar} elevation={3} onClick={() => openModalInfo()}>
                                    <AccionesRapidas imagen={revisar} titulo='¡ Más Información !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes ver toda la información actual.' />
                                </Paper>
                            </Grid>
                            
                            {props.semiActual.solicitud.estado_actual.codigo=='Semi_activo'? <Grid item xs={12} sm={12} md={12} >
                                {/**/}
                                <Paper className={classes.escalar} elevation={3} onClick={() => handleClickOpenModalGestion()}>
                                    <AccionesRapidas imagen={moreInformation} titulo='¡ Gestion de Produccion del Semillero !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes encontrar la sección de gestión para el Semillero' />
                                </Paper>
                            </Grid>: null}
                            {props.semiActual.solicitud.estado_actual.codigo == "Semi_pause" && props.vistaEstudiante != true && usuario.id==profesorLider && !cargando? <Grid item xs={12} sm={12} md={12} >
                                {/**/}
                                <Paper className={classes.escalar} elevation={3} onClick={() => handleClickOpen()}>
                                    <AccionesRapidas imagen={noti3} titulo='¡ Actualiza Semillero !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes actualizar la Información del Semillero' />
                                </Paper>
                            </Grid>: null}
                            
                            <Grid item xs={12} sm={12} md={12} >
                                
                                <Paper className={classes.escalar} elevation={3} onClick={() => handleClickOpenModalIntegrantes()}>
                                    <AccionesRapidas imagen={userSemillero} titulo='¡ Gestion de Integrantes del Semillero !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes encontrar la sección de gestión de Integrantes para el Semillero' />
                                </Paper>
                            </Grid>

                            {props.semiActual.solicitud.estado_actual.codigo=='Semi_activo'?
                            <Grid item xs={12} sm={12} md={12} >
                            <PDFDownloadLink style={{ textDecoration: 'none' }} document={ generarPDF()} fileName={`CerificadoSemillero.pdf`}>
                                {
                                  () => (
                                    
                                    <Paper className={classes.escalar} elevation={3} style={{ border: '1px solid #e0e0e0'}}  >
                                    <AccionesRapidas imagen={file} titulo='¡ Descargar Certificado !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes Descargar tu certificado de participacion en pdf.' />
                                    </Paper>
                                    
                                    )
                                } 
                            </PDFDownloadLink>
                            </Grid>
                            :''}
                        </Grid>
                    </Grid>
                </Grid>
            </div>}

            {props.openModalInfo == true ? <SemillerosListSemi
                openModalInfo={props.openModalInfo}
                setOpenModalInfo={props.setOpenModalInfo}

                semiActual={props.semiActual}

                pos={pos}
                setPos={setPos}

                profesor={true}

                vistaEstudiante={props.vistaEstudiante}
                gestor={false}
            /> : ''}

            {open == true ? <SemilleroActualizar
                handleClickOpen={handleClickOpen}

                open={open}
                setOpen={setOpen}

                semiActual={props.semiActual}
                semilleronuevo={semilleroNuevoParticipantes}
                openModalInfoParticipante={props.openModalInfoParticipante}
                setOpenModalInfoParticipante={props.setOpenModalInfoParticipante}

            /> : ''}
             {props.openModalInfoParticipante == true ? <PaticipantesListaSemi
                openModalInfoParticipante={props.openModalInfoParticipante}
                setOpenModalInfoParticipante={props.setOpenModalInfoParticipante}
                detalle={props.detalle}
                setDetalle = {props.setDetalle}
                semiActual={props.semiActual}
                setSemiActual={props.setSemiActual}
                pos={pos}
                setPos={setPos}
                profesor={true}
                vistaEstudiante={props.vistaEstudiante}
                gestor={false}
                semilleronuevo={semilleronuevo}
            />  : ''}

            {openModalGestion == true ? <SemilleroGestion
                openModalGestion={openModalGestion}
                setOpenModalGestion={setOpenModalGestion}
                semiActual={props.semiActual}
                obtenerliderSemillero={obtenerliderSemillero}
            /> : ''}

            {openModalIntegrantes == true || props.mdlIntegrantes == true ? <SemilleroGestionIntegrantes
                openModalIntegrantes={openModalIntegrantes}
                setOpenModalIntegrantes={setOpenModalIntegrantes}
                mdlIntegrantes={props.mdlIntegrantes} 
                setmdlIntegrantes={props.setmdlIntegrantes}

                semiActual={props.semiActual}
            /> : ''}
        </div>
    )
}

const PintarHistorial = (props) => {

    const isInitialMount = React.useRef(true);
    const [cargando, setCargando] = React.useState(true);
    const classes = useStyles()
    const [openModalInfo, setOpenModalInfo] = React.useState(false);
    const [pos, setPos] = React.useState('Información del Semillero')
    const [semiActual, setSemiActual] = React.useState('Información del Semillero')
    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                let data = await obtenerSemillerosSolicitudes();

                props.setProcesos(data)
                setCargando(false)
            }
            obtenerProcesos()
        }
    })
    //Obtener semilleros solicitudes
    const obtenerSemillerosSolicitudes = async () => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/ObtenerSemillero2?user_reg=1`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    if (props.procesos.length == 0) {
        return (
          <div className="pointer" onClick={() => props.setSeleccion("nuevo")}>
            <TareasTerminadas
              mensaje="Historial vacio"
              marginTop="7%"
              imagen={emma_s}
              widthImg="7%"
            />
          </div>
        );
    }

    const acciones = (data) => {

        const openModal = () =>{
            setOpenModalInfo(true)
            setSemiActual(data);
        }

        const detalle = (
            <div>
                <IconButton aria-label="done" className={classes.colorEmmma} onClick={openModal}>
                    <MenuBookIcon/>
                </IconButton>
            </div>
        );
        return detalle;
    };

    return (
        <div>
            {cargando == true ? <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />
            : <div>
                    <ListarDatos
                    id="tbl_listar_semilleros"
                    avatar={({ nombre }) => nombre.charAt(0)}
                    datos={props.procesos}
                    opciones={true}
                    buscar={true}
                    acciones={(row) => acciones(row)}
                    titulo={"Lista de Semilleros de Investigación"}
                    fila_principal={({ nombre, id }) => `${id} - ${nombre}`}
                    filas={[
                    {
                        'mostrar': ({ id }) => `${id}`,
                        'id': 'id', 'enLista': false
                    },
                    {
                        'mostrar': ({ nombre }) => `${nombre}`,
                        'id': 'nombre', 'enLista': false
                    },
                    {
                        'mostrar': ({solicitud}) => `${solicitud.estado_actual.nombre}`,
                        'id': 'estado_actual', 'enLista': true
                    },
                    { 'id': 'descripcion', 'enLista': false },
                    ]}
                />
                {openModalInfo == true ? <SemillerosListSemi
                        openModalInfo={openModalInfo}
                        setOpenModalInfo={setOpenModalInfo}

                        semiActual={semiActual}

                        pos={pos}
                        setPos={setPos}

                        profesor={false}
                /> : ''}
            </div>
            }
        </div>
    )

}

const SemilleroGestion = (props) => {
    const isInitialMount = React.useRef(true);
    const [tipoSolicitud, setTipoSolicitud] = React.useState([]);
    const [cargando, setCargando] = React.useState(true)
    const [modalForm, setModalForm] = React.useState(false)
    const [seleccion, setSeleccion] = React.useState('tipo')
    const [tipoSolicitudActual, setTipoSolicitudActual] = React.useState('')
    const classes = useStyles()
    const [steps, setSteps] = React.useState(['Información del General', 'Autores'])


    //Variables para Eventos
    const [anio, setAnio] = React.useState(new Date());
    const [fechaInicioEvento, setFechaInicioEvento] = React.useState(new Date());
    const [fechaFinEvento, setFechaFinEvento] = React.useState(new Date());
    const [departamentos, setDepartamentos] = React.useState([]);
    const [departamento, setDepartamento] = React.useState('');
    const [ciudades, setCiudades] = React.useState([])
    const [ciudad, setCiudad] = React.useState('')
    const [nombreDelEvento, setNombreDelEvento] = React.useState('');
    const [adj_evento, setAdj_evento] = React.useState('')
    const [adj_evento_final, setAdj_evento_final] = React.useState('')
    const [adj_certificado, setAdj_certificado] = React.useState([]);
    const [adj_certificado_final, setAdj_certificado_final] = React.useState([]);
    
    const [nombreDelAmbito, setNombreDelAmbito] = React.useState('')
    const [nombreAmbitos, setNombreAmbitos] = React.useState([])//Array de ambitos

    const [nombreModalidadPar, setNombreModalidadPar] = React.useState('')
    const [nombreModalidadPars, setNombreModalidadPars] = React.useState([])//Array de modalidad de particiapacion

    const [cargandoEvento, setCargandoEvento] = React.useState(false)
    //Variables para Proyectos
    const [fechaInicio, setFechaInicio] = React.useState(new Date());
    const [fechaFin, setFechaFin] = React.useState(new Date());
    const [nombreProyecto, setNombreProyecto] = React.useState('')
    const [descripcionProyecto, setDescripcionProyecto] = React.useState('')
    const [asignaturaProyecto, setAsignaturaProyecto] = React.useState('')
    const [nombreprofesorProyecto, setNombreprofesorProyecto] = React.useState('')
    const [profesorProyecto, setProfesorProyecto] = React.useState([])// Array de Profesores

    //Variables Prod. Bibliografica
    const [nombreProductoBiblio, setNombreProductoBiblio] = React.useState('')
    const [tipoProductoBiblio, setTipoProductoBiblio] = React.useState('')
    const [anioBiblio, setAnioBiblio] = React.useState(new Date())
    const [revistaEditorialBiblio, setRevistaEditorialBiblio] = React.useState('')
    const [cuartilBiblio, setCuartilBiblio] = React.useState('')
    const [lugarPublicacionBiblio, setLugarPublicacionBiblio] = React.useState('')
    const [medioDivulgacionBiblio, setMedioDivulgacionBiblio] = React.useState('')

    //Variables Prod. Tec y Tecno
    const [nombreProductoTec, setNombreProductoTec] = React.useState('')
    const [tipoProductoTec, setTipoProductoTec] = React.useState('')
    const [nitTec, setNitTec] = React.useState('')
    const [numeroRegistroTec, setNumeroRegistroTec] = React.useState('')
    const [nombreTitular, setNombreTitular] = React.useState('')
    const [anioTec, setAnioTec] = React.useState(new Date())
    const [paisTec, setPaisTec] = React.useState('')
    const dispatch = useDispatch();
    //Autores y particiapntes
    const [numeroIdParEvent, setNumeroIdParEvent] = React.useState([])
    const [arrai, setArrai] = React.useState([])

    //Stepper
    //Estado para cambiar de step en el SemillerosStepper
    const [activeStep, setActiveStep] = React.useState(0);

    //Cargando Semillero Gestion
    const [cargandoFormGestion, setCargandoFormGestion] = React.useState(true)
    const [permisosDeLaSolicitud, setPermisosDeLaSolicitud] = React.useState([])

    /*Estados necesarios para produccion bibliografica*/
        const [cargandoGestionBibliografica, setCargandoGestionBibliografica] = React.useState(true)
        //Cargando tipos de produccion bibliografica
        const [cargandoProduBibliografico, setCargandoProduBibliografico] = React.useState(true)
        //ActiveStep para produccion bibliografica
        const [activeStepBiblio, setActiveStepBiblio] = React.useState(0);
        //Estado para saber el tipo de produccion bibliografica
        const [tipoProduccionBiblio, setTipoProduccionBiblio] = React.useState([])
        //Campos del tipo Articulo de produccion bibliografica
        const [indexacionSelected, setIndexacionSelected] = React.useState("")
        const [tipoArticuloSelected, setTipoArticuloSelected] = React.useState("")
        const [tipoArticuloOtro, setTipoArticuloOtro] = React.useState("")
        const [tituloArticulo, setTituloArticulo] = React.useState("")
        const [issn, setIssn] = React.useState("")
        const [nombreRevista, setNombreRevista] = React.useState("")
        const [anioPublicacion, setAnioPublicacion] = React.useState(new Date())
        const [numPagTotales, setNumPagTotales] = React.useState("")
        const [volumenRevista, setVolumenRevista] = React.useState("")
        const [numeroRevista, setNumeroRevista] = React.useState("")
        const [paisPublicacion, setPaisPublicacion] = React.useState("")
        const [idioma, setIdioma] = React.useState("")
        const [enlace, setEnlace] = React.useState("")
        const [doi, setDoi] = React.useState("")
        const [paisesTipoArticulo, setPaisesTipoArticulo] = React.useState("")
        const [paisTipoArticuloSelected, setPaisTipoArticuloSelected] = React.useState("")
        const [idiomasTipoArticulo, setIdiomasTipoArticulo] = React.useState([])
        const [idiomaTipoArticuloSelected, setIdiomaTipoArticuloSelected] = React.useState("")
        //Campos del tipo Libro de produccion bibliografica
        const [tipoLibroSelected, setTipoLibroSelected] = React.useState("")
        const [tipoLibroOtro, setTipoLibroOtro] = React.useState("")
        const [tituloLibro, setTituloLibro] = React.useState("")
        const [isbnLibro, setIsbnLibro] = React.useState("")
        const [editorialLibro, setEditorialLibro] = React.useState("")
        const [anioPublicacionLibro, setAnioPublicacionLibro] = React.useState(new Date())
        const [paisPublicacionLibro, setPaisPublicacionLibro] = React.useState("")
        const [idiomaLibro, setIdiomaLibro] = React.useState("")
        const [volumenLibro, setVolumenLibro] = React.useState("")
        const [numeroEdicionLibro, setNumeroEdicionLibro] = React.useState("")
        const [paisTipoLibroSelected, setPaisTipoLibroSelected] = React.useState("")
        const [idiomasLibro, setIdiomasLibro] = React.useState([])
        const [idiomaLibroSelected, setIdiomaLibroSelected] = React.useState("")
        //Capos del tipo Capitulo de Libro de produccion bibliografica
        const [tipoLibroSelectedCap, setTipoLibroSelectedCap] = React.useState("")
        const [tipoLibroOtroCap, setTipoLibroOtroCap] = React.useState("")
        const [tituloCapLibro, setTituloCapLibro] = React.useState("")
        const [tituloLibroCap, setTituloLibroCap] = React.useState("")
        const [isbnLibroCap, setIsbnLibroCap] = React.useState("")
        const [editorialLibroCap, setEditorialLibroCap] = React.useState("")
        const [anioPublicacionLibroCap, setAnioPublicacionLibroCap] = React.useState(new Date())
        const [paisPublicacionLibroCap, setPaisPublicacionLibroCap] = React.useState("")
        const [idiomaLibroCap, setIdiomaLibroCap] = React.useState("")
        const [volumenLibroCap, setVolumenLibroCap] = React.useState("")
        const [numeroEdicionLibroCap, setNumeroEdicionLibroCap] = React.useState("")
        const [paisTipoCapLibroSelected, setPaisTipoCapLibroSelected] = React.useState("")
        const [idiomasCapituloLibro, setIdiomasCapituloLibro] = React.useState([])
        const [idiomaCapituloLibroSelected, setIdiomaCapituloLibroSelected] = React.useState("")
        //Campos del tipo Patente de producion bibliografica
        const [tipoPatente, setTipoPatente] = React.useState([])
        const [tipoPatenteSelected, setTipoPatenteSelected] = React.useState("")
        const [anioObtencionPatente, setAnioObtencionPatente] = React.useState(new Date())
        const [numeroRegistroPatente, setNumeroRegistroPatente] = React.useState("")
        const [tituloPatente, setTituloPatente] = React.useState("")
        const [numeroPatente, setNumeroPatente] = React.useState("")
        const [paisObtencionPatente, setPaisObtencionPatente] = React.useState("")
        const [gacetaIndustrialPatente, setGaceteIndustrialPatente] = React.useState("")
        const [institucionPatente, setInstitucionPatente] = React.useState("")
        const [tipoPatenteOtro, setTipoPatenteOtro] = React.useState("")
        const [paisPatenteSelected, setPaisPatenteSelected] = React.useState("")
        /*Estados necesarios para produccion Tenica*/
        //Estado para saber el tipo de produccion tecnica
        const [tipoProduccionTecnica, setTipoProduccionTecnica] = React.useState([])
        const [cargandoProduTec, setCargandoProduTec] = React.useState(true)
        const [tipoProductoTecSelected, setTipoProductoTecSelected] = React.useState("")
        const [inputs, setInputs] = React.useState([])
        const [inputsSelects, setInputsSelects] = React.useState([])
        //Fechas para los formularios de tecnico
        const [fechaCreacionDiseñoIndus, setFechaCreacionDiseñoIndus] = React.useState(new Date())
        const [fechaDesarrolloSoftware, setFechaDesarrolloSoftware] = React.useState(new Date())
        const [fechaObtencionRegistroSoftware, setFechaObtencionRegistroSoftware] = React.useState(new Date())
        const [fechaCreacionPrototipo, setFechaCreacionPrototipo] = React.useState(new Date())
        const [fechaCreacionSignoDistintivo, setFechaCreacionSignoDistintivo] = React.useState(new Date())
        const [fechaRegistroBaseTecno, setFechaRegistroBaseTecno] = React.useState(new Date())
        const [fechaInnovacionProceServi, setFechaInnovacionProceServi] = React.useState(new Date())
        const [fechaInnovacionGestionEmp, setFechaInnovacionGestionEmp] = React.useState(new Date())
        const [fechaPresentacionObraProducto, setFechaPresentacionObraProducto] = React.useState(new Date())
        const [fechaCreacionObraProducto, setFechaCreacionObraProducto] = React.useState(new Date())
        const [fechaInicioEventoArtistico, setFechaInicioEventoArtistico] = React.useState(new Date())
        const [fechaFinalEventoArtistico, setFechaFinalEventoArtistico] = React.useState(new Date())
        const [fechaInicioTalleresCreacion, setFechaInicioTalleresCreacion] = React.useState(new Date())
        const [fechaFinalTalleresCreacion, setFechaFinalTalleresCreacion] = React.useState(new Date())
        //Fecha para el registro de diseño prototipo tiene
        const [fechaRegistroDiseñoPrototipoTiene, setFechaRegistroDiseñoPrototipoTiene] = React.useState(new Date())
        //Selecteds para los formularios de tecnico
        const [productoTieneSelected, setProductoTieneSelected] = React.useState("")
        const [tipoPrototipoSelected, setTipoPrototipoSelected] = React.useState("")
        const [tipoSoftwareSelected, setTipoSoftwareSelected] = React.useState("")
        const [tipoSignoDistintivoSelected, setTipoSignoDistintivoSelected] = React.useState("")
        const [tipoEmpresaSelected, setTipoEmpresaSelected] = React.useState("")
        const [tipoInnovacionSelected, setTipoInnovacionSelected] = React.useState("")
        const [tipoEspacioEventoSelected, setTipoEspacioEventoSelected] = React.useState("")
        const [tipoDisciplinaAmbitoSelected, setTipoDisciplinaAmbitoSelected] = React.useState("")
        const [tipoAmbitoSelected, setTipoAmbitoSelected]= React.useState("")
        const [tipoRolSelected, setTipoRolSelected]= React.useState("")
        const [tipoEventoSelected, setTipoEventoSelected]= React.useState("")
        //Cuando seleccionen otro en los formularios de tecnico en los selects
        const [otroTipoPrototipoSelected, setOtroTipoPrototipoSelected] = React.useState("")
        const [otroTipoSoftwareSelected, setOtroTipoSoftwareSelected] = React.useState("")
        //Selects de produccion tecnica
        const [productoTiene, setProductoTiene] = React.useState([])
        const [tiposDePrototipos, setTiposDePrototipos] = React.useState([])
        const [tiposDeSoftware, setTiposDeSoftware] = React.useState([])
        const [tiposSignoDistintivos, setTiposSignoDistintivos] = React.useState([])
        const [tiposEmpresas, setTiposEmpresas] = React.useState([])
        const [tiposInnovaciones, setTiposInnovaciones] = React.useState([])
        const [tiposEspacioEvento, setTiposEspacioEvento] = React.useState([])
        const [tiposDisciplinaAmbito, setTiposDisciplinaAmbito] = React.useState([])
        const [tiposAmbitos, setTiposAmbitos] = React.useState([])
        const [tiposRoles, setTiposRoles] = React.useState([])
        const [tiposEventos, setTiposEventos] = React.useState([])
        const [paisesTecTecno, setPaisesTecTecno] = React.useState([])
        const [paisSelectedTecTecno, setPaisSelectedTecTecno] = React.useState("")
        //Empresas de base cultural
        const [fechaRegistroEmpresaCultural, setFechaRegistroEmpresaCultural] = React.useState(new Date())
        const [otroTipoEspacioEvento, setOtroTipoEspacioEvento] = React.useState("")
        //Estados para rol y participacion de algunos formularios para los autores o participantes
        const [rolActive, setRolActive] = React.useState(false)
        const [modalidadParticipacionActive, setModalidadParticipacionActive] = React.useState(false)
        //Data final
    let data = {}

    //Errores
    const [errores, setErrores] = React.useState([]);

    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                obtenerTiposDeSolicitudes()
            }
            obtenerProcesos()
        }
    })


    const obtenerTiposDeSolicitudes = async () => {
        try{
          const data = await Funciones.obtenerValores(Helper.GESTION_TIPOS_INVESTIGACION)

          setTipoSolicitud(data)
          setCargando(false)

        }catch(err){

        }
    }

    const handleClickChangeToForm = async (item) =>{
        setCargandoFormGestion(true)
        setCargando(true)
        let permisos = await obtenerPermisosValor(item.id);
        setPermisosDeLaSolicitud(permisos)

        if(permisos[0].secundario.codigo == "Inves_par_participante"){
            setSteps(['Información del General', 'Información del Participante'])
        }else{
            setSteps(['Información del General', 'Autores'])
        }

        setTipoSolicitudActual(item.codigo)

        if(item.codigo == "Soli_inves_eventos" || item.codigo == "Semi_inves_proyectos"){
            if(item.codigo == "Soli_inves_eventos"){
                setModalidadParticipacionActive(true)
            }
            setSeleccion('gestion')
            setCargando(false)
        }else if(item.codigo == "Semi_inves_bibliografica"){
            setSeleccion('bibliografica')
            setCargando(false)
        }else if(item.codigo == "Semi_inves_tec"){
            setSeleccion('tecnica')
            setCargando(false)
        }

    }

    const handleClickChangeToFormBibliografia = (item) =>{
        setSteps(['Información del General', 'Autores'])
        setSeleccion('gestionBibliografica')
        setCargandoProduBibliografico(true)
        setTipoProduccionBiblio(item)
    }

    const handleClickChangeToFormTec = (item) =>{
        setSteps(['Información del General', 'Autores'])
        setSeleccion('gestionTecnica')
        setTipoProduccionTecnica(item)
    }

    //Sistema de errores

    const getError = (llave, errores) => {
        let existe = errores.find(e => e.llave === llave);
        return existe ? existe.mensaje : '';
    }

    const validateInputs = (campos = []) => {
        return new Promise((resolve) => {
        let errores = [];
        let date_now = new Date()

        function isObjEmpty(obj) {

            if(typeof obj === 'object'){
                for (var prop in obj) {
                    if (obj.hasOwnProperty(prop)) return false;
                  }

                  return true;
            }else if(typeof obj === 'string'){
                return true
            }else{
                return false;
            }

        }

        campos.map(({ value, name, err, value2 }) => {

            if (!err.obj && err.empty && value.replace(/\s+/g, '') == "") errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` });
            if (!err.obj && err.empty == false && value.length == 0) errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
            //Validacion exclusiva para buscar Select.
            if (err.empty && isObjEmpty(value) && err.obj){
                errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
            }
            /*if (err.numeric && isNaN(value)) errores.push({ llave: name, 'mensaje': `Este campo debe ser un numero` });
            if (err.email && !validarCorreo(value)) errores.push({ llave: name, 'mensaje': `Correo invalido` });
            if (err.before_today && value >= date_now) errores.push({ llave: name, 'mensaje': `Fecha futura invalida` });
            if (err.after_today && value <= date_now) errores.push({ llave: name, 'mensaje': `Fecha pasada invalida` });
            if (err.finish_date && value <= value2) errores.push({ llave: name, 'mensaje': `Fecha invalida` });
            if (err.range && (value < 1 || value > value2)) errores.push({ llave: name, 'mensaje': `El valor está fuera del rango permitido` });
            if (err.not_null && value == null) errores.push({ llave:name, 'mensaje': `Este campo es obligatorio` });
            if (err.not_decimal && value % 1 != 0) errores.push({ llave: name, 'mensaje': `El valor no puede ser decimal` });*/
        });

        resolve(errores);
        });
    }
    const validateInputsTecnico = (campos = []) => {
        return new Promise((resolve) => {
        let errores = [];

        function isObjEmpty(obj) {

            if(typeof obj === 'object'){
                for (var prop in obj) {
                    if (obj.hasOwnProperty(prop)) return false;
                  }

                  return true;
            }else if(typeof obj === 'string'){
                return true
            }else{
                return false;
            }

        }

        campos.map(({ value, name, err }) => {

            if (!err.obj && err.empty && value.replace(/\s+/g, '') == "") {
                errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
            }
            if (err.empty && isObjEmpty(value) && err.obj){
                errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
            }
            /*if (err.numeric && isNaN(value)) errores.push({ llave: name, 'mensaje': `Este campo debe ser un numero` });
            if (err.email && !validarCorreo(value)) errores.push({ llave: name, 'mensaje': `Correo invalido` });
            if (err.before_today && value >= date_now) errores.push({ llave: name, 'mensaje': `Fecha futura invalida` });
            if (err.after_today && value <= date_now) errores.push({ llave: name, 'mensaje': `Fecha pasada invalida` });
            if (err.finish_date && value <= value2) errores.push({ llave: name, 'mensaje': `Fecha invalida` });
            if (err.range && (value < 1 || value > value2)) errores.push({ llave: name, 'mensaje': `El valor está fuera del rango permitido` });
            if (err.not_null && value == null) errores.push({ llave:name, 'mensaje': `Este campo es obligatorio` });
            if (err.not_decimal && value % 1 != 0) errores.push({ llave: name, 'mensaje': `El valor no puede ser decimal` });*/
        });

        resolve(errores);
        });
    }
    //Evento para cambiar de step
    const handleNext = async () => {
        if(seleccion == 'gestion'){
            if(tipoSolicitudActual == "Soli_inves_eventos"){
                if(activeStep == 0){

                    function isObjEmpty(obj) {

                        if(typeof obj === 'object'){
                            for (var prop in obj) {
                                if (obj.hasOwnProperty(prop)) return false;
                              }

                              return true;
                        }else if(typeof obj === 'string'){
                            return true
                        }else{
                            return false;
                        }

                    }

                    let array = await validateInputs([
                        { value: nombreDelEvento, name: 'nombreDelEvento', err: { empty: true, obj: false } },
                        { value: departamento, name: 'departamento', err: { empty: true, obj: true } },
                        { value: ciudad, name: 'ciudad', err: { empty: true, obj: true } },
                        { value: nombreDelAmbito, name: 'nombreDelAmbito', err: { empty: true, obj: true } },
                    ])
                    setErrores(array)
                    let fecha_inicio = moment(fechaInicioEvento).format('YYYY-MM-DD');
                    let fecha_fin = moment(fechaFinEvento).format('YYYY-MM-DD');
                    let aniio = moment(anio).format('YYYY')
                    let fecha_inicio_1 = moment(fechaInicioEvento).format('YYYY');
                    let fecha_fin_1 = moment(fechaFinEvento).format('YYYY');

                    if(aniio != fecha_inicio_1 || aniio != fecha_fin_1){
                        let tipo = "info"
                        let titulo = "El año no coincide con la fecha de inicio o fin."
                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                          );
                    }else if(fecha_inicio > fecha_fin){
                        let tipo = "info"
                        let titulo = "La Fecha Inicio es mayor que la Fecha Fin"
                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                          );
                    }else if(array.length > 0){

                    }else if(!adj_evento){
                        dispatch(
                            actualizarMensaje({ titulo: 'Debe adjuntar el soporte', tipo: "info", mostrar: true, tiempo: 5000 })
                        );
                    }else{
                        let adj_evento1 = document.getElementById('adj_evento').files[0];
                        if (adj_evento1) setAdj_evento_final(adj_evento1);

                        data = {
                            nombre:nombreDelEvento,
                            anio:anio,
                            departamento:departamento,
                            ciudad: ciudad,
                            ambito:nombreDelAmbito,
                            fecha_de_inicio: fecha_inicio,
                            fecha_de_fin: fecha_fin,
                            tipo: tipoSolicitudActual
                        }

                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }
                }
            }else if(tipoSolicitudActual == "Semi_inves_proyectos"){
                if(activeStep == 0){

                    let array = await validateInputs([
                        { value: nombreProyecto, name: 'nombreProyecto', err: { empty: true, obj: false } },
                        { value: descripcionProyecto, name: 'descripcionProyecto', err: { empty: true, obj: false } },
                        { value: asignaturaProyecto, name: 'asignaturaProyecto', err: { empty: true, obj: false } },
                        // { value: nombreprofesorProyecto, name: 'nombreprofesorProyecto', err: { empty: true, obj: false } },
                    ])

                    setErrores(array)
                    let fecha_inicio = moment(fechaInicio).format('YYYY-MM-DD');
                    let fecha_fin = moment(fechaFin).format('YYYY-MM-DD');
                    if(fecha_inicio > fecha_fin){
                        let tipo = "info"
                        let titulo = "La Fecha Inicio es mayor que Fecha Fin"
                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                          );
                    }else if(array.length > 0){

                    }else{

                        data = {
                            nombreProyecto:nombreProyecto,
                            descripcionProyecto:descripcionProyecto,
                            asignaturaProyecto:asignaturaProyecto,
                            profesor:nombreprofesorProyecto,
                            fechaInicio: fecha_inicio,
                            fechaFin: fecha_fin
                        }

                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }

                }
            }else if(tipoSolicitudActual == "Semi_inves_bibliografica"){

            }else if(tipoSolicitudActual == "Semi_inves_tec"){

            }else{

            }

        }else if(seleccion == "gestionBibliografica"){
            if(tipoProduccionBiblio.codigo == 'Inves_tipo_biblio_articulo'){
                if(activeStepBiblio == 0){
                    let array = await validateInputs([
                        { value: indexacionSelected, name: 'indexacionSelected', err: { empty: true, obj: true } },
                        { value: tipoArticuloSelected, name: 'tipoArticuloSelected', err: { empty: true, obj: true } },
                        { value: tituloArticulo, name: 'tituloArticulo', err: { empty: true, obj: false } },
                        { value: issn, name: 'issn', err: { empty: true, obj: false } },
                        { value: nombreRevista, name: 'nombreRevista', err: { empty: true, obj: false } },
                        { value: numPagTotales, name: 'numPagTotales', err: { empty: false, obj: false } },
                        { value: volumenRevista, name: 'volumenRevista', err: { empty: true, obj: false } },
                        { value: numeroRevista, name: 'numeroRevista', err: { empty: true, obj: false } },
                        { value: paisTipoArticuloSelected, name: 'paisTipoArticuloSelected', err: { empty: true, obj: true } },
                        { value: idiomaTipoArticuloSelected, name: 'idiomaTipoArticuloSelected', err: { empty: true, obj: true } },
                        { value: enlace, name: 'enlace', err: { empty: true, obj: false } },
                        { value: doi, name: 'doi', err: { empty: true, obj: false } },
                    ])
                    console.log(array)
                    setErrores(array)

                    if(array.length > 0){

                    }else if(tipoArticuloSelected.codigo == "Inves_tipo_articulo_otro"){
                        let array = await validateInputs([
                            { value: indexacionSelected, name: 'indexacionSelected', err: { empty: true, obj: true } },
                            { value: tipoArticuloSelected, name: 'tipoArticuloSelected', err: { empty: true, obj: true } },
                            { value: tipoArticuloOtro, name: 'tipoArticuloOtro', err: { empty: true, obj: false } },
                            { value: tituloArticulo, name: 'tituloArticulo', err: { empty: true, obj: false } },
                            { value: issn, name: 'issn', err: { empty: true, obj: false } },
                            { value: nombreRevista, name: 'nombreRevista', err: { empty: true, obj: false } },
                            { value: numPagTotales, name: 'numPagTotales', err: { empty: false, obj: false } },
                            { value: volumenRevista, name: 'volumenRevista', err: { empty: true, obj: false } },
                            { value: numeroRevista, name: 'numeroRevista', err: { empty: true, obj: false } },
                            { value: paisTipoArticuloSelected, name: 'paisTipoArticuloSelected', err: { empty: true, obj: true } },
                            { value: idiomaTipoArticuloSelected, name: 'idiomaTipoArticuloSelected', err: { empty: true, obj: true } },
                            { value: enlace, name: 'enlace', err: { empty: true, obj: false } },
                            { value: doi, name: 'doi', err: { empty: true, obj: false } },
                        ])
                        setErrores(array)
                        if(array.length > 0){

                        }else{

                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }
                    }else{
                        setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                    }
                }
            }else if(tipoProduccionBiblio.codigo == 'Inves_tipo_biblio_libro'){
                if(activeStepBiblio == 0){

                    let array = await validateInputs([
                        { value: tipoLibroSelected, name: 'tipoLibroSelected', err: { empty: true, obj: true } },
                        { value: tituloLibro, name: 'tituloLibro', err: { empty: true, obj: false } },
                        { value: isbnLibro, name: 'isbnLibro', err: { empty: true, obj: false } },
                        { value: editorialLibro, name: 'editorialLibro', err: { empty: true, obj: false } },
                        { value: paisTipoLibroSelected, name: 'paisTipoLibroSelected', err: { empty: true, obj: true } },
                        { value: idiomaLibroSelected, name: 'idiomaLibroSelected', err: { empty: true, obj: true } },
                        { value: volumenLibro, name: 'volumenLibro', err: { empty: true, obj: false } },
                        { value: numeroEdicionLibro, name: 'numeroEdicionLibro', err: { empty: true, obj: false } },
                    ])
                    setErrores(array)

                    if(array.length > 0){

                    }else if(tipoLibroSelected.codigo == 'Inves_tipo_libro_otro'){

                        let array = await validateInputs([
                            { value: tipoLibroSelected, name: 'tipoLibroSelected', err: { empty: true, obj: true } },
                            { value: tipoLibroOtro, name: 'tipoLibroOtro', err: { empty: true, obj: false } },
                            { value: tituloLibro, name: 'tituloLibro', err: { empty: true, obj: false } },
                            { value: isbnLibro, name: 'isbnLibro', err: { empty: true, obj: false } },
                            { value: editorialLibro, name: 'editorialLibro', err: { empty: true, obj: false } },
                            { value: paisTipoLibroSelected, name: 'paisTipoLibroSelected', err: { empty: true, obj: true } },
                            { value: idiomaLibroSelected, name: 'idiomaLibroSelected', err: { empty: true, obj: true } },
                            { value: volumenLibro, name: 'volumenLibro', err: { empty: true, obj: false } },
                            { value: numeroEdicionLibro, name: 'numeroEdicionLibro', err: { empty: true, obj: false } },
                        ])
                        setErrores(array)

                        if(array.length > 0){

                        }else{
                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }

                    }else{
                        setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                    }
                }
            }else if(tipoProduccionBiblio.codigo == 'Inves_tipo_biblio_capituloLib'){
                if(activeStepBiblio == 0){

                    let array = await validateInputs([
                        { value: tipoLibroSelectedCap, name: 'tipoLibroSelectedCap', err: { empty: true, obj: true } },
                        { value: tituloCapLibro, name: 'tituloCapLibro', err: { empty: true, obj: false } },
                        { value: tituloLibroCap, name: 'tituloLibroCap', err: { empty: true, obj: false } },
                        { value: isbnLibroCap, name: 'isbnLibroCap', err: { empty: true, obj: false } },
                        { value: editorialLibroCap, name: 'editorialLibroCap', err: { empty: false, obj: false } },
                        { value: paisTipoCapLibroSelected, name: 'paisTipoCapLibroSelected', err: { empty: true, obj: true } },
                        { value: idiomaCapituloLibroSelected, name: 'idiomaCapituloLibroSelected', err: { empty: true, obj: true } },
                        { value: volumenLibroCap, name: 'volumenLibroCap', err: { empty: true, obj: false } },
                        { value: numeroEdicionLibroCap, name: 'numeroEdicionLibroCap', err: { empty: true, obj: false } },
                    ])
                    setErrores(array)

                    if(array.length > 0){

                    }else if(tipoLibroSelectedCap.codigo == 'Inves_tipo_capLibro_otro'){
                        let array = await validateInputs([
                            { value: tipoLibroSelectedCap, name: 'tipoLibroSelectedCap', err: { empty: true, obj: true } },
                            { value: tipoLibroOtroCap, name: 'tipoLibroOtroCap', err: { empty: true, obj: false } },
                            { value: tituloCapLibro, name: 'tituloCapLibro', err: { empty: true, obj: false } },
                            { value: tituloLibroCap, name: 'tituloLibroCap', err: { empty: true, obj: false } },
                            { value: isbnLibroCap, name: 'isbnLibroCap', err: { empty: true, obj: false } },
                            { value: editorialLibroCap, name: 'editorialLibroCap', err: { empty: false, obj: false } },
                            { value: paisTipoCapLibroSelected, name: 'paisTipoCapLibroSelected', err: { empty: true, obj: true } },
                            { value: idiomaCapituloLibroSelected, name: 'idiomaCapituloLibroSelected', err: { empty: true, obj: true } },
                            { value: volumenLibroCap, name: 'volumenLibroCap', err: { empty: true, obj: false } },
                            { value: numeroEdicionLibroCap, name: 'numeroEdicionLibroCap', err: { empty: true, obj: false } },
                        ])
                        setErrores(array)
                        if(array.length > 0){

                        }else{
                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }
                    }else{
                        setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                    }
                }
            }else if(tipoProduccionBiblio.codigo == 'Inves_tipo_biblio_patente'){
                if(activeStepBiblio == 0){
                    let arrai
                    if(tipoPatenteSelected.codigo == "Inves_tipo_patente_otro"){
                        arrai = await validateInputs([
                            { value: tipoPatenteSelected, name: 'tipoPatente', err: { empty: true, obj: true } },
                            { value: tipoPatenteOtro, name: 'tipoPatenteOtro', err: { empty: true, obj: false } },
                            { value: tituloPatente, name: 'tituloPatente', err: { empty: true, obj: false } },
                            { value: numeroPatente, name: 'numeroPatente', err: { empty: true, obj: false } },
                            { value: paisPatenteSelected, name: 'paisPatenteSelected', err: { empty: true, obj: true } },
                            { value: gacetaIndustrialPatente, name: 'gacetaIndustrialPatente', err: { empty: true, obj: false } },
                            { value: institucionPatente, name: 'institucionPatente', err: { empty: true, obj: false } },
                        ])
                    }else{
                        arrai = await validateInputs([
                            { value: tipoPatenteSelected, name: 'tipoPatente', err: { empty: true, obj: true } },
                            { value: tituloPatente, name: 'tituloPatente', err: { empty: true, obj: false } },
                            { value: numeroPatente, name: 'numeroPatente', err: { empty: true, obj: false } },
                            { value: paisPatenteSelected, name: 'paisPatenteSelected', err: { empty: true, obj: true } },
                            { value: gacetaIndustrialPatente, name: 'gacetaIndustrialPatente', err: { empty: true, obj: false } },
                            { value: institucionPatente, name: 'institucionPatente', err: { empty: true, obj: false } },
                        ])
                    }


                    setErrores(arrai)

                    if(arrai.length > 0){

                    }else{

                        setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                    }
                }
            }

        }else if(seleccion == "gestionTecnica"){

            if(tipoProduccionTecnica.codigo == "Inves_tipo_tec_prodTecCerVal"){

                if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_diseñoIndustrial"){

                    if(activeStepBiblio == 0){
                        let array = []
                        let errorSelects = []
                        let sw = true
                        for (const item of inputs) {
                            let error = []

                            if(item.codigo.includes('fecha')){
                                if(item.codigo == "fechaCreacion"){
                                    let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                                    let fecha = moment(fechaCreacionDiseñoIndus).format('YYYY-MM-DD');
                                    if(fecha_actual < fecha){
                                        let tipo = "info"
                                        let titulo = "Elija una fecha de Creacion valida"
                                        sw = false
                                        dispatch(
                                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                        );
                                    }
                                }
                            }if(item.codigo.includes('pais')){
                            }else{
                                if(item.codigo != "fechaCreacion"){
                                    error = await validateInputsTecnico([
                                        { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                                    ])
                                }
                            }
                            if(error.length != 0){
                                array.push(error[0])
                            }

                        }

                        errorSelects = await validateInputsTecnico([
                            { value: productoTieneSelected, name: 'productoTiene', err: { empty: true, obj: true } },
                            { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
                        ])

                        if(errorSelects.length != 0){
                            array.push(errorSelects[0])
                        }else {
                            if(productoTieneSelected.codigo != "ningunoDiseñoPrototipoTiene"){
                                for (const item of inputsSelects) {
                                    let error = []

                                    if(item.codigo.includes('fecha')){
                                        if(item.codigo == "fechaRegistroDiseñoPrototipoTiene"){
                                            let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                                            let fecha = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                                            if(fecha_actual < fecha){
                                                let tipo = "info"
                                                let titulo = "Elija una Fecha de Obtención del Registro valida"
                                                sw = false
                                                dispatch(
                                                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                                );
                                            }
                                        }
                                    }else{
                                        error = await validateInputsTecnico([
                                            { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                                        ])
                                    }
                                    if(error.length != 0){
                                        array.push(error[0])
                                    }
                                }
                            }
                        }

                        setErrores(array)
                        if(array.length == 0 && sw == true){
                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }

                    }

                }else if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_software"){
                    if(activeStepBiblio == 0){
                        let array = []
                        let errorSelects = []
                        let sw = true
                        for (const item of inputs) {
                            let error = []

                            if(item.codigo.includes('fecha')){
                                if(item.codigo == "fechaDesarrolloSoftware"){
                                    let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                                    let fecha = moment(fechaDesarrolloSoftware).format('YYYY-MM-DD');
                                    if(fecha_actual < fecha){
                                        let tipo = "info"
                                        let titulo = "Elija una Fecha de Desarrollo valida"
                                        sw = false
                                        dispatch(
                                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                        );
                                    }
                                }else if(item.codigo == "fechaObtencionRegistroSoftware"){
                                    let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                                    let fecha = moment(fechaObtencionRegistroSoftware).format('YYYY-MM-DD');
                                    if(fecha_actual < fecha){
                                        let tipo = "info"
                                        let titulo = "Elija una Fecha de Obtención del Registro valida"
                                        sw = false
                                        dispatch(
                                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                        );
                                    }
                                }
                            }if(item.codigo.includes('pais')){
                            }else{
                                if(item.codigo != "fechaDesarrolloSoftware" && item.codigo != "fechaObtencionRegistroSoftware"){
                                    error = await validateInputsTecnico([
                                        { value: item.value, name: item.codigo, err: { empty: true, obj: false } },

                                    ])
                                }
                            }
                            if(error.length != 0){
                                array.push(error[0])
                            }

                        }

                        errorSelects = await validateInputsTecnico([
                            { value: tipoSoftwareSelected, name: 'tipoSoftwareSelected', err: { empty: true, obj: true } },
                            { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
                        ])

                        if(errorSelects.length != 0){
                            for(let i = 0; i < errorSelects.length; i++){
                                array.push(errorSelects[i])
                            }
                        }else{
                            if(tipoSoftwareSelected.codigo == "Inves_tipo_software_otro"){
                                errorSelects = await validateInputsTecnico([
                                    { value: otroTipoSoftwareSelected, name: 'nombreOtroSoftware', err: { empty: true, obj: false } },
                                ])
                                if(errorSelects.length != 0){
                                    array.push(errorSelects[0])
                                }
                            }
                        }

                        setErrores(array)
                        if(array.length == 0 && sw == true){
                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }
                    }
                }else if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_prototipo"){
                    if(activeStepBiblio == 0){
                        let array = []
                        let errorSelects = []
                        let sw = true
                        for (const item of inputs) {
                            let error = []

                            if(item.codigo.includes('fecha')){
                                if(item.codigo == "fechaCreacionPrototipo"){
                                    let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                                    let fecha = moment(fechaCreacionPrototipo).format('YYYY-MM-DD');
                                    if(fecha_actual < fecha){
                                        let tipo = "info"
                                        let titulo = "Elija una Fecha de Creación Prototipo valida"
                                        sw = false
                                        dispatch(
                                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                        );
                                    }
                                }
                            }if(item.codigo.includes('pais')){
                            }else{
                                if(item.codigo != "fechaCreacionPrototipo"){
                                    error = await validateInputsTecnico([
                                        { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                                    ])
                                }
                            }
                            if(error.length != 0){
                                array.push(error[0])
                            }

                        }

                        errorSelects = await validateInputsTecnico([
                            { value: productoTieneSelected, name: 'productoTiene', err: { empty: true, obj: true } },
                            { value: tipoPrototipoSelected, name: 'tipoPrototipoSelected', err: { empty: true, obj: true } },
                            { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
                        ])

                        if(errorSelects.length != 0){
                            for(let i = 0; i < errorSelects.length; i++){
                                array.push(errorSelects[i])
                            }
                        }else {
                            if(productoTieneSelected.codigo != "ningunoDiseñoPrototipoTiene"){
                                for (const item of inputsSelects) {
                                    let error = []

                                    if(item.codigo.includes('fecha')){
                                        if(item.codigo == "fechaRegistroDiseñoPrototipoTiene"){
                                            let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                                            let fecha = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                                            if(fecha_actual < fecha){
                                                let tipo = "info"
                                                let titulo = "Elija una Fecha de Obtención del Registro valida"
                                                sw = false
                                                dispatch(
                                                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                                );
                                            }
                                        }
                                    }else{
                                        error = await validateInputsTecnico([
                                            { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                                        ])
                                    }
                                    if(error.length != 0){
                                        array.push(error[0])
                                    }
                                }
                            }

                            if(tipoPrototipoSelected.codigo == "Inves_tipo_prototipo_otro"){
                                let error = []
                                errorSelects = await validateInputsTecnico([
                                    { value: otroTipoPrototipoSelected, name: 'nombreOtroPrototipo', err: { empty: true, obj: false } },
                                ])
                                if(errorSelects.length != 0){
                                    array.push(errorSelects[0])
                                }
                            }
                        }

                        setErrores(array)
                        if(array.length == 0 && sw == true){
                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }
                    }
                }else if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_signo_distintivo"){
                    if(activeStepBiblio == 0){
                        let array = []
                        let errorSelects = []
                        let sw = true
                        for (const item of inputs) {
                            let error = []

                            if(item.codigo.includes('fecha')){
                                if(item.codigo == "fechaCreacionSignoDistintivo"){
                                    let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                                    let fecha = moment(fechaCreacionSignoDistintivo).format('YYYY-MM-DD');
                                    if(fecha_actual < fecha){
                                        let tipo = "info"
                                        let titulo = "Elija una Fecha de Creación Signo Distintivo valida"
                                        sw = false
                                        dispatch(
                                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                        );
                                    }
                                }
                            }if(item.codigo.includes('pais')){
                            }else{
                                if(item.codigo != "fechaCreacionSignoDistintivo"){
                                    error = await validateInputsTecnico([
                                        { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                                    ])
                                }
                            }
                            if(error.length != 0){
                                array.push(error[0])
                            }

                        }

                        errorSelects = await validateInputsTecnico([
                            { value: tipoSignoDistintivoSelected, name: 'tipoSignoDistintivoSelected', err: { empty: true, obj: true } },
                            { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
                        ])

                        if(errorSelects.length != 0){
                            for(let i = 0; i < errorSelects.length; i++){
                                array.push(errorSelects[i])
                            }
                        }

                        setErrores(array)
                        if(array.length == 0 && sw == true){
                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }
                    }
                }

            }else if(tipoProduccionTecnica.codigo == "Inves_tipo_tec_prodEmp"){
                if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_empCreaCul"){
                    if(activeStepBiblio == 0){
                        let array = []
                        let errorSelects = []
                        let sw = true
                        for (const item of inputs) {
                            let error = []

                            if(item.codigo.includes('fecha')){
                                if(item.codigo == "fechaRegistroEmpresaCultural"){
                                    let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                                    let fecha = moment(fechaRegistroEmpresaCultural).format('YYYY-MM-DD');
                                    if(fecha_actual < fecha){
                                        let tipo = "info"
                                        let titulo = "Elija una Fecha de Registro valida"
                                        sw = false
                                        dispatch(
                                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                        );
                                    }
                                }
                            }else{
                                error = await validateInputsTecnico([
                                    { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                                ])
                            }
                            if(error.length != 0){
                                array.push(error[0])
                            }

                        }

                        errorSelects = await validateInputsTecnico([
                            { value: tipoDisciplinaAmbitoSelected, name: 'tipoDisciplinaAmbitoSelected', err: { empty: true, obj: true } },
                        ])

                        if(errorSelects.length != 0){
                            for(let i = 0; i < errorSelects.length; i++){
                                array.push(errorSelects[i])
                            }
                        }

                        setErrores(array)
                        if(array.length == 0 && sw == true){
                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }
                    }
                }else if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_empBaseTec"){
                    if(activeStepBiblio == 0){
                        let array = []
                        let errorSelects = []
                        let sw = true
                        for (const item of inputs) {
                            let error = []

                            if(item.codigo.includes('fecha')){
                                if(item.codigo == "fechaRegistroBaseTecno"){
                                    let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                                    let fecha = moment(fechaRegistroBaseTecno).format('YYYY-MM-DD');
                                    if(fecha_actual < fecha){
                                        let tipo = "info"
                                        let titulo = "Elija una Fecha de Registro Base Tecnológica valida"
                                        sw = false
                                        dispatch(
                                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                        );
                                    }
                                }
                            }else{
                                error = await validateInputsTecnico([
                                    { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                                ])
                            }
                            if(error.length != 0){
                                array.push(error[0])
                            }

                        }

                        errorSelects = await validateInputsTecnico([
                            { value: tipoEmpresaSelected, name: 'tipoEmpresaSelected', err: { empty: true, obj: true } },
                        ])

                        if(errorSelects.length != 0){
                            for(let i = 0; i < errorSelects.length; i++){
                                array.push(errorSelects[i])
                            }
                        }

                        setErrores(array)
                        if(array.length == 0 && sw == true){
                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }
                    }
                }else if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_innProProSer"){
                    if(activeStepBiblio == 0){
                        let array = []
                        let errorSelects = []
                        let sw = true
                        for (const item of inputs) {
                            let error = []

                            if(item.codigo.includes('fecha')){
                                if(item.codigo == "fechaInnovacionProceServi"){
                                    let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                                    let fecha = moment(fechaInnovacionProceServi).format('YYYY-MM-DD');
                                    if(fecha_actual < fecha){
                                        let tipo = "info"
                                        let titulo = "Elija una Fecha de la Innovación Procedimientos y Servicios valida"
                                        sw = false
                                        dispatch(
                                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                        );
                                    }
                                }
                            }if(item.codigo.includes('pais')){
                            }else{
                                if(item.codigo != "fechaInnovacionProceServi"){
                                    error = await validateInputsTecnico([
                                        { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                                    ])
                                }
                            }
                            if(error.length != 0){
                                array.push(error[0])
                            }

                        }

                        errorSelects = await validateInputsTecnico([
                            { value: productoTieneSelected, name: 'productoTiene', err: { empty: true, obj: true } },
                            { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
                        ])

                        if(errorSelects.length != 0){
                            for(let i = 0; i < errorSelects.length; i++){
                                array.push(errorSelects[i])
                            }
                        }else {
                            if(productoTieneSelected.codigo != "ningunoDiseñoPrototipoTiene"){
                                for (const item of inputsSelects) {
                                    let error = []

                                    if(item.codigo.includes('fecha')){
                                        if(item.codigo == "fechaRegistroDiseñoPrototipoTiene"){
                                            let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                                            let fecha = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                                            if(fecha_actual < fecha){
                                                let tipo = "info"
                                                let titulo = "Elija una Fecha de Obtención del Registro valida"
                                                sw = false
                                                dispatch(
                                                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                                );
                                            }
                                        }
                                    }else{
                                        error = await validateInputsTecnico([
                                            { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                                        ])
                                    }
                                    if(error.length != 0){
                                        array.push(error[0])
                                    }
                                }
                            }
                        }

                        setErrores(array)
                        if(array.length == 0 && sw == true){
                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }
                    }
                }else if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_innGenGesEmp"){
                    if(activeStepBiblio == 0){
                        let array = []
                        let errorSelects = []
                        let sw = true
                        for (const item of inputs) {
                            let error = []

                            if(item.codigo.includes('fecha')){
                                if(item.codigo == "fechaInnovacionGestionEmp"){
                                    let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                                    let fecha = moment(fechaInnovacionGestionEmp).format('YYYY-MM-DD');
                                    if(fecha_actual < fecha){
                                        let tipo = "info"
                                        let titulo = "Elija una Fecha de la Innovación Gestión Emp. valida"
                                        sw = false
                                        dispatch(
                                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                        );
                                    }
                                }
                            }if(item.codigo.includes('pais')){
                            }else{
                                if(item.codigo != "fechaInnovacionGestionEmp"){
                                    error = await validateInputsTecnico([
                                        { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                                    ])
                                }
                            }
                            if(error.length != 0){
                                array.push(error[0])
                            }

                        }

                        errorSelects = await validateInputsTecnico([
                            { value: productoTieneSelected, name: 'productoTiene', err: { empty: true, obj: true } },
                            { value: tipoInnovacionSelected, name: 'tipoInnovacionSelected', err: { empty: true, obj: true } },
                            { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
                        ])

                        if(errorSelects.length != 0){
                            for(let i = 0; i < errorSelects.length; i++){
                                array.push(errorSelects[i])
                            }
                        }else {
                            if(productoTieneSelected.codigo != "ningunoDiseñoPrototipoTiene"){
                                for (const item of inputsSelects) {
                                    let error = []

                                    if(item.codigo.includes('fecha')){
                                        if(item.codigo == "fechaRegistroDiseñoPrototipoTiene"){
                                            let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                                            let fecha = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                                            if(fecha_actual < fecha){
                                                let tipo = "info"
                                                let titulo = "Elija una Fecha de Obtención del Registro valida"
                                                sw = false
                                                dispatch(
                                                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                                );
                                            }
                                        }
                                    }else{
                                        error = await validateInputsTecnico([
                                            { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                                        ])
                                    }
                                    if(error.length != 0){
                                        array.push(error[0])
                                    }
                                }
                            }
                        }

                        setErrores(array)
                        if(array.length == 0 && sw == true){
                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }
                    }
                }
            }else if(tipoProduccionTecnica.codigo == "Inves_tipo_tec_prodInvesCrea"){
                if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_obrPro"){
                    if(activeStepBiblio == 0){
                        let array = []
                        let errorSelects=[]
                        let sw = true
                        for (const item of inputs) {
                            let error = []

                            if(item.codigo.includes('fecha')){
                                if(item.codigo == "fechaPresentacionObraProducto"){
                                    let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                                    let fecha = moment(fechaPresentacionObraProducto).format('YYYY-MM-DD');
                                    if(fecha_actual < fecha){
                                        let tipo = "info"
                                        let titulo = "Elija una Fecha de Presentación Obra o Producto valida"
                                        sw = false
                                        dispatch(
                                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                        );
                                    }
                                }else if(item.codigo == "fechaCreacionObraProducto"){
                                    let fecha_actual = moment(new Date()).format('YYYY-MM-DD');
                                    let fecha = moment(fechaCreacionObraProducto).format('YYYY-MM-DD');
                                    if(fecha_actual < fecha){
                                        let tipo = "info"
                                        let titulo = "Elija una Fecha de Creación Obra o Producto valida"
                                        sw = false
                                        dispatch(
                                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                        );
                                    }
                                }
                            }if(item.codigo.includes('pais')){
                            }else{
                                if(item.codigo != "fechaCreacionObraProducto" && item.codigo != "fechaPresentacionObraProducto"){
                                    error = await validateInputsTecnico([
                                        { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                                    ])
                                }
                            }
                            if(error.length != 0){
                                array.push(error[0])
                            }

                        }

                        errorSelects = await validateInputsTecnico([
                            { value: tipoAmbitoSelected, name: 'tipoAmbitoSelected', err: { empty: true, obj: true } },
                            { value: tipoDisciplinaAmbitoSelected, name: 'tipoDisciplinaAmbitoSelected', err: { empty: true, obj: true } },
                            { value: tipoEspacioEventoSelected, name: 'tipoEspacioEventoSelected', err: { empty: true, obj: true } },
                            { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
                        ])


                        if(errorSelects.length != 0){
                            for(let i = 0; i < errorSelects.length; i++){
                                array.push(errorSelects[i])
                            }
                        }else{
                            if(tipoEspacioEventoSelected.codigo == "Inves_tipo_espaEven_otro"){
                                let error = []
                                error = await validateInputsTecnico([
                                    { value: otroTipoEspacioEvento, name: 'otroTipoEspacioEvento', err: { empty: true, obj: false } },
                                ])
                                if(error.length != 0){
                                    array.push(error[0])
                                }
                            }
                        }

                        setErrores(array)
                        if(array.length == 0 && sw == true){
                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }
                    }
                }if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_eveArt"){
                    if(activeStepBiblio == 0){
                        let array = []
                        let errorSelects = []
                        let sw = true
                        for (const item of inputs) {
                            let error = []

                            if(item.codigo.includes('fecha')){
                                if(item.codigo == "fechaInicioEventoArtistico"){
                                    let fecha_inicio = moment(fechaInicioEventoArtistico).format('YYYY-MM-DD');
                                    let fecha_final = moment(fechaFinalEventoArtistico).format('YYYY-MM-DD');
                                    if(fecha_inicio > fecha_final){
                                        let tipo = "info"
                                        let titulo = "La fecha de inicio no puede ser mayor que la fecha final"
                                        sw = false
                                        dispatch(
                                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                        );
                                    }
                                }
                            }if(item.codigo.includes('pais')){
                            }else{
                                if(item.codigo != "fechaInicioEventoArtistico" && item.codigo != "fechaFinalEventoArtistico"){
                                    error = await validateInputsTecnico([
                                        { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                                    ])
                                }
                            }
                            if(error.length != 0){
                                array.push(error[0])
                            }

                        }

                        errorSelects = await validateInputsTecnico([
                            { value: tipoAmbitoSelected, name: 'tipoAmbitoSelected', err: { empty: true, obj: true } },
                            { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
                        ])

                        if(errorSelects.length != 0){
                            for(let i = 0; i < errorSelects.length; i++){
                                array.push(errorSelects[i])
                            }
                        }

                        setErrores(array)
                        if(array.length == 0 && sw == true){
                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }
                    }
                }if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_talCre"){
                    if(activeStepBiblio == 0){
                        let array = []
                        let errorSelects = []
                        let sw = true
                        for (const item of inputs) {
                            let error = []

                            if(item.codigo.includes('fecha')){
                                if(item.codigo == "fechaInicioTalleresCreacion"){
                                    let fecha_inicio = moment(fechaInicioTalleresCreacion).format('YYYY-MM-DD');
                                    let fecha_final = moment(fechaFinalTalleresCreacion).format('YYYY-MM-DD');
                                    if(fecha_inicio > fecha_final){
                                        let tipo = "info"
                                        let titulo = "La fecha de inicio no puede ser mayor que la fecha final"
                                        sw = false
                                        dispatch(
                                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                        );
                                    }
                                }
                            }if(item.codigo.includes('pais')){
                            }else{
                                if(item.codigo != "fechaInicioTalleresCreacion" && item.codigo != "fechaFinalTalleresCreacion"){
                                    error = await validateInputsTecnico([
                                        { value: item.value, name: item.codigo, err: { empty: true, obj: false } },
                                    ])
                                }
                            }
                            if(error.length != 0){
                                array.push(error[0])
                            }

                        }

                        errorSelects = await validateInputsTecnico([
                            { value: tipoAmbitoSelected, name: 'tipoAmbitoSelected', err: { empty: true, obj: true } },
                            { value: tipoEventoSelected, name: 'tipoEventoSelected', err: { empty: true, obj: true } },
                            { value: paisSelectedTecTecno, name: 'paisSelectedTecTecno', err: { empty: true, obj: true } },
                        ])

                        if(errorSelects.length != 0){
                            for(let i = 0; i < errorSelects.length; i++){
                                array.push(errorSelects[i])
                            }
                        }

                        setErrores(array)
                        if(array.length == 0 && sw == true){
                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }
                    }
                }
            }
        }

    }

    const handleSendToApi = async () =>{
        if(seleccion == 'gestion'){
            if(tipoSolicitudActual == "Soli_inves_eventos"){
                let fecha_inicio = moment(fechaInicioEvento).format('YYYY-MM-DD');
                let fecha_fin = moment(fechaFinEvento).format('YYYY-MM-DD');
                let aniio = moment(anio).format('YYYY')
                let integrantes = numeroIdParEvent.length
                let data = await Funciones.crear_form_data({ 
                    nombre:nombreDelEvento,
                    anio: aniio,
                    semillero: props.semiActual.id,
                    departamento:departamento.value,
                    ciudad: ciudad.value,
                    ambito:nombreDelAmbito,
                    fecha_de_inicio: fecha_inicio,
                    fecha_de_fin: fecha_fin,
                    tipo: tipoSolicitudActual,
                    // participante: par,
                    total_participante:integrantes,
                    adj_evento:adj_evento_final,
                    
                })
                // if(numeroIdParEvent.length != adj_certificado_final.length){
                //     dispatch(
                //         actualizarMensaje({ titulo: 'Debe adjuntar el soporte', tipo: "info", mostrar: true, tiempo: 5000 })
                //     );
                // }else{
                //         numeroIdParEvent.map(({id,modalidadParticipacion:{codigo}},index)=>{
                //             let adjunto = adj_certificado_final.filter(e =>e.id == id )
                //             data.append(`id${index}`,id)
                //             data.append(`codigo${index}`,codigo)
                //             data.append(`adjunto${index}`, adjunto[0].adjunto);
                //         })
                // }
                if(numeroIdParEvent.length == 0){
                    let tipo = "info"
                    let titulo = "Inserte por lo menos 1 Participante"
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                        );
                    
                }else if(numeroIdParEvent.length != adj_certificado_final.length){
                    dispatch(
                        actualizarMensaje({ titulo: 'Debe adjuntar el soporte', tipo: "info", mostrar: true, tiempo: 5000 })
                    );
                }else{
                    numeroIdParEvent.map(({id,modalidadParticipacion:{codigo}},index)=>{
                        let adjunto = adj_certificado_final.filter(e =>e.id == id )
                        data.append(`id${index}`,id)
                        data.append(`codigo${index}`,codigo)
                        data.append(`adjunto${index}`, adjunto[0].adjunto);
                    })
                    setCargandoEvento(true)
                    let url = "api/v1.0/investigacion/gestionSemillero"
                    const callback = (error, estado, resp) => {
                        let titulo = "Ha ocurrido un error, contacte con el administrador."
                        let tipo = "error"

                        if(!error){
                            if(estado === 201){
                                tipo = "success"
                                titulo = resp.titulo
                            }
                        }

                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                        );
                        setCargandoEvento(false)
                        handleOnClose()
                    }
                    const msg = await Funciones.formulario(url, data, 'POST', callback)

                }

            }else if(tipoSolicitudActual == "Semi_inves_proyectos"){

                let fecha_inicio = moment(fechaInicio).format('YYYY-MM-DD');
                let fecha_fin = moment(fechaFin).format('YYYY-MM-DD');

                data = {
                    nombre:nombreProyecto,
                    descripcionProyecto:descripcionProyecto,
                    asignaturaProyecto:asignaturaProyecto,
                    profesor:nombreprofesorProyecto,
                    semillero: props.semiActual,
                    fecha_de_inicio: fecha_inicio,
                    fecha_de_fin: fecha_fin,
                    autores: numeroIdParEvent,
                    tipo: tipoSolicitudActual
                }

                if(numeroIdParEvent.length == 0){
                    let tipo = "info"
                    let titulo = "Inserte por lo menos 1 Autor"
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                        );
                }else{
                    setCargandoEvento(true)
                    let url = "api/v1.0/investigacion/gestionSemillero"
                    const callback = (error, estado, resp) => {
                        let titulo = "Ha ocurrido un error, contacte con el administrador."
                        let tipo = "error"

                        if(!error){
                            if(estado === 201){
                                tipo = "success"
                                titulo = resp.titulo
                            }
                        }

                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                        );
                        setCargandoEvento(false)
                        handleOnClose()
                    }
                    const msg = await Funciones.consulta(url, data, 'POST', callback)

                }

            }else if(tipoSolicitudActual == "Semi_inves_bibliografica"){

            }else if(tipoSolicitudActual == "Semi_inves_tec"){

            }else{

            }
        }else if(seleccion == "gestionBibliografica"){
            if(tipoSolicitudActual == "Semi_inves_bibliografica"){
                if(tipoProduccionBiblio.codigo == "Inves_tipo_biblio_articulo"){
                    if(numeroIdParEvent.length == 0){
                        let tipo = "info"
                        let titulo = "Inserte por lo menos 1 Participante"
                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                    }else{
                        setCargandoGestionBibliografica(true)
                        let data
                        if(tipoArticuloSelected.codigo == "Inves_tipo_articulo_otro"){
                            data = {
                                tipo: tipoSolicitudActual,
                                tipo_de_producto: tipoProduccionBiblio.codigo,
                                semillero: props.semiActual,
                                indexacion: indexacionSelected,
                                tipoDeArticulo: tipoArticuloSelected,
                                tipoArticuloOtro: tipoArticuloOtro,
                                tituloArticulo: tituloArticulo,
                                issn: issn,
                                nombreRevista: nombreRevista,
                                anio: anio,
                                numPagTotales: numPagTotales,
                                volumenRevista: volumenRevista,
                                numeroRevista: numeroRevista,
                                paisPublicacion: paisTipoArticuloSelected,
                                idioma: idiomaTipoArticuloSelected,
                                enlace: enlace,
                                doi: doi,
                                autores: numeroIdParEvent
                            }
                        }else{
                            data = {
                                tipo: tipoSolicitudActual,
                                semillero: props.semiActual,
                                tipo_de_producto: tipoProduccionBiblio.codigo,
                                indexacion: indexacionSelected,
                                tipoDeArticulo: tipoArticuloSelected,
                                tituloArticulo: tituloArticulo,
                                issn: issn,
                                nombreRevista: nombreRevista,
                                anio: anio,
                                numPagTotales: numPagTotales,
                                volumenRevista: volumenRevista,
                                numeroRevista: numeroRevista,
                                paisPublicacion: paisTipoArticuloSelected,
                                idioma: idiomaTipoArticuloSelected,
                                enlace: enlace,
                                doi: doi,
                                autores: numeroIdParEvent
                            }
                        }

                        let url = "api/v1.0/investigacion/gestionSemillero"
                        const callback = (error, estado, resp) => {
                            let titulo = "Ha ocurrido un error, contacte con el administrador."
                            let tipo = "info"

                            if(!error){
                                if(estado === 201){
                                    tipo = "success"
                                    titulo = resp.titulo
                                }
                            }

                            if(estado == 201){
                                dispatch(
                                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                                handleOnClose()
                            }else{
                                dispatch(
                                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                                setCargandoGestionBibliografica(false)
                            }

                        }
                        const msg = await Funciones.consulta(url, data, 'POST', callback)
                    }
                }else if(tipoProduccionBiblio.codigo == "Inves_tipo_biblio_libro"){
                    if(numeroIdParEvent.length == 0){
                        let tipo = "info"
                        let titulo = "Inserte por lo menos 1 Participante"
                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                    }else{
                        setCargandoGestionBibliografica(true)
                        let data

                        if(tipoLibroSelected.codigo == "Inves_tipo_libro_otro"){
                            let formatAnioPublicacionLibro = moment(anioPublicacionLibro).format('YYYY-MM-DD')
                            data = {
                                tipo: tipoSolicitudActual,
                                tipo_de_producto: tipoProduccionBiblio.codigo,
                                tipoDeLibro: tipoLibroSelected,
                                semillero: props.semiActual,
                                tipoLibroOtro: tipoLibroOtro,
                                tituloLibro: tituloLibro,
                                isbnLibro: isbnLibro,
                                editorialLibro: editorialLibro,
                                anioPublicacionLibro: formatAnioPublicacionLibro,
                                paisPublicacionLibro: paisTipoLibroSelected,
                                idiomaLibro: idiomaLibroSelected,
                                volumenLibro: volumenLibro,
                                numeroEdicionLibro: numeroEdicionLibro,
                                autores: numeroIdParEvent
                            }
                        }else{
                            let formatAnioPublicacionLibro = moment(anioPublicacionLibro).format('YYYY-MM-DD')
                            data = {
                                tipo: tipoSolicitudActual,
                                semillero: props.semiActual,
                                tipo_de_producto: tipoProduccionBiblio.codigo,
                                tipoDeLibro: tipoLibroSelected,
                                tituloLibro: tituloLibro,
                                isbnLibro: isbnLibro,
                                editorialLibro: editorialLibro,
                                anioPublicacionLibro: formatAnioPublicacionLibro,
                                paisPublicacionLibro: paisTipoLibroSelected,
                                idiomaLibro: idiomaLibroSelected,
                                volumenLibro: volumenLibro,
                                numeroEdicionLibro: numeroEdicionLibro,
                                autores: numeroIdParEvent
                            }
                        }

                        let url = "api/v1.0/investigacion/gestionSemillero"
                        const callback = (error, estado, resp) => {
                            let titulo = "Ha ocurrido un error, contacte con el administrador."
                            let tipo = "info"

                            if(!error){
                                if(estado === 201){
                                    tipo = "success"
                                    titulo = resp.titulo
                                }
                            }

                            if(estado == 201){
                                dispatch(
                                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                                handleOnClose()
                            }else{
                                dispatch(
                                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                                setCargandoGestionBibliografica(false)
                            }

                        }

                        const msg = await Funciones.consulta(url, data, 'POST', callback)
                    }
                }else if(tipoProduccionBiblio.codigo == "Inves_tipo_biblio_capituloLib"){
                    if(numeroIdParEvent.length == 0){
                        let tipo = "info"
                        let titulo = "Inserte por lo menos 1 Participante"
                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                    }else{
                        setCargandoGestionBibliografica(true)
                        let data

                        if(tipoLibroSelectedCap.codigo == "Inves_tipo_capLibro_otro"){
                            let formatAnioPublicacionLibroCap = moment(anioPublicacionLibroCap).format('YYYY-MM-DD')
                            data = {
                                tipo: tipoSolicitudActual,
                                semillero: props.semiActual,
                                tipo_de_producto: tipoProduccionBiblio.codigo,
                                tipoDeLibro: tipoLibroSelectedCap,
                                tipoLibroOtroCap: tipoLibroOtroCap,
                                tituloCapLibro: tituloCapLibro,
                                tituloLibroCap: tituloLibroCap,
                                isbnLibroCap: isbnLibroCap,
                                editorialLibroCap: editorialLibroCap,
                                anioPublicacionLibroCap: formatAnioPublicacionLibroCap,
                                paisPublicacionLibroCap: paisTipoCapLibroSelected,
                                idiomaLibroCap: idiomaCapituloLibroSelected,
                                volumenLibroCap: volumenLibroCap,
                                numeroEdicionLibroCap: numeroEdicionLibroCap,
                                autores: numeroIdParEvent
                            }
                        }else{
                            let formatAnioPublicacionLibroCap = moment(anioPublicacionLibroCap).format('YYYY-MM-DD')
                            data = {
                                tipo: tipoSolicitudActual,
                                semillero: props.semiActual,
                                tipo_de_producto: tipoProduccionBiblio.codigo,
                                tipoDeLibro: tipoLibroSelectedCap,
                                tituloCapLibro: tituloCapLibro,
                                tituloLibroCap: tituloLibroCap,
                                isbnLibroCap: isbnLibroCap,
                                editorialLibroCap: editorialLibroCap,
                                anioPublicacionLibroCap: formatAnioPublicacionLibroCap,
                                paisPublicacionLibroCap: paisTipoCapLibroSelected,
                                idiomaLibroCap: idiomaCapituloLibroSelected,
                                volumenLibroCap: volumenLibroCap,
                                numeroEdicionLibroCap: numeroEdicionLibroCap,
                                autores: numeroIdParEvent
                            }
                        }

                        let url = "api/v1.0/investigacion/gestionSemillero"
                        const callback = (error, estado, resp) => {
                            let titulo = "Ha ocurrido un error, contacte con el administrador."
                            let tipo = "info"

                            if(!error){
                                if(estado === 201){
                                    tipo = "success"
                                    titulo = resp.titulo
                                }
                            }

                            if(estado == 201){
                                dispatch(
                                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                                handleOnClose()
                            }else{
                                dispatch(
                                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                                setCargandoGestionBibliografica(false)
                            }

                        }

                        const msg = await Funciones.consulta(url, data, 'POST', callback)
                    }
                }else if(tipoProduccionBiblio.codigo == "Inves_tipo_biblio_patente"){
                    if(numeroIdParEvent.length == 0){
                        let tipo = "info"
                        let titulo = "Inserte por lo menos 1 Participante"
                        dispatch(
                            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                    }else{
                        setCargandoGestionBibliografica(true)
                        let data

                        if(tipoPatenteSelected.codigo == "Inves_tipo_patente_otro"){
                            let formatAnioObtencionPatente = moment(anioObtencionPatente).format('YYYY-MM-DD')
                            data = {
                                tipo: tipoSolicitudActual,
                                semillero: props.semiActual,
                                tipo_de_producto: tipoProduccionBiblio.codigo,
                                tipoPatente: tipoPatenteSelected,
                                tipoPatenteOtro: tipoPatenteOtro,
                                anioDeObtenecion: formatAnioObtencionPatente,
                                tituloPatente:tituloPatente,
                                numeroPatente:numeroPatente,
                                paisObtencionPatente:paisPatenteSelected,
                                gacetaIndustrialPatente:gacetaIndustrialPatente,
                                institucionPatente:institucionPatente,
                                autores: numeroIdParEvent
                            }
                        }else{
                            let formatAnioObtencionPatente = moment(anioObtencionPatente).format('YYYY-MM-DD')
                            data = {
                                tipo: tipoSolicitudActual,
                                semillero: props.semiActual,
                                tipo_de_producto: tipoProduccionBiblio.codigo,
                                tipoPatente: tipoPatenteSelected,
                                anioDeObtenecion: formatAnioObtencionPatente,
                                tituloPatente:tituloPatente,
                                numeroPatente:numeroPatente,
                                paisObtencionPatente:paisPatenteSelected,
                                gacetaIndustrialPatente:gacetaIndustrialPatente,
                                institucionPatente:institucionPatente,
                                autores: numeroIdParEvent
                            }
                        }

                        let url = "api/v1.0/investigacion/gestionSemillero"
                        const callback = (error, estado, resp) => {
                            let titulo = "Ha ocurrido un error, contacte con el administrador."
                            let tipo = "info"

                            if(!error){
                                if(estado === 201){
                                    tipo = "success"
                                    titulo = resp.titulo
                                }
                            }

                            if(estado == 201){
                                dispatch(
                                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                                handleOnClose()
                            }else{
                                dispatch(
                                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                                setCargandoGestionBibliografica(false)
                            }

                        }

                        const msg = await Funciones.consulta(url, data, 'POST', callback)
                    }
                }
            }
        }else if(seleccion == "gestionTecnica"){

            if(tipoProduccionTecnica.codigo == "Inves_tipo_tec_prodTecCerVal"){

                if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_diseñoIndustrial"){

                    if(activeStepBiblio == 1){

                        if(numeroIdParEvent.length == 0){
                            let tipo = "info"
                            let titulo = "Inserte por lo menos 1 Participante"
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                        }else{
                            setCargandoProduTec(true)
                            let data = {}


                            if(productoTieneSelected.codigo != "ningunoDiseñoPrototipoTiene"){
                                let fechaCreacionDiseñoIndusFormat = moment(fechaCreacionDiseñoIndus).format('YYYY-MM-DD');
                                let fechaRegistroDiseñoPrototipoTieneFormat = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                                data = {
                                    semillero: props.semiActual,
                                    tipoSolicitud: tipoSolicitudActual,
                                    tipoProduccionTecnica: tipoProduccionTecnica,
                                    tipoProductoTecSelected: tipoProductoTecSelected,
                                    inputs: inputs,
                                    productoTieneSelected: productoTieneSelected,
                                    inputsExtra: inputsSelects,
                                    fechaRegistroDiseñoPrototipoTiene: fechaRegistroDiseñoPrototipoTieneFormat,
                                    fechaCreacionDiseñoIndus: fechaCreacionDiseñoIndusFormat,
                                    paisSelectedTecTecno:paisSelectedTecTecno,
                                    autores: numeroIdParEvent
                                }
                            }else{
                                let fechaCreacionDiseñoIndusFormat = moment(fechaCreacionDiseñoIndus).format('YYYY-MM-DD');
                                data = {
                                    semillero: props.semiActual,
                                    tipoSolicitud: tipoSolicitudActual,
                                    tipoProduccionTecnica: tipoProduccionTecnica,
                                    tipoProductoTecSelected: tipoProductoTecSelected,
                                    inputs: inputs,
                                    productoTieneSelected: productoTieneSelected,
                                    fechaCreacionDiseñoIndus: fechaCreacionDiseñoIndusFormat,
                                    paisSelectedTecTecno:paisSelectedTecTecno,
                                    autores: numeroIdParEvent
                                }
                            }

                            let url = "api/v1.0/investigacion/gestionProduTecyTecno"
                            const callback = (error, estado, resp) => {
                                let titulo = "Ha ocurrido un error, contacte con el administrador."
                                let tipo = "info"

                                if(!error){
                                    if(estado === 201){
                                        tipo = "success"
                                        titulo = resp.titulo
                                    }
                                }

                                if(estado == 201){
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    handleOnClose()
                                }else{
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    setCargandoProduTec(false)
                                }
                            }

                            const msg = await Funciones.consulta(url, data, 'POST', callback)
                        }

                    }

                }else if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_prototipo"){
                    if(activeStepBiblio == 1){
                        if(numeroIdParEvent.length == 0){
                            let tipo = "info"
                            let titulo = "Inserte por lo menos 1 Participante"
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                        }else{
                            setCargandoProduTec(true)
                            let data = {}
                            if(productoTieneSelected.codigo != "ningunoDiseñoPrototipoTiene"){
                                if(tipoPrototipoSelected.codigo != "Inves_tipo_prototipo_otro"){
                                    let fechaRegistroDiseñoPrototipoTieneFormat = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                                    let fechaCreacionPrototipoFormat = moment(fechaCreacionPrototipo).format('YYYY-MM-DD');
                                    data = {
                                        semillero: props.semiActual,
                                        tipoSolicitud: tipoSolicitudActual,
                                        tipoProduccionTecnica: tipoProduccionTecnica,
                                        tipoProductoTecSelected: tipoProductoTecSelected,
                                        inputs: inputs,
                                        productoTieneSelected: productoTieneSelected,
                                        inputsExtra: inputsSelects,
                                        tipoPrototipoSelected: tipoPrototipoSelected,
                                        fechaRegistroDiseñoPrototipoTiene: fechaRegistroDiseñoPrototipoTieneFormat,
                                        fechaCreacionPrototipo: fechaCreacionPrototipoFormat,
                                        autores: numeroIdParEvent,
                                        paisSelectedTecTecno:paisSelectedTecTecno,
                                    }
                                }else{
                                    let fechaRegistroDiseñoPrototipoTieneFormat = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                                    let fechaCreacionPrototipoFormat = moment(fechaCreacionPrototipo).format('YYYY-MM-DD');

                                    data = {
                                        semillero: props.semiActual,
                                        tipoSolicitud: tipoSolicitudActual,
                                        tipoProduccionTecnica: tipoProduccionTecnica,
                                        tipoProductoTecSelected: tipoProductoTecSelected,
                                        inputs: inputs,
                                        productoTieneSelected: productoTieneSelected,
                                        inputsExtra: inputsSelects,
                                        tipoPrototipoSelected: tipoPrototipoSelected,
                                        otroTipoPrototipoSelected: {codigo: "otroTipoPrototipoSelected", value: otroTipoPrototipoSelected},
                                        fechaRegistroDiseñoPrototipoTiene: fechaRegistroDiseñoPrototipoTieneFormat,
                                        fechaCreacionPrototipo: fechaCreacionPrototipoFormat,
                                        autores: numeroIdParEvent,
                                        paisSelectedTecTecno:paisSelectedTecTecno,
                                    }
                                }
                            }else{
                                if(tipoPrototipoSelected.codigo != "Inves_tipo_prototipo_otro"){
                                    let fechaCreacionPrototipoFormat = moment(fechaCreacionPrototipo).format('YYYY-MM-DD');
                                    data = {
                                        semillero: props.semiActual,
                                        tipoSolicitud: tipoSolicitudActual,
                                        tipoProduccionTecnica: tipoProduccionTecnica,
                                        tipoProductoTecSelected: tipoProductoTecSelected,
                                        inputs: inputs,
                                        productoTieneSelected: productoTieneSelected,
                                        tipoPrototipoSelected: tipoPrototipoSelected,
                                        fechaCreacionPrototipo: fechaCreacionPrototipoFormat,
                                        autores: numeroIdParEvent,
                                        paisSelectedTecTecno:paisSelectedTecTecno,
                                    }
                                }else{
                                    let fechaCreacionPrototipoFormat = moment(fechaCreacionPrototipo).format('YYYY-MM-DD');
                                    data = {
                                        semillero: props.semiActual,
                                        tipoSolicitud: tipoSolicitudActual,
                                        tipoProduccionTecnica: tipoProduccionTecnica,
                                        tipoProductoTecSelected: tipoProductoTecSelected,
                                        inputs: inputs,
                                        productoTieneSelected: productoTieneSelected,
                                        tipoPrototipoSelected: tipoPrototipoSelected,
                                        otroTipoPrototipoSelected: {codigo: "otroTipoPrototipoSelected", value: otroTipoPrototipoSelected},
                                        fechaCreacionPrototipo: fechaCreacionPrototipoFormat,
                                        autores: numeroIdParEvent,
                                        paisSelectedTecTecno:paisSelectedTecTecno,
                                    }
                                }

                            }

                            let url = "api/v1.0/investigacion/gestionProduTecyTecno"
                            const callback = (error, estado, resp) => {
                                let titulo = "Ha ocurrido un error, contacte con el administrador."
                                let tipo = "info"

                                if(!error){
                                    if(estado === 201){
                                        tipo = "success"
                                        titulo = resp.titulo
                                    }
                                }

                                if(estado == 201){
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    handleOnClose()
                                }else{
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    setCargandoProduTec(false)
                                }

                            }

                            const msg = await Funciones.consulta(url, data, 'POST', callback)
                        }
                    }
                }else if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_signo_distintivo"){
                    if(activeStepBiblio == 1){
                        if(numeroIdParEvent.length == 0){
                            let tipo = "info"
                            let titulo = "Inserte por lo menos 1 Participante"
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                        }else{
                            setCargandoProduTec(true)
                            let fechaCreacionSignoDistintivoFormat = moment(fechaCreacionSignoDistintivo).format('YYYY-MM-DD');
                            let data = {
                                semillero: props.semiActual,
                                tipoSolicitud: tipoSolicitudActual,
                                tipoProduccionTecnica: tipoProduccionTecnica,
                                tipoProductoTecSelected: tipoProductoTecSelected,
                                fechaCreacionSignoDistintivo: fechaCreacionSignoDistintivoFormat,
                                tipoSignoDistintivoSelected: tipoSignoDistintivoSelected,
                                inputs: inputs,
                                autores: numeroIdParEvent,
                                paisSelectedTecTecno:paisSelectedTecTecno,
                            }

                            let url = "api/v1.0/investigacion/gestionProduTecyTecno"
                            const callback = (error, estado, resp) => {
                                let titulo = "Ha ocurrido un error, contacte con el administrador."
                                let tipo = "info"

                                if(!error){
                                    if(estado === 201){
                                        tipo = "success"
                                        titulo = resp.titulo
                                    }
                                }

                                if(estado == 201){
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    handleOnClose()
                                }else{
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    setCargandoProduTec(false)
                                }

                            }

                            const msg = await Funciones.consulta(url, data, 'POST', callback)
                        }
                    }
                }else if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_software"){
                    if(activeStepBiblio == 1){
                        if(numeroIdParEvent.length == 0){
                            let tipo = "info"
                            let titulo = "Inserte por lo menos 1 Participante"
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                        }else{
                            setCargandoProduTec(true)
                            let data = {}
                            if(tipoSoftwareSelected.codigo == "Inves_tipo_software_otro"){
                                let fechaDesarrolloSoftwareFormat = moment(fechaDesarrolloSoftware).format('YYYY-MM-DD');
                                let fechaObtencionRegistroSoftwareFormat = moment(fechaObtencionRegistroSoftware).format('YYYY-MM-DD');

                                data = {
                                    semillero: props.semiActual,
                                    tipoSolicitud: tipoSolicitudActual,
                                    tipoProduccionTecnica: tipoProduccionTecnica,
                                    tipoProductoTecSelected: tipoProductoTecSelected,
                                    fechaDesarrolloSoftware: fechaDesarrolloSoftwareFormat,
                                    fechaObtencionRegistroSoftware: fechaObtencionRegistroSoftwareFormat,
                                    tipoSoftwareSelected: tipoSoftwareSelected,
                                    otroTipoSoftwareSelected: {codigo: "otroTipoSoftwareSelected", value: otroTipoSoftwareSelected},
                                    inputs: inputs,
                                    autores: numeroIdParEvent,
                                    paisSelectedTecTecno:paisSelectedTecTecno,
                                }
                            }else{
                                let fechaDesarrolloSoftwareFormat = moment(fechaDesarrolloSoftware).format('YYYY-MM-DD');
                                let fechaObtencionRegistroSoftwareFormat = moment(fechaObtencionRegistroSoftware).format('YYYY-MM-DD');

                                data = {
                                    semillero: props.semiActual,
                                    tipoSolicitud: tipoSolicitudActual,
                                    tipoProduccionTecnica: tipoProduccionTecnica,
                                    tipoProductoTecSelected: tipoProductoTecSelected,
                                    fechaDesarrolloSoftware: fechaDesarrolloSoftwareFormat,
                                    fechaObtencionRegistroSoftware: fechaObtencionRegistroSoftwareFormat,
                                    tipoSoftwareSelected: tipoSoftwareSelected,
                                    inputs: inputs,
                                    autores: numeroIdParEvent,
                                    paisSelectedTecTecno:paisSelectedTecTecno,
                                }
                            }

                            let url = "api/v1.0/investigacion/gestionProduTecyTecno"
                            const callback = (error, estado, resp) => {
                                let titulo = "Ha ocurrido un error, contacte con el administrador."
                                let tipo = "info"

                                if(!error){
                                    if(estado === 201){
                                        tipo = "success"
                                        titulo = resp.titulo
                                    }
                                }

                                if(estado == 201){
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    handleOnClose()
                                }else{
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    setCargandoProduTec(false)
                                }

                            }

                            const msg = await Funciones.consulta(url, data, 'POST', callback)
                        }
                    }
                }

            }else if(tipoProduccionTecnica.codigo == "Inves_tipo_tec_prodEmp"){

                if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_empCreaCul"){
                    if(activeStepBiblio == 1){
                        if(numeroIdParEvent.length == 0){
                            let tipo = "info"
                            let titulo = "Inserte por lo menos 1 Participante"
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                        }else{
                            setCargandoProduTec(true)
                            let fechaRegistroBaseTecnoFormat = moment(fechaRegistroEmpresaCultural).format('YYYY-MM-DD');
                            let data
                            data = {
                                semillero: props.semiActual,
                                tipoSolicitud: tipoSolicitudActual,
                                tipoProduccionTecnica: tipoProduccionTecnica,
                                tipoProductoTecSelected: tipoProductoTecSelected,
                                fechaRegistroEmpresaCultural:fechaRegistroBaseTecnoFormat,
                                inputs: inputs,
                                autores: numeroIdParEvent,
                                ambitoDisiciplinaSelected: tipoDisciplinaAmbitoSelected
                            }
                            let url = "api/v1.0/investigacion/gestionProduTecyTecno"
                            const callback = (error, estado, resp) => {
                                let titulo = "Ha ocurrido un error, contacte con el administrador."
                                let tipo = "info"

                                if(!error){
                                    if(estado === 201){
                                        tipo = "success"
                                        titulo = resp.titulo
                                    }
                                }

                                if(estado == 201){
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    handleOnClose()
                                }else{
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    setCargandoProduTec(false)
                                }

                            }
                            const msg = await Funciones.consulta(url, data, 'POST', callback)
                        }
                    }
                }else if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_empBaseTec"){
                    if(activeStepBiblio == 1){
                        if(numeroIdParEvent.length == 0){
                            let tipo = "info"
                            let titulo = "Inserte por lo menos 1 Participante"
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                        }else{
                            setCargandoProduTec(true)
                            let fechaRegistroBaseTecnoFormat = moment(fechaRegistroBaseTecno).format('YYYY-MM-DD');
                            let data
                            data = {
                                semillero: props.semiActual,
                                tipoSolicitud: tipoSolicitudActual,
                                tipoProduccionTecnica: tipoProduccionTecnica,
                                tipoProductoTecSelected: tipoProductoTecSelected,
                                tipoEmpresaSelected: tipoEmpresaSelected,
                                fechaRegistroBaseTecno:fechaRegistroBaseTecnoFormat,
                                inputs: inputs,
                                autores: numeroIdParEvent,
                                paisSelectedTecTecno:paisSelectedTecTecno,
                            }
                            let url = "api/v1.0/investigacion/gestionProduTecyTecno"
                            const callback = (error, estado, resp) => {
                                let titulo = "Ha ocurrido un error, contacte con el administrador."
                                let tipo = "info"

                                if(!error){
                                    if(estado === 201){
                                        tipo = "success"
                                        titulo = resp.titulo
                                    }
                                }

                                if(estado == 201){
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    handleOnClose()
                                }else{
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    setCargandoProduTec(false)
                                }

                            }

                            const msg = await Funciones.consulta(url, data, 'POST', callback)
                        }
                    }
                }else if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_innProProSer"){
                    if(activeStepBiblio == 1){
                        if(numeroIdParEvent.length == 0){
                            let tipo = "info"
                            let titulo = "Inserte por lo menos 1 Participante"
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                        }else{
                            setCargandoProduTec(true)
                            let data
                            if(productoTieneSelected.codigo != "ningunoDiseñoPrototipoTiene"){
                                let fechaRegistroDiseñoPrototipoTieneFormat = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');
                                let fechaInnovacionProceServiFormat = moment(fechaInnovacionProceServi).format('YYYY-MM-DD');

                                data = {
                                    semillero: props.semiActual,
                                    tipoSolicitud: tipoSolicitudActual,
                                    tipoProduccionTecnica: tipoProduccionTecnica,
                                    tipoProductoTecSelected: tipoProductoTecSelected,
                                    fechaInnovacionProceServi: fechaInnovacionProceServiFormat,
                                    productoTieneSelected: productoTieneSelected,
                                    inputs: inputs,
                                    inputsSelects:inputsSelects,
                                    fechaRegistroDiseñoPrototipoTiene:fechaRegistroDiseñoPrototipoTieneFormat,
                                    autores: numeroIdParEvent,
                                    paisSelectedTecTecno:paisSelectedTecTecno,
                                }
                            }else{
                                let fechaInnovacionProceServiFormat = moment(fechaInnovacionProceServi).format('YYYY-MM-DD');

                                data = {
                                    semillero: props.semiActual,
                                    tipoSolicitud: tipoSolicitudActual,
                                    tipoProduccionTecnica: tipoProduccionTecnica,
                                    tipoProductoTecSelected: tipoProductoTecSelected,
                                    fechaInnovacionProceServi: fechaInnovacionProceServiFormat,
                                    productoTieneSelected: productoTieneSelected,
                                    inputs: inputs,
                                    autores: numeroIdParEvent,
                                    paisSelectedTecTecno:paisSelectedTecTecno,
                                }
                            }

                            let url = "api/v1.0/investigacion/gestionProduTecyTecno"
                            const callback = (error, estado, resp) => {
                                let titulo = "Ha ocurrido un error, contacte con el administrador."
                                let tipo = "info"

                                if(!error){
                                    if(estado === 201){
                                        tipo = "success"
                                        titulo = resp.titulo
                                    }
                                }

                                if(estado == 201){
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    handleOnClose()
                                }else{
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    setCargandoProduTec(false)
                                }

                            }

                            const msg = await Funciones.consulta(url, data, 'POST', callback)
                        }
                    }
                }else if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_innGenGesEmp"){
                    if(activeStepBiblio == 1){
                        if(numeroIdParEvent.length == 0){
                            let tipo = "info"
                            let titulo = "Inserte por lo menos 1 Participante"
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                        }else{
                            setCargandoProduTec(true)
                            let data
                            if(productoTieneSelected.codigo != "ningunoDiseñoPrototipoTiene"){
                                let fechaInnovacionGestionEmpFormat = moment(fechaInnovacionGestionEmp).format('YYYY-MM-DD');
                                let fechaRegistroDiseñoPrototipoTieneFormat = moment(fechaRegistroDiseñoPrototipoTiene).format('YYYY-MM-DD');

                                data = {
                                    semillero: props.semiActual,
                                    tipoSolicitud: tipoSolicitudActual,
                                    tipoProduccionTecnica: tipoProduccionTecnica,
                                    tipoProductoTecSelected: tipoProductoTecSelected,
                                    tipoInnovacionSelected:tipoInnovacionSelected,
                                    fechaInnovacionGestionEmp: fechaInnovacionGestionEmpFormat,
                                    productoTieneSelected: productoTieneSelected,
                                    inputs: inputs,
                                    inputsSelects:inputsSelects,
                                    fechaRegistroDiseñoPrototipoTiene:fechaRegistroDiseñoPrototipoTieneFormat,
                                    autores: numeroIdParEvent,
                                    paisSelectedTecTecno:paisSelectedTecTecno,
                                }
                            }else{
                                let fechaInnovacionGestionEmpFormat = moment(fechaInnovacionGestionEmp).format('YYYY-MM-DD');

                                data = {
                                    semillero: props.semiActual,
                                    tipoSolicitud: tipoSolicitudActual,
                                    tipoProduccionTecnica: tipoProduccionTecnica,
                                    tipoProductoTecSelected: tipoProductoTecSelected,
                                    fechaInnovacionGestionEmp: fechaInnovacionGestionEmpFormat,
                                    tipoInnovacionSelected:tipoInnovacionSelected,
                                    productoTieneSelected: productoTieneSelected,
                                    inputs: inputs,
                                    autores: numeroIdParEvent,
                                    paisSelectedTecTecno:paisSelectedTecTecno,
                                }
                            }

                            let url = "api/v1.0/investigacion/gestionProduTecyTecno"
                            const callback = (error, estado, resp) => {
                                let titulo = "Ha ocurrido un error, contacte con el administrador."
                                let tipo = "info"

                                if(!error){
                                    if(estado === 201){
                                        tipo = "success"
                                        titulo = resp.titulo
                                    }
                                }

                                if(estado == 201){
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    handleOnClose()
                                }else{
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    setCargandoProduTec(false)
                                }

                            }

                            const msg = await Funciones.consulta(url, data, 'POST', callback)
                        }
                    }
                }
            }else if(tipoProduccionTecnica.codigo == "Inves_tipo_tec_prodInvesCrea"){
                if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_eveArt"){
                    if(activeStepBiblio == 1){
                        if(numeroIdParEvent.length == 0){
                            let tipo = "info"
                            let titulo = "Inserte por lo menos 1 Participante"
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                        }else{
                            setCargandoProduTec(true)
                            let data
                            let fechaInicioEventoArtisticoFormat = moment(fechaInicioEventoArtistico).format('YYYY-MM-DD');
                            let fechaFinalEventoArtisticoFormat = moment(fechaFinalEventoArtistico).format('YYYY-MM-DD');
                            data = {
                                semillero: props.semiActual,
                                tipoSolicitud: tipoSolicitudActual,
                                tipoProduccionTecnica: tipoProduccionTecnica,
                                tipoProductoTecSelected: tipoProductoTecSelected,
                                tipoAmbitoSelected:tipoAmbitoSelected,
                                fechaInicioEventoArtistico: fechaInicioEventoArtisticoFormat,
                                fechaFinalEventoArtistico: fechaFinalEventoArtisticoFormat,
                                inputs: inputs,
                                autores: numeroIdParEvent,
                                paisSelectedTecTecno:paisSelectedTecTecno,
                            }


                            let url = "api/v1.0/investigacion/gestionProduTecyTecno"
                            const callback = (error, estado, resp) => {
                                let titulo = "Ha ocurrido un error, contacte con el administrador."
                                let tipo = "info"

                                if(!error){
                                    if(estado === 201){
                                        tipo = "success"
                                        titulo = resp.titulo
                                    }
                                }

                                if(estado == 201){
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    handleOnClose()
                                }else{
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    setCargandoProduTec(false)
                                }

                            }

                            const msg = await Funciones.consulta(url, data, 'POST', callback)
                        }
                    }
                }else if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_obrPro"){
                    if(activeStepBiblio == 1){
                        if(numeroIdParEvent.length == 0){
                            let tipo = "info"
                            let titulo = "Inserte por lo menos 1 Participante"
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                        }else{
                            setCargandoProduTec(true)
                            let data
                            let fechaCreacionObraProductoFormat = moment(fechaCreacionObraProducto).format('YYYY-MM-DD');
                            let fechaPresentacionObraProductoFormat = moment(fechaPresentacionObraProducto).format('YYYY-MM-DD');

                            if(tipoEspacioEventoSelected.codigo == "Inves_tipo_espaEven_otro"){
                                data = {
                                    semillero: props.semiActual,
                                    tipoSolicitud: tipoSolicitudActual,
                                    tipoProduccionTecnica: tipoProduccionTecnica,
                                    tipoProductoTecSelected: tipoProductoTecSelected,
                                    tipoAmbitoSelected:tipoAmbitoSelected,
                                    tipoEspacioEventoSelected:tipoEspacioEventoSelected,
                                    otroTipoEspacioEvento: {codigo: "otroTipoEspacioEvento", value: otroTipoEspacioEvento},
                                    tipoDisciplinaAmbitoSelected:tipoDisciplinaAmbitoSelected,
                                    fechaCreacionObraProducto: fechaCreacionObraProductoFormat,
                                    fechaPresentacionObraProducto: fechaPresentacionObraProductoFormat,
                                    inputs: inputs,
                                    autores: numeroIdParEvent,
                                    paisSelectedTecTecno:paisSelectedTecTecno,
                                }
                            }else{
                                data = {
                                    semillero: props.semiActual,
                                    tipoSolicitud: tipoSolicitudActual,
                                    tipoProduccionTecnica: tipoProduccionTecnica,
                                    tipoProductoTecSelected: tipoProductoTecSelected,
                                    tipoAmbitoSelected:tipoAmbitoSelected,
                                    tipoEspacioEventoSelected:tipoEspacioEventoSelected,
                                    tipoDisciplinaAmbitoSelected:tipoDisciplinaAmbitoSelected,
                                    fechaCreacionObraProducto: fechaCreacionObraProductoFormat,
                                    fechaPresentacionObraProducto: fechaPresentacionObraProductoFormat,
                                    inputs: inputs,
                                    autores: numeroIdParEvent,
                                    paisSelectedTecTecno:paisSelectedTecTecno,
                                }
                            }

                            let url = "api/v1.0/investigacion/gestionProduTecyTecno"
                            const callback = (error, estado, resp) => {
                                let titulo = "Ha ocurrido un error, contacte con el administrador."
                                let tipo = "info"

                                if(!error){
                                    if(estado === 201){
                                        tipo = "success"
                                        titulo = resp.titulo
                                    }
                                }

                                if(estado == 201){
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    handleOnClose()
                                }else{
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    setCargandoProduTec(false)
                                }

                            }

                            const msg = await Funciones.consulta(url, data, 'POST', callback)
                        }
                    }
                }else if(tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_talCre"){
                    if(activeStepBiblio == 1){
                        if(numeroIdParEvent.length == 0){
                            let tipo = "info"
                            let titulo = "Inserte por lo menos 1 Participante"
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                );
                        }else{
                            setCargandoProduTec(true)
                            let data
                            let fechaInicioTalleresCreacionFormat = moment(fechaInicioTalleresCreacion).format('YYYY-MM-DD');
                            let fechaFinalTalleresCreacionFormat = moment(fechaFinalTalleresCreacion).format('YYYY-MM-DD');

                            data = {
                                semillero: props.semiActual,
                                tipoSolicitud: tipoSolicitudActual,
                                tipoProduccionTecnica: tipoProduccionTecnica,
                                tipoProductoTecSelected: tipoProductoTecSelected,
                                tipoAmbitoSelected:tipoAmbitoSelected,
                                tipoEventoSelected:tipoEventoSelected,
                                fechaInicioTalleresCreacion: fechaInicioTalleresCreacionFormat,
                                fechaFinalTalleresCreacion: fechaFinalTalleresCreacionFormat,
                                inputs: inputs,
                                autores: numeroIdParEvent,
                                paisSelectedTecTecno:paisSelectedTecTecno,
                            }

                            let url = "api/v1.0/investigacion/gestionProduTecyTecno"
                            const callback = (error, estado, resp) => {
                                let titulo = "Ha ocurrido un error, contacte con el administrador."
                                let tipo = "info"

                                if(!error){
                                    if(estado === 201){
                                        tipo = "success"
                                        titulo = resp.titulo
                                    }
                                }

                                if(estado == 201){
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    handleOnClose()
                                }else{
                                    dispatch(
                                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                                    );
                                    setCargandoProduTec(false)
                                }

                            }

                            const msg = await Funciones.consulta(url, data, 'POST', callback)
                        }
                    }
                }
            }
        }
    }

    //Funcion para hacer un reset en el Step
    const handleReset = () => {
        setActiveStep(0);
    };

    //Funcion para hacer un reset en el Step de Bibliografia
    const handleResetTipoBiblio = () => {
        setActiveStepBiblio(0);
    };

    //Funcion para hacer un back en el Step
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //Funcion para hacer un back en el Step de Bibliografia
    const handleBackTipoBiblio = () => {
        setActiveStepBiblio((prevActiveStep) => prevActiveStep - 1);
    };

    const handleOnClose = () =>{
        //Reseteo Eventos
        setAnio(new Date())
        setFechaInicioEvento(new Date())
        setFechaFinEvento(new Date())
        setDepartamentos([])
        setDepartamento('')
        setCiudades([])
        setCiudad('')
        setNombreDelEvento('')
        setNombreDelAmbito('')
        setNombreModalidadPar('')
        setAdj_evento('')

        //Reseteo Eventos
        setFechaInicio(new Date())
        setFechaFin(new Date())
        setNombreProyecto('')
        setDescripcionProyecto('')
        setAsignaturaProyecto('')
        setNombreprofesorProyecto('')
        //Variables Prod. Tec y Tecno
        setNombreProductoTec('')
        setTipoProductoTec('')
        setNitTec('')
        setNumeroRegistroTec('')
        setNombreTitular('')
        setAnioTec(new Date())
        setPaisTec('')
        setSeleccion('tipo')

        setErrores([])
        setActiveStep(0);
        setActiveStepBiblio(0);
        props.setOpenModalGestion(false)
    }

    //Seccion de Produccion bibliografica funciones

    const vista = () => {

        switch (seleccion) {
          case 'tipo':
            return (
                cargando == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> :
                    <Grid container direction={'row'} justify={'center'}>
                        {tipoSolicitud.map((item, index) => {
                            return(
                            <Grid key={index} item xs={12} sm={6} style={{ padding: '2% 2% 0% 2%' }} onClick={() => { handleClickChangeToForm(item) }}>
                                <Paper className={classes.color} elevation={8} square>
                                    <Card className={classes.color} style={{ minHeight: '300px', cursor: 'pointer' }} elevation={1}>
                                    <CardHeader
                                        avatar={<Avatar aria-label="recipe" style={{ backgroundColor: item.valora }}>{item.valorc.charAt(0)}</Avatar>}
                                        title={item.valorc}
                                    />
                                    <CardContent >
                                        <Grid container direction={'row'} justify={'center'}>
                                            <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                                                <img src={assigment} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                                                <Typography className={classes.center} variant="body2" color="textSecondary" component="p" align='justify'>
                                                    {item.valorb}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    </Card>
                                </Paper>
                            </Grid>
                            )
                        })}
                    </Grid>
            )
          case 'gestion':
            return (
                <ModalStepperForm
                    //Stepper
                    hola={activeStep}
                    handleReset={handleReset}
                    steps={steps}
                    tipoSolicitudActual={tipoSolicitudActual}
                    setTipoSolicitudActual={setTipoSolicitudActual}

                    //Variables eventos
                    anio={anio}
                    setAnio={setAnio}

                    fechaInicioEvento={fechaInicioEvento}
                    setFechaInicioEvento={setFechaInicioEvento}

                    fechaFinEvento={fechaFinEvento}
                    setFechaFinEvento={setFechaFinEvento}

                    departamentos={departamentos}
                    setDepartamentos={setDepartamentos}

                    departamento={departamento}
                    setDepartamento={setDepartamento}

                    ciudades={ciudades}
                    setCiudades={setCiudades}

                    ciudad={ciudad}
                    setCiudad={setCiudad}

                    nombreDelEvento={nombreDelEvento}
                    setNombreDelEvento={setNombreDelEvento}

                    nombreDelAmbito={nombreDelAmbito}
                    setNombreDelAmbito={setNombreDelAmbito}

                    nombreAmbitos={nombreAmbitos}
                    setNombreAmbitos={setNombreAmbitos}

                    nombreModalidadPar={nombreModalidadPar}
                    setNombreModalidadPar={setNombreModalidadPar}

                    nombreModalidadPars={nombreModalidadPars}
                    setNombreModalidadPars={setNombreModalidadPars}

                    cargandoEvento={cargandoEvento}
                    setCargandoEvento={setCargandoEvento}

                    adj_evento={adj_evento}
                    setAdj_evento={setAdj_evento}

                    adj_certificado_final={adj_certificado_final}
                    setAdj_certificado_final={setAdj_certificado_final}

                    adj_certificado={adj_certificado}
                    setAdj_certificado={setAdj_certificado}

                    //Variables Proyectos
                    fechaInicio={fechaInicio}
                    setFechaInicio={setFechaInicio}

                    fechaFin={fechaFin}
                    setFechaFin={setFechaFin}

                    nombreProyecto={nombreProyecto}
                    setNombreProyecto={setNombreProyecto}

                    nombreprofesorProyecto={nombreprofesorProyecto}
                    setNombreprofesorProyecto={setNombreprofesorProyecto}

                    profesorProyecto={profesorProyecto}
                    setProfesorProyecto={setProfesorProyecto}

                    obtenerliderSemillero={props.obtenerliderSemillero}

                    descripcionProyecto={descripcionProyecto}
                    setDescripcionProyecto={setDescripcionProyecto}

                    asignaturaProyecto={asignaturaProyecto}
                    setAsignaturaProyecto={setAsignaturaProyecto}


                    //Variables Prod. Bibliografica
                    nombreProductoBiblio={nombreProductoBiblio}
                    setNombreProductoBiblio={setNombreProductoBiblio}

                    tipoProductoBiblio={tipoProductoBiblio}
                    setTipoProductoBiblio={setTipoProductoBiblio}

                    anioBiblio={anioBiblio}
                    setAnioBiblio={setAnioBiblio}

                    revistaEditorialBiblio={revistaEditorialBiblio}
                    setRevistaEditorialBiblio={setRevistaEditorialBiblio}

                    cuartilBiblio={cuartilBiblio}
                    setCuartilBiblio={setCuartilBiblio}

                    lugarPublicacionBiblio={lugarPublicacionBiblio}
                    setLugarPublicacionBiblio={setLugarPublicacionBiblio}

                    medioDivulgacionBiblio={medioDivulgacionBiblio}
                    setMedioDivulgacionBiblio={setMedioDivulgacionBiblio}

                    //Variables Prod. Tec y Tecno
                    nombreProductoTec={nombreProductoTec}
                    setNombreProductoTec={setNombreProductoTec}

                    tipoProductoTec={tipoProductoTec}
                    setTipoProductoTec={setTipoProductoTec}

                    nitTec={nitTec}
                    setNitTec={setNitTec}

                    numeroRegistroTec={numeroRegistroTec}
                    setNumeroRegistroTec={setNumeroRegistroTec}

                    nombreTitular={nombreTitular}
                    setNombreTitular={setNombreTitular}

                    anioTec={anioTec}
                    setAnioTec={setAnioTec}

                    paisTec={paisTec}
                    setPaisTec={setPaisTec}

                    //Variables Autores y particiapntes
                    numeroIdParEvent={numeroIdParEvent}
                    setNumeroIdParEvent={setNumeroIdParEvent}
                    arrai={arrai}
                    setArrai={setArrai}

                    //Cargando
                    cargando={cargandoFormGestion}
                    setCargando={setCargandoFormGestion}

                    //Permisos de la solicitud
                    permisosDeLaSolicitud={permisosDeLaSolicitud}
                    setPermisosDeLaSolicitud={setPermisosDeLaSolicitud}

                    //Errores
                    getError={getError}
                    errores={errores}
                    setErrores={setErrores}

                    //rol y modalidad
                    rolActive={rolActive}
                    setRolActive={setRolActive}

                    modalidadParticipacionActive={modalidadParticipacionActive}
                    setModalidadParticipacionActive={setModalidadParticipacionActive}
                />
            )
        case 'bibliografica':
            return(
                <ModalProduccionBibliografica
                //Stepper
                hola={activeStep}
                handleReset={handleReset}
                steps={steps}
                tipoSolicitudActual={tipoSolicitudActual}
                setTipoSolicitudActual={setTipoSolicitudActual}

                //Cargando
                cargandoProduBibliografico={cargandoProduBibliografico}
                setCargandoProduBibliografico={setCargandoProduBibliografico}

                //Funcion para cambiar la vista
                handleClickChangeToFormBibliografia={handleClickChangeToFormBibliografia}
                />
            )
        case 'tecnica':
            return(
                <ModalProduccionTecnica
                //Stepper
                hola={activeStep}
                handleReset={handleReset}
                steps={steps}
                tipoSolicitudActual={tipoSolicitudActual}
                setTipoSolicitudActual={setTipoSolicitudActual}

                //Funcion para cambiar la vista
                handleClickChangeToFormTec={handleClickChangeToFormTec}
                cargandoProduTec={cargandoProduTec}
                setCargandoProduTec={setCargandoProduTec}
                />
            )
        case 'gestionTecnica':
            return(
                <ModalProduccionTecnicaForms
                //Stepper
                hola={activeStepBiblio}
                handleReset={handleResetTipoBiblio}
                steps={steps}

                cargandoProduTec={cargandoProduTec}
                setCargandoProduTec={setCargandoProduTec}

                //Tipo de produccion
                tipoProduccionTecnica={tipoProduccionTecnica}
                setTipoProduccionTecnica={setTipoProduccionTecnica}
                //Tipo de Solicitud Actual
                tipoSolicitudActual={tipoSolicitudActual}
                setTipoSolicitudActual={setTipoSolicitudActual}

                tipoProductoTecSelected={tipoProductoTecSelected}
                setTipoProductoTecSelected={setTipoProductoTecSelected}

                inputs={inputs}
                setInputs={setInputs}

                // Tipo de Solicitud Actual
                // tipoSolicitudActual={tipoSolicitudActual}
                // setTipoSolicitudActual={setTipoSolicitudActual}
                //Variables Autores y particiapntes
                numeroIdParEvent={numeroIdParEvent}
                setNumeroIdParEvent={setNumeroIdParEvent}
                arrai={arrai}
                setArrai={setArrai}

                //Permisos de la solicitud
                permisosDeLaSolicitud={permisosDeLaSolicitud}
                setPermisosDeLaSolicitud={setPermisosDeLaSolicitud}

                cargandoGestionBibliografica={cargandoGestionBibliografica}
                setCargandoGestionBibliografica={setCargandoGestionBibliografica}

                //fechas para los formularios
                fechaCreacionDiseñoIndus={fechaCreacionDiseñoIndus}
                setFechaCreacionDiseñoIndus={setFechaCreacionDiseñoIndus}

                fechaDesarrolloSoftware={fechaDesarrolloSoftware}
                setFechaDesarrolloSoftware={setFechaDesarrolloSoftware}

                fechaObtencionRegistroSoftware={fechaObtencionRegistroSoftware}
                setFechaObtencionRegistroSoftware={setFechaObtencionRegistroSoftware}

                fechaCreacionPrototipo={fechaCreacionPrototipo}
                setFechaCreacionPrototipo={setFechaCreacionPrototipo}

                fechaCreacionSignoDistintivo={fechaCreacionSignoDistintivo}
                setFechaCreacionSignoDistintivo={setFechaCreacionSignoDistintivo}

                fechaRegistroBaseTecno={fechaRegistroBaseTecno}
                setFechaRegistroBaseTecno={setFechaRegistroBaseTecno}

                fechaInnovacionProceServi={fechaInnovacionProceServi}
                setFechaInnovacionProceServi={setFechaInnovacionProceServi}

                fechaInnovacionGestionEmp={fechaInnovacionGestionEmp}
                setFechaInnovacionGestionEmp={setFechaInnovacionGestionEmp}

                fechaPresentacionObraProducto={fechaPresentacionObraProducto}
                setFechaPresentacionObraProducto={setFechaPresentacionObraProducto}

                fechaCreacionObraProducto={fechaCreacionObraProducto}
                setFechaCreacionObraProducto={setFechaCreacionObraProducto}

                fechaInicioEventoArtistico={fechaInicioEventoArtistico}
                setFechaInicioEventoArtistico={setFechaInicioEventoArtistico}

                fechaFinalEventoArtistico={fechaFinalEventoArtistico}
                setFechaFinalEventoArtistico={setFechaFinalEventoArtistico}

                fechaInicioTalleresCreacion={fechaInicioTalleresCreacion}
                setFechaInicioTalleresCreacion={setFechaInicioTalleresCreacion}

                fechaFinalTalleresCreacion={fechaFinalTalleresCreacion}
                setFechaFinalTalleresCreacion={setFechaFinalTalleresCreacion}

                productoTieneSelected={productoTieneSelected}
                setProductoTieneSelected={setProductoTieneSelected}

                inputsSelects={inputsSelects}
                setInputsSelects={setInputsSelects}

                fechaRegistroDiseñoPrototipoTiene={fechaRegistroDiseñoPrototipoTiene}
                setFechaRegistroDiseñoPrototipoTiene={setFechaRegistroDiseñoPrototipoTiene}

                tipoPrototipoSelected={tipoPrototipoSelected}
                setTipoPrototipoSelected={setTipoPrototipoSelected}

                otroTipoPrototipoSelected={otroTipoPrototipoSelected}
                setOtroTipoPrototipoSelected={setOtroTipoPrototipoSelected}

                tipoSoftwareSelected={tipoSoftwareSelected}
                setTipoSoftwareSelected={setTipoSoftwareSelected}

                otroTipoSoftwareSelected={otroTipoSoftwareSelected}
                setOtroTipoSoftwareSelected={setOtroTipoSoftwareSelected}

                tipoSignoDistintivoSelected={tipoSignoDistintivoSelected}
                setTipoSignoDistintivoSelected={setTipoSignoDistintivoSelected}

                tipoEmpresaSelected={tipoEmpresaSelected}
                setTipoEmpresaSelected={setTipoEmpresaSelected}

                tipoInnovacionSelected={tipoInnovacionSelected}
                setTipoInnovacionSelected={setTipoInnovacionSelected}

                tipoEspacioEventoSelected={tipoEspacioEventoSelected}
                setTipoEspacioEventoSelected={setTipoEspacioEventoSelected}

                tipoDisciplinaAmbitoSelected={tipoDisciplinaAmbitoSelected}
                setTipoDisciplinaAmbitoSelected={setTipoDisciplinaAmbitoSelected}

                tipoAmbitoSelected={tipoAmbitoSelected}
                setTipoAmbitoSelected={setTipoAmbitoSelected}

                tipoRolSelected={tipoRolSelected}
                setTipoRolSelected={setTipoRolSelected}

                tipoEventoSelected={tipoEventoSelected}
                setTipoEventoSelected={setTipoEventoSelected}

                productoTiene={productoTiene}
                setProductoTiene={setProductoTiene}

                tiposDePrototipos={tiposDePrototipos}
                setTiposDePrototipos={setTiposDePrototipos}

                tiposDeSoftware={tiposDeSoftware}
                setTiposDeSoftware={setTiposDeSoftware}

                tiposSignoDistintivos={tiposSignoDistintivos}
                setTiposSignoDistintivos={setTiposSignoDistintivos}

                tiposEmpresas={tiposEmpresas}
                setTiposEmpresas={setTiposEmpresas}

                tiposInnovaciones={tiposInnovaciones}
                setTiposInnovaciones={setTiposInnovaciones}

                tiposEspacioEvento={tiposEspacioEvento}
                setTiposEspacioEvento={setTiposEspacioEvento}

                tiposDisciplinaAmbito={tiposDisciplinaAmbito}
                setTiposDisciplinaAmbito={setTiposDisciplinaAmbito}

                tiposAmbitos={tiposAmbitos}
                setTiposAmbitos={setTiposAmbitos}

                tiposRoles={tiposRoles}
                setTiposRoles={setTiposRoles}

                tiposEventos={tiposEventos}
                setTiposEventos={setTiposEventos}

                paisesTecTecno={paisesTecTecno}
                setPaisesTecTecno={setPaisesTecTecno}

                paisSelectedTecTecno={paisSelectedTecTecno}
                setPaisSelectedTecTecno={setPaisSelectedTecTecno}
                //Errores
                getError={getError}
                errores={errores}
                setErrores={setErrores}

                //rol y modalidad
                rolActive={rolActive}
                setRolActive={setRolActive}

                modalidadParticipacionActive={modalidadParticipacionActive}
                setModalidadParticipacionActive={setModalidadParticipacionActive}

                fechaRegistroEmpresaCultural={fechaRegistroEmpresaCultural}
                setFechaRegistroEmpresaCultural={setFechaRegistroEmpresaCultural}

                otroTipoEspacioEvento={otroTipoEspacioEvento}
                setOtroTipoEspacioEvento={setOtroTipoEspacioEvento}
                />
            )
        case 'gestionBibliografica':
            return(
                <ModalProduccionBibliograficaForms
                    hola={activeStepBiblio}
                    handleReset={handleResetTipoBiblio}
                    steps={steps}
                    cargandoGestionBibliografica={cargandoGestionBibliografica}
                    setCargandoGestionBibliografica={setCargandoGestionBibliografica}
                    //Tipo de produccion
                    tipoProduccionBiblio={tipoProduccionBiblio}
                    setTipoProduccionBiblio={setTipoProduccionBiblio}
                    //Tipo de Solicitud Actual
                    tipoSolicitudActual={tipoSolicitudActual}
                    setTipoSolicitudActual={setTipoSolicitudActual}
                    //Variables Autores y particiapntes
                    numeroIdParEvent={numeroIdParEvent}
                    setNumeroIdParEvent={setNumeroIdParEvent}
                    arrai={arrai}
                    setArrai={setArrai}
                    //Permisos de la solicitud
                    permisosDeLaSolicitud={permisosDeLaSolicitud}
                    setPermisosDeLaSolicitud={setPermisosDeLaSolicitud}
                    //Campos de tipo articulo de produccion bibliografica
                    idiomasTipoArticulo={idiomasTipoArticulo}
                    setIdiomasTipoArticulo={setIdiomasTipoArticulo}

                    idiomaTipoArticuloSelected={idiomaTipoArticuloSelected}
                    setIdiomaTipoArticuloSelected={setIdiomaTipoArticuloSelected}
                    
                    indexacionSelected={indexacionSelected}
                    setIndexacionSelected={setIndexacionSelected}

                    tipoArticuloSelected={tipoArticuloSelected}
                    setTipoArticuloSelected={setTipoArticuloSelected}

                    tipoArticuloOtro={tipoArticuloOtro}
                    setTipoArticuloOtro={setTipoArticuloOtro}

                    tituloArticulo={tituloArticulo}
                    setTituloArticulo={setTituloArticulo}

                    issn={issn}
                    setIssn={setIssn}

                    nombreRevista={nombreRevista}
                    setNombreRevista={setNombreRevista}

                    anioPublicacion={anioPublicacion}
                    setAnioPublicacion={setAnioPublicacion}

                    numPagTotales={numPagTotales}
                    setNumPagTotales={setNumPagTotales}

                    volumenRevista={volumenRevista}
                    setVolumenRevista={setVolumenRevista}

                    numeroRevista={numeroRevista}
                    setNumeroRevista={setNumeroRevista}

                    paisPublicacion={paisPublicacion}
                    setPaisPublicacion={setPaisPublicacion}

                    idioma={idioma}
                    setIdioma={setIdioma}

                    enlace={enlace}
                    setEnlace={setEnlace}

                    doi={doi}
                    setDoi={setDoi}

                    //Campos de tipo libro de produccion bibliografica

                    idiomasLibro={idiomasLibro}
                    setIdiomasLibro={setIdiomasLibro}

                    idiomaLibroSelected={idiomaLibroSelected}
                    setIdiomaLibroSelected={setIdiomaLibroSelected}

                    tipoLibroSelected={tipoLibroSelected}
                    setTipoLibroSelected={setTipoLibroSelected}

                    tipoLibroOtro={tipoLibroOtro}
                    setTipoLibroOtro={setTipoLibroOtro}

                    tituloLibro={tituloLibro}
                    setTituloLibro={setTituloLibro}

                    isbnLibro={isbnLibro}
                    setIsbnLibro={setIsbnLibro}

                    editorialLibro={editorialLibro}
                    setEditorialLibro={setEditorialLibro}

                    anioPublicacionLibro={anioPublicacionLibro}
                    setAnioPublicacionLibro={setAnioPublicacionLibro}

                    paisPublicacionLibro={paisPublicacionLibro}
                    setPaisPublicacionLibro={setPaisPublicacionLibro}

                    idiomaLibro={idiomaLibro}
                    setIdiomaLibro={setIdiomaLibro}

                    volumenLibro={volumenLibro}
                    setVolumenLibro={setVolumenLibro}

                    numeroEdicionLibro={numeroEdicionLibro}
                    setNumeroEdicionLibro={setNumeroEdicionLibro}

                    //Campos de tipo Capitulo de Libro de produccion bibliografica
                    idiomasCapituloLibro={idiomasCapituloLibro}
                    setIdiomasCapituloLibro={setIdiomasCapituloLibro}

                    idiomaCapituloLibroSelected={idiomaCapituloLibroSelected}
                    setIdiomaCapituloLibroSelected={setIdiomaCapituloLibroSelected}

                    tipoLibroSelectedCap={tipoLibroSelectedCap}
                    setTipoLibroSelectedCap={setTipoLibroSelectedCap}

                    tipoLibroOtroCap={tipoLibroOtroCap}
                    setTipoLibroOtroCap={setTipoLibroOtroCap}

                    tituloCapLibro={tituloCapLibro}
                    setTituloCapLibro={setTituloCapLibro}

                    tituloLibroCap={tituloLibroCap}
                    setTituloLibroCap={setTituloLibroCap}

                    isbnLibroCap={isbnLibroCap}
                    setIsbnLibroCap={setIsbnLibroCap}

                    editorialLibroCap={editorialLibroCap}
                    setEditorialLibroCap={setEditorialLibroCap}

                    anioPublicacionLibroCap={anioPublicacionLibroCap}
                    setAnioPublicacionLibroCap={setAnioPublicacionLibroCap}

                    paisPublicacionLibroCap={paisPublicacionLibroCap}
                    setPaisPublicacionLibroCap={setPaisPublicacionLibroCap}

                    idiomaLibroCap={idiomaLibroCap}
                    setIdiomaLibroCap={setIdiomaLibroCap}

                    volumenLibroCap={volumenLibroCap}
                    setVolumenLibroCap={setVolumenLibroCap}

                    numeroEdicionLibroCap={numeroEdicionLibroCap}
                    setNumeroEdicionLibroCap={setNumeroEdicionLibroCap}

                    //Campos del tipo Patente de producion bibliografica
                    tipoPatente={tipoPatente}
                    setTipoPatente={setTipoPatente}

                    anioObtencionPatente={anioObtencionPatente}
                    setAnioObtencionPatente={setAnioObtencionPatente}

                    numeroRegistroPatente={numeroRegistroPatente}
                    setNumeroRegistroPatente={setNumeroRegistroPatente}

                    tituloPatente={tituloPatente}
                    setTituloPatente={setTituloPatente}

                    numeroPatente={numeroPatente}
                    setNumeroPatente={setNumeroPatente}

                    paisObtencionPatente={paisObtencionPatente}
                    setPaisObtencionPatente={setPaisObtencionPatente}

                    gacetaIndustrialPatente={gacetaIndustrialPatente}
                    setGaceteIndustrialPatente={setGaceteIndustrialPatente}

                    institucionPatente={institucionPatente}
                    setInstitucionPatente={setInstitucionPatente}

                    tipoPatenteSelected={tipoPatenteSelected}
                    setTipoPatenteSelected={setTipoPatenteSelected}

                    tipoPatenteOtro={tipoPatenteOtro}
                    setTipoPatenteOtro={setTipoPatenteOtro}

                    paisesTipoArticulo={paisesTipoArticulo}
                    setPaisesTipoArticulo={setPaisesTipoArticulo}

                    paisTipoArticuloSelected={paisTipoArticuloSelected}
                    setPaisTipoArticuloSelected={setPaisTipoArticuloSelected}

                    paisTipoLibroSelected={paisTipoLibroSelected}
                    setPaisTipoLibroSelected={setPaisTipoLibroSelected}

                    paisTipoCapLibroSelected={paisTipoCapLibroSelected}
                    setPaisTipoCapLibroSelected={setPaisTipoCapLibroSelected}

                    paisPatenteSelected={paisPatenteSelected}
                    setPaisPatenteSelected={setPaisPatenteSelected}
                    //Errores
                    getError={getError}
                    errores={errores}
                    setErrores={setErrores}

                    //rol y modalidad
                    rolActive={rolActive}
                    setRolActive={setRolActive}

                    modalidadParticipacionActive={modalidadParticipacionActive}
                    setModalidadParticipacionActive={setModalidadParticipacionActive}
                />
            )
          default:
            break;
        }
    }

    function handleBackToTipos(){
        //Reseteo Eventos
        setAnio(new Date())
        setFechaInicioEvento(new Date())
        setFechaFinEvento(new Date())
        setDepartamentos([])
        setDepartamento('')
        setCiudades([])
        setCiudad('')
        setNombreDelEvento('')
        setNombreDelAmbito('')
        setNombreModalidadPar('')

        //Reseteo Eventos
        setFechaInicio(new Date())
        setFechaFin(new Date())
        setNombreProyecto('')

        //Reseteo Prod. Bibliografica
        setNombreProductoBiblio('')
        setTipoProductoBiblio('')
        setAnioBiblio(new Date())
        setRevistaEditorialBiblio('')
        setCuartilBiblio('')
        setLugarPublicacionBiblio('')
        setMedioDivulgacionBiblio('')

        //Variables Prod. Tec y Tecno
        setNombreProductoTec('')
        setTipoProductoTec('')
        setNitTec('')
        setNumeroRegistroTec('')
        setNombreTitular('')
        setAnioTec(new Date())
        setPaisTec('')
        setSeleccion('tipo')

        setErrores([])
        setActiveStep(0);
    }

    const actionsBackButton = () => {
        if(seleccion == 'gestion'){
            setSeleccion('tipo')
            setDepartamentos([])
            setDepartamento('')
            setCiudades([])
            setCiudad('')
            setNombreDelEvento('')
            setNombreDelAmbito('')
            setNombreAmbitos([])
            setNombreModalidadPar('')
            setNombreModalidadPars([])
            setNombreProyecto('')
            setNombreprofesorProyecto('')
            setProfesorProyecto([])
            setAdj_evento('')
            setAdj_evento_final('')
        }else if(seleccion == 'bibliografica'){
            setCargandoProduBibliografico(true)
            setSeleccion('tipo')
        }else if(seleccion == 'gestionBibliografica'){
            setCargandoGestionBibliografica(true)
            setSeleccion('bibliografica')
        }else if(seleccion == 'tecnica'){
            setCargandoProduTec(true)
            setSeleccion('tipo')
        }else if(seleccion == 'gestionTecnica'){
            setCargandoProduTec(true)
            setInputs([])
            setTipoProductoTecSelected('')
            setSeleccion('tecnica')
        }
    }

    const botones = () => {

        if(seleccion == 'tipo'){
            return(
                <div></div>
            )
        }else if(seleccion == 'gestion'){
            return(
                <div>
                    <Button disabled={cargando == true || cargandoEvento == true} onClick={() => actionsBackButton()} >
                        Volver a Inicio
                    </Button>
                    <Button disabled={activeStep === 0 || cargando == true || cargandoEvento == true} onClick={handleBack} >
                        Atras
                    </Button>
                    {activeStep === steps.length - 1 ? <Button disabled={cargandoEvento == true} variant="contained" color="primary" style={{color: "#fff"}} onClick={handleSendToApi}>Enviar</Button> : <Button disabled={cargandoEvento == true} variant="contained" color="primary" style={{color: '#fff'}} onClick={handleNext}>Siguiente</Button>}
                </div>
            )
        }else if(seleccion == 'bibliografica'){
            return(
                <div>
                    <Button onClick={() => actionsBackButton()} >
                        Volver a Inicio
                    </Button>
                </div>
            )
        }else if(seleccion == 'gestionBibliografica'){
            return(
                <div>
                    <Button onClick={() => actionsBackButton()} >
                        Volver a Productos
                    </Button>
                    <Button disabled={activeStepBiblio === 0} onClick={handleBackTipoBiblio} >
                        Atras
                    </Button>
                    {activeStepBiblio === steps.length - 1 ? <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={()=>{handleSendToApi()}}>Enviar</Button> : <Button variant="contained" color="primary" style={{color: '#fff'}} onClick={handleNext}>Siguiente</Button>}
                </div>
            )
        }else if(seleccion == 'tecnica'){
            return(
                <div>
                    <Button onClick={() => actionsBackButton()} >
                        Volver a Inicio
                    </Button>
                </div>
            )
        }else if(seleccion == 'gestionTecnica'){
            return(
                <div>
                    <Button onClick={() => actionsBackButton()} >
                        Volver a Productos
                    </Button>
                    <Button disabled={activeStepBiblio === 0} onClick={handleBackTipoBiblio} >
                        Atras
                    </Button>
                    {activeStepBiblio === steps.length - 1 ? <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={()=>{handleSendToApi()}}>Enviar</Button> : <Button variant="contained" color="primary" style={{color: '#fff'}} onClick={handleNext}>Siguiente</Button>}
                </div>
            )
        }
        else{
            return(
                <div>
                </div>
            )
        }

    }

    const titulos = () =>{
        if(seleccion == "bibliografica" || seleccion == "tecnica"){
            return 'Elija el Tipo de Producto'
        }else if(seleccion == "tipo" || seleccion == "gestion"){
            return 'Elija lo que desea realizar'
        }else if(seleccion == "gestionBibliografica" || seleccion == "gestionTecnica"){
            return 'Diligencie el Formulario'
        }
    }

    return (
        <div >

            <Dialog TransitionComponent={Slide} open={props.openModalGestion} onClose={()=>{handleOnClose()}} aria-labelledby="form-dialog-title">

                <AppBarModal
                titulo={ titulos() }
                mostrarModal={()=>{handleOnClose()}}
                titulo_accion="" a
                ccion="" />
                {/* Contenido del modal del formulario de */}
                <DialogContent className={classes.modalMinWith}>
                    {cargandoEvento == true ? <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> : vista()}
                </DialogContent>

                <DialogActions>
                    {/* className={classes.backButton}*/}
                    <div>
                        {botones()}
                    </div>

                </DialogActions>

            </Dialog>
        </div>
    )
}

//Este se ejecuta cuando setSeleccion es igual a tecnica
const ModalProduccionTecnica = (props) => {
    const isInitialMount = React.useRef(true);
    const [tiposProductos, setTiposProductos] = React.useState([]);
    const classes = useStyles()
    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                obtenerTiposDeProductos()

            }
            obtenerProcesos()
        }
    })

    const obtenerTiposDeProductos = async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.TIPOS_DE_PROD_TEC_INVES)

            setTiposProductos(data)
            props.setCargandoProduTec(false)
        }catch(err){
          console.log(err)
        }
    }

    return(
        props.cargandoProduTec == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> :

        <Grid container direction={'row'} justify={'center'}>
            {tiposProductos.map((item, index) => {
                return(
                <Grid key={index} item xs={12} sm={6} style={{ padding: '2% 2% 0% 2%' }} onClick={() => { props.handleClickChangeToFormTec(item) }}>
                    <Paper className={classes.color} elevation={8} square>
                        <Card  className={classes.color} style={{ maxWidth: "100%", cursor: 'pointer'  }} elevation={1}>
                        <CardHeader
                            avatar={<Avatar aria-label="recipe" style={{ backgroundColor: item.valora }}>{item.valorc.charAt(0)}</Avatar>}
                            title={item.valorc}
                        />
                        <CardContent >
                            <Grid container direction={'row'} justify={'center'}>
                                <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                                    <img src={assigment} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                                    <Typography className={classes.center} variant="body2" color="textSecondary" component="p" align='justify'>
                                        {item.valorb}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        </Card>
                    </Paper>
                </Grid>
                )
            })}
        </Grid>
    )

}

//Aqui estan los formularios de la parte de tipos de produccion tecnica y tecnologica
const ModalProduccionTecnicaForms = (props) =>{

    const classes = useStyles()
    const isInitialMount = React.useRef(true);
    const [tipoDeProductoTec, setTipoProductoTec] = React.useState([]);
    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                obtenerPRODUCTO()
                obtenerTiposRoles()
                obtenerPaises()
            }
            obtenerProcesos()
        }
    })

    const obtenerTiposRoles = async () =>{
        let permisos = await Funciones.obtenerValores(Helper.TIPO_ROL_INVESTIGACION)

        props.setTiposRoles(permisos)
    }

    const obtenerPaises= async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.PAISES)
            props.setPaisesTecTecno(data)
            props.setCargandoProduTec(false)
        }catch(err){
          console.log(err)
        }
    }

    const obtenerPRODUCTO = async () =>{
        let permisos = await obtenerPermisosValor(props.tipoProduccionTecnica.id);
        permisos = permisos.map(({ secundario }) => ({
            value: secundario.id,
            label: secundario.nombre,
            codigo: secundario.codigo
        }));
        setTipoProductoTec(permisos)

    }

    return (
        <div className={classes.root}>
          <Stepper activeStep={props.hola} alternativeLabel>
            {props.steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
          {/* Aqui es cuando el Stepper llega al final.*/}
            {props.hola === props.steps.length ? (
              <div>
                <Typography className={classes.instructions}>{props.hola}</Typography>
                <Button onClick={props.handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <GetStepContentTec
                    //Control del Stepper
                    stepIndex = {props.hola}
                    //Tipo de Solicitud Actual
                    tipoSolicitudActual={props.tipoSolicitudActual}
                    setTipoSolicitudActual={props.setTipoSolicitudActual}

                    cargando={props.cargandoProduTec}
                    setCargando={props.setCargandoProduTec}

                    tipoDeProductoTec={tipoDeProductoTec}
                    setTipoProductoTec={setTipoProductoTec}

                    tipoProductoTecSelected={props.tipoProductoTecSelected}
                    setTipoProductoTecSelected={props.setTipoProductoTecSelected}

                    inputs={props.inputs}
                    setInputs={props.setInputs}

                    //Tipo de Solicitud Actual
                    // tipoSolicitudActual={props.tipoSolicitudActual}
                    // setTipoSolicitudActual={props.setTipoSolicitudActual}
                    //Variables Autores y particiapntes
                    numeroIdParEvent={props.numeroIdParEvent}
                    setNumeroIdParEvent={props.setNumeroIdParEvent}
                    arrai={props.arrai}
                    setArrai={props.setArrai}

                    //Permisos de la solicitud
                    permisosDeLaSolicitud={props.permisosDeLaSolicitud}
                    setPermisosDeLaSolicitud={props.setPermisosDeLaSolicitud}

                    //fechas para los formularios
                    fechaCreacionDiseñoIndus={props.fechaCreacionDiseñoIndus}
                    setFechaCreacionDiseñoIndus={props.setFechaCreacionDiseñoIndus}

                    fechaDesarrolloSoftware={props.fechaDesarrolloSoftware}
                    setFechaDesarrolloSoftware={props.setFechaDesarrolloSoftware}

                    fechaObtencionRegistroSoftware={props.fechaObtencionRegistroSoftware}
                    setFechaObtencionRegistroSoftware={props.setFechaObtencionRegistroSoftware}

                    fechaCreacionPrototipo={props.fechaCreacionPrototipo}
                    setFechaCreacionPrototipo={props.setFechaCreacionPrototipo}

                    fechaCreacionSignoDistintivo={props.fechaCreacionSignoDistintivo}
                    setFechaCreacionSignoDistintivo={props.setFechaCreacionSignoDistintivo}

                    fechaRegistroBaseTecno={props.fechaRegistroBaseTecno}
                    setFechaRegistroBaseTecno={props.setFechaRegistroBaseTecno}

                    fechaInnovacionProceServi={props.fechaInnovacionProceServi}
                    setFechaInnovacionProceServi={props.setFechaInnovacionProceServi}

                    fechaInnovacionGestionEmp={props.fechaInnovacionGestionEmp}
                    setFechaInnovacionGestionEmp={props.setFechaInnovacionGestionEmp}

                    fechaPresentacionObraProducto={props.fechaPresentacionObraProducto}
                    setFechaPresentacionObraProducto={props.setFechaPresentacionObraProducto}

                    fechaCreacionObraProducto={props.fechaCreacionObraProducto}
                    setFechaCreacionObraProducto={props.setFechaCreacionObraProducto}

                    fechaInicioEventoArtistico={props.fechaInicioEventoArtistico}
                    setFechaInicioEventoArtistico={props.setFechaInicioEventoArtistico}

                    fechaFinalEventoArtistico={props.fechaFinalEventoArtistico}
                    setFechaFinalEventoArtistico={props.setFechaFinalEventoArtistico}

                    fechaInicioTalleresCreacion={props.fechaInicioTalleresCreacion}
                    setFechaInicioTalleresCreacion={props.setFechaInicioTalleresCreacion}

                    fechaFinalTalleresCreacion={props.fechaFinalTalleresCreacion}
                    setFechaFinalTalleresCreacion={props.setFechaFinalTalleresCreacion}

                    productoTieneSelected={props.productoTieneSelected}
                    setProductoTieneSelected={props.setProductoTieneSelected}

                    inputsSelects={props.inputsSelects}
                    setInputsSelects={props.setInputsSelects}

                    fechaRegistroDiseñoPrototipoTiene={props.fechaRegistroDiseñoPrototipoTiene}
                    setFechaRegistroDiseñoPrototipoTiene={props.setFechaRegistroDiseñoPrototipoTiene}

                    tipoPrototipoSelected={props.tipoPrototipoSelected}
                    setTipoPrototipoSelected={props.setTipoPrototipoSelected}

                    otroTipoPrototipoSelected={props.otroTipoPrototipoSelected}
                    setOtroTipoPrototipoSelected={props.setOtroTipoPrototipoSelected}

                    tipoSoftwareSelected={props.tipoSoftwareSelected}
                    setTipoSoftwareSelected={props.setTipoSoftwareSelected}

                    otroTipoSoftwareSelected={props.otroTipoSoftwareSelected}
                    setOtroTipoSoftwareSelected={props.setOtroTipoSoftwareSelected}

                    tipoSignoDistintivoSelected={props.tipoSignoDistintivoSelected}
                    setTipoSignoDistintivoSelected={props.setTipoSignoDistintivoSelected}

                    tipoEmpresaSelected={props.tipoEmpresaSelected}
                    setTipoEmpresaSelected={props.setTipoEmpresaSelected}

                    tipoInnovacionSelected={props.tipoInnovacionSelected}
                    setTipoInnovacionSelected={props.setTipoInnovacionSelected}

                    tipoEspacioEventoSelected={props.tipoEspacioEventoSelected}
                    setTipoEspacioEventoSelected={props.setTipoEspacioEventoSelected}

                    tipoDisciplinaAmbitoSelected={props.tipoDisciplinaAmbitoSelected}
                    setTipoDisciplinaAmbitoSelected={props.setTipoDisciplinaAmbitoSelected}

                    tipoAmbitoSelected={props.tipoAmbitoSelected}
                    setTipoAmbitoSelected={props.setTipoAmbitoSelected}

                    tipoRolSelected={props.tipoRolSelected}
                    setTipoRolSelected={props.setTipoRolSelected}

                    tipoEventoSelected={props.tipoEventoSelected}
                    setTipoEventoSelected={props.setTipoEventoSelected}


                    productoTiene={props.productoTiene}
                    setProductoTiene={props.setProductoTiene}

                    tiposDePrototipos={props.tiposDePrototipos}
                    setTiposDePrototipos={props.setTiposDePrototipos}

                    tiposDeSoftware={props.tiposDeSoftware}
                    setTiposDeSoftware={props.setTiposDeSoftware}

                    tiposSignoDistintivos={props.tiposSignoDistintivos}
                    setTiposSignoDistintivos={props.setTiposSignoDistintivos}

                    tiposEmpresas={props.tiposEmpresas}
                    setTiposEmpresas={props.setTiposEmpresas}

                    tiposInnovaciones={props.tiposInnovaciones}
                    setTiposInnovaciones={props.setTiposInnovaciones}

                    tiposEspacioEvento={props.tiposEspacioEvento}
                    setTiposEspacioEvento={props.setTiposEspacioEvento}

                    tiposDisciplinaAmbito={props.tiposDisciplinaAmbito}
                    setTiposDisciplinaAmbito={props.setTiposDisciplinaAmbito}

                    tiposAmbitos={props.tiposAmbitos}
                    setTiposAmbitos={props.setTiposAmbitos}

                    tiposRoles={props.tiposRoles}
                    setTiposRoles={props.setTiposRoles}

                    tiposEventos={props.tiposEventos}
                    setTiposEventos={props.setTiposEventos}

                    paisesTecTecno={props.paisesTecTecno}
                    setPaisesTecTecno={props.setPaisesTecTecno}

                    paisSelectedTecTecno={props.paisSelectedTecTecno}
                    setPaisSelectedTecTecno={props.setPaisSelectedTecTecno}
                    //Errores
                     getError={props.getError}
                     errores={props.errores}
                     setErrores={props.setErrores}

                      //rol y modalidad
                    rolActive={props.rolActive}
                    setRolActive={props.setRolActive}

                    modalidadParticipacionActive={props.modalidadParticipacionActive}
                    setModalidadParticipacionActive={props.setModalidadParticipacionActive}

                    fechaRegistroEmpresaCultural={props.fechaRegistroEmpresaCultural}
                    setFechaRegistroEmpresaCultural={props.setFechaRegistroEmpresaCultural}

                    otroTipoEspacioEvento={props.otroTipoEspacioEvento}
                    setOtroTipoEspacioEvento={props.setOtroTipoEspacioEvento}
                />
              </div>
            )}
          </div>
        </div>
    )
}

const GetStepContentTec = (props) => {
    switch (props.stepIndex) {
        case 0:
            return (
                <ModalProduccionTecForm1

                    cargando={props.cargando}
                    setCargando={props.setCargando}

                    tipoDeProductoTec={props.tipoDeProductoTec}
                    setTipoProductoTec={props.setTipoProductoTec}

                    tipoProductoTecSelected={props.tipoProductoTecSelected}
                    setTipoProductoTecSelected={props.setTipoProductoTecSelected}

                    inputs={props.inputs}
                    setInputs={props.setInputs}


                    //fechas para los formularios
                    fechaCreacionDiseñoIndus={props.fechaCreacionDiseñoIndus}
                    setFechaCreacionDiseñoIndus={props.setFechaCreacionDiseñoIndus}

                    fechaDesarrolloSoftware={props.fechaDesarrolloSoftware}
                    setFechaDesarrolloSoftware={props.setFechaDesarrolloSoftware}

                    fechaObtencionRegistroSoftware={props.fechaObtencionRegistroSoftware}
                    setFechaObtencionRegistroSoftware={props.setFechaObtencionRegistroSoftware}

                    fechaCreacionPrototipo={props.fechaCreacionPrototipo}
                    setFechaCreacionPrototipo={props.setFechaCreacionPrototipo}

                    fechaCreacionSignoDistintivo={props.fechaCreacionSignoDistintivo}
                    setFechaCreacionSignoDistintivo={props.setFechaCreacionSignoDistintivo}

                    fechaRegistroBaseTecno={props.fechaRegistroBaseTecno}
                    setFechaRegistroBaseTecno={props.setFechaRegistroBaseTecno}

                    fechaInnovacionProceServi={props.fechaInnovacionProceServi}
                    setFechaInnovacionProceServi={props.setFechaInnovacionProceServi}

                    fechaInnovacionGestionEmp={props.fechaInnovacionGestionEmp}
                    setFechaInnovacionGestionEmp={props.setFechaInnovacionGestionEmp}

                    fechaPresentacionObraProducto={props.fechaPresentacionObraProducto}
                    setFechaPresentacionObraProducto={props.setFechaPresentacionObraProducto}

                    fechaCreacionObraProducto={props.fechaCreacionObraProducto}
                    setFechaCreacionObraProducto={props.setFechaCreacionObraProducto}

                    fechaInicioEventoArtistico={props.fechaInicioEventoArtistico}
                    setFechaInicioEventoArtistico={props.setFechaInicioEventoArtistico}

                    fechaFinalEventoArtistico={props.fechaFinalEventoArtistico}
                    setFechaFinalEventoArtistico={props.setFechaFinalEventoArtistico}

                    fechaInicioTalleresCreacion={props.fechaInicioTalleresCreacion}
                    setFechaInicioTalleresCreacion={props.setFechaInicioTalleresCreacion}

                    fechaFinalTalleresCreacion={props.fechaFinalTalleresCreacion}
                    setFechaFinalTalleresCreacion={props.setFechaFinalTalleresCreacion}

                    productoTieneSelected={props.productoTieneSelected}
                    setProductoTieneSelected={props.setProductoTieneSelected}

                    inputsSelects={props.inputsSelects}
                    setInputsSelects={props.setInputsSelects}

                    fechaRegistroDiseñoPrototipoTiene={props.fechaRegistroDiseñoPrototipoTiene}
                    setFechaRegistroDiseñoPrototipoTiene={props.setFechaRegistroDiseñoPrototipoTiene}

                    tipoPrototipoSelected={props.tipoPrototipoSelected}
                    setTipoPrototipoSelected={props.setTipoPrototipoSelected}

                    otroTipoPrototipoSelected={props.otroTipoPrototipoSelected}
                    setOtroTipoPrototipoSelected={props.setOtroTipoPrototipoSelected}

                    tipoSoftwareSelected={props.tipoSoftwareSelected}
                    setTipoSoftwareSelected={props.setTipoSoftwareSelected}

                    otroTipoSoftwareSelected={props.otroTipoSoftwareSelected}
                    setOtroTipoSoftwareSelected={props.setOtroTipoSoftwareSelected}

                    tipoSignoDistintivoSelected={props.tipoSignoDistintivoSelected}
                    setTipoSignoDistintivoSelected={props.setTipoSignoDistintivoSelected}

                    tipoEmpresaSelected={props.tipoEmpresaSelected}
                    setTipoEmpresaSelected={props.setTipoEmpresaSelected}

                    tipoInnovacionSelected={props.tipoInnovacionSelected}
                    setTipoInnovacionSelected={props.setTipoInnovacionSelected}

                    tipoEspacioEventoSelected={props.tipoEspacioEventoSelected}
                    setTipoEspacioEventoSelected={props.setTipoEspacioEventoSelected}

                    tipoDisciplinaAmbitoSelected={props.tipoDisciplinaAmbitoSelected}
                    setTipoDisciplinaAmbitoSelected={props.setTipoDisciplinaAmbitoSelected}

                    tipoAmbitoSelected={props.tipoAmbitoSelected}
                    setTipoAmbitoSelected={props.setTipoAmbitoSelected}

                    tipoRolSelected={props.tipoRolSelected}
                    setTipoRolSelected={props.setTipoRolSelected}

                    tipoEventoSelected={props.tipoEventoSelected}
                    setTipoEventoSelected={props.setTipoEventoSelected}

                    productoTiene={props.productoTiene}
                    setProductoTiene={props.setProductoTiene}

                    tiposDePrototipos={props.tiposDePrototipos}
                    setTiposDePrototipos={props.setTiposDePrototipos}

                    tiposDeSoftware={props.tiposDeSoftware}
                    setTiposDeSoftware={props.setTiposDeSoftware}

                    tiposSignoDistintivos={props.tiposSignoDistintivos}
                    setTiposSignoDistintivos={props.setTiposSignoDistintivos}

                    tiposEmpresas={props.tiposEmpresas}
                    setTiposEmpresas={props.setTiposEmpresas}

                    tiposInnovaciones={props.tiposInnovaciones}
                    setTiposInnovaciones={props.setTiposInnovaciones}

                    tiposEspacioEvento={props.tiposEspacioEvento}
                    setTiposEspacioEvento={props.setTiposEspacioEvento}

                    tiposDisciplinaAmbito={props.tiposDisciplinaAmbito}
                    setTiposDisciplinaAmbito={props.setTiposDisciplinaAmbito}

                    tiposAmbitos={props.tiposAmbitos}
                    setTiposAmbitos={props.setTiposAmbitos}

                    tiposRoles={props.tiposRoles}
                    setTiposRoles={props.setTiposRoles}

                    tiposEventos={props.tiposEventos}
                    setTiposEventos={props.setTiposEventos}

                    paisesTecTecno={props.paisesTecTecno}
                    setPaisesTecTecno={props.setPaisesTecTecno}

                    paisSelectedTecTecno={props.paisSelectedTecTecno}
                    setPaisSelectedTecTecno={props.setPaisSelectedTecTecno}
                    //Errores
                     getError={props.getError}
                     errores={props.errores}
                     setErrores={props.setErrores}

                    //rol y modalidad
                    rolActive={props.rolActive}
                    setRolActive={props.setRolActive}

                    fechaRegistroEmpresaCultural={props.fechaRegistroEmpresaCultural}
                    setFechaRegistroEmpresaCultural={props.setFechaRegistroEmpresaCultural}

                    otroTipoEspacioEvento={props.otroTipoEspacioEvento}
                    setOtroTipoEspacioEvento={props.setOtroTipoEspacioEvento}
                />
            )
        case 1:
            return (
                <ModalFormGestionAutores
                    tipoSolicitudActual={props.tipoSolicitudActual}
                    setTipoSolicitudActual={props.setTipoSolicitudActual}
                    //Variables Autores y Particiapntes
                    numeroIdParEvent={props.numeroIdParEvent}
                    setNumeroIdParEvent={props.setNumeroIdParEvent}
                    arrai={props.arrai}
                    setArrai={props.setArrai}

                    //Permisos de la solicitud
                    permisosDeLaSolicitud={props.permisosDeLaSolicitud}
                    setPermisosDeLaSolicitud={props.setPermisosDeLaSolicitud}

                    cargando={props.cargando}
                    setCargando={props.setCargando}

                    //rol y modalidad
                    rolActive={props.rolActive}
                    setRolActive={props.setRolActive}

                    modalidadParticipacionActive={props.modalidadParticipacionActive}
                    setModalidadParticipacionActive={props.setModalidadParticipacionActive}

                    tiposRoles={props.tiposRoles}
                    setTiposRoles={props.setTiposRoles}
                />
            )
    }
}

const ModalProduccionTecForm1 = (props) => {


    const classes = useStyles()
    const handleOnchange = async (e) =>{

        if(e.target.name == "producto"){
            props.setCargando(true)
            props.setTipoProductoTecSelected(e.target.value)
            if(e.target.value != ""){
                let producto = e.target.value
                props.setProductoTieneSelected("")
                await obtenerPRODUCTO(producto)
            }else{
                props.setInputs([])
                props.setInputsSelects([])
                props.setCargando(false)
            }
        }else if(e.target.name == "productoTiene"){
            props.setCargando(true)
            props.setProductoTieneSelected(e.target.value)
            if(e.target.value != ""){
                let producto = e.target.value
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "productoTiene"));
                    // Elimina error cuando se escribe en el campo
                }
                await obtenerPermisosInputExtra(producto)
            }else{
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "productoTiene"));
                    // Elimina error cuando se escribe en el campo
                }
                props.setCargando(false)
            }
        }else if(e.target.name == "tipoPrototipoSelected"){
            props.setTipoPrototipoSelected(e.target.value)
            if(props.errores.length > 0){
                props.setErrores(props.errores.filter(er => er.llave != "tipoPrototipoSelected"));
                // Elimina error cuando se escribe en el campo
            }
        }else if(e.target.name == "otroTipoEspacioEvento"){
            props.setOtroTipoEspacioEvento(e.target.value)
            if(props.errores.length > 0){
                props.setErrores(props.errores.filter(er => er.llave != "otroTipoEspacioEvento"));
                // Elimina error cuando se escribe en el campo
            }
        }
        else if(e.target.name == "nombreOtroPrototipo"){
            props.setOtroTipoPrototipoSelected(e.target.value)
            if(props.errores.length > 0){
                props.setErrores(props.errores.filter(er => er.llave != "nombreOtroPrototipo"));
                // Elimina error cuando se escribe en el campo
            }
        }else if(e.target.name == "tipoSoftwareSelected"){
            props.setTipoSoftwareSelected(e.target.value)
            if(props.errores.length > 0){
                props.setErrores(props.errores.filter(er => er.llave != "tipoSoftwareSelected"));
                // Elimina error cuando se escribe en el campo
            }
        }else if(e.target.name == "tipoSignoDistintivoSelected"){
            props.setTipoSignoDistintivoSelected(e.target.value)
            if(props.errores.length > 0){
                props.setErrores(props.errores.filter(er => er.llave != "tipoSignoDistintivoSelected"));
                // Elimina error cuando se escribe en el campo
            }
        }else if(e.target.name == "tipoEmpresaSelected"){
            props.setTipoEmpresaSelected(e.target.value)
            if(props.errores.length > 0){
                props.setErrores(props.errores.filter(er => er.llave != "tipoEmpresaSelected"));
                // Elimina error cuando se escribe en el campo
            }
        }else if(e.target.name == "tipoInnovacionSelected"){
            props.setTipoInnovacionSelected(e.target.value)
            if(props.errores.length > 0){
                props.setErrores(props.errores.filter(er => er.llave != "tipoInnovacionSelected"));
                // Elimina error cuando se escribe en el campo
            }
        }else if(e.target.name == "tipoEspacioEventoSelected"){
            props.setTipoEspacioEventoSelected(e.target.value)
            if(props.errores.length > 0){
                props.setErrores(props.errores.filter(er => er.llave != "tipoEspacioEventoSelected"));
                // Elimina error cuando se escribe en el campo
            }
        }else if(e.target.name == "tipoDisciplinaAmbitoSelected"){
            props.setTipoDisciplinaAmbitoSelected(e.target.value)
            if(props.errores.length > 0){
                props.setErrores(props.errores.filter(er => er.llave != "tipoDisciplinaAmbitoSelected"));
                // Elimina error cuando se escribe en el campo
            }
        }else if(e.target.name == "tipoAmbitoSelected"){
            props.setTipoAmbitoSelected(e.target.value)
            if(props.errores.length > 0){
                props.setErrores(props.errores.filter(er => er.llave != "tipoAmbitoSelected"));
                // Elimina error cuando se escribe en el campo
            }
        }else if(e.target.name == "tipoEventoSelected"){
            props.setTipoEventoSelected(e.target.value)
            if(props.errores.length > 0){
                props.setErrores(props.errores.filter(er => er.llave != "tipoEventoSelected"));
                // Elimina error cuando se escribe en el campo
            }
        }else if(e.target.name == "nombreOtroSoftware"){
            props.setOtroTipoSoftwareSelected(e.target.value)
            if(props.errores.length > 0){
                props.setErrores(props.errores.filter(er => er.llave != "nombreOtroSoftware"));
                // Elimina error cuando se escribe en el campo
            }
        }else if(e.target.name == "paisSelectedTecTecno"){
            props.setPaisSelectedTecTecno(e.target.value)
            if(props.errores.length > 0){
                props.setErrores(props.errores.filter(er => er.llave != "paisSelectedTecTecno"));
                // Elimina error cuando se escribe en el campo
            }
        }
        else{

            let array = props.inputs.map((item)=>{
                if(item.codigo == e.target.name){
                    item.value = e.target.value
                    if(props.errores.length > 0){
                        props.setErrores(props.errores.filter(er => er.llave != item.codigo));
                        // Elimina error cuando se escribe en el campo
                    }
                }

                return item
            })

            let arraySelects = props.inputsSelects.map((item)=>{
                if(item.codigo == e.target.name){
                    item.value = e.target.value
                    if(props.errores.length > 0){
                        props.setErrores(props.errores.filter(er => er.llave != item.codigo));
                        // Elimina error cuando se escribe en el campo
                    }
                }

                return item
            })
            props.setInputs(array)
            props.setInputsSelects(arraySelects)
        }
    }

    const obtenerPRODUCTO = async (producto) =>{
        let permisos = await obtenerPermisosValor(producto.value);
        permisos = permisos.map(({ secundario }) => {
            return {
                id: secundario.id,
                label: secundario.nombre,
                codigo: secundario.codigo,
                error: props.errores,
                value: ''
            }
        });

        if(producto.codigo == "Inves_tipo_tec_pro_diseñoIndustrial" || producto.codigo == "Inves_tipo_tec_pro_prototipo"
        || producto.codigo == "Inves_tipo_tec_pro_innGenGesEmp" || producto.codigo == "Inves_tipo_tec_pro_innProProSer"){
            await obtenerProductoTiene()
            if(producto.codigo == "Inves_tipo_tec_pro_prototipo"){
                await obtenerTiposPrototipos()
            }
        }

        if(producto.codigo == "Inves_tipo_tec_pro_software"){
            await obtenerTiposSoftware()
        }else if(producto.codigo == "Inves_tipo_tec_pro_signo_distintivo"){
            await obtenerTiposSignoDistintivo()
        }else if(producto.codigo == "Inves_tipo_tec_pro_empBaseTec"){
            await obtenerTiposEmpresas()
        }else if(producto.codigo == "Inves_tipo_tec_pro_empCreaCul"){
            await obtenerTiposDisciplinaAmbito()
        }else if(producto.codigo == "Inves_tipo_tec_pro_innGenGesEmp"){
            await obtenerTiposInnovaciones()
        }else if(producto.codigo == "Inves_tipo_tec_pro_obrPro"){
            await obtenerTiposEspacioEvento()
            await obtenerTiposDisciplinaAmbito()
            await obtenerTiposAmbitos()
            props.setRolActive(false)
        }else if(producto.codigo == "Inves_tipo_tec_pro_eveArt"){
            await obtenerTiposAmbitos()
            props.setRolActive(true)
        }else if(producto.codigo == "Inves_tipo_tec_pro_talCre"){
            await obtenerTiposAmbitos()
            await obtenerTiposEventos()
            props.setRolActive(true)
        }



        props.setInputs(permisos)
        props.setCargando(false)
    }

    const obtenerProductoTiene = async () =>{
        let permisos = await Funciones.obtenerValores(Helper.DISEÑO_INDUSTRIAL_PROTOTIPO_TIENE)

        props.setProductoTiene(permisos)
    }

    const obtenerTiposPrototipos = async () =>{
        let permisos = await Funciones.obtenerValores(Helper.TIPOS_DE_PROTOTIPOS_INVESTIGACION)

        props.setTiposDePrototipos(permisos)
    }

    const obtenerTiposSoftware = async () =>{
        let permisos = await Funciones.obtenerValores(Helper.TIPOS_DE_SOFTWARE_INVESTIGACION)

        props.setTiposDeSoftware(permisos)
    }

    const obtenerTiposSignoDistintivo = async () =>{
        let permisos = await Funciones.obtenerValores(Helper.TIPO_SIGNO_DISTINTIVO_INVESTIGACION)

        props.setTiposSignoDistintivos(permisos)
    }

    const obtenerTiposEmpresas = async () =>{
        let permisos = await Funciones.obtenerValores(Helper.TIPO_DE_EMPRESA_INVESTIGACION)

        props.setTiposEmpresas(permisos)
    }

    const obtenerTiposInnovaciones = async () =>{
        let permisos = await Funciones.obtenerValores(Helper.TIPO_INNOVACION_EMP_INVESTIGACION)

        props.setTiposInnovaciones(permisos)
    }

    const obtenerTiposEspacioEvento = async () =>{
        let permisos = await Funciones.obtenerValores(Helper.TIPO_ESPACIO_EVENTO_INVESTIGACION)

        props.setTiposEspacioEvento(permisos)
    }

    const obtenerTiposDisciplinaAmbito = async () =>{
        let permisos = await Funciones.obtenerValores(Helper.TIPO_DISCIPLINA_AMBITO_INVESTIGACION)

        props.setTiposDisciplinaAmbito(permisos)
    }

    const obtenerTiposAmbitos = async () =>{
        let permisos = await Funciones.obtenerValores(Helper.TIPO_AMBITO_INVESTIGACION)

        props.setTiposAmbitos(permisos)
    }



    const obtenerTiposEventos = async () =>{
        let permisos = await Funciones.obtenerValores(Helper.TIPO_EVENTO_INVESTIGACION)

        props.setTiposEventos(permisos)
    }

    const obtenerPermisosInputExtra = async (producto) =>{
        let permisos = await obtenerPermisosValor(producto.id);
        permisos = permisos.map(({ secundario }) => {
            return {
                id: secundario.id,
                label: secundario.nombre,
                codigo: secundario.codigo,
                error: props.errores,
                value: ''
            }
        });

        props.setInputsSelects(permisos)
        props.setCargando(false)
    }

    return (
        props.cargando == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> :
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                        {/*<FormControl className={classes.formControl} fullWidth error = { props.getError('indexacionSelected', props.errores).length > 0}>*/}
                        <InputLabel htmlFor="age-native-simple">Producto: </InputLabel>
                            <Select
                                value={props.tipoProductoTecSelected}
                                displayEmpty
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={handleOnchange}
                                name="producto"
                            >
                                <MenuItem value="">
                                    <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tipoDeProductoTec.length} Tipos de Productos Disponibles` }</em>
                                </MenuItem>
                                {props.tipoDeProductoTec.map((item, index) => (
                                <MenuItem key= { index } value = { item }>{`${item.label}`}</MenuItem>
                                ))}
                            </Select>
                        {/*</FormControl>*/}
                    {/*<FormHelperText>{ props.getError('indexacionSelected', props.errores) }</FormHelperText>*/}
                </Grid>

                {props.tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_obrPro" ?
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Tipo de espacio o evento: </InputLabel>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoEspacioEventoSelected', props.errores).length > 0}>
                            <Select
                                value={props.tipoEspacioEventoSelected}
                                displayEmpty
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={handleOnchange}
                                name="tipoEspacioEventoSelected"
                            >
                                <MenuItem value="">
                                    <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposEspacioEvento.length} Opciones a Elegir` }</em>
                                </MenuItem>
                                {props.tiposEspacioEvento.map((item, index) => (
                                <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('tipoEspacioEventoSelected', props.errores) }</FormHelperText>
                    </Grid>

                : ''}

                {props.tipoEspacioEventoSelected.codigo == "Inves_tipo_espaEven_otro" ?
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label='Nombre del Otro Espacio o Evento'
                            error = { props.getError('otroTipoEspacioEvento', props.errores).length > 0}
                            helperText = { props.getError('otroTipoEspacioEvento', props.errores) }
                            value={props.otroTipoEspacioEvento}
                            onChange={handleOnchange}
                            name='otroTipoEspacioEvento'
                        />
                    </Grid>
                :''}

                {props.tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_obrPro" || props.tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_empCreaCul"?
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Disciplina o Ambito de Origen: </InputLabel>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoDisciplinaAmbitoSelected', props.errores).length > 0}>
                            <Select
                                value={props.tipoDisciplinaAmbitoSelected}
                                displayEmpty
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={handleOnchange}
                                name="tipoDisciplinaAmbitoSelected"
                            >
                                <MenuItem value="">
                                    <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposDisciplinaAmbito.length} Opciones a Elegir` }</em>
                                </MenuItem>
                                {props.tiposDisciplinaAmbito.map((item, index) => (
                                <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('tipoDisciplinaAmbitoSelected', props.errores) }</FormHelperText>
                    </Grid>

                : ''}

                {props.tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_talCre"?
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Tipo Evento: </InputLabel>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoEventoSelected', props.errores).length > 0}>
                            <Select
                                value={props.tipoEventoSelected}
                                displayEmpty
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={handleOnchange}
                                name="tipoEventoSelected"
                            >
                                <MenuItem value="">
                                    <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposEventos.length} Opciones a Elegir` }</em>
                                </MenuItem>
                                {props.tiposEventos.map((item, index) => (
                                <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('tipoEventoSelected', props.errores) }</FormHelperText>
                    </Grid>

                : ''}

                {props.tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_obrPro" || props.tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_eveArt" || props.tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_talCre"?
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Ambito: </InputLabel>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoAmbitoSelected', props.errores).length > 0}>
                            <Select
                                value={props.tipoAmbitoSelected}
                                displayEmpty
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={handleOnchange}
                                name="tipoAmbitoSelected"
                            >
                                <MenuItem value="">
                                    <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposAmbitos.length} Opciones a Elegir` }</em>
                                </MenuItem>
                                {props.tiposAmbitos.map((item, index) => (
                                <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('tipoAmbitoSelected', props.errores) }</FormHelperText>
                    </Grid>

                : ''}

                {props.tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_innGenGesEmp" ?
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Tipo de Innovacion: </InputLabel>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoInnovacionSelected', props.errores).length > 0}>
                            <Select
                                value={props.tipoInnovacionSelected}
                                displayEmpty
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={handleOnchange}
                                name="tipoInnovacionSelected"
                            >
                                <MenuItem value="">
                                    <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposInnovaciones.length} Opciones a Elegir` }</em>
                                </MenuItem>
                                {props.tiposInnovaciones.map((item, index) => (
                                <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('tipoInnovacionSelected', props.errores) }</FormHelperText>
                    </Grid>

                : ''}

                {props.tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_empBaseTec" ?
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Tipo de Empresa: </InputLabel>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoEmpresaSelected', props.errores).length > 0}>
                            <Select
                                value={props.tipoEmpresaSelected}
                                displayEmpty
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={handleOnchange}
                                name="tipoEmpresaSelected"
                            >
                                <MenuItem value="">
                                    <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposEmpresas.length} Opciones a Elegir` }</em>
                                </MenuItem>
                                {props.tiposEmpresas.map((item, index) => (
                                <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('tipoEmpresaSelected', props.errores) }</FormHelperText>
                    </Grid>

                : ''}

                {props.tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_signo_distintivo" ?
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Tipo de Signo Distintivo: </InputLabel>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoSignoDistintivoSelected', props.errores).length > 0}>
                            <Select
                                value={props.tipoSignoDistintivoSelected}
                                displayEmpty
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={handleOnchange}
                                name="tipoSignoDistintivoSelected"
                            >
                                <MenuItem value="">
                                    <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposSignoDistintivos.length} Opciones a Elegir` }</em>
                                </MenuItem>
                                {props.tiposSignoDistintivos.map((item, index) => (
                                <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('tipoSignoDistintivoSelected', props.errores) }</FormHelperText>
                    </Grid>

                : ''}

                {props.tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_software" ?
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Tipo de Software: </InputLabel>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoSoftwareSelected', props.errores).length > 0}>
                            <Select
                                value={props.tipoSoftwareSelected}
                                displayEmpty
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={handleOnchange}
                                name="tipoSoftwareSelected"
                            >
                                <MenuItem value="">
                                    <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposDeSoftware.length} Opciones a Elegir` }</em>
                                </MenuItem>
                                {props.tiposDeSoftware.map((item, index) => (
                                <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('tipoSoftwareSelected', props.errores) }</FormHelperText>
                    </Grid>

                : ''}

                {props.tipoSoftwareSelected.codigo == "Inves_tipo_software_otro" ?
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label='Nombre del Otro Software'
                            error = { props.getError('nombreOtroSoftware', props.errores).length > 0}
                            helperText = { props.getError('nombreOtroSoftware', props.errores) }
                            value={props.otroTipoSoftwareSelected}
                            onChange={handleOnchange}
                            name='nombreOtroSoftware'
                        />
                    </Grid>
                :''}

                {/* Tipo de prototipo */}
                {props.tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_prototipo"?
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Tipo de Prototipo: </InputLabel>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoPrototipoSelected', props.errores).length > 0}>
                            <Select
                                value={props.tipoPrototipoSelected}
                                displayEmpty
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={handleOnchange}
                                name="tipoPrototipoSelected"
                            >
                                <MenuItem value="">
                                    <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposDePrototipos.length} Opciones a Elegir` }</em>
                                </MenuItem>
                                {props.tiposDePrototipos.map((item, index) => (
                                <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('tipoPrototipoSelected', props.errores) }</FormHelperText>
                    </Grid>
                :''}

                {props.tipoPrototipoSelected.codigo == "Inves_tipo_prototipo_otro" ?
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label='Nombre del Otro Prototipo'
                            error = { props.getError('nombreOtroPrototipo', props.errores).length > 0}
                            helperText = { props.getError('nombreOtroPrototipo', props.errores) }
                            value={props.otroTipoPrototipoSelected}
                            onChange={handleOnchange}
                            name='nombreOtroPrototipo'
                        />
                    </Grid>
                :''}

                {props.tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_diseñoIndustrial" || props.tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_prototipo" || props.tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_innGenGesEmp" || props.tipoProductoTecSelected.codigo == "Inves_tipo_tec_pro_innProProSer"?
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">El Producto tiene: </InputLabel>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('productoTiene', props.errores).length > 0}>
                            <Select
                                value={props.productoTieneSelected}
                                displayEmpty
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={handleOnchange}
                                name="productoTiene"
                            >
                                <MenuItem value="">
                                    <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.productoTiene.length} Opciones a Elegir` }</em>
                                </MenuItem>
                                {props.productoTiene.map((item, index) => (
                                <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('productoTiene', props.errores) }</FormHelperText>
                    </Grid>
                : ''}


                {props.productoTieneSelected.codigo == "patenteDiseñoPrototipoTiene" || props.productoTieneSelected.codigo == "registroDiseñoPrototipoTiene" || props.productoTieneSelected.codigo == "secreEmpDiseñoPrototipoTiene"?
                    props.inputsSelects.map((item, index)=>{
                        if(item.codigo.includes('fecha')){
                            if(item.codigo == "fechaRegistroDiseñoPrototipoTiene"){
                                return(
                                    <Grid item xs={12} key={index}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <Fragment>
                                            <KeyboardDatePicker
                                                required={true}                           
                                                style={{ marginTop: 3, width: '100%' }}
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label={item.label}
                                                value={props.fechaRegistroDiseñoPrototipoTiene}
                                                onChange={newDate => props.setFechaRegistroDiseñoPrototipoTiene(newDate)}
                                                name={item.codigo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage='Formato Invalido'

                                            />
                                            </Fragment>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )
                            }
                        }else{
                            return(
                                <Grid item xs={12}>
                                <TextField
                                fullWidth
                                label={item.label}
                                error = { props.getError(item.codigo, props.errores).length > 0}
                                helperText = { props.getError(item.codigo, props.errores) }
                                value={item.value}
                                onChange={handleOnchange}
                                name={item.codigo}
                                />
                                </Grid>
                            )
                        }

                    })
                : ''}

                {props.tipoProductoTecSelected.codigo != "" ?
                    props.inputs.map((item, index)=>{
                        if(item.codigo.includes('fecha')){
                            if(item.codigo == "fechaCreacion"){
                                return(
                                    <Grid item xs={12} key={index}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <Fragment>
                                            <KeyboardDatePicker
                                                required={true}                           
                                                style={{ marginTop: 3, width: '100%' }}
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label={item.label}
                                                value={props.fechaCreacionDiseñoIndus}
                                                onChange={newDate => props.setFechaCreacionDiseñoIndus(newDate)}
                                                name={item.codigo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                            </Fragment>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )
                            }else if(item.codigo == "fechaDesarrolloSoftware"){
                                return(
                                    <Grid item xs={12} key={index}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <Fragment>
                                            <KeyboardDatePicker
                                                required={true}                           
                                                style={{ marginTop: 3, width: '100%' }}
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label={item.label}
                                                value={props.fechaDesarrolloSoftware}
                                                onChange={newDate => props.setFechaDesarrolloSoftware(newDate)}
                                                name={item.codigo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                            </Fragment>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )
                            }else if(item.codigo == "fechaObtencionRegistroSoftware"){
                                return(
                                    <Grid item xs={12} key={index}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <Fragment>
                                            <KeyboardDatePicker
                                                required={true}                           
                                                style={{ marginTop: 3, width: '100%' }}
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label={item.label}
                                                value={props.fechaObtencionRegistroSoftware}
                                                onChange={newDate => props.setFechaObtencionRegistroSoftware(newDate)}
                                                name={item.codigo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                            </Fragment>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )
                            }else if(item.codigo == "fechaCreacionPrototipo"){
                                return(
                                    <Grid item xs={12} key={index}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <Fragment>
                                            <KeyboardDatePicker
                                                required={true}                           
                                                style={{ marginTop: 3, width: '100%' }}
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label={item.label}
                                                value={props.fechaCreacionPrototipo}
                                                onChange={newDate => props.setFechaCreacionPrototipo(newDate)}
                                                name={item.codigo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                            </Fragment>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )
                            }else if(item.codigo == "fechaCreacionSignoDistintivo"){
                                return(
                                    <Grid item xs={12} key={index}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <Fragment>
                                            <KeyboardDatePicker
                                                required={true}                           
                                                style={{ marginTop: 3, width: '100%' }}
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label={item.label}
                                                value={props.fechaCreacionSignoDistintivo}
                                                onChange={newDate => props.setFechaCreacionSignoDistintivo(newDate)}
                                                name={item.codigo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                            </Fragment>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )
                            }else if(item.codigo == "fechaRegistroBaseTecno"){
                                return(
                                    <Grid item xs={12} key={index}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <Fragment>
                                            <KeyboardDatePicker
                                                required={true}                           
                                                style={{ marginTop: 3, width: '100%' }}
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label={item.label}
                                                value={props.fechaRegistroBaseTecno}
                                                onChange={newDate => props.setFechaRegistroBaseTecno(newDate)}
                                                name={item.codigo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                            </Fragment>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )
                            }else if(item.codigo == "fechaInnovacionProceServi"){
                                return(
                                    <Grid item xs={12} key={index}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <Fragment>
                                            <KeyboardDatePicker
                                                required={true}                           
                                                style={{ marginTop: 3, width: '100%' }}
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label={item.label}
                                                value={props.fechaInnovacionProceServi}
                                                onChange={newDate => props.setFechaInnovacionProceServi(newDate)}
                                                name={item.codigo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                            </Fragment>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )
                            }else if(item.codigo == "fechaInnovacionGestionEmp"){
                                return(
                                    <Grid item xs={12} key={index}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <Fragment>
                                            <KeyboardDatePicker
                                                required={true}                           
                                                style={{ marginTop: 3, width: '100%' }}
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label={item.label}
                                                value={props.fechaInnovacionGestionEmp}
                                                onChange={newDate => props.setFechaInnovacionGestionEmp(newDate)}
                                                name={item.codigo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                            </Fragment>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )
                            }else if(item.codigo == "fechaPresentacionObraProducto"){
                                return(
                                    <Grid item xs={12} key={index}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <Fragment>
                                            <KeyboardDatePicker
                                                required={true}                           
                                                style={{ marginTop: 3, width: '100%' }}
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label={item.label}
                                                value={props.fechaPresentacionObraProducto}
                                                onChange={newDate => props.setFechaPresentacionObraProducto(newDate)}
                                                name={item.codigo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                            </Fragment>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )
                            }else if(item.codigo == "fechaCreacionObraProducto"){
                                return(
                                    <Grid item xs={12} key={index}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <Fragment>
                                            <KeyboardDatePicker
                                                required={true}                           
                                                style={{ marginTop: 3, width: '100%' }}
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label={item.label}
                                                value={props.fechaCreacionObraProducto}
                                                onChange={newDate => props.setFechaCreacionObraProducto(newDate)}
                                                name={item.codigo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                            </Fragment>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )
                            }else if(item.codigo == "fechaInicioEventoArtistico"){
                                return(
                                    <Grid item xs={12} key={index}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <Fragment>
                                            <KeyboardDatePicker
                                                required={true}                           
                                                style={{ marginTop: 3, width: '100%' }}
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label={item.label}
                                                value={props.fechaInicioEventoArtistico}
                                                onChange={newDate => props.setFechaInicioEventoArtistico(newDate)}
                                                name={item.codigo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                            </Fragment>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )
                            }else if(item.codigo == "fechaFinalEventoArtistico"){
                                return(
                                    <Grid item xs={12} key={index}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <Fragment>
                                            <KeyboardDatePicker
                                                required={true}                           
                                                style={{ marginTop: 3, width: '100%' }}
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label={item.label}
                                                value={props.fechaFinalEventoArtistico}
                                                onChange={newDate => props.setFechaFinalEventoArtistico(newDate)}
                                                name={item.codigo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                            </Fragment>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )
                            }else if(item.codigo == "fechaInicioTalleresCreacion"){
                                return(
                                    <Grid item xs={12} key={index}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <Fragment>
                                            <KeyboardDatePicker
                                                required={true}                           
                                                style={{ marginTop: 3, width: '100%' }}
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label={item.label}
                                                value={props.fechaInicioTalleresCreacion}
                                                onChange={newDate => props.setFechaInicioTalleresCreacion(newDate)}
                                                name={item.codigo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                            </Fragment>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )
                            }else if(item.codigo == "fechaFinalTalleresCreacion"){
                                return(
                                    <Grid item xs={12} key={index}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <Fragment>
                                            <KeyboardDatePicker
                                                required={true}                           
                                                style={{ marginTop: 3, width: '100%' }}
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label={item.label}
                                                value={props.fechaFinalTalleresCreacion}
                                                onChange={newDate => props.setFechaFinalTalleresCreacion(newDate)}
                                                name={item.codigo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                            </Fragment>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )
                            }else if(item.codigo == "fechaRegistroEmpresaCultural"){
                                return(
                                    <Grid item xs={12} key={index}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <Fragment>
                                            <KeyboardDatePicker
                                                required={true}                           
                                                style={{ marginTop: 3, width: '100%' }}
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label={item.label}
                                                value={props.fechaRegistroEmpresaCultural}
                                                onChange={newDate => props.setFechaRegistroEmpresaCultural(newDate)}
                                                name={item.codigo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                            </Fragment>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )
                            }

                        }if(item.codigo.includes('pais')){
                            return(<Grid item xs={12}>
                                <InputLabel htmlFor="age-native-simple">País</InputLabel>
                                    <FormControl className={classes.formControl} fullWidth error = { props.getError('paisSelectedTecTecno', props.errores).length > 0}>
                                        <Select
                                            value={props.paisSelectedTecTecno}
                                            displayEmpty
                                            fullWidth
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            onChange={handleOnchange}
                                            name="paisSelectedTecTecno"
                                        >
                                            <MenuItem value="">
                                                <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.paisesTecTecno.length} Paises para Seleccionar` }</em>
                                            </MenuItem>
                                            {props.paisesTecTecno.map((item, index) => (
                                            <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                <FormHelperText>{ props.getError('paisSelectedTecTecno', props.errores) }</FormHelperText>
                            </Grid> )
                        }else{
                            return(
                                <Grid item xs={12} key={index}>
                                    <TextField
                                    fullWidth
                                    label={item.label}
                                    error = { props.getError(item.codigo, props.errores).length > 0}
                                    helperText = { props.getError(item.codigo, props.errores) }
                                    value={item.value}
                                    onChange={handleOnchange}
                                    name={item.codigo}
                                    />
                                </Grid>
                            )
                        }
                    })
                : ''}
            </Grid>
        </div>
    )

}

//Este se ejecuta cuando setSeleccion es igual a bibliografia
const ModalProduccionBibliografica = (props) =>{

    const isInitialMount = React.useRef(true);
    const [tiposProductos, setTiposProductos] = React.useState([]);
    const classes = useStyles()
    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                obtenerTiposDeProductos()
            }
            obtenerProcesos()
        }
    })

    const obtenerTiposDeProductos = async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.TIPOS_DE_PROD_BIBLIO_INVES)

            setTiposProductos(data)
            props.setCargandoProduBibliografico(false)
        }catch(err){
          console.log(err)
        }
    }

    return(
        props.cargandoProduBibliografico == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> :

        <Grid container direction={'row'} justify={'center'}>
            {tiposProductos.map((item, index) => {
                return(
                <Grid key={index} item xs={12} sm={6} style={{ padding: '2% 2% 0% 2%' }} onClick={() => { props.handleClickChangeToFormBibliografia(item) }}>
                    <Paper className={classes.color} elevation={8} square>
                        <Card className={classes.color} style={{ maxWidth: "100%", minHeight: '300px', cursor: 'pointer' }} elevation={1}>
                        <CardHeader
                            avatar={<Avatar aria-label="recipe" style={{ backgroundColor: item.valora }}>{item.valorc.charAt(0)}</Avatar>}
                            title={item.valorc}
                        />
                        <CardContent >
                            <Grid container direction={'row'} justify={'center'}>
                                <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                                    <img src={assigment} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                                    <Typography className={classes.center} variant="body2" color="textSecondary" component="p" align='justify'>
                                        {item.valorb}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        </Card>
                    </Paper>
                </Grid>
                )
            })}
        </Grid>
    )

}

//Aqui estan los formularios de la parte de tipos de produccion bibliografica
const ModalProduccionBibliograficaForms = (props) =>{

    const classes = useStyles()
    const isInitialMount = React.useRef(true);
    //Tipo bibliografico Articulo
    const [indexaciones, setIndexaciones] = React.useState([])
    const [tipoArticulo, setTipoArticulo] = React.useState([])
    //Tipo bibliografico Libro
    const [tiposLibros, setTiposLibros] = React.useState([])
    //Tipo bibliografico Capitulo Libro
    const [tiposLibrosCap, setTiposLibrosCap] = React.useState([])


    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                await obtenerIndexacion()
                await obtenerTiposDeArticulo()
                await obtenerTiposDeLibros()
                await obtenerTiposDeCapitulosLibros()
                await obtenerTiposDePatentes()
                await obtenerPaises()
                await obtenerIdiomas()
            }
            obtenerProcesos()
        }
    })

    const obtenerPaises= async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.PAISES)
            props.setPaisesTipoArticulo(data)
            
        }catch(err){
          console.log(err)
        }
    }

    const obtenerIndexacion = async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.INDEXACION_INVESTIGACION)
            setIndexaciones(data)
        }catch(err){
          console.log(err)
        }
    }

    const obtenerTiposDeArticulo = async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.TIPO_DE_ARTICULO_INVESTIGACION)
            setTipoArticulo(data)
        }catch(err){
          console.log(err)
        }
    }

    const obtenerTiposDeLibros = async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.TIPO_DE_LIBRO_INVESTIGACION)
            setTiposLibros(data)
        }catch(err){
          console.log(err)
        }
    }

    const obtenerTiposDeCapitulosLibros = async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.TIPO_DE_CAPITULO_LIBRO_INVESTIGACION)
            setTiposLibrosCap(data)
        }catch(err){
          console.log(err)
        }
    }

    const obtenerTiposDePatentes = async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.TIPOS_DE_PATENTE_INVESTIGACION)
            props.setTipoPatente(data)

        }catch(err){
          console.log(err)
        }
    }

    const obtenerIdiomas = async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.IDIOMAS)
            props.setIdiomasTipoArticulo(data)
            props.setIdiomasLibro(data)
            props.setIdiomasCapituloLibro(data)
            props.setCargandoGestionBibliografica(false)
        }catch(err){
          console.log(err)
        }
    }

    return (
        <div className={classes.root}>
          <Stepper activeStep={props.hola} alternativeLabel>
            {props.steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
          {/* Aqui es cuando el Stepper llega al final.*/}
            {props.hola === props.steps.length ? (
              <div>
                <Typography className={classes.instructions}>{props.hola}</Typography>
                <Button onClick={props.handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <GetStepContentBiblio
                    //Control del Stepper
                    stepIndex = {props.hola}
                    //Tipo de Solicitud Actual
                    tipoSolicitudActual={props.tipoSolicitudActual}
                    setTipoSolicitudActual={props.setTipoSolicitudActual}
                    //Variables Autores y particiapntes
                    numeroIdParEvent={props.numeroIdParEvent}
                    setNumeroIdParEvent={props.setNumeroIdParEvent}
                    arrai={props.arrai}
                    setArrai={props.setArrai}
                    //Permisos de la solicitud
                    permisosDeLaSolicitud={props.permisosDeLaSolicitud}
                    setPermisosDeLaSolicitud={props.setPermisosDeLaSolicitud}
                    //Tipo de Articulo
                    tipoProduccionBiblio={props.tipoProduccionBiblio}
                    setTipoProduccionBiblio={props.setTipoProduccionBiblio}
                    //Selects y cargando
                    indexaciones={indexaciones}
                    setIndexaciones={setIndexaciones}

                    tipoArticulo={tipoArticulo}
                    setTipoArticulo={setTipoArticulo}

                    tiposLibros={tiposLibros}
                    setTiposLibros={setTiposLibros}

                    cargando={props.cargandoGestionBibliografica}
                    setCargando={props.setCargandoGestionBibliografica}
                    //campos de tipo Articulo de produccion bibliografica
                    idiomasTipoArticulo={props.idiomasTipoArticulo}
                    setIdiomasTipoArticulo={props.setIdiomasTipoArticulo}

                    idiomaTipoArticuloSelected={props.idiomaTipoArticuloSelected}
                    setIdiomaTipoArticuloSelected={props.setIdiomaTipoArticuloSelected}
                   
                    indexacionSelected={props.indexacionSelected}
                    setIndexacionSelected={props.setIndexacionSelected}

                    tipoArticuloSelected={props.tipoArticuloSelected}
                    setTipoArticuloSelected={props.setTipoArticuloSelected}

                    tipoArticuloOtro={props.tipoArticuloOtro}
                    setTipoArticuloOtro={props.setTipoArticuloOtro}

                    tituloArticulo={props.tituloArticulo}
                    setTituloArticulo={props.setTituloArticulo}

                    issn={props.issn}
                    setIssn={props.setIssn}

                    nombreRevista={props.nombreRevista}
                    setNombreRevista={props.setNombreRevista}

                    anioPublicacion={props.anioPublicacion}
                    setAnioPublicacion={props.setAnioPublicacion}

                    numPagTotales={props.numPagTotales}
                    setNumPagTotales={props.setNumPagTotales}

                    volumenRevista={props.volumenRevista}
                    setVolumenRevista={props.setVolumenRevista}

                    numeroRevista={props.numeroRevista}
                    setNumeroRevista={props.setNumeroRevista}

                    paisPublicacion={props.paisPublicacion}
                    setPaisPublicacion={props.setPaisPublicacion}

                    idioma={props.idioma}
                    setIdioma={props.setIdioma}

                    enlace={props.enlace}
                    setEnlace={props.setEnlace}

                    doi={props.doi}
                    setDoi={props.setDoi}
                    //campos de tipo libro de produccion bibliografica
                    idiomasLibro={props.idiomasLibro}
                    setIdiomasLibro={props.setIdiomasLibro}

                    idiomaLibroSelected={props.idiomaLibroSelected}
                    setIdiomaLibroSelected={props.setIdiomaLibroSelected}

                    tipoLibroSelected={props.tipoLibroSelected}
                    setTipoLibroSelected={props.setTipoLibroSelected}

                    tipoLibroOtro={props.tipoLibroOtro}
                    setTipoLibroOtro={props.setTipoLibroOtro}

                    tituloLibro={props.tituloLibro}
                    setTituloLibro={props.setTituloLibro}

                    isbnLibro={props.isbnLibro}
                    setIsbnLibro={props.setIsbnLibro}

                    editorialLibro={props.editorialLibro}
                    setEditorialLibro={props.setEditorialLibro}

                    anioPublicacionLibro={props.anioPublicacionLibro}
                    setAnioPublicacionLibro={props.setAnioPublicacionLibro}

                    paisPublicacionLibro={props.paisPublicacionLibro}
                    setPaisPublicacionLibro={props.setPaisPublicacionLibro}

                    idiomaLibro={props.idiomaLibro}
                    setIdiomaLibro={props.setIdiomaLibro}

                    volumenLibro={props.volumenLibro}
                    setVolumenLibro={props.setVolumenLibro}

                    numeroEdicionLibro={props.numeroEdicionLibro}
                    setNumeroEdicionLibro={props.setNumeroEdicionLibro}

                    //Campos de tipo Capitulo de Libro de produccion bibliografica
                    idiomasCapituloLibro={props.idiomasCapituloLibro}
                    setIdiomasCapituloLibro={props.setIdiomasCapituloLibro}

                    idiomaCapituloLibroSelected={props.idiomaCapituloLibroSelected}
                    setIdiomaCapituloLibroSelected={props.setIdiomaCapituloLibroSelected}

                    tiposLibrosCap={tiposLibrosCap}
                    setTiposLibrosCap={setTiposLibrosCap}

                    tipoLibroSelectedCap={props.tipoLibroSelectedCap}
                    setTipoLibroSelectedCap={props.setTipoLibroSelectedCap}

                    tipoLibroOtroCap={props.tipoLibroOtroCap}
                    setTipoLibroOtroCap={props.setTipoLibroOtroCap}

                    tituloCapLibro={props.tituloCapLibro}
                    setTituloCapLibro={props.setTituloCapLibro}

                    tituloLibroCap={props.tituloLibroCap}
                    setTituloLibroCap={props.setTituloLibroCap}

                    isbnLibroCap={props.isbnLibroCap}
                    setIsbnLibroCap={props.setIsbnLibroCap}

                    editorialLibroCap={props.editorialLibroCap}
                    setEditorialLibroCap={props.setEditorialLibroCap}

                    anioPublicacionLibroCap={props.anioPublicacionLibroCap}
                    setAnioPublicacionLibroCap={props.setAnioPublicacionLibroCap}

                    paisPublicacionLibroCap={props.paisPublicacionLibroCap}
                    setPaisPublicacionLibroCap={props.setPaisPublicacionLibroCap}

                    idiomaLibroCap={props.idiomaLibroCap}
                    setIdiomaLibroCap={props.setIdiomaLibroCap}

                    volumenLibroCap={props.volumenLibroCap}
                    setVolumenLibroCap={props.setVolumenLibroCap}

                    numeroEdicionLibroCap={props.numeroEdicionLibroCap}
                    setNumeroEdicionLibroCap={props.setNumeroEdicionLibroCap}

                    //Campos del tipo Patente de producion bibliografica
                    tipoPatente={props.tipoPatente}
                    setTipoPatente={props.setTipoPatente}

                    anioObtencionPatente={props.anioObtencionPatente}
                    setAnioObtencionPatente={props.setAnioObtencionPatente}

                    numeroRegistroPatente={props.numeroRegistroPatente}
                    setNumeroRegistroPatente={props.setNumeroRegistroPatente}

                    tituloPatente={props.tituloPatente}
                    setTituloPatente={props.setTituloPatente}

                    numeroPatente={props.numeroPatente}
                    setNumeroPatente={props.setNumeroPatente}

                    paisObtencionPatente={props.paisObtencionPatente}
                    setPaisObtencionPatente={props.setPaisObtencionPatente}

                    gacetaIndustrialPatente={props.gacetaIndustrialPatente}
                    setGaceteIndustrialPatente={props.setGaceteIndustrialPatente}

                    institucionPatente={props.institucionPatente}
                    setInstitucionPatente={props.setInstitucionPatente}

                    tipoPatenteSelected={props.tipoPatenteSelected}
                    setTipoPatenteSelected={props.setTipoPatenteSelected}

                    tipoPatenteOtro={props.tipoPatenteOtro}
                    setTipoPatenteOtro={props.setTipoPatenteOtro}

                    paisesTipoArticulo={props.paisesTipoArticulo}
                    setPaisesTipoArticulo={props.setPaisesTipoArticulo}
                    paisTipoArticuloSelected={props.paisTipoArticuloSelected}
                    setPaisTipoArticuloSelected={props.setPaisTipoArticuloSelected}

                    paisTipoLibroSelected={props.paisTipoLibroSelected}
                    setPaisTipoLibroSelected={props.setPaisTipoLibroSelected}

                    paisTipoCapLibroSelected={props.paisTipoCapLibroSelected}
                    setPaisTipoCapLibroSelected={props.setPaisTipoCapLibroSelected}

                    paisPatenteSelected={props.paisPatenteSelected}
                    setPaisPatenteSelected={props.setPaisPatenteSelected}
                    //Errores
                    getError={props.getError}
                    errores={props.errores}
                    setErrores={props.setErrores}

                    //rol y modalidad
                    rolActive={props.rolActive}
                    setRolActive={props.setRolActive}

                    modalidadParticipacionActive={props.modalidadParticipacionActive}
                    setModalidadParticipacionActive={props.setModalidadParticipacionActive}
                />
              </div>
            )}
          </div>
        </div>
    )
}

const GetStepContentBiblio = (props) => {
    switch (props.stepIndex) {
        case 0:
            return (
                <ModalProduccionBibliograficaForm1
                    //Tipo de produccion
                    tipoProduccionBiblio={props.tipoProduccionBiblio}
                    setTipoProduccionBiblio={props.setTipoProduccionBiblio}
                    //campos de tipo Articulo de produccion bibliografica
                    idiomasTipoArticulo={props.idiomasTipoArticulo}
                    setIdiomasTipoArticulo={props.setIdiomasTipoArticulo}

                    idiomaTipoArticuloSelected={props.idiomaTipoArticuloSelected}
                    setIdiomaTipoArticuloSelected={props.setIdiomaTipoArticuloSelected}
                    
                    indexacionSelected={props.indexacionSelected}
                    setIndexacionSelected={props.setIndexacionSelected}

                    tipoArticuloSelected={props.tipoArticuloSelected}
                    setTipoArticuloSelected={props.setTipoArticuloSelected}

                    tipoArticuloOtro={props.tipoArticuloOtro}
                    setTipoArticuloOtro={props.setTipoArticuloOtro}

                    tituloArticulo={props.tituloArticulo}
                    setTituloArticulo={props.setTituloArticulo}

                    issn={props.issn}
                    setIssn={props.setIssn}

                    nombreRevista={props.nombreRevista}
                    setNombreRevista={props.setNombreRevista}

                    anioPublicacion={props.anioPublicacion}
                    setAnioPublicacion={props.setAnioPublicacion}

                    numPagTotales={props.numPagTotales}
                    setNumPagTotales={props.setNumPagTotales}

                    volumenRevista={props.volumenRevista}
                    setVolumenRevista={props.setVolumenRevista}

                    numeroRevista={props.numeroRevista}
                    setNumeroRevista={props.setNumeroRevista}

                    paisPublicacion={props.paisPublicacion}
                    setPaisPublicacion={props.setPaisPublicacion}

                    idioma={props.idioma}
                    setIdioma={props.setIdioma}

                    enlace={props.enlace}
                    setEnlace={props.setEnlace}

                    doi={props.doi}
                    setDoi={props.setDoi}

                    indexaciones={props.indexaciones}
                    setIndexaciones={props.setIndexaciones}

                    tipoArticulo={props.tipoArticulo}
                    setTipoArticulo={props.setTipoArticulo}

                    cargando={props.cargando}
                    setCargando={props.setCargando}

                    //campos de tipo libro de produccion bibliografica
                    idiomasLibro={props.idiomasLibro}
                    setIdiomasLibro={props.setIdiomasLibro}

                    idiomaLibroSelected={props.idiomaLibroSelected}
                    setIdiomaLibroSelected={props.setIdiomaLibroSelected}

                    tiposLibros={props.tiposLibros}
                    setTiposLibros={props.setTiposLibros}

                    tipoLibroSelected={props.tipoLibroSelected}
                    setTipoLibroSelected={props.setTipoLibroSelected}

                    tipoLibroOtro={props.tipoLibroOtro}
                    setTipoLibroOtro={props.setTipoLibroOtro}

                    tituloLibro={props.tituloLibro}
                    setTituloLibro={props.setTituloLibro}

                    isbnLibro={props.isbnLibro}
                    setIsbnLibro={props.setIsbnLibro}

                    editorialLibro={props.editorialLibro}
                    setEditorialLibro={props.setEditorialLibro}

                    anioPublicacionLibro={props.anioPublicacionLibro}
                    setAnioPublicacionLibro={props.setAnioPublicacionLibro}

                    paisPublicacionLibro={props.paisPublicacionLibro}
                    setPaisPublicacionLibro={props.setPaisPublicacionLibro}

                    idiomaLibro={props.idiomaLibro}
                    setIdiomaLibro={props.setIdiomaLibro}

                    volumenLibro={props.volumenLibro}
                    setVolumenLibro={props.setVolumenLibro}

                    numeroEdicionLibro={props.numeroEdicionLibro}
                    setNumeroEdicionLibro={props.setNumeroEdicionLibro}

                    //Campos de tipo Capitulo de Libro de produccion bibliografica
                    idiomasCapituloLibro={props.idiomasCapituloLibro}
                    setIdiomasCapituloLibro={props.setIdiomasCapituloLibro}

                    idiomaCapituloLibroSelected={props.idiomaCapituloLibroSelected}
                    setIdiomaCapituloLibroSelected={props.setIdiomaCapituloLibroSelected}

                    tiposLibrosCap={props.tiposLibrosCap}
                    setTiposLibrosCap={props.setTiposLibrosCap}

                    tipoLibroSelectedCap={props.tipoLibroSelectedCap}
                    setTipoLibroSelectedCap={props.setTipoLibroSelectedCap}

                    tipoLibroOtroCap={props.tipoLibroOtroCap}
                    setTipoLibroOtroCap={props.setTipoLibroOtroCap}

                    tituloCapLibro={props.tituloCapLibro}
                    setTituloCapLibro={props.setTituloCapLibro}

                    tituloLibroCap={props.tituloLibroCap}
                    setTituloLibroCap={props.setTituloLibroCap}

                    isbnLibroCap={props.isbnLibroCap}
                    setIsbnLibroCap={props.setIsbnLibroCap}

                    editorialLibroCap={props.editorialLibroCap}
                    setEditorialLibroCap={props.setEditorialLibroCap}

                    anioPublicacionLibroCap={props.anioPublicacionLibroCap}
                    setAnioPublicacionLibroCap={props.setAnioPublicacionLibroCap}

                    paisPublicacionLibroCap={props.paisPublicacionLibroCap}
                    setPaisPublicacionLibroCap={props.setPaisPublicacionLibroCap}

                    idiomaLibroCap={props.idiomaLibroCap}
                    setIdiomaLibroCap={props.setIdiomaLibroCap}

                    volumenLibroCap={props.volumenLibroCap}
                    setVolumenLibroCap={props.setVolumenLibroCap}

                    numeroEdicionLibroCap={props.numeroEdicionLibroCap}
                    setNumeroEdicionLibroCap={props.setNumeroEdicionLibroCap}

                    //Campos del tipo Patente de producion bibliografica
                    tipoPatente={props.tipoPatente}
                    setTipoPatente={props.setTipoPatente}

                    anioObtencionPatente={props.anioObtencionPatente}
                    setAnioObtencionPatente={props.setAnioObtencionPatente}

                    numeroRegistroPatente={props.numeroRegistroPatente}
                    setNumeroRegistroPatente={props.setNumeroRegistroPatente}

                    tituloPatente={props.tituloPatente}
                    setTituloPatente={props.setTituloPatente}

                    numeroPatente={props.numeroPatente}
                    setNumeroPatente={props.setNumeroPatente}

                    paisObtencionPatente={props.paisObtencionPatente}
                    setPaisObtencionPatente={props.setPaisObtencionPatente}

                    gacetaIndustrialPatente={props.gacetaIndustrialPatente}
                    setGaceteIndustrialPatente={props.setGaceteIndustrialPatente}

                    institucionPatente={props.institucionPatente}
                    setInstitucionPatente={props.setInstitucionPatente}

                    tipoPatenteSelected={props.tipoPatenteSelected}
                    setTipoPatenteSelected={props.setTipoPatenteSelected}

                    tipoPatenteOtro={props.tipoPatenteOtro}
                    setTipoPatenteOtro={props.setTipoPatenteOtro}

                    paisesTipoArticulo={props.paisesTipoArticulo}
                    setPaisesTipoArticulo={props.setPaisesTipoArticulo}
                    paisTipoArticuloSelected={props.paisTipoArticuloSelected}
                    setPaisTipoArticuloSelected={props.setPaisTipoArticuloSelected}

                    paisTipoLibroSelected={props.paisTipoLibroSelected}
                    setPaisTipoLibroSelected={props.setPaisTipoLibroSelected}

                    paisTipoCapLibroSelected={props.paisTipoCapLibroSelected}
                    setPaisTipoCapLibroSelected={props.setPaisTipoCapLibroSelected}

                    paisPatenteSelected={props.paisPatenteSelected}
                    setPaisPatenteSelected={props.setPaisPatenteSelected}
                    //Errores
                    getError={props.getError}
                    errores={props.errores}
                    setErrores={props.setErrores}
                />
            )
        case 1:
            return (
                <ModalFormGestionAutores
                    tipoSolicitudActual={props.tipoSolicitudActual}
                    setTipoSolicitudActual={props.setTipoSolicitudActual}

                    //Variables Autores y Particiapntes
                    numeroIdParEvent={props.numeroIdParEvent}
                    setNumeroIdParEvent={props.setNumeroIdParEvent}
                    arrai={props.arrai}
                    setArrai={props.setArrai}

                    //Permisos de la solicitud
                    permisosDeLaSolicitud={props.permisosDeLaSolicitud}
                    setPermisosDeLaSolicitud={props.setPermisosDeLaSolicitud}

                    cargando={props.cargando}
                    setCargando={props.setCargando}

                    //rol y modalidad
                    rolActive={props.rolActive}
                    setRolActive={props.setRolActive}

                    modalidadParticipacionActive={props.modalidadParticipacionActive}
                    setModalidadParticipacionActive={props.setModalidadParticipacionActive}

                    tiposRoles={[]}
                    setTiposRoles={[]}
                />
            )
    }
}

const ModalProduccionBibliograficaForm1 = (props) =>{
    const classes = useStyles()
    const handleOnchange = (e) =>{
        if(props.tipoProduccionBiblio.codigo == "Inves_tipo_biblio_articulo"){
            if(e.target.name == "indexaciones"){
                props.setIndexacionSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "indexacionSelected"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tipoArticulo"){
                props.setTipoArticuloSelected(e.target.value)
                props.setTipoArticuloOtro('')
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tipoArticuloSelected"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tipoArticuloOtro"){
                props.setTipoArticuloOtro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tipoArticuloOtro"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tituloArticulo"){
                props.setTituloArticulo(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tituloArticulo"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "issn"){
                props.setIssn(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "issn"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "nombreRevista"){
                props.setNombreRevista(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "nombreRevista"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "numPagTotales"){
                props.setNumPagTotales(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "numPagTotales"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "volumenRevista"){
                props.setVolumenRevista(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "volumenRevista"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "numeroRevista"){
                props.setNumeroRevista(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "numeroRevista"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "idioma"){
                props.setIdioma(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "idioma"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "enlace"){
                props.setEnlace(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "enlace"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "doi"){
                props.setDoi(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "doi"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "paisTipoArticuloSelected"){
                props.setPaisTipoArticuloSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "paisTipoArticuloSelected"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "idiomaTipoArticuloSelected"){
                props.setIdiomaTipoArticuloSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "idiomaTipoArticuloSelected"));
                    // Elimina error cuando se escribe en el campo
                }
            }
        }else if(props.tipoProduccionBiblio.codigo == "Inves_tipo_biblio_libro"){
            if(e.target.name == "tipoDeLibro"){
                props.setTipoLibroSelected(e.target.value)
                props.setTipoLibroOtro('')
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tipoLibroSelected"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tipoLibroOtro"){
                props.setTipoLibroOtro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tipoLibroOtro"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tituloLibro"){
                props.setTituloLibro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tituloLibro"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "isbnLibro"){
                props.setIsbnLibro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "isbnLibro"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "editorialLibro"){
                props.setEditorialLibro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "editorialLibro"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "paisTipoLibroSelected"){
                props.setPaisTipoLibroSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "paisTipoLibroSelected"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "idiomaLibro"){
                props.setIdiomaLibro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "idiomaLibro"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "volumenLibro"){
                props.setVolumenLibro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "volumenLibro"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "numeroEdicionLibro"){
                props.setNumeroEdicionLibro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "numeroEdicionLibro"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "idiomaLibroSelected"){
                props.setIdiomaLibroSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "idiomaLibroSelected"));
                    // Elimina error cuando se escribe en el campo
                }
            }
        }else if(props.tipoProduccionBiblio.codigo == "Inves_tipo_biblio_capituloLib"){
            if(e.target.name == "tipoDeLibro"){
                props.setTipoLibroSelectedCap(e.target.value)
                props.setTipoLibroOtroCap('')
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tipoLibroSelectedCap"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tipoLibroOtroCap"){
                props.setTipoLibroOtroCap(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tipoLibroOtroCap"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tituloCapLibro"){
                props.setTituloCapLibro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tituloCapLibro"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tituloLibroCap"){
                props.setTituloLibroCap(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tituloLibroCap"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "isbnLibroCap"){
                props.setIsbnLibroCap(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "isbnLibroCap"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "editorialLibroCap"){
                props.setEditorialLibroCap(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "editorialLibroCap"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "paisTipoCapLibroSelected"){
                props.setPaisTipoCapLibroSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "paisTipoCapLibroSelected"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "idiomaLibroCap"){
                props.setIdiomaLibroCap(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "idiomaLibroCap"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "volumenLibroCap"){
                props.setVolumenLibroCap(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "volumenLibroCap"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "numeroEdicionLibroCap"){
                props.setNumeroEdicionLibroCap(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "numeroEdicionLibroCap"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "idiomaCapituloLibroSelected"){
                props.setIdiomaCapituloLibroSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "idiomaCapituloLibroSelected"));
                    // Elimina error cuando se escribe en el campo
                }
            }
        }else if(props.tipoProduccionBiblio.codigo == "Inves_tipo_biblio_patente"){
            if(e.target.name == "tipoPatente"){
                props.setTipoPatenteSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tipoPatente"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tituloPatente"){
                props.setTituloPatente(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tituloPatente"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "numeroPatente"){
                props.setNumeroPatente(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "numeroPatente"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "paisPatenteSelected"){
                props.setPaisPatenteSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "paisPatenteSelected"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "gacetaIndustrialPatente"){
                props.setGaceteIndustrialPatente(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "gacetaIndustrialPatente"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "institucionPatente"){
                props.setInstitucionPatente(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "institucionPatente"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tipoPatenteOtro"){
                props.setTipoPatenteOtro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tipoPatenteOtro"));
                    // Elimina error cuando se escribe en el campo
                }
            }
        }

    }

    return(
        props.cargando == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> :
        <div>

                {/* Seccion de Produccion Bibliografica */}
                { props.tipoProduccionBiblio.codigo == "Inves_tipo_biblio_articulo" ?
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Indexacion</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('indexacionSelected', props.errores).length > 0}>
                                <Select
                                    value={props.indexacionSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="indexaciones"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.indexaciones.length} Indexaciones para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.indexaciones.map((item, index) => (
                                    <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('indexacionSelected', props.errores) }</FormHelperText>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Tipo de Articulo</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoArticuloSelected', props.errores).length > 0}>
                                <Select
                                    value={props.tipoArticuloSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="tipoArticulo"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tipoArticulo.length} Tipos de Articulos para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.tipoArticulo.map((item, index) => (
                                    <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                    <FormHelperText>{ props.getError('tipoArticuloSelected', props.errores) }</FormHelperText>
                    </Grid>

                    {props.tipoArticuloSelected.codigo == "Inves_tipo_articulo_otro" ?
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Tipo de Articulo"
                        id = 'tipoArticuloOtro'
                        error = { props.getError('tipoArticuloOtro', props.errores).length > 0}
                        helperText = { props.getError('tipoArticuloOtro', props.errores) }
                        value={props.tipoArticuloOtro}
                        onChange={handleOnchange}
                        name="tipoArticuloOtro"/>
                    </Grid> : ''}

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Título del artículo"
                        id = 'tituloArticulo'
                        error = { props.getError('tituloArticulo', props.errores).length > 0}
                        helperText = { props.getError('tituloArticulo', props.errores) }
                        value={props.tituloArticulo}
                        onChange={handleOnchange}
                        name="tituloArticulo"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="ISSN"
                        id = 'issn'
                        error = { props.getError('issn', props.errores).length > 0}
                        helperText = { props.getError('issn', props.errores) }
                        value={props.issn}
                        onChange={handleOnchange}
                        name="issn"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Nombre de la revista"
                        id = 'nombreRevista'
                        error = { props.getError('nombreRevista', props.errores).length > 0}
                        helperText = { props.getError('nombreRevista', props.errores) }
                        value={props.nombreRevista}
                        onChange={handleOnchange}
                        name="nombreRevista"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <Fragment>
                            <DatePicker
                                views={["year"]}
                                label="Año de publicación"
                                value={props.anioPublicacion}
                                fullWidth
                                onChange={props.setAnioPublicacion}
                                animateYearScrolling
                            />
                            </Fragment>
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                        fullWidth
                        label="Número de páginas totales"
                        id = 'numPagTotales'
                        error = { props.getError('numPagTotales', props.errores).length > 0}
                        helperText = { props.getError('numPagTotales', props.errores) }
                        value={props.numPagTotales}
                        onChange={handleOnchange}
                        name="numPagTotales"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Volumen de la revista"
                        id = 'volumenRevista'
                        error = { props.getError('volumenRevista', props.errores).length > 0}
                        helperText = { props.getError('volumenRevista', props.errores) }
                        value={props.volumenRevista}
                        onChange={handleOnchange}
                        name="volumenRevista"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="No. de la revista"
                        id = 'numeroRevista'
                        error = { props.getError('numeroRevista', props.errores).length > 0}
                        helperText = { props.getError('numeroRevista', props.errores) }
                        value={props.numeroRevista}
                        onChange={handleOnchange}
                        name="numeroRevista"/>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">País de Publicación</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('paisTipoArticuloSelected', props.errores).length > 0}>
                                <Select
                                    value={props.paisTipoArticuloSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="paisTipoArticuloSelected"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.paisesTipoArticulo.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.paisesTipoArticulo.map((item, index) => (
                                    <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('paisTipoArticuloSelected', props.errores) }</FormHelperText>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Idiomas</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('idiomaTipoArticuloSelected', props.errores).length > 0}>
                                <Select
                                    value={props.idiomaTipoArticuloSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="idiomaTipoArticuloSelected"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.idiomasTipoArticulo.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.idiomasTipoArticulo.map((item, index) => (
                                    <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('idiomaTipoArticuloSelected', props.errores) }</FormHelperText>
                    </Grid>
                    
                    {/* <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Idioma"
                        id = 'idioma'
                        error = { props.getError('idioma', props.errores).length > 0}
                        helperText = { props.getError('idioma', props.errores) }
                        value={props.idioma}
                        onChange={handleOnchange}
                        name="idioma"/>
                    </Grid> */}

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Enlace"
                        id = 'enlace'
                        error = { props.getError('enlace', props.errores).length > 0}
                        helperText = { props.getError('enlace', props.errores) }
                        value={props.enlace}
                        onChange={handleOnchange}
                        name="enlace"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="DOI"
                        id = 'doi'
                        error = { props.getError('doi', props.errores).length > 0}
                        helperText = { props.getError('doi', props.errores) }
                        value={props.doi}
                        onChange={handleOnchange}
                        name="doi"/>
                    </Grid>

                </Grid>: ''}

                {/* Seccion de Produccion Bibliografica Libros*/}
                { props.tipoProduccionBiblio.codigo == "Inves_tipo_biblio_libro" ?
                <Grid container spacing={3}>
                     <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Tipo de Libro</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoLibroSelected', props.errores).length > 0}>
                                <Select
                                    value={props.tipoLibroSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="tipoDeLibro"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposLibros.length} Libros para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.tiposLibros.map((item, index) => (
                                    <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('tipoLibroSelected', props.errores) }</FormHelperText>
                    </Grid>

                    {props.tipoLibroSelected.codigo == "Inves_tipo_libro_otro" ?
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Tipo de Libro"
                        id = 'tipoLibroOtro'
                        error = { props.getError('tipoLibroOtro', props.errores).length > 0}
                        helperText = { props.getError('tipoLibroOtro', props.errores) }
                        value={props.tipoLibroOtro}
                        onChange={handleOnchange}
                        name="tipoLibroOtro"/>
                    </Grid> : ''}

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Título del libro"
                        id = 'tituloLibro'
                        error = { props.getError('tituloLibro', props.errores).length > 0}
                        helperText = { props.getError('tituloLibro', props.errores) }
                        value={props.tituloLibro}
                        onChange={handleOnchange}
                        name="tituloLibro"/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="ISBN"
                        id = 'isbnLibro'
                        error = { props.getError('isbnLibro', props.errores).length > 0}
                        helperText = { props.getError('isbnLibro', props.errores) }
                        value={props.isbnLibro}
                        onChange={handleOnchange}
                        name="isbnLibro"/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Editorial"
                        id = 'editorialLibro'
                        error = { props.getError('editorialLibro', props.errores).length > 0}
                        helperText = { props.getError('editorialLibro', props.errores) }
                        value={props.editorialLibro}
                        onChange={handleOnchange}
                        name="editorialLibro"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <Fragment>
                            <DatePicker
                                views={["year"]}
                                label="Año de publicación"
                                value={props.anioPublicacionLibro}
                                fullWidth
                                onChange={props.setAnioPublicacionLibro}
                                animateYearScrolling
                            />
                            </Fragment>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">País de Publicación</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('paisTipoLibroSelected', props.errores).length > 0}>
                                <Select
                                    value={props.paisTipoLibroSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="paisTipoLibroSelected"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.paisesTipoArticulo.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.paisesTipoArticulo.map((item, index) => (
                                    <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('paisTipoLibroSelected', props.errores) }</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Idiomas</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('idiomaLibroSelected', props.errores).length > 0}>
                                <Select
                                    value={props.idiomaLibroSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="idiomaLibroSelected"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.idiomasLibro.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.idiomasLibro.map((item, index) => (
                                    <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('idiomaLibroSelected', props.errores) }</FormHelperText>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Idioma"
                        id = 'idiomaLibro'
                        error = { props.getError('idiomaLibro', props.errores).length > 0}
                        helperText = { props.getError('idiomaLibro', props.errores) }
                        value={props.idiomaLibro}
                        onChange={handleOnchange}
                        name="idiomaLibro"/>
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                        fullWidth
                        label="Volumen del libro"
                        id = 'volumenLibro'
                        error = { props.getError('volumenLibro', props.errores).length > 0}
                        helperText = { props.getError('volumenLibro', props.errores) }
                        value={props.volumenLibro}
                        onChange={handleOnchange}
                        name="volumenLibro"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        fullWidth
                        label="No. de edición"
                        id = 'numeroEdicionLibro'
                        error = { props.getError('numeroEdicionLibro', props.errores).length > 0}
                        helperText = { props.getError('numeroEdicionLibro', props.errores) }
                        value={props.numeroEdicionLibro}
                        onChange={handleOnchange}
                        name="numeroEdicionLibro"/>
                    </Grid>

                </Grid> : ''}
                {/* Seccion de Produccion Bibliografica Libros capitulo*/}
                { props.tipoProduccionBiblio.codigo == "Inves_tipo_biblio_capituloLib" ?
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Tipo de Libro</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoLibroSelectedCap', props.errores).length > 0}>
                                <Select
                                    value={props.tipoLibroSelectedCap}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="tipoDeLibro"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposLibrosCap.length} Libros para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.tiposLibrosCap.map((item, index) => (
                                    <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('tipoLibroSelectedCap', props.errores) }</FormHelperText>
                    </Grid>

                    {props.tipoLibroSelectedCap.codigo == "Inves_tipo_capLibro_otro" ?
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Tipo de Libro"
                        id = 'tipoLibroOtroCap'
                        error = { props.getError('tipoLibroOtroCap', props.errores).length > 0}
                        helperText = { props.getError('tipoLibroOtroCap', props.errores) }
                        value={props.tipoLibroOtroCap}
                        onChange={handleOnchange}
                        name="tipoLibroOtroCap"/>
                    </Grid> : ''}

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Título del capítulo de libro"
                        id = 'tituloCapLibro'
                        error = { props.getError('tituloCapLibro', props.errores).length > 0}
                        helperText = { props.getError('tituloCapLibro', props.errores) }
                        value={props.tituloCapLibro}
                        onChange={handleOnchange}
                        name="tituloCapLibro"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Título del libro"
                        id = 'tituloLibroCap'
                        error = { props.getError('tituloLibroCap', props.errores).length > 0}
                        helperText = { props.getError('tituloLibroCap', props.errores) }
                        value={props.tituloLibroCap}
                        onChange={handleOnchange}
                        name="tituloLibroCap"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="ISBN"
                        id = 'isbnLibroCap'
                        error = { props.getError('isbnLibroCap', props.errores).length > 0}
                        helperText = { props.getError('isbnLibroCap', props.errores) }
                        value={props.isbnLibroCap}
                        onChange={handleOnchange}
                        name="isbnLibroCap"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Editorial"
                        id = 'editorialLibroCap'
                        error = { props.getError('editorialLibroCap', props.errores).length > 0}
                        helperText = { props.getError('editorialLibroCap', props.errores) }
                        value={props.editorialLibroCap}
                        onChange={handleOnchange}
                        name="editorialLibroCap"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <Fragment>
                            <DatePicker
                                views={["year"]}
                                label="Año de publicación"
                                value={props.anioPublicacionLibroCap}
                                fullWidth
                                onChange={props.setAnioPublicacionLibroCap}
                                animateYearScrolling
                            />
                            </Fragment>
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">País de Publicación</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('paisTipoCapLibroSelected', props.errores).length > 0}>
                                <Select
                                    value={props.paisTipoCapLibroSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="paisTipoCapLibroSelected"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.paisesTipoArticulo.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.paisesTipoArticulo.map((item, index) => (
                                    <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('paisTipoCapLibroSelected', props.errores) }</FormHelperText>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Idiomas</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('idiomaCapituloLibroSelected', props.errores).length > 0}>
                                <Select
                                    value={props.idiomaCapituloLibroSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="idiomaCapituloLibroSelected"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.idiomasCapituloLibro.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.idiomasCapituloLibro.map((item, index) => (
                                    <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('idiomaCapituloLibroSelected', props.errores) }</FormHelperText>
                    </Grid>

                    {/* <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Idioma"
                        id = 'idiomaLibroCap'
                        error = { props.getError('idiomaLibroCap', props.errores).length > 0}
                        helperText = { props.getError('idiomaLibroCap', props.errores) }
                        value={props.idiomaLibroCap}
                        onChange={handleOnchange}
                        name="idiomaLibroCap"/>
                    </Grid> */}

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Volumen del libro"
                        id = 'volumenLibroCap'
                        error = { props.getError('volumenLibroCap', props.errores).length > 0}
                        helperText = { props.getError('volumenLibroCap', props.errores) }
                        value={props.volumenLibroCap}
                        onChange={handleOnchange}
                        name="volumenLibroCap"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="No. de edición"
                        id = 'numeroEdicionLibroCap'
                        error = { props.getError('numeroEdicionLibroCap', props.errores).length > 0}
                        helperText = { props.getError('numeroEdicionLibroCap', props.errores) }
                        value={props.numeroEdicionLibroCap}
                        onChange={handleOnchange}
                        name="numeroEdicionLibroCap"/>
                    </Grid>

                </Grid> : ''}
                {/* Seccion de Produccion Bibliografica Libros capitulo*/}
                { props.tipoProduccionBiblio.codigo == "Inves_tipo_biblio_patente" ?
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Tipo de patente</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoPatente', props.errores).length > 0}>
                                <Select
                                    value={props.tipoPatenteSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="tipoPatente"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tipoPatente.length} Patentes para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.tipoPatente.map((item, index) => (
                                    <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('tipoPatente', props.errores) }</FormHelperText>
                    </Grid>

                    {props.tipoPatenteSelected.codigo == "Inves_tipo_patente_otro" ?
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Tipo de Patente"
                        error = { props.getError('tipoPatenteOtro', props.errores).length > 0}
                        helperText = { props.getError('tipoPatenteOtro', props.errores) }
                        value={props.tipoPatenteOtro}
                        onChange={handleOnchange}
                        name="tipoPatenteOtro"/>
                    </Grid> : ''}

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Titulo de la Patente"
                        id = 'tituloPatente'
                        error = { props.getError('tituloPatente', props.errores).length > 0}
                        helperText = { props.getError('tituloPatente', props.errores) }
                        value={props.tituloPatente}
                        onChange={handleOnchange}
                        name="tituloPatente"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Numero Patente"
                        id = 'numeroPatente'
                        error = { props.getError('numeroPatente', props.errores).length > 0}
                        helperText = { props.getError('numeroPatente', props.errores) }
                        value={props.numeroPatente}
                        onChange={handleOnchange}
                        name="numeroPatente"/>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">País de Publicación</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('paisPatenteSelected', props.errores).length > 0}>
                                <Select
                                    value={props.paisPatenteSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="paisPatenteSelected"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.paisesTipoArticulo.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.paisesTipoArticulo.map((item, index) => (
                                    <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('paisPatenteSelected', props.errores) }</FormHelperText>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Gaceta Industrial de Publicación"
                        id = 'gacetaIndustrialPatente'
                        error = { props.getError('gacetaIndustrialPatente', props.errores).length > 0}
                        helperText = { props.getError('gacetaIndustrialPatente', props.errores) }
                        value={props.gacetaIndustrialPatente}
                        onChange={handleOnchange}
                        name="gacetaIndustrialPatente"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Institución"
                        id = 'institucionPatente'
                        error = { props.getError('institucionPatente', props.errores).length > 0}
                        helperText = { props.getError('institucionPatente', props.errores) }
                        value={props.institucionPatente}
                        onChange={handleOnchange}
                        name="institucionPatente"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <Fragment>
                            <DatePicker
                                views={["year"]}
                                label="Fecha de Solicitud"
                                value={props.anioObtencionPatente}
                                fullWidth
                                onChange={props.setAnioObtencionPatente}
                                animateYearScrolling
                            />
                            </Fragment>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid> : ''}
        </div>
    )

}
//Este solo se ejecuta cuando setSeleccion es igual a eventos o proyectos
const ModalStepperForm = (props) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
          <Stepper activeStep={props.hola} alternativeLabel>
            {props.steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
          {/* Aqui es cuando el Stepper llega al final.*/}
            {props.hola === props.steps.length ? (
              <div>
                <Typography className={classes.instructions}>{props.hola}</Typography>
                <Button onClick={props.handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                {getStepContent(props.hola, props)}
              </div>
            )}
          </div>
        </div>
    )
}

function getStepContent(stepIndex, props) {
    switch (stepIndex) {
        case 0:
            return (
                <ModalFormGestion
                    tipoSolicitudActual={props.tipoSolicitudActual}
                    setTipoSolicitudActual={props.setTipoSolicitudActual}

                    //Variables eventos
                    anio={props.anio}
                    setAnio={props.setAnio}

                    fechaInicioEvento={props.fechaInicioEvento}
                    setFechaInicioEvento={props.setFechaInicioEvento}

                    fechaFinEvento={props.fechaFinEvento}
                    setFechaFinEvento={props.setFechaFinEvento}

                    departamentos={props.departamentos}
                    setDepartamentos={props.setDepartamentos}

                    departamento={props.departamento}
                    setDepartamento={props.setDepartamento}

                    ciudades={props.ciudades}
                    setCiudades={props.setCiudades}

                    ciudad={props.ciudad}
                    setCiudad={props.setCiudad}

                    nombreDelEvento={props.nombreDelEvento}
                    setNombreDelEvento={props.setNombreDelEvento}

                    nombreDelAmbito={props.nombreDelAmbito}
                    setNombreDelAmbito={props.setNombreDelAmbito}

                    nombreAmbitos={props.nombreAmbitos}
                    setNombreAmbitos={props.setNombreAmbitos}

                    nombreModalidadPar={props.nombreModalidadPar}
                    setNombreModalidadPar={props.setNombreModalidadPar}

                    nombreModalidadPars={props.nombreModalidadPars}
                    setNombreModalidadPars={props.setNombreModalidadPars}

                    adj_evento={props.adj_evento}
                    setAdj_evento={props.setAdj_evento}

                    //Variables Proyectos
                    fechaInicio={props.fechaInicio}
                    setFechaInicio={props.setFechaInicio}

                    fechaFin={props.fechaFin}
                    setFechaFin={props.setFechaFin}

                    nombreProyecto={props.nombreProyecto}
                    setNombreProyecto={props.setNombreProyecto}

                    descripcionProyecto={props.descripcionProyecto}
                    setDescripcionProyecto={props.setDescripcionProyecto}

                    asignaturaProyecto={props.asignaturaProyecto}
                    setAsignaturaProyecto={props.setAsignaturaProyecto}
                    
                    nombreprofesorProyecto={props.nombreprofesorProyecto}
                    setNombreprofesorProyecto={props.setNombreprofesorProyecto}

                    profesorProyecto={props.profesorProyecto}
                    setProfesorProyecto={props.setProfesorProyecto}

                    obtenerliderSemillero={props.obtenerliderSemillero}
                    //Variables Prod. Bibliografica
                    nombreProductoBiblio={props.nombreProductoBiblio}
                    setNombreProductoBiblio={props.setNombreProductoBiblio}

                    tipoProductoBiblio={props.tipoProductoBiblio}
                    setTipoProductoBiblio={props.setTipoProductoBiblio}

                    anioBiblio={props.anioBiblio}
                    setAnioBiblio={props.setAnioBiblio}

                    revistaEditorialBiblio={props.revistaEditorialBiblio}
                    setRevistaEditorialBiblio={props.setRevistaEditorialBiblio}

                    cuartilBiblio={props.cuartilBiblio}
                    setCuartilBiblio={props.setCuartilBiblio}

                    lugarPublicacionBiblio={props.lugarPublicacionBiblio}
                    setLugarPublicacionBiblio={props.setLugarPublicacionBiblio}

                    medioDivulgacionBiblio={props.medioDivulgacionBiblio}
                    setMedioDivulgacionBiblio={props.setMedioDivulgacionBiblio}

                    //Variables Prod. Tec y Tecno
                    nombreProductoTec={props.nombreProductoTec}
                    setNombreProductoTec={props.setNombreProductoTec}

                    tipoProductoTec={props.tipoProductoTec}
                    setTipoProductoTec={props.setTipoProductoTec}

                    nitTec={props.nitTec}
                    setNitTec={props.setNitTec}

                    numeroRegistroTec={props.numeroRegistroTec}
                    setNumeroRegistroTec={props.setNumeroRegistroTec}

                    nombreTitular={props.nombreTitular}
                    setNombreTitular={props.setNombreTitular}

                    anioTec={props.anioTec}
                    setAnioTec={props.setAnioTec}

                    paisTec={props.paisTec}
                    setPaisTec={props.setPaisTec}
                    //Cargando
                    cargando={props.cargando}
                    setCargando={props.setCargando}

                    //Errores
                    getError={props.getError}
                    errores={props.errores}
                    setErrores={props.setErrores}

                    //rol y modalidad
                    rolActive={props.rolActive}
                    setRolActive={props.setRolActive}
                   
                />
            )
        case 1:
            return (
                <ModalFormGestionAutores
                    tipoSolicitudActual={props.tipoSolicitudActual}
                    setTipoSolicitudActual={props.setTipoSolicitudActual}

                    //Variables Autores y Particiapntes
                    numeroIdParEvent={props.numeroIdParEvent}
                    setNumeroIdParEvent={props.setNumeroIdParEvent}
                    arrai={props.arrai}
                    setArrai={props.setArrai}

                    //Permisos de la solicitud
                    permisosDeLaSolicitud={props.permisosDeLaSolicitud}
                    setPermisosDeLaSolicitud={props.setPermisosDeLaSolicitud}

                    //rol y modalidad
                    rolActive={props.rolActive}
                    setRolActive={props.setRolActive}

                    modalidadParticipacionActive={props.modalidadParticipacionActive}
                    setModalidadParticipacionActive={props.setModalidadParticipacionActive}

                    tiposRoles={props.nombreModalidadPars}
                    setTiposRoles={props.setNombreModalidadPars}

                    //Errores
                    getError={props.getError}
                    errores={props.errores}
                    setErrores={props.setErrores}

                     // Certificado
                     adj_certificado_final={props.adj_certificado_final}
                     setAdj_certificado_final={props.setAdj_certificado_final}
 
                     adj_certificado={props.adj_certificado}
                     setAdj_certificado={props.setAdj_certificado}
                />
            )
    }
}

const ModalFormGestion = (props) => {
    //Variables para proyectos
    const isInitialMount = React.useRef(true);
    const classes = useStyles();
    const fileInput = React.createRef();

    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                obtenerDepartamentos()
                obtenerAmbitoModalidad()
                obtenerProfesores()
            }
            obtenerProcesos()
        }
    })


    const obtenerDepartamentos = async () =>{
        let departamentos = await obtenerValores(7);
        const transformar = data => {
            return data.map(e => ({
              value: e.id,
              label: e.nombre,
              codigo: e.codigo,
            }));
        }
        props.setDepartamentos(transformar(departamentos))
    }

    const obtenerAmbitoModalidad = async () => {
        try{

          const data = await Funciones.obtenerValores(Helper.AMBITO_INVESTIGACION)
          const dataModalidad = await Funciones.obtenerValores(Helper.MODALIDAD_PAR_INVESTIGACION)
          props.setNombreAmbitos(data)
          props.setNombreModalidadPars(dataModalidad)
          props.setCargando(false)
        }catch(err){
          console.log(err)
        }

      }

    const obtenerCiudades = async (value) =>{

        if(props.ciudad != ''){
            props.setCiudad('')
        }
        let ciudades = await obtenerPermisosValor(value.value);
        ciudades = ciudades.map(({ secundario }) => ({
            value: secundario.id,
            label: secundario.nombre,
          }));
        props.setCiudades(ciudades)
    }
    // const obtenerliderSemillero = async () => {
    //     return new Promise(resolve => {
    //         Funciones.consulta(`api/v1.0/investigacion/listalideres/${props.semiActual.id}`, null, null, (error, estado, resp) => {
    //           resolve(estado === 200 && !error ? resp : null);
    //         })
    //     })
    // }
    const obtenerProfesores = async () =>{
        let data = await props.obtenerliderSemillero()
        let profesore= data.lideres.filter(l=>l.rol.codigo != 'Semi_rol1')
        props.setProfesorProyecto(profesore)
    }
    const handleOnchange = (e) =>{
        if(props.tipoSolicitudActual == "Semi_inves_proyectos" ){
            if(e.target.name == "nombreProyecto"){
                props.setNombreProyecto(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "nombreProyecto"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if (e.target.name == "descripcionProyecto") {
                props.setDescripcionProyecto(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "descripcionProyecto"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if (e.target.name == "asignaturaProyecto") {
                props.setAsignaturaProyecto(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "asignaturaProyecto"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if (e.target.name == "nombreprofesorProyecto") {
                props.setNombreprofesorProyecto(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "nombreprofesorProyecto"));
                    // Elimina error cuando se escribe en el campo
                }
            }
        }else if(props.tipoSolicitudActual == "Soli_inves_eventos"){
            if(e.target.name == "nombreDelEvento"){
                props.setNombreDelEvento(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "nombreDelEvento"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "nombreDelAmbito"){
                props.setNombreDelAmbito(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "nombreDelAmbito"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "nombreModalidadPar"){
                props.setNombreModalidadPar(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "nombreModalidadPar"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "adj_evento"){
                props.setAdj_evento(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "adj_evento"));
                    // Elimina error cuando se escribe en el campo
                }
            }
        }else if(props.tipoSolicitudActual == "Semi_inves_bibliografica"){
            if(e.target.name == "nombreProductoBiblio"){
                props.setNombreProductoBiblio(e.target.value)
            }else if(e.target.name == "tipoProductoBiblio"){
                props.setTipoProductoBiblio(e.target.value)
            }else if(e.target.name == "revistaEditorialBiblio"){
                props.setRevistaEditorialBiblio(e.target.value)
            }else if(e.target.name == "cuartilBiblio"){
                props.setCuartilBiblio(e.target.value)
            }else if(e.target.name == "lugarPublicacionBiblio"){
                props.setLugarPublicacionBiblio(e.target.value)
            }else if(e.target.name == "medioDivulgacionBiblio"){
                props.setMedioDivulgacionBiblio(e.target.value)
            }
        }else if(props.tipoSolicitudActual == "Semi_inves_tec"){
            if(e.target.name == "nombreProductoTec"){
                props.setNombreProductoTec(e.target.value)
            }else if(e.target.name == "tipoProductoTec"){
                props.setTipoProductoTec(e.target.value)
            }else if(e.target.name == "nitTec"){
                props.setNitTec(e.target.value)
            }else if(e.target.name == "numeroRegistroTec"){
                props.setNumeroRegistroTec(e.target.value)
            }else if(e.target.name == "nombreTitular"){
                props.setNombreTitular(e.target.value)
            }else if(e.target.name == "anioTec"){
                props.setAnioTec(e.target.value)
            }else if(e.target.name == "paisTec"){
                props.setPaisTec(e.target.value)
            }
        }
    }

    return (
        <div>
        {props.cargando == false ?
            <div>
                {/* PROYECTOS */}
                {props.tipoSolicitudActual == "Semi_inves_proyectos" ?
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Nombre del Proyecto"
                        error = { props.getError('nombreProyecto', props.errores).length > 0}
                        helperText = { props.getError('nombreProyecto', props.errores) }
                        value={props.nombreProyecto}
                        onChange={handleOnchange}
                        name="nombreProyecto"/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Descripción del proyecto"
                        error = { props.getError('descripcionProyecto', props.errores).length > 0}
                        helperText = { props.getError('descripcionProyecto', props.errores) }
                        value={props.descripcionProyecto}
                        onChange={handleOnchange}
                        name="descripcionProyecto"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        fullWidth
                        label="Nombre de asignatura o área de conocimiento"
                        error = { props.getError('asignaturaProyecto', props.errores).length > 0}
                        helperText = { props.getError('asignaturaProyecto', props.errores) }
                        value={props.asignaturaProyecto}
                        onChange={handleOnchange}
                        name="asignaturaProyecto"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="age-native-simple">Profesor Asesor</InputLabel>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('nombreprofesorProyecto', props.errores).length > 0}>
                        <Select
                            value={props.nombreprofesorProyecto}
                            displayEmpty
                            fullWidth
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={handleOnchange}
                            name="nombreprofesorProyecto"
                        >
                            <MenuItem value="">
                                <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.profesorProyecto.length} Profesores para Seleccionar` }</em>
                            </MenuItem>
                            {props.profesorProyecto.map((item, index) => (
                            <MenuItem key= { index } value = { item.encargado_id }>{`${item.nombre}`}</MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('nombreprofesorProyecto', props.errores) }</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <Fragment>
                            <KeyboardDatePicker
                                required={true}                           
                                style={{ marginTop: 3, width: '100%' }}
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="Fecha de Inicio"
                                value={props.fechaInicio}
                                onChange={props.setFechaInicio}
                                fullWidth
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                invalidDateMessage='Formato Invalido'
                            />
                            </Fragment>
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <Fragment>
                            <KeyboardDatePicker
                                required={true}                           
                                style={{ marginTop: 3, width: '100%' }}
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="Fecha de Fin"
                                value={props.fechaFin}
                                onChange={props.setFechaFin}
                                fullWidth
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                invalidDateMessage='Formato Invalido'
                            />
                            </Fragment>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>: ''}
                {/* FIN PROYECTOS */}

                {/* EVENTOS */}
                {props.tipoSolicitudActual == "Soli_inves_eventos" ?
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Nombre del Evento"
                        id = 'nombreEvento'
                        error = { props.getError('nombreDelEvento', props.errores).length > 0}
                        helperText = { props.getError('nombreDelEvento', props.errores) }
                        value={props.nombreDelEvento}
                        onChange={handleOnchange}
                        name="nombreDelEvento"/>
                    </Grid>

                    <Grid item xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <Fragment>
                            <DatePicker
                                views={["year"]}
                                label="Año"
                                value={props.anio}
                                fullWidth
                                onChange={props.setAnio}
                                animateYearScrolling
                            />
                            </Fragment>
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('departamento', props.errores).length > 0}>
                        <BuscarSelect
                            datos={props.departamentos}
                            cambiarEstado={(value) => {
                                obtenerCiudades(value)

                                props.setDepartamento(value)
                                if(props.errores.length > 0){
                                    props.setErrores(props.errores.filter(er => er.llave != "departamento"));
                                }
                            }} valor={props.departamento}
                            id='select-departamento'
                            nombre='Departamento'
                            name='departamento'
                            placeholder='Seleccione Opción' />
                        </FormControl>
                        <FormHelperText style={{color: "#FF2D00"}}>{ props.getError('departamento', props.errores) }</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('ciudad', props.errores).length > 0}>
                            <BuscarSelect
                            datos={props.ciudades}
                            cambiarEstado={(value) => {
                                props.setCiudad(value)

                                if(props.errores.length > 0){
                                    props.setErrores(props.errores.filter(er => er.llave != "ciudad"));
                                }
                            }}
                            valor={props.ciudad}
                            id='select-ciudad'
                            nombre='Ciudad'
                            name='ciudad'
                            placeholder='Seleccione Opción'/>
                        </FormControl>
                        <FormHelperText style={{color: "#FF2D00"}}>{ props.getError('ciudad', props.errores) }</FormHelperText>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Ambito</InputLabel>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('nombreDelAmbito', props.errores).length > 0}>
                        <Select
                            value={props.nombreDelAmbito}
                            displayEmpty
                            fullWidth
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={handleOnchange}
                            name="nombreDelAmbito"
                        >
                            <MenuItem value="">
                                <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.nombreAmbitos.length} Ambitos para Seleccionar` }</em>
                            </MenuItem>
                            {props.nombreAmbitos.map((item, index) => (
                            <MenuItem key= { index } value = { item.id }>{`${item.nombre}`}</MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('nombreDelAmbito', props.errores) }</FormHelperText>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <Fragment>
                            <KeyboardDatePicker
                                required={true}                           
                                style={{ marginTop: 3, width: '100%' }}
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="Fecha de Inicio"
                                value={props.fechaInicioEvento}
                                onChange={props.setFechaInicioEvento}
                                fullWidth
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                invalidDateMessage='Formato Invalido'
                            />
                            </Fragment>
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <Fragment>
                            <KeyboardDatePicker
                                required={true}                           
                                style={{ marginTop: 3, width: '100%' }}
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="Fecha de Fin"
                                value={props.fechaFinEvento}
                                onChange={props.setFechaFinEvento}
                                fullWidth
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                invalidDateMessage='Formato Invalido'
                            />
                            </Fragment>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} className='oculto'>
                        <TextField
                        ref={fileInput}
                        required
                        type="file"
                        id="adj_evento"
                        name="adj_evento"
                        label="Adjuntar Identificación"
                        fullWidth
                        autoComplete="billing address-line1"
                        onChange={handleOnchange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <InputFile 
                    label='Adjuntar Soporte de Evento' 
                    id='adj_evento' 
                    value={props.adj_evento} 
                    required={props.adj_evento} 
                    error={props.getError('adj_evento', props.errores) > 0}
                    helperText = { props.getError('adj_evento', props.errores) }/>
                   </Grid>

                </Grid> : ''}
                {/* FIN EVENTOS */}
                {/* PROD. BIBLIOGRÁFICO */}

                {props.tipoSolicitudActual == "Semi_inves_bibliografica" ?
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Nombre del Producto"
                        id = 'nombreProyecto'
                        //error = { props.getError('nameSemi', props.errores).length > 0}
                        //helperText = { props.getError('nameSemi', props.errores) }
                        value={props.nombreProductoBiblio}
                        onChange={handleOnchange}
                        name="nombreProductoBiblio"/>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                        fullWidth
                        label="Tipo del Producto"
                        id = 'nombreTipoProducto'
                        //error = { props.getError('nameSemi', props.errores).length > 0}
                        //helperText = { props.getError('nameSemi', props.errores) }
                        value={props.tipoProductoBiblio}
                        onChange={handleOnchange}
                        name="tipoProductoBiblio"/>
                    </Grid>

                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <Fragment>
                            <DatePicker
                                views={["year"]}
                                label="Año"
                                value={props.anioBiblio}
                                fullWidth
                                onChange={props.setAnioBiblio}
                                animateYearScrolling
                            />
                            </Fragment>
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                        fullWidth
                        label="Revista/Editorial"
                        id = 'nombreRevistaEditorial'
                        //error = { props.getError('nameSemi', props.errores).length > 0}
                        //helperText = { props.getError('nameSemi', props.errores) }
                        value={props.revistaEditorialBiblio}
                        onChange={handleOnchange}
                        name="revistaEditorialBiblio"/>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                        fullWidth
                        label="Cuartil"
                        id = 'nombreCuartil'
                        //error = { props.getError('nameSemi', props.errores).length > 0}
                        //helperText = { props.getError('nameSemi', props.errores) }
                        value={props.cuartilBiblio}
                        onChange={handleOnchange}
                        name="cuartilBiblio"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Lugar de Publicacion"
                        id = 'nombreLugarPublicacion'
                        //error = { props.getError('nameSemi', props.errores).length > 0}
                        //helperText = { props.getError('nameSemi', props.errores) }
                        value={props.lugarPublicacionBiblio}
                        onChange={handleOnchange}
                        name="lugarPublicacionBiblio"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Medio de Divulgacion"
                        id = 'nombreMedioDivulgacion'
                        //error = { props.getError('nameSemi', props.errores).length > 0}
                        //helperText = { props.getError('nameSemi', props.errores) }
                        value={props.medioDivulgacionBiblio}
                        onChange={handleOnchange}
                        name="medioDivulgacionBiblio"/>
                    </Grid>
                </Grid>
                : ''}

                {/* FIN PROD. BIBLIOGRAFICO */}

                {/* PROD. TEC Y TECNO */}
                {props.tipoSolicitudActual == "Semi_inves_tec" ?
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Nombre del Producto"
                        id = 'nombreProductoTecTecno'
                        //error = { props.getError('nameSemi', props.errores).length > 0}
                        //helperText = { props.getError('nameSemi', props.errores) }
                        value={props.nombreProductoTec}
                        onChange={handleOnchange}
                        name="nombreProductoTec"/>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                        fullWidth
                        label="Tipo de Producto"
                        id = 'nombreTipoProductoTecTecno'
                        //error = { props.getError('nameSemi', props.errores).length > 0}
                        //helperText = { props.getError('nameSemi', props.errores) }
                        value={props.tipoProductoTec}
                        onChange={handleOnchange}
                        name="tipoProductoTec"/>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                        fullWidth
                        label="NIT"
                        id = 'nombreNITTecTecno'
                        //error = { props.getError('nameSemi', props.errores).length > 0}
                        //helperText = { props.getError('nameSemi', props.errores) }
                        value={props.nitTec}
                        onChange={handleOnchange}
                        name="nitTec"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Nombre de Registro"
                        id = 'nombreRegistroTecTecno'
                        //error = { props.getError('nameSemi', props.errores).length > 0}
                        //helperText = { props.getError('nameSemi', props.errores) }
                        value={props.numeroRegistroTec}
                        onChange={handleOnchange}
                        name="numeroRegistroTec"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Nombre del Titular"
                        id = 'nombreTitularTecTecno'
                        //error = { props.getError('nameSemi', props.errores).length > 0}
                        //helperText = { props.getError('nameSemi', props.errores) }
                        value={props.nombreTitular}
                        onChange={handleOnchange}
                        name="nombreTitular"/>
                    </Grid>

                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <Fragment>
                            <DatePicker
                                views={["year"]}
                                label="Año"
                                value={props.anioTec}
                                fullWidth
                                onChange={props.setAnioTec}
                                animateYearScrolling
                            />
                            </Fragment>
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Pais"
                        id = 'paisTec'
                        //error = { props.getError('nameSemi', props.errores).length > 0}
                        //helperText = { props.getError('nameSemi', props.errores) }
                        value={props.paisTec}
                        onChange={handleOnchange}
                        name="paisTec"/>
                    </Grid>
                </Grid>
                : ''}
                {/* FIN PROD. TEC Y TECNO */}
            </div> : <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />}
        </div>
    )
}

const ModalFormGestionAutores = (props) => {

    const [openModalAddParti, setopenModalAddParti] = React.useState(false);
    const [openModalCargarArchivo, setOpenModalCargarArchivo] = React.useState(false);
    const [id, setId] = React.useState('');
    const actions = (requisito) => {

      const eventoDetalle = (requisito) => {
        let i = props.numeroIdParEvent.indexOf(requisito)
        let filtredData = ''
        let filtredDataArrai = ''
        if ( i !== -1 ) {
          filtredData = props.numeroIdParEvent.filter(item => item.id != requisito.id);
          filtredDataArrai = props.arrai.filter(item => item.id != requisito.id);
        }

        props.setNumeroIdParEvent(filtredData)
        props.setArrai(filtredDataArrai)
    }  

    const adjuntarCertificado = (requisito) => {
        let data = props.numeroIdParEvent
        let id = data.filter(e => e.id == requisito.id)
        setId(id[0].id)
        setOpenModalCargarArchivo(true)
    }  



      let detalle =(
      <div>
        {props.tipoSolicitudActual =='Soli_inves_eventos' && (
        // <Tooltip title="Subir Archivo" aria-label="add">
        // </Tooltip>
            <BtnCargar callback={() => adjuntarCertificado(requisito)} color= {coloresEmma.secondarycolor} texto='Gestionar' />
      )}
        <BtnEliminar callback={() => eventoDetalle(requisito)} /> 
      </div> 
      )

      return detalle

    }

    return(
        props.cargando == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> :
        <div>
        <ListarDatos
            id="tbl_listar_encargados"
            datos={props.numeroIdParEvent}
            opciones={true}
            agregar={true}
            buscar={true}
            actAgregar={() => setopenModalAddParti(true)}
            css={{
            list: { padding: '0px 0px 20px 0px' },
            appBar: { padding: '0px 0px 0px 0px' },
            }}
            acciones={(row) => actions(row)}
            titulo={"Lista de encargados"}
            fila_principal={({ nombre, id }) => `${nombre}`}
            filas={[
            {
                'mostrar': ({ nombre }) => `${nombre}`,
                'id': 'nombre', 'enLista': false
            },
            {
                'mostrar': ({ correo }) => `${correo}`,
                'id': 'nombre', 'enLista': true
            },
            {
                'mostrar': ({ identificacion }) => `${identificacion}`,
                'id': 'nombre', 'enLista': true
            },
            {
                'mostrar': ({ modalidadParticipacion }) => {
                    if(modalidadParticipacion != null){
                        return `${modalidadParticipacion.nombre}`
                    }else{
                        return ''
                    }
                },
                'id': 'nombre', 'enLista': true
            },
            {
                'mostrar': ({ rolDeParticipante }) => {
                    if(rolDeParticipante != null){
                        return `${rolDeParticipante.nombre}`
                    }else{
                        return ''
                    }
                },
                'id': 'nombre', 'enLista': true
            },
            { 'id': 'descripcion', 'enLista': false },
            ]}
        />

        <SemilleroAddParti
            openModalAddParti={openModalAddParti}
            setopenModalAddParti={setopenModalAddParti}

            participantes={props.numeroIdParEvent}
            setParticipantes={props.setNumeroIdParEvent}

            arrai={props.arrai}
            setArrai={props.setArrai}

            modalidadParticipacion={props.modalidadParticipacionActive}
            rol={props.rolActive}

            tiposModalidadParticipacion={props.tiposRoles}
        />
        {openModalCargarArchivo == true? 
        <ModalAdjuntarArchivo
            openModalCargarArchivo={openModalCargarArchivo}
            setOpenModalCargarArchivo={setOpenModalCargarArchivo}
            adj_certificado_final={props.adj_certificado_final}
            setAdj_certificado_final={props.setAdj_certificado_final}
            adj_certificado={props.adj_certificado}
            setAdj_certificado={props.setAdj_certificado}
            numeroIdParEvent={props.numeroIdParEvent}
            id={id}
            setId={setId}
            //Errores
            getError={props.getError}
            errores={props.errores}
            setErrores={props.setErrores}
        />
            
        :''}
        
    </div>
    )

}
const ModalAdjuntarArchivo = (props) => {
    const [cargando, setCargando] = React.useState(false)
    const fileInput = React.createRef();
    const dispatch = useDispatch();
    const handleClickSendTioApi = async() => {
        setCargando(true)
        let tipo = "success";
        let titulo = "Adjunto Cargado Correctamente";
        dispatch(
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        );
        handleCloseModal()
        setCargando(false)
    }
          
    

    const handleCloseModal = () => {
        if(!props.adj_certificado_final){
            props.setOpenModalCargarArchivo(false)
            props.setAdj_certificado('')
        } else{
            props.setOpenModalCargarArchivo(false)
        }       
    } 
    const handleOnchange = (e) =>{
        if (e.target.name === "adj_certificado") {
            const adj_evento1 = document.getElementById('adj_certificado').files[0];
            if (adj_evento1) {
              const nuevoAdjCertificado = { ...props.adj_certificado };
              props.setAdj_certificado(e.target.value);

              nuevoAdjCertificado[props.id] = e.target.value;
              props.setAdj_certificado(nuevoAdjCertificado);
              
              let adjuntos = [];
              const participanteYaTieneAdjunto = props.adj_certificado_final.find((adjunto) => adjunto.id === props.id);
              if (participanteYaTieneAdjunto) {
                adjuntos = props.adj_certificado_final.filter((adjunto) => adjunto.id !== props.id);
              } else {
                adjuntos = [...props.adj_certificado_final];
              }
              adjuntos.push({ id: props.id, adjunto: adj_evento1 });
              props.setAdj_certificado_final(adjuntos);
            }
        }
        if(props.errores.length > 0){
            props.setErrores(props.errores.filter(er => er.llave != "adj_evento"));
            // Elimina error cuando se escribe en el campo
        }
            
    }
    
    
      
      
    return(
        <Dialog open={props.openModalCargarArchivo} onClose={() => { handleCloseModal() }} aria-labelledby="form-dialog-title" maxWidth='sm' fullWidth={true}>
            <AppBarModal titulo='Adjuntar Certificado' mostrarModal={() => { handleCloseModal() }} titulo_accion="" accion="" />
            <DialogContent>
            {cargando == false ?<Grid item xs={12}>
                         <FormControl fullWidth >
                         <Grid item xs={12} className='oculto'>
                                <TextField
                                ref={fileInput}
                                required
                                type="file"
                                id="adj_certificado"
                                name="adj_certificado"
                                label="Adjuntar Identificación"
                                fullWidth
                                autoComplete="billing address-line1"
                                onChange={handleOnchange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <InputFile
                             
                            label='Adjuntar Soporte de Evento' 
                            id='adj_certificado' 
                            value={props.adj_certificado[props.id] || ''} 
                            required={props.adj_certificado[props.id] || false} 
                            // error={props.getError('adj_certificado', props.errores) > 0}
                            // helperText = { props.getError('adj_certificado', props.errores) }
                            />
                        </Grid>
                         </FormControl>
                         <FormHelperText></FormHelperText>
                     </Grid> : <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />}
                   </DialogContent>
                   <DialogActions>
                <BtnForm texto="Cerrar" callback={() => { handleCloseModal()}}  />
                <Button disabled={cargando == true} variant="contained" color="primary" style={{color: "#fff"}} onClick={() =>handleClickSendTioApi()}>
                            Enviar
                        </Button>
            </DialogActions>
           
        </Dialog>
    )
}
const SemilleroGestionIntegrantes = (props) =>{
    const dispatch = useDispatch();
    const isInitialMount = React.useRef(true);
    const [integrantes, setIntegrantes] = React.useState([])
    const [cargando, setCargando] = React.useState(true)
    const [cargandoAddInt, setCargandoAddInt] = React.useState(false)
    const [cargandoEdit, setCargandoEdit] = React.useState(true)
    const classes = useStyles()
    const [openFiltrar, setOpenFiltrar] = React.useState(false);
    const [dataDelete, setDataDelete] = React.useState("");
    const [openModal, setOpenModal] = React.useState(false);
    const [msg, setMsg] = React.useState("");
    //Edit
    const [cvlac, setCvlac] = React.useState("");
    const [scholar, setScholar] = React.useState("");
    const [researchGate, setResearchGate] = React.useState("");
    const [academiaEdu, setAcademiaEdu] = React.useState("");
    const [openModalEdit, setOpenModalEdit] = React.useState(false);
    const [openModalExp, setOpenModalExp] = React.useState(false);
    const [dataEdit, setDataEdit] = React.useState("");
    const [adj_seminario, setAdj_seminario] = React.useState('');
    const [ nombrecompleto_tutorlegal, setnombrecompleto_tutorlegal]=React.useState("");
    const [ numeroidentificacion_tutorlegal, setnumeroidentificacion_tutorlegal]= React.useState("")
    const [ PermisoTutor, setPermisoTutor]= React.useState("")
    const [adj_tutor, setadj_tutor]=React.useState('')
    const [adj_seminario_final, setAdj_seminario_final] = React.useState('');
    const [openModalMessage, setOpenModalMessage] = React.useState(false);
    const [dataRow, setDataRow] = React.useState("");

    //Add
    const [openModalAddIntegrante, setOpenModalAddIntegrante] = React.useState(false)
    const [programaSelect, setProgramaSelect] = React.useState('')
    const [programa, setPrograma] = React.useState([])
    const [profesor, setProfesor] = React.useState('')
    const [errores, setErrores] = React.useState([]);
    let data = {}
    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
       
            const obtenerProcesos = async () => {
                setCargando(true)

                if(props.openModalIntegrantes==true&&props.mdlIntegrantes==false){
                    let data = await obtenerIntegrantesActivosSemillero();
                    setIntegrantes(data)
                }else if(props.mdlIntegrantes==true) {
                    props.setOpenModalIntegrantes(true)
                    let data = await filtrarParticipanteSolicitudesenvia();
                    setIntegrantes(data)
                }
            }
            obtenerProcesos()
            setCargando(false)
        
    },[cargandoAddInt])

    const cambiarestado =(estado)=>{
        
        props.setmdlIntegrantes(estado)
        props.setOpenModalIntegrantes(estado)

    }
    
    //Obtener semilleros solicitudes
    const obtenerIntegrantesActivosSemillero = async () => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/listarParticipante2?estado_actual__codigo=Semi_par_acep&semillero=${props.semiActual.id}`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    const filtrarParticipanteSolicitudesenvia = async () => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/listarParticipante2?estado_actual__codigo=Semi_par_env&semillero=${props.semiActual.id}`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
            })
        })
}

    //Sistema de errores

    const getError = (llave, errores) => {
        let existe = errores.find(e => e.llave === llave);
        return existe ? existe.mensaje : '';
    }

    const validateInputs = (campos = []) => {
        return new Promise((resolve) => {
        let errores = [];
        let date_now = new Date()

        function isObjEmpty(obj) {

            if(typeof obj === 'object'){
                for (var prop in obj) {
                    if (obj.hasOwnProperty(prop)) return false;
                  }

                  return true;
            }else if(typeof obj === 'string'){
                return true
            }else{
                return false;
            }

        }

        campos.map(({ value, name, err, value2 }) => {

            if (!err.obj && err.empty && value.replace(/\s+/g, '') == "") errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` });
            if (!err.obj && err.empty == false && value.length == 0) errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
            //Validacion exclusiva para buscar Select.
            if (err.empty && isObjEmpty(value) && err.obj){
                errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
            }
            if (err.numeric && isNaN(value)) errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` });
            /*if (err.email && !validarCorreo(value)) errores.push({ llave: name, 'mensaje': `Correo invalido` });
            if (err.before_today && value >= date_now) errores.push({ llave: name, 'mensaje': `Fecha futura invalida` });
            if (err.after_today && value <= date_now) errores.push({ llave: name, 'mensaje': `Fecha pasada invalida` });
            if (err.finish_date && value <= value2) errores.push({ llave: name, 'mensaje': `Fecha invalida` });
            if (err.range && (value < 1 || value > value2)) errores.push({ llave: name, 'mensaje': `El valor está fuera del rango permitido` });
            if (err.not_null && value == null) errores.push({ llave:name, 'mensaje': `Este campo es obligatorio` });
            if (err.not_decimal && value % 1 != 0) errores.push({ llave: name, 'mensaje': `El valor no puede ser decimal` });*/
        });

        resolve(errores);
        });
    }

    const handleClickOpenEdit = (data) =>{
        setDataEdit(data)
        setOpenModalEdit(true)
    }

    const handleClickOpenExperiencias = (data) =>{
        setDataEdit(data)
        setOpenModalExp(true)
    }

    const handleClick = async (data) =>{
        setCargando(true)
        let url = "api/v1.0/investigacion/aceptarParticipante"

        const callback = async (error, estado, resp) => {
          let titulo = "Ha ocurrido un error, contacte con el administrador."
          let tipo = "error"

          if(!error){
              if(estado === 200){
                  tipo = "success"
                  titulo = resp.titulo
              }
          }

          dispatch(
              actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
            );

          let dataSolicitudes = await obtenerIntegrantesActivosSemillero();
          setIntegrantes(dataSolicitudes)
          setCargando(false)
        }

        const msg = await Funciones.consulta(url, data, 'POST', callback)//Fin handle click
    }

    const handleClickDelete = async () =>{
        if(msg == ""){
          alert("Campo vacio")
        }else{
          const hola = {
            mensaje: msg
          }
          const returnedTarget = Object.assign(dataDelete, hola);

          setCargando(true)
          let url = "api/v1.0/investigacion/rechazarParticipante"

          const callback = async (error, estado, resp) => {
            let titulo = "Ha ocurrido un error, contacte con el administrador."
            let tipo = "error"

            if(!error){
                if(estado === 200){
                    tipo = "success"
                    titulo = resp.titulo
                }
            }

            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
              );

            let dataSolicitudes = await obtenerIntegrantesActivosSemillero();
            setIntegrantes(dataSolicitudes)
            setCargando(false)
          }
          const mensaje = await Funciones.consulta(url, returnedTarget, 'POST', callback)
          setOpenModal(false)
        }
    }

    const handleClickOpen = (data) =>{
        setDataDelete(data)
        setOpenModal(true)
    }
    const down = (data) =>{
        setDataRow(data)
        setOpenModalMessage(true)
    }
    const closeDownModal = () =>{
        setOpenModalMessage(false)
    }
    const downWithMessageSenToApi = async () => {
        let data = {
            id: dataRow.id,
            msg: msg 
        }; 
        console.log("Datos a enviar:", data);
        setCargando(true);
        let url = "api/v1.0/investigacion/baja";
        
        const callback = async (error, estado, resp) => {
            let titulo = "Ha ocurrido un error, contacte con el administrador.";
            let tipo = "error";
            if (!error) {
                if (estado === 201) {
                    tipo = "success";
                    titulo = resp.titulo;
                }
            }
            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
            );
            let dataSolicitudes = await obtenerIntegrantesActivosSemillero();
            setIntegrantes(dataSolicitudes);
            setCargando(false);
            setMsg(""); 
            setOpenModalMessage(false)
            
        };
    
        await Funciones.consulta(url, data, 'POST', callback);
    };
    
    const botones = (data) =>{
        if(data.estado_actual.codigo == "Semi_par_env"){
            return (
                <div>
                    <IconButton aria-label="done" className={classes.colorCheck} onClick={()=>{handleClick(data)}}>
                    <DoneIcon/>
                    </IconButton>
                    <IconButton aria-label="delete" className={classes.colorDelete} onClick={()=>{handleClickOpen(data)}}>
                    <ClearIcon/>
                    </IconButton>
                    <IconButton aria-label="delete" className={classes.colorEdit} onClick={()=>{handleClickOpenEdit(data)}}>
                    <EditIcon/>
                    </IconButton>
                    <IconButton aria-label="delete" className={classes.colorEdit} onClick={()=>{handleClickOpenExperiencias(data)}}>
                    <MenuBookIcon/>
                    </IconButton>
                </div>
            )
        }else if (data.estado_actual.codigo == "Semi_par_acep"){
            return (
                <div>
                    <IconButton aria-label="delete" className={classes.colorEdit} onClick={()=>{handleClickOpenEdit(data)}}>
                    <EditIcon/>
                    </IconButton>
                    <IconButton aria-label="delete" className={classes.colorDelete} onClick={()=>{down(data)}}>
                    <ArrowDownwardIcon/>
                    </IconButton>
                </div>
            )
        }else{
            return ''
        }
    }

    const acciones = (data) => {
        const detalle = (
            <div>
            {botones(data)}
            </div>
        );

        return (
            detalle
        );
    }

    const handleClickSendTioApi = async() => {
        let array = await validateInputs([
            { value: profesor, name: 'profesor', err: { empty: true, obj: true, numeric: false } },
            { value: programaSelect, name: 'programaSelect', err: { empty: false, obj: false, numeric: true } },
        ])
        setErrores(array)

        if(array.length > 0){

        }else if(!adj_seminario){
            dispatch(
                actualizarMensaje({ titulo: 'Debe adjuntar el soporte', tipo: "info", mostrar: true, tiempo: 6000 })
            );
        }else{

            setCargandoAddInt(true)
            data = await Funciones.crear_form_data({
                idUsuario: profesor.id,
                programa:programaSelect,
                idSemillero:props.semiActual.id,
                link_cvlac:cvlac,
                link_google_scholar:scholar,
                link_research_gate:researchGate,
                link_academia_edu:academiaEdu,
                adj_seminario:adj_seminario_final,
                // nombrecompleto_tutorlegal: nombrecompleto_tutorlegal,
                // numeroidentificacion_tutorlegal:numeroidentificacion_tutorlegal,
                // PermisoTutor:PermisoTutor,
            })


            let url = "api/v1.0/investigacion/crearParticipante"
            const callback = (error, estado, resp) => {
                let titulo = "Ha ocurrido un error, contacte con el administrador."
                let tipo = "info"

                if(!error){
                    if(estado === 201){
                        tipo = "success"
                        titulo = resp.titulo
                        handleCloseModalAddPar()
                    }else{
                        tipo = "info"
                        titulo = resp.titulo
                    }
                }

                dispatch(
                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                  );
                setCargandoAddInt(false)
                if(tipo == "info"){

                }else{
                    setOpenModalAddIntegrante(false)
                }
            }
            const msg = await Funciones.formulario(url, data, 'POST', callback)
        }
    }

    const handleClickSendToApiEdit = async() => {
        setCargandoEdit(true)
        data = {
            idParticipante:dataEdit,
            link_cvlac:cvlac,
            // link_google_scholar:scholar,
            // link_research_gate:researchGate,
            // link_academia_edu:academiaEdu
        }


        let url = "api/v1.0/investigacion/actualizarParticipante"
            const callback = (error, estado, resp) => {
                let titulo = "Ha ocurrido un error, contacte con el administrador."
                let tipo = "info"

                if(!error){
                    if(estado === 200){
                        tipo = "success"
                        titulo = resp.titulo
                    }else{
                        tipo = "info"
                        titulo = resp.titulo
                    }
                }

                dispatch(
                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                  );
                  setCargandoEdit(false)
                if(tipo == "info"){
                }else{
                    setOpenModalEdit(false)
                }
            }
        const msg = await Funciones.consulta(url, data, 'POST', callback)
    }

    const handleCloseModalEdit = () =>{
        setDataEdit('')
        setCvlac('')
        setScholar('')
        setResearchGate('')
        setAcademiaEdu('')

        setOpenModalEdit(false)
    }

    const handleCloseModalExp = () =>{
        setOpenModalExp(false)
    }

    const handleCloseModalAddPar = () =>{
        setProfesor('')
        setProgramaSelect('')
        setDataEdit('')
        setCvlac('')
        setScholar('')
        setResearchGate('')
        setAcademiaEdu('')
        setAdj_seminario('')

        setOpenModalAddIntegrante(false)
    }
    return (
        <div>
            <Dialog PaperProps={{style: {height: '80vh', overflow: 'auto',top: '30px', },}} open={props.openModalIntegrantes} onClose={()=>{cambiarestado(false)}} aria-labelledby="form-dialog-title">

                <AppBarModal titulo='Gestion de Integrantes del Semillero' mostrarModal={()=>{cambiarestado(false)}} titulo_accion="" accion="" />
                {/* Contenido del modal del formulario de */}
                <DialogContent>
                <div>
                    {cargando == true ? <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />
                    : <div>
                            <ListarDatos
                            id="tbl_listar_participantes"
                            avatar={({ participante }) => participante.primer_nombre.charAt(0)}
                            datos={integrantes}
                            opciones={true}
                            agregar={true}
                            buscar={true}
                            filtrar={true}
                            actfiltrar={() => setOpenFiltrar(true)}
                            actAgregar={() => setOpenModalAddIntegrante(true)}
                            css={{
                            list: { padding: '0px 0px 20px 0px' },
                            appBar: { padding: '0px 0px 0px 0px' },
                            }}
                            acciones={(row) => acciones(row)}
                            titulo={"Integrantes del Semillero"}
                            fila_principal={({ participante }) => `${participante.primer_nombre} ${participante.segundo_nombre} ${participante.primer_apellido} ${participante.segundo_apellido}`}
                            filas={[
                            {
                                'mostrar': ({ participante }) => `${participante.correo}`,
                                'id': 'Correo', 'enLista': true
                            },
                            {
                                'mostrar': ({ participante }) => `${participante.celular ? participante.celular : ''}`,
                                'id': 'Celular', 'enLista': true
                            },
                            {   'nombre': 'Fecha de Solicitud: ',
                                'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'),
                                'id': 'fecha_registro', 'enLista': true
                            }
                            ]}
                        />
                    </div>
                    }
                </div>
                </DialogContent>

                <DialogActions>

                </DialogActions>

            </Dialog>
            {openModalMessage == true ? 
          <MessageToDown 
          openModal={openModalMessage} 
          setOpenModal={setOpenModalMessage} 
          downWithMessageSenToApi={downWithMessageSenToApi} 
          setMsg={setMsg} 
          msg={msg} 
          closeDownModal={closeDownModal}
        />
         : null}

            {openModalEdit == true ?
                <SemilleroRowEdit
                    openModalEdit={openModalEdit}
                    setOpenModalEdit={setOpenModalEdit}

                    cvlac={cvlac}
                    setCvlac={setCvlac}
                    scholar={scholar}
                    setScholar={setScholar}
                    researchGate={researchGate}
                    setResearchGate={setResearchGate}
                    academiaEdu={academiaEdu}
                    setAcademiaEdu={setAcademiaEdu}

                    dataEdit={dataEdit}
                    setDataEdit={setDataEdit}

                    handleClickSendToApiEdit={handleClickSendToApiEdit}

                    cargandoEdit={cargandoEdit}
                    setCargandoEdit={setCargandoEdit}

                    handleCloseModalEdit={handleCloseModalEdit}
            />: null}

            {openModalAddIntegrante == true ?
                <SemilleroModalAddIntegrante
                    openModalAddIntegrante={openModalAddIntegrante}
                    setOpenModalAddIntegrante={setOpenModalAddIntegrante}

                    cvlac={cvlac}
                    setCvlac={setCvlac}
                    scholar={scholar}
                    setScholar={setScholar}
                    researchGate={researchGate}
                    setResearchGate={setResearchGate}
                    academiaEdu={academiaEdu}
                    setAcademiaEdu={setAcademiaEdu}
                    adj_seminario={adj_seminario}
                    setAdj_seminario={setAdj_seminario}
                    adj_seminario_final={adj_seminario_final}
                    setAdj_seminario_final={setAdj_seminario_final}
                    programaSelect={programaSelect}
                    setProgramaSelect={setProgramaSelect}
                    nombrecompleto_tutorlegal= {setnombrecompleto_tutorlegal}
                    numeroidentificacion_tutorlegal={setnumeroidentificacion_tutorlegal}
                    PermisoTutor={setPermisoTutor}
                    adj_tutor={setadj_tutor}
                    programa={programa}
                    setPrograma={setPrograma}

                    handleClickSendTioApi={handleClickSendTioApi}

                    profesor={profesor}
                    setProfesor={setProfesor}

                    //Errores
                    getError={getError}
                    errores={errores}
                    setErrores={setErrores}

                    cargandoAddInt={cargandoAddInt}
                    setCargandoAddInt={setCargandoAddInt}

                    handleCloseModalAddPar={handleCloseModalAddPar}
            /> : ''}

            {openFiltrar == true ?
            <ModalFiltrar
                openFiltrar = {openFiltrar}
                setOpenFiltrar = {setOpenFiltrar}

                semiActual = {props.semiActual}

                infoSoli = {integrantes}
                setInfoSoli = {setIntegrantes}

                cargando = {cargando}
                setCargando = {setCargando}
          /> : null}

            {openModal == true ?
            <SemilleroMessage
                openModal={openModal}
                setOpenModal={setOpenModal}

                msg={msg}
                setMsg={setMsg}

                handleClickDelete={handleClickDelete}
            />: null}

            {openModalExp == true &&
            <ModalExperiencias
                openModalExp={openModalExp}
                setOpenModalExp={setOpenModalExp}
                handleCloseModalExp={handleCloseModalExp}

                dataEdit={dataEdit}
                setDataEdit={setDataEdit}
            />
            }
        </div>
    )
}

const useStylesTables = makeStyles((theme) => ({
    columna: {
      display: 'flex',
    },
}));

const ModalExperiencias = (props) => {
    const btnVer = archivo =>
        <Link href={`${Funciones.api}${archivo}`} target="_blank">
            <IconButton style={{ color: '#01579b' }} aria-label="delete">
                <VisibilityIcon fontSize="small" />
            </IconButton>
        </Link>

    const classes = useStylesTables();
    return(
        <Dialog open={props.openModalExp} onClose={() => { props.handleCloseModalExp() }} aria-labelledby="form-dialog-title" maxWidth='sm' fullWidth={true}>
            <AppBarModal titulo='Detalle Participante' mostrarModal={() => { props.handleCloseModalExp() }} titulo_accion="" accion="" />
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Table>
                            <TableBody>
                                <TableRow key={1}>
                                    <TableCell component="th" scope="row">Experiencias</TableCell>
                                    <TableCell align="left">{props.dataEdit.experiencia}</TableCell>
                                </TableRow>
                                {props.dataEdit.adj_seminario &&
                                    <TableRow key={2}>
                                        <TableCell component="th" scope="row">Soporte Seminario Adjunto</TableCell>
                                        <TableCell align="left">{btnVer(props.dataEdit.adj_seminario)}</TableCell>
                                    </TableRow>
                                }
                                {props.dataEdit.adj_metodologia &&
                                    <TableRow key={3}>
                                        <TableCell component="th" scope="row">Soporte Metodologia de Investigación</TableCell>
                                        <TableCell align="left">{btnVer(props.dataEdit.adj_metodologia)}</TableCell>
                                    </TableRow>
                                } 
                                {props.dataEdit.nombrecompleto_tutorlegal &&
                                <TableRow key={1}>
                                    <TableCell component="th" scope="row">Nombre del tutor legal</TableCell>
                                    <TableCell align="left">{props.dataEdit.nombrecompleto_tutorlegal}</TableCell>
                                </TableRow>  
                                }
                                {props.dataEdit.numeroidentificacion_tutorlegal &&
                                <TableRow key={1}>
                                    <TableCell component="th" scope="row">Número de identificación del tutor legal</TableCell>
                                    <TableCell align="left">{props.dataEdit.numeroidentificacion_tutorlegal}</TableCell>
                                </TableRow> }
                                {props.dataEdit && props.dataEdit.PermisoTutor && (
                                    <TableRow key={1}>
                                    <TableCell component="th" scope="row">Autorización del tutor legal</TableCell>
                                    <TableCell align="left">{props.dataEdit.PermisoTutor}</TableCell>
                                    </TableRow>
                                    )}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <BtnForm texto="Cerrar" callback={() => { props.handleCloseModalExp()}} />
            </DialogActions>
        </Dialog>
    )
}
const MessageToDown = (props) => {
    
    const handleOnchange2 = (e) =>{
        props.setMsg(e.target.value);
    }
  
    return (
      <div>
        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModal} onClose={() => props.closeDownModal()}>
          <AppBarModal titulo={'¡ Digite su Mensaje !'} mostrarModal={() => props.closeDownModal()} titulo_accion="" accion="" />
          <DialogContent>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField 
                        fullWidth 
                        multiline
                        label="Escriba un mensaje indicándole la razón  por qué se desvinculó del grupo." 
                        id = 'msg'
                        value={props.msg} 
                        onChange={handleOnchange2}
                        name="msg" 
                        
                    />
                </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={props.downWithMessageSenToApi}>
                Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  
  }

const SemilleroRowEdit = (props) => {

    const isInitialMount = React.useRef(true);

    const handleOnchange = (e) =>{
      if(e.target.name == "cvlac"){
        props.setCvlac(e.target.value)
      }else if(e.target.name == "scholar"){
        props.setScholar(e.target.value)
      }
    }

    React.useEffect( () => {
      //Obtengo los prceosos activos de la persona activa.
      if(isInitialMount.current){
          isInitialMount.current = false
          const obtenerProcesos = async () => {
              let data = await obtenerLinks();

              if(data[0].link_academia_edu != null){
                props.setAcademiaEdu(data[0].link_academia_edu)
              }
              if(data[0].link_cvlac != null){
                props.setCvlac(data[0].link_cvlac)
              }
              if(data[0].link_google_scholar != null){
                props.setScholar(data[0].link_google_scholar)
              }
              if(data[0].link_research_gate != null){
                props.setResearchGate(data[0].link_google_scholar)
              }
              props.setCargandoEdit(false)
          }
          obtenerProcesos()
      }
    })

    //Obtener semilleros solicitudes
    const obtenerLinks = async () => {
      return new Promise(resolve => {
          Funciones.consulta(`api/v1.0/investigacion/listarParticipante2?id=${props.dataEdit.id}`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
          })
      })
    }

    return(
      <div>
              <Dialog open={props.openModalEdit} onClose={()=>{props.handleCloseModalEdit()}} aria-labelledby="form-dialog-title">

                  <AppBarModal titulo='Actualizar Links' mostrarModal={()=>{props.handleCloseModalEdit()}} titulo_accion="" accion="" />
                  {/* Contenido del modal del formulario de */}
                  <DialogContent>
                  {props.cargandoEdit == true ? <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> :
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        //id="standard-multiline-flexible"
                        label="Link de Cvlac (en caso de tenerlo) O linkedin"
                        //error = { props.getError('correo', props.errores).length > 0}
                        //helperText = { props.getError('correo', props.errores) }
                        fullWidth
                        value={props.cvlac}
                        onChange={handleOnchange}
                        name="cvlac"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        //id="standard-multiline-flexible"
                        label="Link Google Scholar (en caso de tenerlo)"
                        //error = { props.getError('correo', props.errores).length > 0}
                        //helperText = { props.getError('correo', props.errores) }
                        fullWidth
                        value={props.scholar}
                        onChange={handleOnchange}
                        name="scholar"
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <TextField
                        //id="standard-multiline-flexible"
                        label="Link ResearchGate (en caso de tenerlo)"
                        //error = { props.getError('correo', props.errores).length > 0}
                        //helperText = { props.getError('correo', props.errores) }
                        fullWidth
                        value={props.researchGate}
                        onChange={handleOnchange}
                        name="researchGate"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        //id="standard-multiline-flexible"
                        label="Link academia.edu (en caso de tenerlo)"
                        //error = { props.getError('correo', props.errores).length > 0}
                        //helperText = { props.getError('correo', props.errores) }
                        fullWidth
                        value={props.academiaEdu}
                        onChange={handleOnchange}
                        name="academiaEdu"
                      />
                    </Grid> */}
                    </Grid>}
                  </DialogContent>

                  <DialogActions>
                    <BtnForm texto="Enviar" callback={props.handleClickSendToApiEdit} />
                  </DialogActions>

              </Dialog>
          </div>
    )

}

const SemilleroModalAddIntegrante = (props) =>{

    const [openModal, setOpenModal] = React.useState(false)
    const classes = useStyles();
    const fileInput = React.createRef();
    const handleOnchange = (e) =>{
        if(e.target.name == "cvlac"){
          props.setCvlac(e.target.value)
          if(props.errores.length > 0){
            props.setErrores(props.errores.filter(er => er.llave != "cvlac"));
            // Elimina error cuando se escribe en el campo
          }
        }else if(e.target.name == "scholar"){
          props.setScholar(e.target.value)
        }else if(e.target.name == "adj_seminario"){
            props.setAdj_seminario(e.target.value)
            let adj_semi = document.getElementById('adj_seminario').files[0];
            if (adj_semi) props.setAdj_seminario_final(adj_semi);
            if(props.errores.length > 0){
              props.setErrores(props.errores.filter(er => er.llave != "adj_seminario"));
              // Elimina error cuando se escribe en el campo
            }
          }
    }

    const obtenerInfoUser = async (id) => {
        const data = await Funciones.obtenerMisDatos(id)
        props.setPrograma(data.programas)

    }

    //Funcion para asginar Departamentos y Programas al select.
    const handleChangeDepaPro = (event) => {
        props.setProgramaSelect(event.target.value);
        if(props.errores.length > 0){
            props.setErrores(props.errores.filter(er => er.llave != "programaSelect"));
            // Elimina error cuando se escribe en el campo
        }
    };

    return (
        <div>
              <Dialog open={props.openModalAddIntegrante} onClose={()=>{props.handleCloseModalAddPar()}} aria-labelledby="form-dialog-title">

                  <AppBarModal titulo='Agregar Integrante al Semillero' mostrarModal={()=>{props.handleCloseModalAddPar()}} titulo_accion="" accion="" />
                  {/* Contenido del modal del formulario de */}
                  <DialogContent>
                    <Box p={2}>
                        {props.cargandoAddInt == false ? <Grid container spacing={3}>
                            <Grid item xs={12}>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('profesor', props.errores).length > 0}>
                                <InputBuscar
                                    id='profesor'
                                    label='Estudiante'
                                    name="profesor"
                                    fullWidth
                                    placeholder='Clic aquí para buscar'
                                    value={props.profesor.nombre == undefined ? '' : props.profesor.nombre}
                                    callback={() => {
                                        setOpenModal(true)
                                        if(props.errores.length > 0){
                                            props.setErrores(props.errores.filter(er => er.llave != "profesor"));
                                        }
                                    }} />
                                </FormControl>
                            <FormHelperText style={{color: "#FF2D00"}}>{ props.getError('profesor', props.errores) }</FormHelperText>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="age-native-simple">Programa</InputLabel>
                                <FormControl className={classes.formControl} fullWidth error = { props.getError('programaSelect', props.errores).length > 0}>
                                    <Select
                                        value={props.programaSelect}
                                        displayEmpty
                                        fullWidth
                                        className={classes.selectEmpty}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        onChange={handleChangeDepaPro}
                                        name="programaSelect"
                                    >
                                        <MenuItem value="">
                                            <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.programa.length} Progra. Seleccionados` }</em>
                                        </MenuItem>
                                        {props.programa.map((item, index) => (
                                        <MenuItem key= { index } value = { item.relacion.id }>{`${item.relacion.nombre}`}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormHelperText style={{color: "#FF2D00"}}>{ props.getError('programaSelect', props.errores) }</FormHelperText>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="cvlac"
                                    label="Link de ORCID o CVLAC (en caso de tenerlo)"
                                    // error = { props.getError('cvlac', props.errores).length > 0}
                                    // helperText = { props.getError('cvlac', props.errores) }
                                    fullWidth
                                    value={props.cvlac}
                                    onChange={handleOnchange}
                                    name="cvlac"
                                />
                             <FormHelperText style={{color: "#FF2D00"}}>{ props.getError('cvlac', props.errores) }</FormHelperText>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    //id="standard-multiline-flexible"
                                    label=" Link de Google Scholar o LinkedIn (en caso de tenerlo)"
                                    //error = { props.getError('correo', props.errores).length > 0}
                                    //helperText = { props.getError('correo', props.errores) }
                                    fullWidth
                                    value={props.scholar}
                                    onChange={handleOnchange}
                                    name="scholar"
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <TextField
                                    //id="standard-multiline-flexible"
                                    label="Link ResearchGate (en caso de tenerlo)"
                                    //error = { props.getError('correo', props.errores).length > 0}
                                    //helperText = { props.getError('correo', props.errores) }
                                    fullWidth
                                    value={props.researchGate}
                                    onChange={handleOnchange}
                                    name="researchGate"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    //id="standard-multiline-flexible"
                                    label="Link academia.edu (en caso de tenerlo)"
                                    //error = { props.getError('correo', props.errores).length > 0}
                                    //helperText = { props.getError('correo', props.errores) }
                                    fullWidth
                                    value={props.academiaEdu}
                                    onChange={handleOnchange}
                                    name="academiaEdu"
                                />
                            </Grid> */}
                            <Grid item xs={12} className='oculto'>
                                <TextField
                                ref={fileInput}
                                required
                                type="file"
                                id="adj_seminario"
                                name="adj_seminario"
                                label="Adjuntar"
                                fullWidth
                                autoComplete="billing address-line1"
                                onChange={handleOnchange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                 <InputFile 
                                 label='Adjuntar Certificado de Seminario de Investigación Adjunto' 
                                 id='adj_seminario' 
                                 value={props.adj_seminario} 
                                 required={props.adj_seminario} 
                                 fullWidth
                                 error={props.getError('adj_seminario', props.errores) > 0} 
                                 helperText = { props.getError('adj_seminario', props.errores) }/>
                            </Grid>
                        </Grid> : <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />}
                    </Box>
                  </DialogContent>

                  <DialogActions>
                        <Button disabled={props.cargandoAddInt == true} variant="contained" color="primary" style={{color: "#fff"}} onClick={() => props.handleClickSendTioApi()}>
                            Enviar
                        </Button>
                  </DialogActions>

              </Dialog>

              {openModal == true ?
                <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={openModal} onClose={() => setOpenModal(false)}>
                    <AppBarModal titulo={'¡ Buscar Estudiante !'} mostrarModal={() => setOpenModal(false)} titulo_accion="CERRAR" accion={() => setOpenModal(false)} />
                    <DialogContent style={{ padding: '0' }} className='scroll'>
                        <BuscarPersona callback={(data) => {
                            props.setProfesor({id: data.id, nombre:  `${data.primer_nombre} ${data.segundo_nombre} ${data.primer_apellido} ${data.segundo_apellido}`})
                            props.setProgramaSelect('')
                            obtenerInfoUser(data.id)
                            setOpenModal(false)
                        }} />
                    </DialogContent>
                    <DialogActions>
                            <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={() => setOpenModal(false)}>
                                Cancelar
                            </Button>
                    </DialogActions>
                </Dialog>
                : ''}
          </div>
    )

}

const ModalFiltrar = (props) =>{

    const [lineaSeleccion, setLineaSeleccion] = React.useState('');
    const [estadosPar, setEstadosPar] =  React.useState([]);
    const isInitialMount = React.useRef(true);
    const [cargandoPar, setCargadoPar] = React.useState(true);
    const classes = useStyles();
    React.useEffect( () => {
      //Obtengo los prceosos activos de la persona activa.
      if(isInitialMount.current){
          isInitialMount.current = false
          obtenerEstados()
      }
    })
    //Obtengo los estados
    const obtenerEstados = async () => {
      try{
        const data = await Funciones.obtenerValores(Helper.SOLICITUDES_PAR_INVES)

        setEstadosPar(data)
        setCargadoPar(false)
      }catch(err){
        console.log(err)
      }
    }

    const filtrarParticipanteSolicitudes = async () => {
      return new Promise(resolve => {
          Funciones.consulta(`api/v1.0/investigacion/listarParticipante2?estado_actual__codigo=${lineaSeleccion.codigo}&semillero=${props.semiActual.id}`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
          })
      })
    }

    //Funcipan para cambiar el seleccionado
    const handleChangeLinea = (event) => {
      setLineaSeleccion(event.target.value);
    };

    const filtrar = async() =>{
      if(lineaSeleccion == ""){
        alert("elija una opcion")
      }else{
        props.setCargando(true)
        let data = await filtrarParticipanteSolicitudes()
        props.setInfoSoli(data)
        props.setOpenFiltrar(false)
        props.setCargando(false)
      }
    }

    return(
      <div>
        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openFiltrar} onClose={() => props.setOpenFiltrar(false)}>
          <AppBarModal titulo={'¡ Más Información !'} mostrarModal={() => props.setOpenFiltrar(false)} titulo_accion="" accion="" />
          <DialogContent>
            {cargandoPar == true ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /> : <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel htmlFor="age-native-simple">Estados</InputLabel>
                <FormControl className={classes.formControl} fullWidth /*error = { props.getError('lineaSubInves', props.errores).length > 0}*/>
                  <Select
                    value={lineaSeleccion}
                    displayEmpty
                    fullWidth
                    //className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleChangeLinea}
                  >
                    <MenuItem value="">
                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${estadosPar.length} Estados Disponibles` }</em>
                    </MenuItem>
                    {estadosPar.map((item, index) => (
                      <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                    ))}
                    </Select>
                </FormControl>
                {/*<FormHelperText>{ props.getError('lineaSubInves', props.errores) }</FormHelperText>*/}
              </Grid>
            </Grid>}
          </DialogContent>
          <DialogActions>
                <BtnForm texto="Filtrar" callback={filtrar} />
          </DialogActions>
        </Dialog>
      </div>
    )
}

export default Semilleros