const MODAL_DET_SOLICITUD = 'MODAL_DET_SOLICITUD'
const MODAL_GESTIONAR_SOLICITUD = 'MODAL_GESTIONAR_SOLICITUD'
const MODAL_CANCELAR_SOLICITUD = 'MODAL_CANCELAR_SOLICITUD'
const MODAL_ADD_MOV_ESTUDIANTIL = 'MODAL_ADD_MOV_ESTUDIANTIL'
const MODAL_ADD_CONVENIO = 'MODAL_ADD_CONVENIO'
const MODAL_ADD_REGISTRO = 'MODAL_ADD_REGISTRO'
const MODAL_ADD_CONVOCATORIAS = 'MODAL_ADD_CONVOCATORIAS'
const MODAL_MOD_CONVOCATORIAS = 'MODAL_MOD_CONVOCATORIAS'
const MODAL_DEL_CONVOCATORIAS = 'MODAL_DEL_CONVOCATORIAS'
const MODAL_DET_CONVOCATORIAS = 'MODAL_DET_CONVOCATORIAS'
const MODAL_ADD_INSTITUCIONES = 'MODAL_ADD_INSTITUCIONES'
const MODAL_MOD_INSTITUCIONES = 'MODAL_MOD_INSTITUCIONES'
const MODAL_DEL_INSTITUCIONES = 'MODAL_DEL_INSTITUCIONES'
const MODAL_DET_INSTITUCIONES = 'MODAL_DET_INSTITUCIONES'
const MODAL_ADD_REQUISITOS = 'MODAL_ADD_REQUISITOS'
const MODAL_MOD_REQUISITOS = 'MODAL_MOD_REQUISITOS'
const MODAL_DEL_REQUISITOS = 'MODAL_DEL_REQUISITOS'
const MODAL_DET_REQUISITOS = 'MODAL_DET_REQUISITOS'
const MODAL_LISTA_UNIVERSIDADES = 'MODAL_LISTA_UNIVERSIDADES'
const MODAL_LISTA_REQUISITOS = 'MODAL_LISTA_REQUISITOS'
const MODAL_LISTA_ARCHIVOS = 'MODAL_LISTA_ARCHIVOS'
const MODAL_ADJUNTAR_DOCUMENTOS = 'MODAL_ADJUNTAR_DOCUMENTOS'
const MODAL_ADD_FILTROS = 'MODAL_ADD_FILTROS'
const MODAL_DET_ADJUNTOS = 'MODAL_DET_ADJUNTOS'
const MODAL_MOD_ADJUNTOS = 'MODAL_MOD_ADJUNTOS'
const MODAL_APROBAR_ADJUNTO = 'MODAL_APROBAR_ADJUNTO'
const MODAL_NEGAR_ADJUNTO = 'MODAL_NEGAR_ADJUNTO'
const ACT_DATA_SOLICITUD = 'ACT_DATA_SOLICITUD'
const ACT_DATA_CONVOCATORIA = 'ACT_DATA_CONVOCATORIA'
const ACT_DATA_CONVOCATORIAS = 'ACT_DATA_CONVOCATORIAS'
const ACT_DATA_INSTITUCION = 'ACT_DATA_INSTITUCION'
const ACT_DATA_INSTITUCIONES = 'ACT_DATA_INSTITUCIONES'
const ACT_DATA_REQUISITO = 'ACT_DATA_REQUISITO'
const ACT_DATA_REQUISITOS = 'ACT_DATA_REQUISITOS'
const MODAL_AGREGAR_FINANCIACIONES = 'MODAL_AGREGAR_FINANCIACIONES'
const MODAL_EDI_CONVENIO = 'MODAL_EDI_CONVENIO'
const MODAL_ADD_PER_DEP = 'MODAL_ADD_PER_DEP'
const MODAL_DET_CONV_INST = 'MODAL_DET_CONV_INST'
const MODAL_SOL_INSTI = 'MODAL_SOL_INSTI'
const MODAL_SOL_RED = 'MODAL_SOL_RED'
const MODAL_EDI_IES = 'MODAL_EDI_IES'

const mostrarModalDetSolicitud = show => {
  return {
    type: MODAL_DET_SOLICITUD,
    show
  }
}

const mostrarModalGestionarSolicitud = show => {
  return {
    type: MODAL_GESTIONAR_SOLICITUD,
    show
  }
}

const mostrarModalCancelarSolicitud = show => {
  return {
    type: MODAL_CANCELAR_SOLICITUD,
    show
  }
}

const mostrarModalAddMovEstudiantil = show => {
  return {
    type: MODAL_ADD_MOV_ESTUDIANTIL,
    show
  }
}

const mostrarModalAddConvenio = show => {
  return {
    type: MODAL_ADD_CONVENIO,
    show
  }
}

const mostrarModalAddRegistro = show => {
  return {
    type: MODAL_ADD_REGISTRO,
    show
  }
}

const mostrarModalAddConvocatorias = show => {
  return {
    type: MODAL_ADD_CONVOCATORIAS,
    show
  }
}

const mostrarModalModConvocatorias = show => {
  return {
    type: MODAL_MOD_CONVOCATORIAS,
    show
  }
}

const mostrarModalDelConvocatorias = show => {
  return {
    type: MODAL_DEL_CONVOCATORIAS,
    show
  }
}

const mostrarModalDetConvocatorias = show => {
  return {
    type: MODAL_DET_CONVOCATORIAS,
    show
  }
}

const mostrarModalAddInstituciones = show => {
  return {
    type: MODAL_ADD_INSTITUCIONES,
    show
  }
}

const mostrarModalModInstituciones = show => {
  return {
    type: MODAL_MOD_INSTITUCIONES,
    show
  }
}

const mostrarModalDelInstituciones = show => {
  return {
    type: MODAL_DEL_INSTITUCIONES,
    show
  }
}

const mostrarModalDetInstituciones = show => {
  return {
    type: MODAL_DET_INSTITUCIONES,
    show
  }
}

const mostrarModalAddRequisitos = show => {
  return {
    type: MODAL_ADD_REQUISITOS,
    show
  }
}

const mostrarModalModRequisitos = show => {
  return {
    type: MODAL_MOD_REQUISITOS,
    show
  }
}

const mostrarModalDelRequisitos = show => {
  return {
    type: MODAL_DEL_REQUISITOS,
    show
  }
}

const mostrarModalDetRequisitos = show => {
  return {
    type: MODAL_DET_REQUISITOS,
    show
  }
}

const mostrarModalListaUniversidades = show => {
  return {
    type: MODAL_LISTA_UNIVERSIDADES,
    show
  }
}

const mostrarModalListaRequisitos = show => {
  return {
    type: MODAL_LISTA_REQUISITOS,
    show
  }
}

const mostrarModalListaArchivos = show => {
  return {
    type: MODAL_LISTA_ARCHIVOS,
    show
  }
}

const mostrarModalAdjuntarDocumentos = show => {
  return {
    type: MODAL_ADJUNTAR_DOCUMENTOS,
    show
  }
}

const mostrarModalAddFiltros = show => {
  return {
    type: MODAL_ADD_FILTROS,
    show
  }
}

const mostrarModalDetAdjuntos = show => {
  return {
    type: MODAL_DET_ADJUNTOS,
    show
  }
}

const mostrarModalModAdjuntos = show => {
  return {
    type: MODAL_MOD_ADJUNTOS,
    show
  }
}

const mostrarModalAprobarAdjunto = show => {
  return {
    type: MODAL_APROBAR_ADJUNTO,
    show
  }
}

const mostrarModalNegarAdjunto = show => {
  return {
    type: MODAL_NEGAR_ADJUNTO,
    show
  }
}

const actualizarDataSolicitud = (solicitud = null) => {
  return {
    type: ACT_DATA_SOLICITUD,
    solicitud
  }
}

const actualizarDataConvocatoria = (convocatoria = null) => {
  return {
    type: ACT_DATA_CONVOCATORIA,
    convocatoria
  }
}

const actualizarDataConvocatorias = convocatorias => {
  return {
    type: ACT_DATA_CONVOCATORIAS,
    convocatorias,
  }
}

const actualizarDataInstitucion = (institucion = null) => {
  return {
    type: ACT_DATA_INSTITUCION,
    institucion
  }
}

const actualizarDataInstituciones = instituciones => {
  return {
    type: ACT_DATA_INSTITUCIONES,
    instituciones,
  }
}

const actualizarDataRequisito = (requisito = null) => {
  return {
    type: ACT_DATA_REQUISITO,
    requisito
  }
}

const actualizarDataRequisitos = requisitos => {
  return {
    type: ACT_DATA_REQUISITOS,
    requisitos,
  }
}

const mostrarModalAddFinanciaciones = show => {
  return {
    type: MODAL_AGREGAR_FINANCIACIONES,
    show,
  }
}

const mostrarModalEdiConvenio = show => {
  return {
    type: MODAL_EDI_CONVENIO,
    show
  }
}

const mostrarModalAddPermisosDep = show => {
  return {
    type: MODAL_ADD_PER_DEP,
    show
  }
}

const mostrarModalDetConvInst = show => {
  return {
    type: MODAL_DET_CONV_INST,
    show
  }
}

const mostrarModalSolInst = show => {
  return {
    type: MODAL_SOL_INSTI,
    show
  }
}

const mostrarModalSolRed = show => {
  return {
    type: MODAL_SOL_RED,
    show
  }
}

const mostrarModalEdiIes = show => {
  return {
    type: MODAL_EDI_IES,
    show
  }
}

module.exports = {
  MODAL_DET_SOLICITUD,
  MODAL_GESTIONAR_SOLICITUD,
  MODAL_CANCELAR_SOLICITUD,
  MODAL_ADD_MOV_ESTUDIANTIL,
  MODAL_ADD_CONVENIO,
  MODAL_ADD_REGISTRO,
  MODAL_ADD_CONVOCATORIAS,
  MODAL_MOD_CONVOCATORIAS,
  MODAL_DEL_CONVOCATORIAS,
  MODAL_DET_CONVOCATORIAS,
  MODAL_ADD_INSTITUCIONES,
  MODAL_MOD_INSTITUCIONES,
  MODAL_DEL_INSTITUCIONES,
  MODAL_DET_INSTITUCIONES,
  MODAL_ADD_REQUISITOS,
  MODAL_MOD_REQUISITOS,
  MODAL_DEL_REQUISITOS,
  MODAL_DET_REQUISITOS,
  MODAL_LISTA_UNIVERSIDADES,
  MODAL_LISTA_REQUISITOS,
  MODAL_LISTA_ARCHIVOS,
  MODAL_ADJUNTAR_DOCUMENTOS,
  MODAL_ADD_FILTROS,
  MODAL_DET_ADJUNTOS,
  MODAL_MOD_ADJUNTOS,
  MODAL_APROBAR_ADJUNTO,
  MODAL_NEGAR_ADJUNTO,
  ACT_DATA_SOLICITUD,
  ACT_DATA_CONVOCATORIA,
  ACT_DATA_CONVOCATORIAS,
  ACT_DATA_INSTITUCION,
  ACT_DATA_INSTITUCIONES,
  ACT_DATA_REQUISITO,
  ACT_DATA_REQUISITOS,
  MODAL_AGREGAR_FINANCIACIONES,
  MODAL_EDI_CONVENIO,
  MODAL_ADD_PER_DEP,
  MODAL_DET_CONV_INST,
  MODAL_SOL_INSTI,
  MODAL_SOL_RED,
  MODAL_EDI_IES,
  mostrarModalDetSolicitud,
  mostrarModalGestionarSolicitud,
  mostrarModalCancelarSolicitud,
  mostrarModalAddMovEstudiantil,
  mostrarModalAddConvenio,
  mostrarModalAddRegistro,
  mostrarModalAddConvocatorias,
  mostrarModalModConvocatorias,
  mostrarModalDelConvocatorias,
  mostrarModalDetConvocatorias,
  mostrarModalAddInstituciones,
  mostrarModalModInstituciones,
  mostrarModalDelInstituciones,
  mostrarModalDetInstituciones,
  mostrarModalAddRequisitos,
  mostrarModalModRequisitos,
  mostrarModalDelRequisitos,
  mostrarModalDetRequisitos,
  mostrarModalListaUniversidades,
  mostrarModalListaRequisitos,
  mostrarModalListaArchivos,
  mostrarModalAdjuntarDocumentos,
  mostrarModalAddFiltros,
  mostrarModalDetAdjuntos,
  mostrarModalModAdjuntos,
  mostrarModalAprobarAdjunto,
  mostrarModalNegarAdjunto,
  actualizarDataConvocatoria,
  actualizarDataConvocatorias,
  actualizarDataInstitucion,
  actualizarDataInstituciones,
  actualizarDataRequisito,
  actualizarDataRequisitos,
  mostrarModalAddFinanciaciones,
  mostrarModalEdiConvenio,
  mostrarModalAddPermisosDep,
  mostrarModalDetConvInst,
  mostrarModalSolInst,
  mostrarModalSolRed,
  mostrarModalEdiIes,
}
