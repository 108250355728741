import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { mostrarModulo } from "../../redux/actions/actGlobal";
import { Typography } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Buscar } from "./app/Buscar";
import Menu from './Menu';
import Cargando from "./app/Cargando";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import { coloresEmma, consulta } from "../../global/js/funciones";
import cuc_amarilla from "../../global/imagenes/emmaAmarilla.png";
import PintarEncuestaGenero from './encuesta_genero/PintarEncuestaGenero'


class Inicio extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dato_buscar: '',
      mostrar_encuesta: false,
    }
  }

  componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/', nombre: 'inicio' });
    this.validar_encuesta_genero();
  }

  convertir = dato => typeof dato === 'string' || dato instanceof String ? dato.toLowerCase() : `${dato}`;

  buscar = () => {
    let { dato_buscar } = this.state;
    let { actividades } = this.props;
    let datos = [];
    actividades.map((data) => {
      let existe = data.nombre.includes(this.convertir(dato_buscar));
      if (existe) datos.push(data)
    });
    return datos;
  }

  validar_encuesta_genero = () => {
    let { usuario } = this.props
    if (usuario) {
      consulta('api/v1.0/personas/validar_encuesta_genero', { id_persona: usuario.id }, 'post', (error, estado, resp) => {
        if (estado === 404) {
          if (usuario.perfil.codigo === 'Per_Nor') this.setState({ mostrar_encuesta: true });
        }
      });
    }
  }


  render() {
    let { cargandoMenu, submodulo } = this.props
    return (
      <div className='contenedor_menu' style={{ height: "10hv" }}>
        {cargandoMenu ?
          <Cargando marginTop="100px" />
          :
          <>
            {this.state.mostrar_encuesta ?
              <PintarEncuestaGenero mostrar_encuesta={this.state.mostrar_encuesta} setMostrarEncuesta={(mostrar_encuesta) => this.setState({mostrar_encuesta})} />
              :
              <>
              <img src={cuc_amarilla} alt="Logo emma" style={{ width: "15%", minWidth: "240px", margin: '', padding: '20px 0px 50px 0px' }} />
                <Grid container justifyContent="space-between" alignItems="center" style={{ width: '100%' }} >
                  <Grid item xs={12} sm={12} md={6} lg={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography gutterBottom variant="h6" component="body1" style={{ color: '#000000', paddingRight: '20px' }}>PROCESOS<span style={{ color: coloresEmma.secondarycolor, fontWeight: '700' }}> ACADÉMICOS</span> </Typography>

                  </Grid >
                  <Grid item xs={12} sm={12} md={6} lg={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Buscar dato_buscar={this.state.dato_buscar} setBuscar={(dato_buscar) => this.setState({ dato_buscar })} className='Buscar2' StyleExt={{ marginTop: 30, paddingLeft: '20px' }} />
                  </Grid>
                </Grid>
                <br /><br />
                <div className="Linea"></div>

                {
                  submodulo && (
                    <div className="TituloInicio2" style={{ display: 'flex', alignSelf: 'center' }}>
                      <Link to="/">
                        <ArrowBackIcon style={{ fontSize: '35px', color: coloresEmma.secondarycolor, marginTop: '5px' }} />
                      </Link>
                    </div>
                  )
                }
                <div style={{ paddingTop: '20px', textAlign: "center", height: 'auto', display: 'flex', justifyContent: 'center', }}>
                  <Menu actividades={this.state.dato_buscar ? this.buscar() : this.props.actividades} submodulo={submodulo} />
                </div>
              </>
            }
          </>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let { cargandoMenu, usuario } = state.redGlobal;
  return {
    cargandoMenu,
    usuario
  }
};

const mapDispatchToProps = {
  mostrarModulo
}

Inicio.propTypes = {
  mostrarModulo: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Inicio);