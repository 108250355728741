import React from 'react'
import * as Funciones from '../../../../global/js/funciones';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Tooltip, makeStyles } from '@material-ui/core';
import TareasTerminadas from '../../../general/TareasTerminadas';
import TituloAccion from '../../../general/TituloAccion';
import AccionesRapidas from '../../../general/AccionesRapidas';
import emma_w from '../../../../global/imagenes/emma_w.png';
import FilterListIcon from '@material-ui/icons/FilterList';
import emma_s1 from '../../../../global/imagenes/emma_s1.png';
import { BtnRegresar } from '../../../general/BotonesAccion';
import ListarDatos from '../../../general/ListarDatos';
import moment from 'moment';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CursosList from '../CursosList';
import { useDispatch } from 'react-redux';
import * as Helper from '../Helper';
import AppBarModal from '../../../general/AppBarModal';
import { actualizarMensaje } from '../../../../redux/actions/actGlobal';

const useStyles = makeStyles((theme) => ({
  colorEmmma: {
    color: "#c79e32",
    transition: 'all 0.3s',
    '&:hover': {
      color: "#fff",
      backgroundColor: "#c79e32",
      transform: "scale(1.3)",
    },
  },
  colorDelete: {
    color: "#d11a2a",
    transition: "all 0.3s",
    '&:hover': {
      color: "#fff",
      backgroundColor: "#d11a2a",
      transform: "scale(1.3)",
    },
  },
  colorEdit: {
    color: "#0074C5",
    transition: "all 0.3s",
    '&:hover': {
      color: "#fff",
      backgroundColor: "#0074C5",
      transform: "scale(1.3)",
    },
  },
  colorGestion: {
    color: "#41DD5B",
    transition: "all 0.3s",
    '&:hover': {
      color: "#fff",
      backgroundColor: "#41DD5B",
      transform: "scale(1.3)",
    },
  }
}));

export const AdminListarCursos = (props) => {
  const classes = useStyles();
  const isInitialMount = React.useRef(true);
  const [cursos, setCursos] = React.useState([]);
  const [cargando, setCargando] = React.useState(true)
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalAll, setOpenModalAll] = React.useState(false);
  const [profesor, setProfesor] = React.useState('')
  const [fila, setFila] = React.useState('');
  const [accion, setAccion] = React.useState('Procesos');
  const [solicitudes, setSolicitudes] = React.useState([]);
  const [periodo, setPeriodo] = React.useState('');
  const [modalFilterCard, setModalFilterCard] = React.useState(false);
  const [modalFilterCardAll, setModalFilterCardAll] = React.useState(false);
  const [codigoActually, setCodigoActually] = React.useState('')
  const [pos, setPos] = React.useState('detalle')
  const [openModalInfo, setOpenModalInfo] = React.useState(false);
  const [semiActual, setSemiActual] = React.useState([])
  const [nombre, setNombre] = React.useState('')
  const [identificacion, setIdentificacion] = React.useState('')
  const [idusuario, setIdusuario] = React.useState(0);
  const [periodoactual, setPeriodoActual] = React.useState('');

  React.useEffect(() => {
    //Obtengo los prceosos activos de la persona activa.
    if (isInitialMount.current) {
      isInitialMount.current = false
      const obtenerProcesos = async () => {
        //let data = await getAllSemilleros();
        let periodo = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Cur' }]);
        obtenerEstadoSolicitudes(periodo[0].nombre);
        if (periodo.length > 0) {
          setPeriodo(periodo[0].nombre)
        }
      }
      obtenerProcesos()
      obtenerPeriodo()
    }
  })

const obtenerPeriodo = async () => {
    let periodo = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Cur' }]);
    setPeriodoActual(periodo)
    setPeriodoActual(periodo[0].nombre)
    
}

  const obtenerEstadoSolicitudes = (periodo) => {
    Funciones.consulta(`api/v1.0/cursoformacion/solicitud/estados/${periodo}`, null, null, (error, estado, resp) => {
      if (estado === 200) {
        let { general, extra } = resp
        if (extra.length > 0) {
          extra.map(({ solicitudes, nombre, codigo, orden }) => {
            let reporte = {
              'estado_actual__codigo': codigo,
              'estado_actual__nombre': nombre,
              'num_sol': solicitudes,
              'orden': orden
            }

            general.push(reporte);
          })
        }
        setSolicitudes(general.sort((a, b) => a.orden - b.orden))
        setPeriodo(periodo)
        setCargando(false)
      }
    })
  }

  const obtenerCursos = async (f) => {
    // let f = await Funciones.generarFiltros([{ 'llave': 'usuario_registro', 'valor': props.usuario.id }, { 'llave': 'estado', 'valor': '1' }]);
    return new Promise(resolve => {
      Funciones.consulta(`api/v1.0/cursoformacion/curso?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  const handleClick = () => {
    setCargando(true)
    obtenerEstadoSolicitudes(periodo)
    setAccion("Procesos")
  };

  const PintarLista = (props) => {
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [mostrarCheckboxes, setMostrarCheckboxes] = React.useState(false);
    const [mostrarCheckbox, setMostrarCheckbox] =  React.useState(false);
    const [mostrarbuscar, setMostrarbuscar] =  React.useState(true);
    const [cambiarestado, setcambiarestado]= React.useState(false);

    const actmostrarCheckboxes = () => {
      setMostrarCheckboxes(prevState => !prevState);
      setMostrarCheckbox(prevState => !prevState);
      // setMostrarbuscar(prevState => !prevState);
      setcambiarestado(prevState => !prevState);
    };
    const actcheckbox = () => {
      setSelectedItems((prevSelectedItems) => 
        prevSelectedItems.length === cursos.length ? [] : cursos.map((item) => item.id)
      );
    };
    const acciones = (row) => {
      let detalle
      const eventoDetalle = (row) => {
        console.log(row)
        setFila(row)
        setOpenModal(true)
      }
      const openModalInfo = (row) => {
        setFila(row)
        console.log(row)
        let nombre = row.usuario_registro.primer_nombre + ' ' + row.usuario_registro.segundo_nombre + ' ' + row.usuario_registro.primer_apellido + ' ' + row.usuario_registro.segundo_apellido
        let cedula = row.usuario_registro.identificacion
        setOpenModalInfo(true)
        setIdusuario(row.usuario_registro.id)
        setIdentificacion(cedula)
        setNombre(nombre)
      }
        detalle = (<React.Fragment>
          <IconButton className={classes.colorGestion} onClick={() => eventoDetalle(row)}><VerifiedUserIcon /></IconButton>
          <IconButton className={classes.colorEmmma} onClick={() => openModalInfo(row)}><MenuBookIcon /></IconButton>
        {mostrarCheckboxes && ( 
        <Checkbox
        checked={selectedItems.includes(row.id)}
        onChange={() => handleSelectItem(row.id)}
        color="primary"
        />
        )}
        </React.Fragment>);
      return detalle
    }
    const handleSelectItem = (id) => {
      if (selectedItems.includes(id)) {
        setSelectedItems(selectedItems.filter((item) => item !== id));
      } else {
        setSelectedItems([...selectedItems, id]);
      }
    };
    const actcambiarestado = () => {
      const selectedRows = cursos.filter((item) => selectedItems.includes(item.id));
      if (selectedRows.length > 1 && selectedItems.length > 1) {
        setFila(selectedRows);
        setOpenModal(true);
      } else {
        console.error("No se encontraron filas seleccionadas");
      }
    };
    
    const ListarDatosConCheckbox = ({ datos }) => {
      if (!datos || datos.length === 0) {
        return <div>No hay datos para mostrar</div>;
      }
      return (
        <div>
          {datos.map((item) => (
            <div key={item.id}>
              <Checkbox
                checked={selectedItems.includes(item.id)}
                onChange={() => handleSelectItem(item.id)}
                color="primary"
              />
            </div>
          ))}
        </div>
      );
    };
  
    return (
      <div>
        {cargando ? (
          <TareasTerminadas 
          imagen={emma_w} 
          mensaje={"Cargando..."}
          widthImg="7%"
          marginTop="7%"/>
        ) : (
          <div>
            <ListarDatos
              titulo="Lista de Solicitudes"
              id="tbl_listar_cursos"
              avatar={({ solicitud: { tipo } }) => tipo.nombre.charAt(0)}
              datos={cursos}
              descargar="CURSOS SOLICITUDES - EMMA"
              opciones={true}
              cambiarestado={cambiarestado}
              actcambiarestado={actcambiarestado}
              buscar={mostrarbuscar}
              filtrar={false}
              actfiltrar={() => setModalFilterCardAll(true)}
              checkbox={mostrarCheckbox}
              actcheckbox={actcheckbox}
              actmostrarcheckboxes={actmostrarCheckboxes}
              mostrarcheckboxes={true}
              acciones={(row) => acciones(row)}
              fila_principal={({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.segundo_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`}
              filas={[
                {
                  'mostrar': ({ usuario_registro }) => `${usuario_registro.correo}`,
                  'id': 'Correo de Solicitante', 'enLista': false
                },
                {
                  'mostrar': ({ solicitud: { tipo } }) => `${tipo.nombre}`,
                  'id': 'Tipo de Solicitud', 'enLista': true
                },
                {
                  'mostrar': ({ solicitud: { periodo } }) => `${periodo}`,
                  'id': 'Periodo', 'enLista': false
                },
                {
                  'mostrar': ({ horario }) => `${horario}`,
                  'id': 'Horario', 'enLista': false
                },
                {
                  'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.segundo_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`,
                  'id': 'Solicitante', 'enLista': false
                },
                {
                  'mostrar': ({ usuario_registro }) => `${usuario_registro.identificacion}`,
                  'id': 'Identificación', 'enLista': false
                },
                {
                  'mostrar': ({ programa: { nombre } }) => `${nombre}`,
                  'id': 'Programa', 'enLista': false
                },
                {
                  'mostrar': ({ semestre }) => `${semestre}`,
                  'id': 'Semestre', 'enLista': false
                },
                {
                  'mostrar': ({ usuario_registro: { correo } }) => `${correo}`,
                  'id': 'Correo Institucional', 'enLista': false
                },
                {
                  'mostrar': ({ usuario_registro: { celular } }) => `${celular}`,
                  'id': 'Número telefónico', 'enLista': false
                },
                {
                  'mostrar': ({ semillero }) => semillero !== null ? 'SI' : 'NO',
                  'id': '¿Miembro de un grupo de semillero?', 'enLista': false
                },
                {
                  'mostrar': ({ semillero }) => semillero !== null ? semillero.nombre : 'NO',
                  'id': 'Semillero', 'enLista': false
                },
                {
                  'mostrar': ({ solicitud: { estado_actual } }) => `${estado_actual.nombre}`,
                  'id': 'Estado Actual', 'enLista': true
                },
                {
                  'mostrar': ({ fecha_registro }) => `${moment(fecha_registro).format('YYYY-MM-DD, h:mm a')}`,
                  'id': 'Fecha de Registro', 'enLista': true
                },

                // {
                //     'mostrar': ({ solicitud_semillero_investigacion }) => `${solicitud_semillero_investigacion[0].nombre}`,
                //     'id': 'Nombre Semillero', 'enLista': true
                // },

              ]}
            />   
        </div>
      )}
          {openModal && (
            <ModalChangeStates
              openModal={openModal}
              setOpenModal={setOpenModal}
              row={fila}

              cursos={cursos}
              setCursos={setCursos}

              cargandoList={cargando}
              setCargandoList={setCargando}

              obtenerCursos={obtenerCursos}

              codigoActually={codigoActually}
              periodo={periodo}
            />
          )}

      {openModalInfo && (
        <CursosList
          openModalInfo={openModalInfo}
          setOpenModalInfo={setOpenModalInfo}
          id_solicitud={fila.solicitud.id}
          pos={pos}
          setPos={setPos}
          idusuario={idusuario}
          identificacion={identificacion}
          nombre={nombre}
        />
      )}
    </div>
  );
};

  const PintarInicioProcesos = (props) => {

    const cambiarEstadoProceso = async (codigo) => {
      if (codigo != 'Tod_Bec') {
        setCodigoActually(codigo)
        setCargando(true)
        let filtro = `solicitud__estado_actual__codigo=${codigo}&solicitud__periodo=${periodo}&estado=1`
        let data = await obtenerCursos(filtro);
        console.log(data)
        setCursos(data)
        setAccion('Listar')
        setCargando(false)
      } else {
        setOpenModalAll(true)
      }
    }

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'inscri_proceso_curso': { 'backgroundColor': '#9e9e9e', 'color': color },
        'certificado_curso': { 'backgroundColor': '#ffc107', color },
        'inscrito_curso': { 'backgroundColor': '#009688', color },
        'retirado_curso': { 'backgroundColor': '#b71c1c', color },
        'no_culmi_curso': { 'backgroundColor': '#b71c1c', color },
        'Tod_Bec': { 'backgroundColor': '#0d47a1', color }
      }
      return (colores[codigo])
    }

    return (
      <div>
        {cargando == true ? <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />
          :
          <div>
            <div style={{ padding: '2% 2% 0% 2%' }} >
              <Grid container direction={'row'} spacing={1} justify={'center'} >
                <Grid item xs={12} sm={12} md={12} lg={10} >
                  <Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
                    <Grid item xs={6} sm={6} md={6} lg={6} >
                      <TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} >
                        <Tooltip title='Filtrar' aria-label="add" >
                        <IconButton edge={false} color="secondary" style={{ float: 'right' }} onClick={() => setModalFilterCard(true)}><FilterListIcon /></IconButton>
                        </Tooltip>
                    </Grid>
                  </Grid>
                  <Paper elevation={0} square className="scroll contenido_fijo">
                    {(solicitudes.length === 0) ?
                      <TareasTerminadas mensaje='Aún no hay solicitudes para este periodo.' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
                      <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                        {solicitudes.map(({ estado_actual__codigo: codigo, estado_actual__nombre: nombre, num_sol }, i) => {
                          return (
                            <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                              <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => cambiarEstadoProceso(codigo)}>
                                <AccionesRapidas imagen='' titulo={`¡ ${nombre} !`} sub_titulo='' des_sub_titulo='' avatar={obtenerColor(codigo)} avatar_name={nombre.charAt(0).toUpperCase()} list_desc={Number.isInteger(num_sol) ? [`Numero de Solicitudes : ${num_sol}`] : ['Debe aplicar los filtros.']} />
                              </Paper>
                            </Grid>
                          )
                        })}
                      </Grid>
                    }
                  </Paper>
                </Grid>
              </Grid>
            </div>

          </div>}
      </div>
    )
  }


  return (
    <div>
      {
        !cargando ?
          <div>
            {accion == 'Procesos' ? <PintarInicioProcesos /> : <PintarLista />}
            {accion != 'Procesos' && <div className={'regresar'} ><BtnRegresar callback={handleClick} /></div>}
          </div>
          : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
      }
      {modalFilterCard == true ? 
      <ModalChangeFilterPeriodo
          modalFilterCard = {modalFilterCard}
          setModalFilterCard = {setModalFilterCard}
          obtenerEstadoSolicitudes={obtenerEstadoSolicitudes}
          cargando={cargando}
          setCargando={setCargando}
          periodo_generica={periodo}

      /> : ''}

    </div>
  )
}
const ModalChangeStates = function (props) {
  const isInitialMount = React.useRef(true);
  const [cargando, setCargando] = React.useState(true)
  const [estadosSig, setEstadosSig] = React.useState('')
  const [checked, setChecked] = React.useState([])
  const dispatch = useDispatch();
  let array = []
  React.useEffect( () => {
      //Obtengo los prceosos activos de la persona activa.
      if(isInitialMount.current){
          isInitialMount.current = false
          obtenerEstados()
      }
  })

  const obtenerEstados = async () => {
    console.log("Contenido de props.row:", props.row);
    
    if (!Array.isArray(props.row)) {
        // Maneja el caso de una sola solicitud
        if (!props.row || !props.row.solicitud || !props.row.solicitud.tipo) {
            console.error("props.row no tiene la estructura esperada");
            return; 
        }
        let estados_siguiente = await Funciones.obtenerEstadosProceso(
            props.row.solicitud.tipo.id,[{ 'llave': 'principal', 'valor': props.row.solicitud.tipo.id },{ 'llave': 'secundario__generica', 'valor': Helper.ESTADOS_CURSOS }]
        );
        setEstadosSig(estados_siguiente);
    } else if (props.row.length > 0) {
        // Maneja el caso de múltiples solicitudes
        const estadosUnicos = new Map(); // Map para evitar que se dupliquen los estados
        for (const item of props.row) {
            if (!item.solicitud || !item.solicitud.tipo) {
                console.error("Elemento no tiene la estructura esperada:", item);
                continue; 
            }
            let estados_siguiente = await Funciones.obtenerEstadosProceso(item.solicitud.tipo.id,[{ 'llave': 'principal', 'valor': item.solicitud.tipo.id },{ 'llave': 'secundario__generica', 'valor': Helper.ESTADOS_CURSOS }]
            );
            estados_siguiente.forEach(estado => {
                if (estado.id) { estadosUnicos.set(estado.id, estado);}
            });
        }
        // Convertir el Map a un array antes de establecer el estado
        setEstadosSig(Array.from(estadosUnicos.values())); 
    } else {
        console.error("props.row no es un array válido");
        return; 
    }
    setCargando(false);
};
  const handleToggle = (value) =>{
      const seleccionado = checked.indexOf(value);
      if (seleccionado === -1) array.push(value);
      else array.splice(seleccionado, 1);
      
      setChecked(array)
  }

  const acciones = (data) => {
      const detalle = (
        <Checkbox edge="end" onChange={() => handleToggle(data.secundario.id)} checked={checked.indexOf(data.secundario.id) !== -1} inputProps={{ 'aria-labelledby': data.secundario.id }}/>
        );
      return detalle;
  };
  const sendChangeStatToApi = async () => {
    if (checked.length == 0) {
        alert("Elija una opción");
    } else {
        props.setCargandoList(true);
        setCargando(true);

        const promises = checked.map((estado) => {
            let data = {
                estado: estado,
                solicitud: props.row
            };
            let url = "api/v1.0/cursoformacion/cambiarEstadoAdm";
            return new Promise((resolve, reject) => {
                const callback = (error, estado, resp) => {
                    let titulo = "Ha ocurrido un error, contacte con el administrador.";
                    let tipo = "error";

                    if (!error) {
                        if (estado === 201) {
                            tipo = "success";
                            titulo = resp.titulo;
                        }
                    }
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    resolve();  
                };
                Funciones.consulta(url, data, 'POST', callback);
            });
        });
        // Esperar a que todas las promesas se resuelvan
        await Promise.all(promises);

        let filtro = `solicitud__estado_actual__codigo=${props.codigoActually}&solicitud__periodo=${props.periodo}&estado=1`;
        let data = await props.obtenerCursos(filtro);
        props.setCursos(data);
        setCargando(false);
        props.setCargandoList(false);
        props.setOpenModal(false);
    }
};
  return(
      <div>
          <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModal} onClose={() => props.setOpenModal(false)}>
              <AppBarModal titulo={'¡ Cambiar Estado !'} mostrarModal={() => props.setOpenModal(false)} titulo_accion="" accion="" />
              <DialogContent>
              {cargando == true ? <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> :
                  <ListarDatos
                      id="tbl_listar_estados_Adm"
                      //avatar={({ nombre }) => nombre.charAt(0)}
                      datos={estadosSig}
                      opciones={true}
                      buscar={true}
                      acciones={(row) => acciones(row)}
                      titulo={"Lista de Semilleros de Investigación"}
                      fila_principal={({ secundario }) => `${secundario.nombre}`}
                      filas={[]}
                  />
              }
              </DialogContent>
              <DialogActions>
                  <Button disabled={cargando == true} variant="contained" color="primary" style={{color: "#fff"}} onClick={sendChangeStatToApi}>
                      Enviar
                  </Button>
              </DialogActions>
          </Dialog>
      </div>
  )
}
const ModalChangeFilterPeriodo = (props) => {

  const isInitialMount = React.useRef(true);
  const [periodo_filtro, setPeriodo_filtro] = React.useState([])
  const [periodo_value, setPeriodo_value] = React.useState('')
  const [cargando, setCargando] = React.useState(true)

  React.useEffect( () => {
      //Obtengo los prceosos activos de la persona activa.
      if(isInitialMount.current){
          isInitialMount.current = false
          const peticion = async () => {
              let data = await obtenerPeriodos()
              console.log(data);
              if(!(data.find(e => e.periodo == props.periodo_generica))) data.push({'periodo': props.periodo_generica})
              setPeriodo_filtro(data)
              setCargando(false)
          }
          peticion()
      }
  })

  const obtenerPeriodos = async () => {
      return new Promise(resolve => {
          Funciones.consulta(`api/v1.0/cursoformacion/periodos`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
          })
      })
  }

  const onChangeFiltro = (event) =>{
      setPeriodo_value(event.target.value);
  }

  const onclickFriltro = (event) => {
      if(periodo_value == ""){
          alert("Seleccione una opcion")
      }else{
          props.setCargando(true)
          props.obtenerEstadoSolicitudes(periodo_value)
          props.setModalFilterCard(false)
      }
  }

  return (
      <Dialog fullWidth={true} open={props.modalFilterCard} onClose={() => props.setModalFilterCard(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => props.setModalFilterCard(false)} titulo_accion="" accion="" />
              <DialogContent className='scroll'>
                  {!cargando ?
                  <Grid container className='margin_cont' >
                      <Grid item xs={12}>
                      <InputLabel htmlFor="age-native-simple">Periodos disponibles</InputLabel>
                      <FormControl className='form-control'>
                          <InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
                          <Select
                          value={periodo_value}
                          onChange={onChangeFiltro}
                          required={true}>
                          {periodo_filtro.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                          </Select>
                      </FormControl>
                      </Grid>
                  </Grid>
                  : <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' />
                      }
              </DialogContent>
              <DialogActions>
                  <Button disabled={cargando == true} variant="contained" color="primary" style={{color: "#fff"}} onClick={() => onclickFriltro()}>
                      Filtrar
                  </Button>
              </DialogActions>
      </Dialog >
  )
}
