import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// Material UI
import {
  Grid,
  Typography,
  FormControl,
  FormLabel,
  Button,
  Box,
  AppBar,
  Toolbar,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

// Funciones globales
import {
  consulta,
  mostrarError,
  obtenerPermisosValor,
  obtenerPermisosValorAlt,
} from "../../../../../global/js/funciones";
import TareasTerminadas from "../../../../general/TareasTerminadas";
import { actualizarMensaje } from "../../../../../redux/actions/actGlobal";

// Componentes locales
import PintarRespuestas from "./PintarRespuestas";

// Imagenes
import emma_w from "../../../../../global/imagenes/emma_w.png";
import logo_mineducacion from "../../../../../global/imagenes/logo-mineducación.png";
import logo_observatorio_laboral from "../../../../../global/imagenes/logo-observatorio-laboral-para-la-educacion.png";
import ConfirmarAccion from "../../../../general/ConfirmarAccion";
import {
  OPCION_OTRO_ENCUESTA_GRADUADOS,
  RESPUESTAS_ENCUESTA_GRADUADOS,
} from "../../helper";
import { coloresEmma } from "../../../../../global/js/funciones";
const useStyles = makeStyles((theme) => ({
  fixedColumn: {
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    width: "25%",
    boxShadow: "0 0 20px 0 rgba(0,0,0,0.1)",
    padding: "90px 30px",
  },
}));

const PintarEncuesta = ({ pasos, programa, pasoActualPrograma }) => {
  const [pasoActual, setPasoActual] = useState(pasoActualPrograma ?? 0);
  const [preguntas, setPreguntas] = useState([]);
  const [respuestas, setRespuestas] = useState([]);
  const [confirmar, setConfirmar] = useState(false);
  const [terminado, setTerminado] = useState(false);
  const [cargando, setCargando] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      if (pasos.length > 0) {
        setCargando(true);

        let respuestasAcumuladas = [];
        let preguntasAcumuladas = [];

        const obtenerRespuestasprograma = await obtenerPermisosValorAlt([
          {
            llave: "principal",
            valor: programa,
          },
          {
            llave: "secundario__generica",
            valor: RESPUESTAS_ENCUESTA_GRADUADOS,
          },
        ]);

        obtenerRespuestasprograma.sort((a, b) =>
          parseInt(a.secundario.valorb) > parseInt(b.secundario.valorb) ? 1 : -1
        );

        for (const { value } of pasos) {
          let preguntasResp = await obtenerPermisosValor(value);
          preguntasResp.sort((a, b) =>
            parseInt(a.secundario.valorc) > parseInt(b.secundario.valorc)
              ? 1
              : -1
          );

          let respuestasTemp = [];
          for (const { secundario } of preguntasResp) {
            let respuestasConsulta = await obtenerPermisosValor(secundario.id);
            respuestasConsulta.sort((a, b) =>
              parseInt(a.secundario.valorb) > parseInt(b.secundario.valorb)
                ? 1
                : -1
            );
            respuestasTemp.push(transformarRespuestas(respuestasConsulta));
          }

          respuestasAcumuladas.push(respuestasTemp);
          preguntasAcumuladas.push(trasnformarPreguntas(preguntasResp, value));
        }

        if (obtenerRespuestasprograma.length > 0) {
          respuestasAcumuladas[respuestasAcumuladas.length - 1][8] =
            transformarRespuestas(obtenerRespuestasprograma);
        } else {
          preguntasAcumuladas[preguntasAcumuladas.length - 1].splice(5, 1);
          respuestasAcumuladas[respuestasAcumuladas.length - 1].splice(5, 1);
        }

        let { estado, encuesta } = await obtenerRespuestas(programa);
        /* Verifar en los subniveles de preguntasAcumuladas si hay respuestas que coincidan
         * con las respuestas obtenidas, eso con el atributo id de preguntasAcumuladas y con
         * el atributo pregunta de obtenerRespuestas.
         * Si hay coincidencias, actualizar el valor de la respuesta de preguntasAcumuladas con el valor de obtenerRespuestas
         */
        if (estado === 200) {
          preguntasAcumuladas.forEach((preguntas, index) => {
            preguntas.forEach((pregunta) => {
              let respuestaObtenida = encuesta.find(
                (item) => item.pregunta === pregunta.id
              );
              if (respuestaObtenida) {
                pregunta.valor = respuestaObtenida.valor;
              }
            });
          });
        }

        setRespuestas((prevRespuestas) => [
          ...prevRespuestas,
          ...respuestasAcumuladas,
        ]);
        setPreguntas((prevPreguntas) => [
          ...prevPreguntas,
          ...preguntasAcumuladas,
        ]);
      }
    } catch (error) {
      dispatch(
        actualizarMensaje({
          titulo: "Ha ocurrido un error, contacte con el administrador.",
          tipo: "warning",
          mostrar: true,
          tiempo: 4000,
        })
      );
    } finally {
      setCargando(false);
    }
  };

  const changeStep = async (step) => {
    let { sw, items } = validarRespuestas();
    // TODO: Validar que los paso decrementen incluso cuando no se haya respondido la pregunta "SW = false" y se haya presionado el botón atras "step = false"
    if (sw) {
      let pasoActualA = step ? pasoActual + 1 : pasoActual - 1;
      if (pasoActualA >= 0 && pasoActualA < pasos.length) {
        // TODO: Refactorizar este bloque de código y aplicar validación para comprabar que el el array de preguntas haya sido cambiado con respecto a la copia
        if (step) {
          await guardarEncuesta();
        }
        setPasoActual(pasoActualA);
      } else {
        setConfirmar(true);
      }
    } else {
      dispatch(
        actualizarMensaje({
          titulo: `Debe responder las preguntas ${items.join(", ")}`,
          tipo: "info",
          mostrar: true,
          tiempo: 4000,
        })
      );
    }
  };

  const guardarEncuesta = async () => {
    let titulo = "Ha ocurrido un error, contacte con el administrador.";
    let tipo = "warning";
    let isFinal = pasoActual === pasos.length - 1;
    setCargando(true);
    setConfirmar(false);
    consulta(
      `api/v1.0/matriculas/encuesta_graduados/crear`,
      {
        formatPresuntas: preguntas[pasoActual],
        programa,
        paso: pasoActual + 1,
        isFinal,
      },
      "POST",
      (error, estado, resp) => {
        if (!error) {
          if (estado === 201) {
            tipo = "success";
            titulo = resp.titulo;
            if (isFinal) {
              setTerminado(true);
            }
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        dispatch(
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        );
        setCargando(false);
      }
    );
  };

  const obtenerRespuestas = async (programaId) => {
    return new Promise((resolve, reject) => {
      consulta(
        `api/v1.0/matriculas/encuesta_graduados/preguntas/${programaId}`,
        null,
        null,
        (error, estado, resp) => {
          if (estado === 200) {
            resolve(resp);
          } else {
            reject(error);
          }
        }
      );
    });
  };

  const trasnformarPreguntas = (datos, paso) => {
    let preguntas = [];
    datos.map(({ secundario }) => {
      let { id, nombre, valora, valorb, valord } = secundario;
      preguntas.push({
        id: id,
        nombre: `${nombre}`,
        tipo: `${valora}`,
        valor: [],
        obligatorio: valorb,
        condicion: valord,
        paso,
      });
      return true;
    });
    return preguntas;
  };

  const transformarRespuestas = (datos) => {
    let respuestas = [];
    datos.map(({ secundario }) => {
      let { id, nombre, valora } = secundario;
      respuestas.push({
        id: id,
        nombre: `${nombre}`,
        codigo: valora,
      });
      return true;
    });
    return respuestas;
  };

  const handleOnChange = (pregunta, e, respuestaId) => {
    let preguntasActualizadas = [...preguntas];

    const preguntaActual = preguntasActualizadas[pasoActual].find(
      (item) => item.id === pregunta.id
    );

    if (
      (preguntaActual && preguntaActual.tipo === "1") ||
      preguntaActual.tipo === "3"
    ) {
      const nuevoValor =
        preguntaActual.tipo === "1" ? e.target.value : e.target.checked;
      const respuestaExistenteIndex = preguntaActual.valor.findIndex(
        (item) => item.id === respuestaId.id
      );

      // Crear la nueva respuesta
      const nuevaRespuesta = {
        id: respuestaId.id,
        valor: nuevoValor,
        validar: nuevoValor,
        codigo: respuestaId.codigo ?? null,
        tipo: 1,
      };

      if (respuestaExistenteIndex !== -1) {
        preguntaActual.valor[respuestaExistenteIndex] = nuevaRespuesta;
      } else {
        preguntaActual.valor.push(nuevaRespuesta);
      }
    } else {
      const nuevoValor = {
        id: parseInt(e?.id),
        valor: respuestaId ?? null,
        validar: e.codigo && true,
        codigo: e.codigo ?? null,
        tipo: 2,
      };
      preguntaActual.valor = [nuevoValor];
    }

    preguntasActualizadas[pasoActual] = [...preguntasActualizadas[pasoActual]];
    setPreguntas(preguntasActualizadas);
  };

  const validarRespuestas = () => {
    let sw = true;
    let items = [];
    preguntas[pasoActual].forEach((pregunta, index) => {
      if (pregunta.obligatorio === "1") {
        if (pregunta.tipo === "1") {
          if (respuestas[pasoActual][index].length !== pregunta.valor.length) {
            sw = false;
            items.push(index + 1);
          }
        } else if (pregunta.tipo === "3") {
          if (
            !pregunta.valor.some((item) => item.validar && item.valor === true)
          ) {
            sw = false;
            items.push(index + 1);
          }
        } else {
          if (pregunta.valor[0]?.id === OPCION_OTRO_ENCUESTA_GRADUADOS) {
            if (!pregunta.valor[0].valor) {
              sw = false;
              items.push(index + 1);
            }
          } else if (pregunta.valor.length === 0) {
            sw = false;
            items.push(index + 1);
          }
        }
      }
    });
    return { sw, items };
  };

  // Este es el bueno
  const validarCondicion = (pregunta) => {
    if (!pregunta.condicion) return true;

    const respuestasValidas = pregunta.condicion.split(",");
    let esValido = true;

    preguntas[pasoActual].forEach((preguntaItem) => {
      if (preguntaItem.id === pregunta.id) return;

      const tieneValorVerdadero = preguntaItem.valor.some(
        (item) => item.codigo && item.validar
      );
      if (!tieneValorVerdadero) return;

      const cumpleCondicion = preguntaItem.valor.some(
        (item) =>
          item.validar &&
          item.codigo
            .split(",")
            .some((codigo) => respuestasValidas.includes(codigo))
      );
      if (!cumpleCondicion) {
        esValido = false;
        pregunta.obligatorio = "0";
        pregunta.valor = [];
      } else {
        if (esValido) pregunta.obligatorio = "1";
      }
    });

    return esValido;
  };

  return (
    <>
      <ConfirmarAccion
        titulo="¿Enviar datos?"
        ejecutarAccion={guardarEncuesta}
        mostrarModalConfAccion={() => setConfirmar(false)}
        modalConAccion={confirmar}
        dato={{ id: 0 }}
      />

      {!cargando ? (
        !terminado ? (
          <Grid
            container
            direction={"row"}
            alignItems="center"
            justifyContent="space-evenly"
          >
            {isMobile ? (
              <>
                <Grid item xs={12} sm={12} md={12}>
                  <AppBar position="fixed" elevation={0}>
                    <Toolbar>
                      <div
                        style={{
                          padding: "5px 0px 5px 3px",
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        <p>
                          <span
                            className="step_pers"
                            style={{
                              backgroundColor: "white",
                              color: coloresEmma.secondarycolor,
                            }}
                          >
                            {pasoActual + 1}
                          </span>
                          <span style={{ marginLeft: "10px" }}>
                            {pasos[pasoActual].label}
                          </span>
                        </p>
                      </div>
                    </Toolbar>
                  </AppBar>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className="contenido_encuesta"
                >
                  {preguntas[pasoActual]?.map((pregunta, index) => (
                    <FormControl
                      key={pregunta.id}
                      component="fieldset"
                      style={{ margin: "0 30px 40px 30px" }}
                    >
                      <FormLabel
                        style={{
                          textAlign: "justify",
                          margin: "0 0 15px 0",
                          color: "#000",
                        }}
                      >
                        {`${index + 1}) ${pregunta.nombre} ${
                          pregunta.obligatorio === "1" ? "*" : ""
                        }`}
                      </FormLabel>
                      <PintarRespuestas
                        key={pregunta.id}
                        respuestas={respuestas[pasoActual][index]}
                        pregunta={pregunta}
                        handleChange={handleOnChange}
                      />
                    </FormControl>
                  ))}
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={3}>
                  <Box className={classes.fixedColumn}>
                    <Typography variant="subtitle1">
                      EMMA ENCUESTA DE GRADUADOS -OLE
                    </Typography>
                    <Typography
                      variant="h4"
                      fontSize={8}
                      style={{ marginTop: "10px" }}
                    >
                      {`${pasos[pasoActual].label}`}
                    </Typography>
                    <Grid
                      container
                      style={{
                        margin: "0 auto",
                        width: "100%",
                        paddingTop: "90%",
                      }}
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={logo_mineducacion}
                          alt="Ministerio de Educación Nacional"
                          style={{ width: "100%", padding: "20px 0" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: "normal",
                            fontSize: "8px",
                            textAlign: "center",
                            padding: "20px 0",
                          }}
                        >
                          ENCUESTA DE SEGUIMIENTO A GRADUADOS DE EDUCACIÓN
                          SUPERIOR EN EL MOMENTO 0
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={logo_observatorio_laboral}
                          alt="Observatorio Laboral para la Educación"
                          style={{ width: "100%", padding: "20px 0" }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{ backgroundColor: "#fbfafb", right: "0" }}
                >
                  <Box px={10} py={3}>
                    {preguntas[pasoActual]?.map(
                      (pregunta, index) =>
                        validarCondicion(pregunta) && (
                          <FormControl
                            key={pregunta.id}
                            component="fieldset"
                            style={{
                              width: "100%",
                            }}
                          >
                            <FormLabel
                              style={{
                                textAlign: "justify",
                                margin: "30px 0 18px 0",
                                color: "#000",
                              }}
                            >
                              {`${index + 1}) ${pregunta.nombre} ${
                                pregunta.obligatorio === "1" ? "*" : ""
                              }`}
                            </FormLabel>
                            <PintarRespuestas
                              key={pregunta.id}
                              respuestas={respuestas[pasoActual][index]}
                              pregunta={pregunta}
                              handleChange={handleOnChange}
                            />
                          </FormControl>
                        )
                    )}
                  </Box>
                </Grid>
              </>
            )}
            <AppBar
              position="fixed"
              color="primary"
              style={{ top: "auto", bottom: 0, backgroundColor: "#fff" }}
            >
              <Toolbar>
                <div>
                  <Button
                    disabled={pasoActual === 0}
                    onClick={() => changeStep(false)}
                    style={{ marginRight: "20px" }}
                  >
                    Atras
                  </Button>
                  <Button
                    style={{ felxGrow: 1 }}
                    color="primary"
                    variant="contained"
                    onClick={() => changeStep(true)}
                  >
                    {pasoActual === pasos.length - 1 ? "Terminar" : "Siguiente"}
                  </Button>
                </div>
              </Toolbar>
            </AppBar>
          </Grid>
        ) : (
          <TareasTerminadas
            mensaje="Hola, los datos se han enviado con éxito. Muchas gracias por tu tiempo. Puedes continuar con tu proceso de solicitud de grado"
            imagen={emma_w}
            widthImg="7%"
            marginTop="7%"
          />
        )
      ) : (
        <TareasTerminadas
          mensaje={"Cargando.."}
          marginTop="7%"
          imagen={emma_w}
          widthImg="7%"
          cargando={true}
        />
      )}
    </>
  );
};

export default PintarEncuesta;
