import React from 'react';
import { Dialog, DialogActions, DialogContent, FormControl, FormHelperText,
  Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import AppBarModal from "../../general/AppBarModal";
import { BtnForm } from "../../general/BotonesAccion";
import { getError } from '../../../global/js/funciones';

export default function ParticipanteExtCrear(component_props) {
  let { participante_externo: { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, tipo_identificacion,
    numero_identificacion, nacionalidad, correo, indicativo_celular, celular }, modal, tipos_identificacion,
    mostrar_modal, onChange, guardarDatosParticipanteExterno, errores } = component_props;
  return(
    // <Dialog open={this.props.modalAddFinanciaciones} onClose={()=>this.props.mostrarModalAddFinanciaciones(false)} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm"></Dialog>
    // <Dialog fullWidth = { true } maxWidth = "sm" open = { modal } onClose = { () => mostrar_modal(false) }>
    <Dialog fullWidth = { true } maxWidth = "sm" open = { modal } onClose = { () => mostrar_modal(false) }>
      <AppBarModal titulo = {'¡ Agregar Persona Externa !'} mostrarModal = { () => mostrar_modal(false) } titulo_accion = "CERRAR" accion = { () => mostrar_modal(false) } />
      <DialogContent className = 'scroll'>
        <Grid container spacing = { 3 }>
          <Grid item xs = { 6 }>
            <TextField
              value = { primer_nombre }
              required
              error = { getError('primer_nombre', errores).length > 0 }
              helperText = { getError('primer_nombre', errores) }
              id = 'primer_nombre'
              name = 'primer_nombre'
              label = 'Primer nombre'
              fullWidth
              onChange = { onChange }
            />
          </Grid>
          <Grid item xs = { 6 }>
            <TextField
              value = { segundo_nombre }
              id = 'segundo_nombre'
              name = 'segundo_nombre'
              label = 'Segundo nombre'
              fullWidth
              onChange = { onChange }
            />
          </Grid>
          <Grid item xs = { 6 }>
            <TextField
              value = { primer_apellido }
              required
              error = { getError('primer_apellido', errores).length > 0 }
              helperText = { getError('primer_apellido', errores) }
              id = 'primer_apellido'
              name = 'primer_apellido'
              label = 'Primer apellido'
              fullWidth
              onChange = { onChange }
            />
          </Grid>
          <Grid item xs = { 6 }>
            <TextField
              value = { segundo_apellido }
              // required
              // error = { getError('segundo_apellido', errores).length > 0 }
              // helperText = { getError('segundo_apellido', errores) }
              id = 'segundo_apellido'
              name = 'segundo_apellido'
              label = 'Segundo apellido'
              fullWidth
              onChange = { onChange }
            />
          </Grid>
          <Grid item xs = { 6 }>
            <FormControl className = 'form-control' required error = { getError('tipo_identificacion', errores).length > 0 }>
              <InputLabel id = 'tipo_identificacion_label'>Tipo de identificación</InputLabel>
              <Select
                labelId = 'tipo_identificacion_label'
                id = 'tipo_identificacion'
                name = 'tipo_identificacion'
                value = { tipo_identificacion }
                label = 'Tipo de identificación'
                fullWidth
                onChange = { onChange }
              >
                {
                  tipos_identificacion
                  .filter(({ valorg }) => valorg == '1')
                  .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                  .map(({ id, nombre }) => <MenuItem key = { id } value = { id }>{ nombre }</MenuItem>)
                }
              </Select>
              <FormHelperText>{ getError('tipo_identificacion', errores) }</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs = { 6 }>
            <TextField
              value = { numero_identificacion }
              required
              // type = 'number'
              error = { getError('numero_identificacion', errores).length > 0 }
              helperText = { getError('numero_identificacion', errores) }
              id = 'numero_identificacion'
              name = 'numero_identificacion'
              label = 'Número de identificación'
              fullWidth
              onChange = { onChange }
            />
          </Grid>
          <Grid item xs = { 6 }>
            <TextField
              value = { nacionalidad }
              required
              error = { getError('nacionalidad', errores).length > 0 }
              helperText = { getError('nacionalidad', errores) }
              id = 'nacionalidad'
              name = 'nacionalidad'
              label = 'Nacionalidad'
              fullWidth
              onChange = { onChange }
            />
          </Grid>
          <Grid item xs = { 6 }>
            <TextField
              value = { correo }
              // required
              // error = { getError('correo', errores).length > 0 }
              // helperText = { getError('correo', errores) }
              id = 'correo'
              name = 'correo'
              label = 'Correo'
              fullWidth
              onChange = { onChange }
            />
          </Grid>
          <Grid item xs = { 6 }>
            <TextField // se agrego (indicativo_celular)
              value = { indicativo_celular }
              type = 'number'
              id = 'indicativo_celular'
              name = 'indicativo_celular'
              label = 'Indicativo celular'
              placeholder='Ej: 57'
              fullWidth
              onChange = { onChange }
            />
          </Grid>
          <Grid item xs = { 6 }>
            <TextField // se agrego (celular)
              value = { celular }
              type = 'number'
              id = 'celular'
              name = 'celular'
              label = 'Celular'
              fullWidth
              onChange = { onChange }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <BtnForm texto = "GUARDAR" callback = { () => guardarDatosParticipanteExterno() } />
      </DialogActions>
    </Dialog>
  )
}
